// exportToDOCX.js
import pptxgen from 'pptxgenjs';

export const mindMapsExport = (map) => {
    const pres = new pptxgen();
  
    // Function to handle creating slides for main topics and their linked topics recursively
    const handleTopic = (presentation, topic) => {
    const slide = presentation.addSlide(); //creating a slide

    let textObj = [ //adding a topic and resume
        {text: topic.topic, options: {fontSize: 24, bold: true, align: "center", paraSpaceAfter: 10, breakLine: true}},
        {text: topic.resume, options: {fontSize: 12, italic: true, align: "center", fontFace: "Helvetica, Arial", color: "#555555"}},
    ]

    //bullet points
    topic.linkedTopics.forEach((linkedTopic) => {
        textObj.push({
            text: linkedTopic.topic, 
            options: { //taken from presentation
                bullet: true, 
                fontSize: 14, 
                fontFace: "Helvetica, Arial", 
                color: "#555555",
                breakLine: true
            }
        })
    });
    //adding all content to slide
    slide.addText(textObj, {x: 1, y: 1.5, w: 8, h: 2});

    topic.linkedTopics.forEach((linkedTopic) => {
        if (linkedTopic.linkedTopics && linkedTopic.linkedTopics.length > 0) {
            handleTopic(presentation, linkedTopic); // Recursively handling nested topics
        }
    });
    
    };
  
    //creating slides for each main topic and its linked topics
    map.topics.forEach((topic) => {
      handleTopic(pres, topic);
    });
  
    pres.writeFile(`mindmap_presentation_${new Date().toISOString()}.pptx`);
};