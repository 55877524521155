import React, { useState, useContext } from 'react';
import UserContext from '../components/User_Context';
import { useCookies } from 'react-cookie';
import * as labels from "../components/localized/labels";
import Modal from 'react-bootstrap/Modal';

function CookieConsent() {
  const [cookies, setCookie] = useCookies(localStorage.getItem("cookieConsent"));
  const [cookiesThirdParty, setThirdPartyCookie] = useCookies(localStorage.getItem("thirdPartyCookies"));
  const [isVisible, setIsVisible] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [thirdPartyCookies, setThirdPartyCookies] = useState(true);
  let { user } = useContext(UserContext);
  if (user) {
    user = JSON.parse(user);
  }
  const handleAccept = () => {
    if (!thirdPartyCookies) {
      setCookie("cookieConsent", "true", { path: "/", maxAge: 3600 * 24 * 365 });
      localStorage.setItem("cookieConsent", cookies);
    }
    else if (thirdPartyCookies) {
      setCookie("cookieConsent", "true", { path: "/", maxAge: 3600 * 24 * 365 });
      localStorage.setItem("cookieConsent", cookies);
      setThirdPartyCookie("thirdPartyCookies", "true", { path: "/", maxAge: 3600 * 24 * 365 });
      localStorage.setItem("thirdPartyCookies", cookies);
    }
    setIsVisible(false);
  };

  const handleDecline = () => {
    setCookie("cookieConsent", "false", { path: "/", maxAge: 3600 * 24 * 365 });
    localStorage.setItem("cookieConsent", cookies);
    setIsVisible(false);

  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (!isVisible) {
    return null;
  }
  if (!cookies.cookieConsent && !user?.printMode) { //checking whether is set to consented or not
    return (<>
      <Modal show={isVisible} dialogClassName="modal-dialog-centered">
        <Modal.Body >
          <h2>{labels.getText()["COOKIE_POLICY_WELCOME"]()}</h2>
          {labels.getText()["COOKIE_POLICY_TEXT"]()}
          <button className="gdpr-toggle-more Text700__5" onClick={toggleShowMore}>
            {!showMore ? (labels.getText()["SHOW_MORE"]()) : (labels.getText()["SHOW_LESS"]())}{showMore ? "▲" : "▼"}
          </button>

          {showMore && (
            labels.getText()["COOKIE_POLICY_TABLE"]()
          )}
          <p className='Text500__5' style={{ textAlign: 'justify' }}>
            {labels.getText()["COOKIE_POLICY_CONTACT"]()}
          </p>

          <div className='d-flex flex-row justify-between Text500__5' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'80%'}}>
            <p>{labels.getText()["NECESSARY_COOKIES"]()}
            </p>
            <label class="switchCookie">
              <input type="checkbox"
                 disabled={true} checked={true}style={{color:'grey'}}/>
              <span class="slider round"></span>
            </label>
          </div>
          <div className='d-flex flex-row justify-between Text500__5' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'80%'}}>
            <p>{labels.getText()["THIRD_PARTY_COOKIES"]()}
            </p>
            <label class="switchCookie">
              <input key={thirdPartyCookies} type="checkbox" onClick={e => {
                if (!thirdPartyCookies) {
                  setThirdPartyCookies(true)
                }
                else if (thirdPartyCookies) {
                  setThirdPartyCookies(false);
                }
              }} checked={thirdPartyCookies} />
              <span class="slider round"></span>
            </label>
          </div>
          <div className="gdpr-modal-buttons">
            <button onClick={handleAccept} className="then-small-button">
              {labels.getText()["I_UNDERSTAND"]()}
            </button>
          </div>

          <a href="/privacy_policy" className="gdpr-link Text700__5">
            {labels.getText()["LEARN_MORE"]()}
          </a>
        </Modal.Body>
      </Modal>
    </>);
  }
};

export default CookieConsent;