import { useEffect } from 'react';

function Language_Default_Screen(params) {
    useEffect(() => {
        if (localStorage.getItem("preferred.language")) {
            window.location.href = `/${localStorage.getItem("preferred.language")}`;
        } else {
            let _language = (window.navigator.language ?? "en").split("-")[0];

            switch (_language) {
                case "en":
                case "it":
                case "es":
                case "fr":
                case "lt": {
                    window.location.href = `/${_language}`;
                }
                    break;
                default: {
                    window.location.href = `/en`;
                }
            }
        }
    }, []);
}

export default Language_Default_Screen;
