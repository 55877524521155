import React from "react";
import styles from "./card.module.css";

const Card = ({ icon, stickerLabel, text, link, buttonLabel }) => {
    return (
        <div className={styles.card}>
          <div className={styles.cardBadge}>
            <div className={styles.topCard}>
              <div className={styles.topCardDiv}>
                <div className={styles.cardIconDiv}>
                  <img className={styles.cardIcon} alt='robot' src={icon}></img>
                  <span className={styles.sticker}>
                  {stickerLabel}
                </span>
                </div>
              </div>
            </div>
          </div>
          <p className={styles.cardText}>
            {text}
          </p>
          { link && buttonLabel && (
            <a href={link}>
                <button className='then-small-link'>{buttonLabel}</button>
            </a>
          )}
        </div>
    );
};
export default Card;