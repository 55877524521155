// exportToDOCX.js
import { AlignmentType, Document, HeadingLevel, Packer, Paragraph, TextRun, Footer, ImageRun } from 'docx';
import { saveAs } from 'file-saver';

export const exportToDOCX = async(map) => {
  const doc = new Document({
    sections: [],
  });

  // Load image as ArrayBuffer
  const imageUrl = '../../logopng.png'; // Replace with your image URL
  const response = await fetch(imageUrl);
  const imageBuffer = await response.arrayBuffer();

  // Create a footer with an image
  const footer = new Footer({
    children: [
      new Paragraph({
        children: [
          new ImageRun({
            data: imageBuffer,
            type: 'png',
            transformation: {
              width: 100,
              height: 50,
            },
          }),
        ],
      }),
    ],
  });


  const handleTopic = (content, topic) => {
    
    let paragraphs = [];

    paragraphs.push(
      new Paragraph({
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({ text: topic.topic, size: 40, break: 1, color: "#000000"}),
        ],
      }),

      new Paragraph({
        spacing: {
          after: 200,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({ text: topic.resume ?? "", size: 24, italics: true, color: "#555555", break: 1, font: "Helvetica, Arial" }),
        ],
      })
    );

    topic.linkedTopics.forEach((linkedTopic) => {
      paragraphs.push(
        new Paragraph({
          spacing: {
            after: 50,
            before: 50,
          },
          bullet: {
            level: 0,
          },
          children: [
            new TextRun({ text: linkedTopic.topic, size: 24, color: "#555555", font: "Helvetica, Arial" }),
          ],
          //thematicBreak: true,
        })
      );
    });

    topic.linkedTopics.forEach((linkedTopic) => {
      if (linkedTopic.linkedTopics && linkedTopic.linkedTopics.length > 0) {
        paragraphs.push(...handleTopic(content, linkedTopic)); // Recursively handling nested topics
      }
    });

    return paragraphs;
  };

  const docContent = [];

  map.topics.forEach((topic) => {
    docContent.push(...handleTopic(doc, topic));
  });

  doc.addSection({
    footers: {
      default: footer,
    },
    children: docContent,
  });

  // Convert the Document to a Blob and save it
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `mindmap_document_${new Date().toISOString()}.docx`);
  });

};
