import '../App.css';
import UserContext from '../components/User_Context';
import { React, useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import * as api from '../api/apiConnector';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as labels from "../components/localized/labels";
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import AgGrid from '../components/grid/AgGrid';
import Form from 'react-bootstrap/Form';
import { FaPencil } from "react-icons/fa6";
import { IconContext } from "react-icons";
import folderIcon from '../assets/img/huge-icon/FilesAndFolder/bulk/folder.svg'
import quickCard from '../assets/img/huge-icon/Generic/quickCard.svg'


function Folder_Screen() {
    const [documents, setDocuments] = useState(null);
    const [folder, setFolder] = useState(null);
    let { user } = useContext(UserContext);
    const { folderId, professorclassroomId, classroomId } = useParams();
    const navigate = useNavigate();
    const [folderName, setFolderName] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [relations, setRelations] = useState(null);
    const [classroom, setClassroom] = useState(null);


    user = user ? JSON.parse(user) : null;
    const getData = async () => {
        var docs = (await api.sendMessage("getDocs", {
            user: user,
            body: {
                professorclassroomId: professorclassroomId,
                classroomId: classroomId
            },
        })).documents;


        var _folder = (await api.sendMessage("getFolder", {
            body: {
                id: folderId,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).folder;
        if (!_folder) {
            navigate("/");
        }
        else {
            setFolder(_folder);
            setFolderName(_folder.name);
        }

        var rel = (await api.sendMessage("getFolderRelations", {
            body: {
                folderId: folderId,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).result;

        var classroom = (await api.sendMessage("getClassroomFromId", {
            body: {
                id: professorclassroomId || classroomId,
            },
            user: user,
        })).classroom;


        //if there is doc's upload time - assign

        setRelations(rel);
        setDocuments(docs);
        setClassroom(classroom);
        setIsLoaded(true);
        toggleButtons();
    }

    useEffect(() => {
        try {
            (async () => {
                getData();
            })();
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    }, []);

    async function renameFolder(name) {
        if (name === folder.name) return setIsEditing(false);

        const result = await api.sendMessage("changeFolderName", {
            body: { folderName: name, id: folderId },
            user,
        });

        const { success, message } = result;
        
        if (success) {
            // Update the folder object with the new name and date
            folder.name = name;
            folder.date = new Date(); // or get the date from the API response
            setFolder(folder);
            setFolderName(name);
        }
        setFolderName(folder.name);
        withReactContent(Swal).fire({
            title: <i>{labels.getText()[success ? "DONE" : "ERROR"]()}</i>,
            icon: success ? "success" : "error",
            text: message,
        });

        setIsEditing(false);
    }

    const toggleButtons = () => {
        let show = false;
        let checked = window.$("input").each((i, e) => {
            let checkBox = window.$(e);
            if (checkBox.is(":checked")) {
                show = true;
            }
        });

        if (!show) {
            window.$(".then-small-link").hide();
        }
        else {
            window.$(".then-small-link").show();
        }
    }

    const handleSelectionChange = useCallback((selectedBook, isSelected) => {
        let _relations = relations;

        if (isSelected) {
            addDocumentToFolderRelation(selectedBook);
        }
        else {
            removeDocumentToFolderRelation(selectedBook);
        }       

        toggleButtons();
    }, []);

    const handleFolderDelete = async () => {

        withReactContent(Swal).fire({
            title: <i>{labels.getText()["DELETE"]()}</i>,
            icon: "question",
            text: `${labels.getText()["DO_YOU_WANT_TO_DELETE"]()} ${folder.name}?`,
            showConfirmButton: true,
            showCancelButton: true,
            showCloseButton: false
        }).then(async (result) => {

            if (result.isConfirmed) {
                withReactContent(Swal).fire({
                    title: <i>{labels.getText()["WAIT"]()}</i>,
                    icon: "info",
                    text: `${labels.getText()["DELETING_DOCUMENT"]()}`,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    showCloseButton: false
                });

                var deleteResult = (await api.sendMessage("deleteFolder", {
                    body: {
                        folderId: folder._id,
                        classroomId: professorclassroomId
                    },
                    user: user,
                }));

                withReactContent(Swal).close();

                if (deleteResult.success) {
                    withReactContent(Swal).fire({
                        title: <i>{labels.getText()["DONE"]()}</i>,
                        icon: "success",
                        text: result.message
                    });

                    navigate("/");

                    //Call refreshDocuments after API call
                    //props.refreshDocuments();
                } else {
                    withReactContent(Swal).fire({
                        title: <i>{labels.getText()["ERROR"]()}</i>,
                        icon: "error",
                        text: result.message
                    });
                }
            }
        });
    }

    const addDocumentToFolderRelation = async (selectedData) => {
        try {
            var addToFolderResult = await api.sendMessage("addDocumentToFolderRelation", {
                body: {
                    document: selectedData,
                    folderId: folderId,
                    classroomId: professorclassroomId
                },
                user: user,
            });
        } catch (error) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: error
            });
        }


    };


    const removeDocumentToFolderRelation = async (selectedData) => {
        try {
            var removeToFolderResult = await api.sendMessage("removeDocumentToFolderRelation", {
                body: {
                    document: selectedData,
                    folderId: folderId,
                    classroomId: professorclassroomId
                },
                user: user,
            });
        }
        catch (error) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: error
            });
        }
    };

    const removeDocument = async (selectedData) => {
        try {
            var addToFolderResult = await api.sendMessage("removeDocumentFromFolder", {
                body: {
                    document: selectedData,
                    folderId: folderId,
                    classroomId: professorclassroomId
                },
                user: user,
            });
        } catch (error) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: error
            });
        }
    }


    //creating second part with left text    
    return (
        <div className='wrap'>
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-5 wow fadeIn column-sx" data-wow-delay="0.1s">
                        <div className="stack-title">
                            {SetMenu({ user: user })}

                            {getBreadcrumb({ page: "folder", folderId: folderId, name: (folder) ? folder.name : folderName, professorclassroomId: professorclassroomId, classroomId: classroomId, classroomName: classroom?.name })}

                            <h1 className="document-boost">{labels.getText()["DOCUMENT_PAGE_HEADER"]()}</h1>
                        </div>

                        {/* <p>
                            {labels.getText()["FOLDER_EXPLANATION"]()}
                        </p> */}

                        <div className="card-document">
                            <div className="list-items">
                                <div className="list-items-content">
                                    <img className="list-items-content-icon-color" src={folderIcon}></img>
                                    <div className="list-items-content-text">
                                        {isEditing ? (
                                            <Form.Control
                                                type="text"
                                                placeholder={folderName}
                                                value={folderName}
                                                className='bg-transparent'
                                                onChange={(event) => setFolderName(event.target.value)}
                                                onBlur={() => renameFolder(folderName, true)}
                                                autoFocus
                                            />
                                        ) : (
                                            <span className="title-number Text700__5" onClick={() => setIsEditing(true)}>
                                                {folderName}
                                                <IconContext.Provider value={{ size: "1em" }}>
                                                    <FaPencil />
                                                </IconContext.Provider>
                                            </span>
                                        )}





                                        <span className="Text500__5">{labels.getText()["DOCUMENT_UPLOADED"]({ date: new Date(folder?.creationDate) })}</span>
                                    </div>

                                </div>
                            </div>


                        </div>

                        <div className='stretch'>
                            <AgGrid
                                books={documents}
                                checkBox={!classroomId}
                                onSelectionChange={(selectedBook, isSelected) => { handleSelectionChange(selectedBook, isSelected) }}
                                relations={relations}>
                            </AgGrid>
                        </div>

                        {professorclassroomId || classroomId ? null : <a className='then-big-button-ghost Text500__5 mt-3' onClick={() => {
                            handleFolderDelete();
                        }}><i style={{ fontSize: "1em" }} className="fa fa-trash fa-2x"> </i> {labels.getText()["DELETE_FOLDER"]()}</a>}
                    </div>

                    <div className='col-lg-7 column-dx-bg document-folder'>
                        <div className="row g-4">
                            <div className="col-md-6">
                                <div className="row g-4">
                                    {/* Q&A */}
                                    <div className="col-12 wow fadeIn custom-box" data-wow-delay="0.1s">


                                        <div className="function-card service-item d-flex flex-column justify-content-center text-center rounded">
                                            <div className="top-card">
                                                <div className="div-top-card">
                                                    <img className="document-screen-icon" src="/huge-icon/QA/Robot.svg"></img>
                                                    <div className="sticker">
                                                        <h4 className="Desktop__H4">{labels.getText()["DOCUMENT_QA_SESSION_TITLE"]()}</h4>
                                                    </div>
                                                </div>

                                            </div>

                                            <p className="stretch Text500__5">{labels.getText()["DOCUMENT_QA_SESSION_FOLDER"]()}</p>

                                            {professorclassroomId ?
                                                <a href={`/professor/qa/folder/${folderId}/${professorclassroomId}`}>
                                                    <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>
                                                </a>
                                                : classroomId ?
                                                    <a href={`/classroom/qa/folder/${folderId}/${classroomId}`}>

                                                        <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                    </a> :
                                                    <a href={`/folder/${folderId}/qa`}>
                                                        <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                    </a>
                                            }

                                        </div>


                                    </div>
                                    {/* TRAINING */}
                                    <div className="col-12 wow fadeIn custom-box" data-wow-delay="0.1s">
                                        <div className="function-card service-item d-flex flex-column justify-content-center text-center rounded">
                                            <div className="top-card">
                                                <div className="div-top-card">
                                                    <img className="document-screen-icon" src="/huge-icon/QA/test.svg"></img>
                                                    <div className="sticker">
                                                        <h4 className="Desktop__H4">{labels.getText()["TRAINING_SESSION_TITLE"]()}</h4>
                                                    </div>
                                                </div>

                                            </div>
                                            <p className="stretch Text500__5">{labels.getText()["TRAINING_SESSION_FOLDER"]()}</p>


                                            {professorclassroomId ?
                                                <a href={`/professor/folder/${folderId}/${professorclassroomId}/training`}>
                                                    <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>
                                                </a>
                                                : classroomId ?
                                                    <a href={`/classroom/folder/${folderId}/${classroomId}/training`}>

                                                        <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                    </a> :
                                                    <a href={`/folder/${folderId}/training`}>
                                                        <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                    </a>
                                            }

                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="col-md-6 pt-md-4" id="document-service-second-screen">
                                <div className="row g-4">
                                    <div className="col-12 wow fadeIn custom-box" data-wow-delay="0.3s">
                                        <div className="function-card service-item d-flex flex-column justify-content-center text-center rounded">
                                            <div className="top-card">
                                                <div className="div-top-card">
                                                    <img className="document-screen-icon" src="/huge-icon/QA/blockNotes.svg"></img>
                                                    <div className="sticker">
                                                        <h4 className="Desktop__H4">{labels.getText()["MIND_MAP_SESSION_TITLE"]()}</h4>
                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                                <p className="stretch Text500__5">{labels.getText()["MIND_MAP_SESSION_FOLDER"]()}</p>
                                            </div>
                                            {professorclassroomId ?
                                                <a href={`/professor/folder/mindmap/${folderId}/${professorclassroomId}`}>
                                                    <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>
                                                </a>
                                                : classroomId ?
                                                    <a href={`/classroom/folder/mindmap/${folderId}/${classroomId}`}>

                                                        <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                    </a> :
                                                    <a href={`/folder/${folderId}/mindmap`}>
                                                        <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                    </a>
                                            }
                                        </div>

                                    </div>
                                    <div className="col-12 wow fadeIn custom-box" data-wow-delay="0.3s">
                                            <div className="function-card service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="top-card">
                                                    <div className="div-top-card">
                                                        <img alt='quickCardIcon' className="document-screen-icon" src={quickCard}></img>
                                                        <div className="sticker">
                                                            <h4 className="Desktop__H4">{labels.getText()["QUICKCARDS_SESSION_TITLE"]()}</h4>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div>
                                                    <p className="stretch Text500__5">{labels.getText()["QUICKCARDS_SESSION"]()}</p>
                                                </div>
                                                {professorclassroomId ?
                                                    <a href={`/professor/quickcards/${folderId}/${professorclassroomId}`}>
                                                        <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>
                                                    </a>
                                                    : classroomId ?
                                                        <a href={`/classroom/quickcards/${folderId}/${classroomId}`}>

                                                            <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                        </a> :
                                                        <a href={`/folder/${folderId}/quickcards/`}>
                                                            <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                        </a>
                                                }
                                            </div>

                                        </div>

                                </div>                                
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div >


    )
}

export default Folder_Screen;
