import '../App.css';
import UserContext from '../components/User_Context';
import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as api from '../api/apiConnector';
import Question_Packages_List from '../components/Question_Packages_List';
import { Modal } from 'react-bootstrap';
import * as labels from "../components/localized/labels";
import * as React from 'react';
import { getBreadcrumb, SetMenu, } from '../components/Navigation';

function Training_Test_Screen() {
    const [newPackageId, setNewPackageId] = useState(null);
    const [document, setDocument] = useState(null);
    const [questionPackages, setQuestionPackages] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showProgressModal, setShowProgressModal] = useState(false);
    const [showSelectedQuestionsCount, setSelectedQuestionsCount] = useState("5");
    const [showSelectedQuestionsDifficulty, setSelectedQuestionsDifficulty] = useState("medium");
    const [showSelectedAnswersType, setSelectedAnswersType] = useState("close_answers");
    const [CorrectAnswersQuantity, setCorrectAnswersQuantity] = useState("single_choice");
    const [folder, setFolder] = useState();
    let { user } = useContext(UserContext);
    const { id, packageId = null, folderId, professorclassroomId, classroomId } = useParams();
    const [classroom, setClassroom] = useState(null);
    const {language} = useParams(); 

    const navigate = useNavigate();

    user = user ? JSON.parse(user) : null;

    function calculateQuestionPercentage(questionCount, executedQuestionCount, fixed = 0) {

        return questionCount === 0 ? 0 : (executedQuestionCount / questionCount * 100).toFixed(fixed);

    }


    const getDoc = async () => {
        return (await api.sendMessage("getDoc", {
            body: {
                id: id,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).document;
    }

    const getClassroom = async () => {
        return (await api.sendMessage("getClassroomFromId", {
            body: {
                id: professorclassroomId || classroomId,
            },
            user: user,
        })).classroom;
    }


    const getFolder = async () => {
        return (await api.sendMessage("getFolder", {
            body: {
                id: folderId,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).folder;
    }

    const generatePackage = async (questionsCount, testDifficulty, answersType, correctAnswers) => {

        setShowProgressModal(true);


        return (await api.sendMessage("generateQuestionsPackage", {
            body: {
                folderId: folderId,
                id: id,
                questionsCount: questionsCount,
                difficulty: testDifficulty,
                answersType: answersType,
                correctAnswers: correctAnswers,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).questionsPackage;
    }

    const getPackages = async () => {
        return (await api.sendMessage("getQuestionsPackages", {
            body: {
                folderId: folderId,
                docId: id,
                classroomId: professorclassroomId || classroomId
            },
            user: user,
        })).questionsPackages;
    }

    const refreshPackages = async () => {
        let _allDone = false;

        while (!_allDone) {
            try {
                // Get the packages from the API

                var _packages = null;

                //TODO CLEANING
                if (id) {
                    _packages = await getPackages();
                }
                else {
                    _packages = await getPackages();
                }

                // Refresh the packages state variable, bounded by the list component
                setQuestionPackages(_packages);

                // Check if any document is still indexing
                if (!_packages.find(x => x.status === "processing")) {
                    _allDone = true;
                    console.log("All documents are OK");
                } else {
                    console.log("Waiting for indexing in progress");
                    await new Promise(resolve => setTimeout(resolve, 2000));
                }
            } catch (error) {
                console.error("Error while refreshing packages:", error);
                _allDone = true;  // To break out of the loop in case of error
            }
        }
    }

    function createModal() {

        return (
            <Modal show={showModal} onHide={() => { setShowModal(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {labels.getText()["QUESTIONS_COUNT"]()}

                    </div>
                    <div className="options-container">
                        <div>
                            <select className="form-select" value={showSelectedQuestionsCount} onChange={
                                (event) => setSelectedQuestionsCount(event.target.value)}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <br />
                        {/* <div>
                            {labels.getText()["TEST_DIFFICULTY"]()}
                        </div>
                        <div>
                            <select className="form-select" value={showSelectedQuestionsDifficulty} onChange={
                                (event) => setSelectedQuestionsDifficulty(event.target.value)}>
                                <option value="easy">{labels.getText()["EASY_QUESTIONS"]()}</option>
                                <option value="medium">{labels.getText()["MEDIUM_QUESTIONS"]()}</option>
                                <option value="hard">{labels.getText()["HARD_QUESTIONS"]()}</option>
                                <option value="extreme">{labels.getText()["EXTREME_QUESTIONS"]()}</option>
                            </select>
                        </div>
                        <br /> */}
                        <div>
                            {labels.getText()["ANSWERS_TYPE"]()}
                        </div>
                        <div>
                            <select className="form-select" value={showSelectedAnswersType} onChange={
                                (event) => setSelectedAnswersType(event.target.value)}>
                                <option value="close_answers">{labels.getText()["CLOSE_ANSWERS"]()}</option>
                                <option value="open">{labels.getText()["OPEN_ANSWERS"]()}</option>
                            </select>
                        </div>
                        {showSelectedAnswersType === "close_answers" ?
                            (
                                <div>
                                    <br />
                                    <div>
                                        {labels.getText()["ANSWERS_QUANTITY"]()}
                                    </div>
                                    <div>
                                        <select className="form-select" value={CorrectAnswersQuantity} onChange={
                                            (event) => setCorrectAnswersQuantity(event.target.value)}>
                                            <option value="single_choice">{labels.getText()["SINGLE_CHOICES"]()}
                                            </option>
                                            <option value="multiple_choices">{labels.getText()["MULTIPLE_CHOICES"]()}</option>
                                        </select>
                                    </div>
                                </div>) : null}

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" type="button" onClick={() => { setShowModal(false) }}>
                        {
                            labels.getText()["CLOSE"]()
                        }

                    </button>

                    <button className="btn btn-primary" type="button"
                        onClick={async () => {
                            setShowModal(false);
                            let _newPackage = await generatePackage(showSelectedQuestionsCount, showSelectedQuestionsDifficulty, showSelectedAnswersType, CorrectAnswersQuantity);
                            if (_newPackage) {
                                setNewPackageId(_newPackage._id);
                            }
                            else {
                                setShowProgressModal(false);
                            }
                            refreshPackages();
                        }}>
                        {
                            labels.getText()["CREATE_TEST"]()
                        }
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }




    function createProgressModal() {

        let _newPackage = questionPackages.find(x => x._id == newPackageId);

        if (_newPackage) {
            let packageIndex = questionPackages.findIndex(x => x._id == newPackageId) + 1;

            if (_newPackage.status == "done") {
                if (_newPackage.questions.length < showSelectedQuestionsCount) {
                    return (
                        <Modal show={showProgressModal} dialogClassName="modal-dialog-centered">
                            <Modal.Header closeButton onClick={(e) => { setNewPackageId(null) }}>
                                <Modal.Title>{labels.getText()["PACKAGE_NUMBER"]({ number: packageIndex })}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="modal-body text-center">
                                    <p>{labels.getText()["PACKAGE_PROCCESS_FINISH"]()}</p>
                                </div>
                                <div className="modal-body text-center">
                                    <p>{labels.getText()["PACKAGE_PROCCESS_FINISH_LESS_QUESTIONS"]()}</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" type="button" onClick={(e) => { setNewPackageId(null); setShowProgressModal(false) }}>
                                    {labels.getText()["CLOSE"]()}
                                </button>
                            </Modal.Footer>
                        </Modal>
                    );

                }
                else {
                    return (
                        <Modal show={showProgressModal} dialogClassName="modal-dialog-centered">
                            <Modal.Header closeButton onClick={(e) => { setNewPackageId(null) }}>
                                <Modal.Title>{labels.getText()["PACKAGE_NUMBER"]({ number: packageIndex })}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="modal-body text-center">
                                    <p>{labels.getText()["PACKAGE_PROCCESS_FINISH"]()}</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" type="button" onClick={(e) => { setNewPackageId(null); setShowProgressModal(false) }}>
                                    {labels.getText()["CLOSE"]()}
                                </button>
                            </Modal.Footer>
                        </Modal>
                    );
                }
            }

            // else {
            //     var percent = calculateQuestionPercentage(_newPackage.questionChunks, _newPackage.questionExecutedChunks);

            //     if (isNaN(percent)) {
            //         percent = 5;
            //         return (
            //             <Modal show={showProgressModal} dialogClassName="modal-dialog-centered">
            //                 <Modal.Header closeButton onClick={(e) => { setNewPackageId(null) }}>
            //                     <Modal.Title>{labels.getText()["PACKAGE_NUMBER"]({ number: packageIndex })}</Modal.Title>
            //                 </Modal.Header>
            //                 <Modal.Body>
            //                     <div>
            //                         <label>{labels.getText()["PREPARING_PACKAGE"]()}</label>
            //                         <div className="progress" style={{ marginBottom: "2em", height: "3em", width: "80%", marginLeft: "auto", marginRight: "auto", marginTop: "1em" }}>
            //                             <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${percent}%` }}>{`${percent}%`}</div>
            //                         </div>
            //                     </div>
            //                 </Modal.Body>
            //                 <Modal.Footer>
            //                     <button className="btn btn-primary" type="button" onClick={(e) => { setNewPackageId(null) }}>
            //                         {labels.getText()["CLOSE"]()}
            //                     </button>
            //                 </Modal.Footer>
            //             </Modal>
            //         );
            //     }
            //     if (percent < 100) {
            //         return (
            //             <Modal show={showProgressModal} dialogClassName="modal-dialog-centered">
            //                 <Modal.Header closeButton onClick={(e) => { setNewPackageId(null) }}>
            //                     <Modal.Title>{labels.getText()["PACKAGE_NUMBER"]({ number: packageIndex })}</Modal.Title>
            //                 </Modal.Header>
            //                 <Modal.Body>
            //                     <div>
            //                         <label>{labels.getText()["PREPARING_PACKAGE"]()}</label>
            //                         <div className="progress" style={{ marginBottom: "2em", height: "3em", width: "80%", marginLeft: "auto", marginRight: "auto", marginTop: "1em" }}>
            //                             <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${percent}%` }}>{`${percent}%`}</div>
            //                         </div>
            //                     </div>
            //                 </Modal.Body>
            //                 <Modal.Footer>
            //                     <button className="btn btn-primary" type="button" onClick={(e) => { setNewPackageId(null) }}>
            //                         {labels.getText()["CLOSE"]()}
            //                     </button>
            //                 </Modal.Footer>
            //             </Modal>
            //         );
            //     }
            //     else if (percent == 100) {
            //         if (_newPackage.questions.length < showSelectedQuestionsCount) {
            //             return (
            //                 <Modal show={showProgressModal} dialogClassName="modal-dialog-centered">
            //                     <Modal.Header closeButton onClick={(e) => { setNewPackageId(null) }}>
            //                         <Modal.Title>{labels.getText()["PACKAGE_NUMBER"]({ number: packageIndex })}</Modal.Title>
            //                     </Modal.Header>
            //                     <Modal.Body>
            //                         <div className="modal-body text-center">
            //                             <p>{labels.getText()["PACKAGE_PROCCESS_FINISH"]()}</p>
            //                         </div>
            //                         <div className="modal-body text-center">
            //                             <p>{labels.getText()["PACKAGE_PROCCESS_FINISH_LESS_QUESTIONS"]()}</p>
            //                         </div>
            //                     </Modal.Body>
            //                     <Modal.Footer>
            //                         <button className="btn btn-primary" type="button" onClick={(e) => { setNewPackageId(null); setShowProgressModal(false) }}>
            //                             {labels.getText()["CLOSE"]()}
            //                         </button>
            //                     </Modal.Footer>
            //                 </Modal>
            //             );

            //         }
            //         else {
            //             return (
            //                 <Modal show={true} dialogClassName="modal-dialog-centered">
            //                     <Modal.Header closeButton onClick={(e) => { setNewPackageId(null) }}>
            //                         <Modal.Title>{labels.getText()["PACKAGE_NUMBER"]({ number: packageIndex })}</Modal.Title>
            //                     </Modal.Header>
            //                     <Modal.Body>
            //                         <div className="modal-body text-center">
            //                             <p>{labels.getText()["PACKAGE_PROCCESS_FINISH"]()}</p>
            //                         </div>
            //                     </Modal.Body>
            //                     <Modal.Footer>
            //                         <button className="btn btn-primary" type="button" onClick={(e) => { setNewPackageId(null) }}>
            //                             {labels.getText()["CLOSE"]()}
            //                         </button>
            //                     </Modal.Footer>
            //                 </Modal>
            //             );
            //         }
            //     }
            // }

        }
        else {
            return (
                <Modal show={showProgressModal} dialogClassName="modal-dialog-centered">
                    <Modal.Header closeButton onClick={(e) => { setNewPackageId(null) }}>
                        <Modal.Title>{labels.getText()["PACKAGE"]()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <label>{labels.getText()["PREPARING_PACKAGE"]()}</label>
                            <div className="progress" style={{ marginBottom: "2em", height: "3em", width: "80%", marginLeft: "auto", marginRight: "auto", marginTop: "1em" }}>
                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `45%` }}>{`50%`}</div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" type="button" onClick={(e) => { setNewPackageId(null); setShowProgressModal(false) }}>
                            {labels.getText()["CLOSE"]()}
                        </button>
                    </Modal.Footer>
                </Modal>
            );
        }
    }



    useEffect(() => {
        (async () => {
            try {
                const _book = await getDoc();
                const _folder = await getFolder();
                const _classroom = await getClassroom();


                // We call setDocument and setFolder outside of `getPackages`
                setDocument(_book);
                setFolder(_folder);
                setClassroom(_classroom);

                await refreshPackages();
            } catch (error) {
                console.error("Error in useEffect:", error);
            }
        })();
    }, []);


    if ((!questionPackages && !document) || (!questionPackages && !folder)) {
        return (
            <div className="App App-body">
                {SetMenu({ user: user, professorclassroomId, classroomId })}
                <h1>{labels.getText()["WAITING_FOR_DATA"]()}</h1>
            </div>
        );
    }
    else {
        const _packages = () => {
            return (
                <div>
                    {SetMenu({ user: user, professorclassroomId, classroomId })}
                    {id ?
                        (getBreadcrumb({ page: "training", id: document._id, name: document.fileName, professorclassroomId, classroomId })) :
                        (getBreadcrumb({ page: "training_folder", folderId: folderId, name: folder?.name, professorclassroomId: professorclassroomId, classroomId: classroomId, classroomName: classroom?.name }))}


                    <div style={{ marginBottom: "2em" }}>
                        {labels.getText()["TEST_PAGE_LABELS"]({ user: user.name, questionsPackages: questionPackages?.length })}
                    </div>
                    <div>
                        {classroomId ? null :
                            <button className="btn btn-primary ml-2 mb-3 mr-2" type="button" onClick={() => {
                                setShowModal(true);
                            }}>

                                {labels.getText()["CREATE_TEST"]()}
                            </button>}
                    </div>

                    <Question_Packages_List items={questionPackages}
                        onPress={(item) => {
                            if (folderId) {
                                if (professorclassroomId) {
                                    navigate(`/${language}/professor/survey/folder/${item._id}/${folderId}/${professorclassroomId}`);

                                } else if (classroomId) {
                                    navigate(`/${language}/classroom/survey/folder/${item._id}/${folderId}/${classroomId}`);
                                } else {
                                    navigate(`/${language}/survey/folder/${item._id}/${folderId}`);
                                }
                            } else {
                                if (professorclassroomId) {
                                    navigate(`/${language}/professor/survey/${item._id}/${document._id}/${professorclassroomId}`);

                                } else if (classroomId) {
                                    navigate(`/${language}/classroom/survey/${item._id}/${document._id}/${classroomId}`);
                                } else {
                                    navigate(`/${language}/survey/${item._id}/${document._id}`);
                                }
                            }
                        }}
                    ></Question_Packages_List>
                </div>);
        }

        return (
            <div className="App App-body">
                {SetMenu({ user: user, professorclassroomId, classroomId })}

                <h1>
                    {id ? (document.title ? document?.title : document?.fileName) : folder?.name}

                    <hr />
                </h1>
                <div>
                    {createModal()}
                    {createProgressModal()}
                    {_packages()}
                </div>

            </div>
        );
    }

}

export default Training_Test_Screen;
