import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login_Screen from '../screens/Login_Screen';
import Home_Screen from '../screens/Home_Screen';
import Library_Screen from '../screens/Library_Screen';
import Logout_Screen from '../screens/Logout_Screen';
import Settings_Screen from '../screens/Settings_Screen';
import Document_Screen from '../screens/Document_Screen';
import ClassRooms_Screen from '../screens/ClassRooms_Screen';
import Camera_Book_Screen from '../screens/Camera_Book_Screen';
import YouTube_Screen from '../screens/YouTube_Screen';
import Speech_Screen from '../screens/Speech_Screen';
import Q_A_Screen from '../screens/Q_A_Screen';
import Training_Test_Screen from '../screens/Training_Test_Screen';
import Training_Survey_Screen from '../screens/Training_Survey_Screen';
import Mindmap_Screen from '../screens/Mindmap_Screen'
import { React, useContext } from 'react';
import Guru_Medidation from '../screens/Guru_Medidation';
import Class_Screen from '../screens/Class_Screen';
import Folder_Screen from '../screens/Folder_Screen';
import Registration_Screen from '../screens/Registration_Screen';
import Registration_Professor_Screen from '../screens/Registration_Professor_Screen';
import UserContext from '../components/User_Context';
import Terms_Conditions_Screen from '../screens/Terms_Conditions_Screen';
import User_Profile_Screen from '../screens/User_Profile_Screen';
import Invitation_Screen from '../screens/Invitation_Screen';
import User_Subscription_Screen from '../screens/User_Subscription_Screen';
import Subscription_Catalogue_Screen from '../screens/Subscription_Catalogue_Screen';
import Checkout_Screen from '../screens/Checkout_Screen';
import User_Payments_Screen from '../screens/User_Payments_Screen';
import Privacy_Policy_Screen from '../screens/Privacy_Policy_Screen';
import Then_Training_Test_Screen from '../screens/Then_Training_Test_Screen';
import ContactUS_Screen from "../screens/ContactUS_Screen";
import Password_Reset_Screen from '../screens/Password_Reset_Screen';
import Language_Assistant_Screen from '../screens/Language_Assistant_Screen';
import AboutAnna_Screen from '../screens/AboutAnna_Screen';
import QuickCard_Screen from '../screens/QuickCard_Screen';
import Public_QuickCard_Screen from '../screens/Public_QuickCard_Screen';
import Public_QuickCards_Screen from '../screens/Public_QuickCards_Screen';
import Blog_Screen from '../screens/Blog_Screen';
import Single_Blog_Screen from '../screens/Single_Blog_Screen';
import Language_Default_Screen from '../screens/Language_Default_Screen';
import Blog_Manager_Screen from '../screens/blogManager/BlogManager_Screen';
import Blog_Editor_Screen from '../screens/blogManager/BlogEditor_Screen';
function Site_Routing(props) {

    const { user } = useContext(UserContext);
    if (user) {
        return (
            <Router>
                <Routes>
                    <Route
                        path="/:language/library"
                        element={<Library_Screen />}
                    />
                    <Route
                        path="/:language/privacy_policy"
                        element={<Privacy_Policy_Screen />}
                    />
                    <Route
                        path="/:language/professor/library/classroom/:professorclassroomId"
                        element={<Library_Screen />}
                    />
                    <Route
                        path="/:language/library/classroom/:classroomId"
                        element={<Library_Screen />}
                    />
                    <Route
                        path="/:language/login"
                        element={<Login_Screen />}
                    />
                    <Route
                        path="/:language/login/:provider/:loginId"
                        element={<Login_Screen />}
                    />
                    <Route
                        path="/:language/logout"
                        element={<Logout_Screen />}
                    />
                    <Route
                        path="/:language/"
                        element={<Home_Screen />}
                    />
                    <Route
                        path="/:language/settings"
                        element={<Settings_Screen />}
                    />
                    <Route
                        path="/:language/document/:id/?:page?"
                        element={<Document_Screen />}
                    />
                    <Route
                        path="/:language/professor/document/classroom/:id/:professorclassroomId"
                        element={<Document_Screen />}
                    />
                    <Route
                        path="/:language/document/classroom/:id/:classroomId"
                        element={<Document_Screen />}
                    />
                    <Route
                        path="/:language/train/:id"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/folder/:folderId/train"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/professor/folder/:folderId/:professorclassroomId/train"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/classroom/folder/:folderId/:classroomId/train"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/folder/:folderId/:professorClassroom/train"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/train/folder/:folderId/:classroom"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/train/:id/:classroom"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/professor/train/:id/:professorclassroomId"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/classroom/train/:id/:classroomId"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/classroom/train/folder/:id/:classroomId"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/survey/:id/:documentId"
                        element={<Training_Survey_Screen />}
                    />
                    <Route
                        path="/:language/professor/survey/:id/:documentId/:professorclassroomId"
                        element={<Training_Survey_Screen />}
                    />
                    <Route
                        path="/:language/survey/folder/:id/:folderId"
                        element={<Training_Survey_Screen />}
                    />
                    <Route
                        path="/:language/professor/survey/folder/:id/:folderId/:professorclassroomId"
                        element={<Training_Survey_Screen />}
                    />
                    <Route
                        path="/:language/classroom/survey/:id/:documentId/:classroomId"
                        element={<Training_Survey_Screen />}
                    />
                    <Route
                        path="/:language/classroom/survey/folder/:id/:folderId/:classroomId"
                        element={<Training_Survey_Screen />}
                    />

                    <Route
                        path="/:language/quickCardsQA/:documentId/:autoSearch"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/:language/professor/qa/:documentId/:professorclassroomId"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/:language/classroom/qa/:documentId/:classroomId"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/:language/professor/qa/folder/:folderId/:professorclassroomId"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/:language/classroom/qa/folder/:folderId/:classroomId"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/:language/qa/:documentId"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/:language/qa/:documentId/:autoSearch"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/:language/folder/:folderId/qa"
                        element={<Q_A_Screen />}
                    />

                    <Route
                        path="/:language/folder/:folderId/qa/:autoSearch"
                        element={<Q_A_Screen />}
                    />

                    <Route
                        path="/:language/quickcards/:id"
                        element={<QuickCard_Screen />}
                    />

                    <Route
                        path="/:language/public/quickcards/:id"
                        element={<Public_QuickCard_Screen />}
                    />

                    <Route
                        path="/:language/public/quickcards/list/:topic?"
                        element={<Public_QuickCards_Screen />}
                    />

                    <Route
                        path="/:language/folder/:folderId/quickcards"
                        element={<QuickCard_Screen />}
                    />
                    <Route
                        path="/:language/classroom/mindmap/:id/:classroomId/:topic?"
                        element={<Mindmap_Screen />}
                    />
                    <Route
                        path="/:language/professor/mindmap/:id/:professorclassroomId/:topic?"
                        element={<Mindmap_Screen />}
                    />
                    <Route
                        path="/:language/mindmap/:id/:topic?/:refresh?"
                        element={<Mindmap_Screen />}
                    />
                    <Route
                        path="/:language/folder/:folderId/mindmap/:topic?/:refresh?"
                        element={<Mindmap_Screen />}
                    />
                    <Route
                        path="/:language/professor/folder/mindmap/:folderId/:professorclassroomId/:topic?"
                        element={<Mindmap_Screen />}
                    />
                    <Route
                        path="/:language/classroom/folder/mindmap/:folderId/:classroomId/:topic?"
                        element={<Mindmap_Screen />}
                    />
                    <Route
                        path="/:language/Guru_meditation"
                        element={<Guru_Medidation />}
                    />
                    <Route
                        path="/:language/folder/:folderId"
                        element={<Folder_Screen />}
                    />
                    <Route
                        path="/:language/professor/folder/classroom/:folderId/:professorclassroomId"
                        element={<Folder_Screen />}
                    />
                    <Route
                        path="/:language/folder/classroom/:folderId/:classroomId"
                        element={<Folder_Screen />}
                    />
                    <Route
                        path="/:language/professor/classroom/:professorClassRoomId"
                        element={<Class_Screen />}
                    />
                    <Route
                        path="/:language/register"
                        element={<Registration_Screen />}
                    />
                    <Route
                        path="/:language/register_professor"
                        element={<Registration_Professor_Screen />}
                    />
                    <Route
                        path="/:language/:professor/classrooms"
                        element={<ClassRooms_Screen />}
                    />
                    <Route
                        path="/:language/classrooms"
                        element={<ClassRooms_Screen />}
                    />
                    <Route
                        path="/:language/camera"
                        element={<Camera_Book_Screen />}
                    />
                    <Route
                        path="/:language/youtube"
                        element={<YouTube_Screen />}
                    />
                    <Route
                        path="/:language/speech"
                        element={<Speech_Screen />}
                    />
                    <Route
                        path="/:language/professor/classroom/:professorClassRoomId/speech"
                        element={<Speech_Screen />}
                    />
                    <Route
                        path="/:language/terms_and_conditions"
                        element={<Terms_Conditions_Screen />}
                    />
                    <Route
                        path="/:language/invitation/:classroomId"
                        element={<Invitation_Screen />}
                    />
                    <Route
                        path="/:language/user_profile"
                        element={<User_Profile_Screen />}
                    />
                    <Route
                        path="/:language/user_subscription"
                        element={<User_Subscription_Screen />}
                    />
                    <Route
                        path="/:language/catalogue"
                        element={<Subscription_Catalogue_Screen />}
                    />
                    <Route
                        path="/:language/checkout/:profileCode"
                        element={<Checkout_Screen />}
                    />
                   <Route
                        path="/:language/blog"
                        element={<Blog_Screen />}
                    />
                    <Route
                        path="/:language/blog/article/:id"
                        element={<Single_Blog_Screen />}
                    />
                   
                    <Route
                        path="/:language/training/:id"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/training/:id"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/folder/:folderId/training"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/professor/folder/:folderId/:professorclassroomId/training"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/classroom/folder/:folderId/:classroomId/training"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/folder/:folderId/:professorClassroom/training"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/training/folder/:folderId/:classroom"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/training/:id/:classroom"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/professor/training/:id/:professorclassroomId"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/classroom/training/:id/:classroomId"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/classroom/training/folder/:id/:classroomId"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/:language/user_payments"
                        element={<User_Payments_Screen />}
                    />
                    <Route
                        path="/:language/contactUs"
                        element={<ContactUS_Screen />}
                    />
                    <Route
                        path="/:language/translate"
                        element={<Language_Assistant_Screen />}
                    />
                    <Route
                        path="/:language/aboutANNA"
                        element={<AboutAnna_Screen />}
                    />
                    <Route
                        path="/:language/blogManager"
                        element={<Blog_Manager_Screen />}
                    />
                    <Route
                        path="/:language/blogEditor/:id?"
                        element={<Blog_Editor_Screen />}
                    />

                    <Route
                        path="*"
                        element={< Language_Default_Screen />}
                    />
                </Routes>
                {props.children}
            </Router>
        );
    }
    else {
        return (
            <Router>
                <Routes>
                    <Route
                        path="/:language/terms_and_conditions"
                        element={<Terms_Conditions_Screen />}
                    />
                    <Route
                        path="/:language/passwordReset"
                        element={<Password_Reset_Screen />}
                    />
                    <Route
                        path="/:language/passwordReset/:randomCode"
                        element={<Password_Reset_Screen />}
                    />
                    <Route
                        path="/:language/"
                        element={<Home_Screen />}
                    />
                    <Route
                        path="/:language/login"
                        element={<Login_Screen />}
                    />
                    <Route
                        path="/:language/login/:profileCode"
                        element={<Login_Screen />}
                    />
                    <Route
                        path="/:language/Guru_meditation"
                        element={<Guru_Medidation />}
                    />
                    <Route
                        path="/:language/login/:provider/:loginId"
                        element={<Login_Screen />}
                    />
                    <Route
                        path="/:language/register"
                        element={<Registration_Screen />}
                    />
                    <Route
                        path="/:language/register/:profileCode"
                        element={<Registration_Screen />}
                    />
                    <Route
                        path="/:language/register_professor"
                        element={<Registration_Professor_Screen />}
                    />
                    <Route
                        path="/:language/catalogue"
                        element={<Subscription_Catalogue_Screen />}
                    />
                    <Route
                        path="/:language/privacy_policy"
                        element={<Privacy_Policy_Screen />}
                    />
                    <Route
                        path="/:language/contactUs"
                        element={<ContactUS_Screen />}
                    />
                    <Route
                        path="/:language/aboutANNA"
                        element={<AboutAnna_Screen />}
                    />
                    <Route
                        path="/:language/blog"
                        element={<Blog_Screen />}
                    />
                    <Route
                        path="/:language/blog/article/:id"
                        element={<Single_Blog_Screen />}
                    />


                    <Route
                        path="/:language/public/quickcards/list/:topic?"
                        element={<Public_QuickCards_Screen />}
                    />

                    <Route
                        path="/:language/public/quickcards/:id"
                        element={<Public_QuickCard_Screen />}
                    />
                    <Route
                        path="*"
                        element={< Language_Default_Screen />}
                    />
                </Routes>
            </Router>
        );
    }


}

export default Site_Routing;