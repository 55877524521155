import { React, useState, useContext, useEffect } from "react";
import UserContext from '../components/User_Context';
import * as labels from "../components/localized/labels";
import folderIcon from '../assets/img/huge-icon/FilesAndFolder/bulk/folder.svg';
import fileIcon from '../assets/img/huge-icon/FilesAndFolder/bulk/file03.svg';
import audioIcon from '../assets/img/Generic/mic.svg';
import videoIcon from '../assets/img/Generic/clapperboard.svg';
import removeAccents from 'remove-accents';


import $ from 'jquery';

function Books_List(props) {
    const { professor, student, items, folders, itemsPerPage = 5, activePage = 1, onBookPress, onFolderPress, onDelete } = props;
    const [_activePage, _setActivePage] = useState(activePage);
    const [searchText, setSearchText] = useState("");
    const [searchInputText, setSearchInputText] = useState("");
    let searchTimer = null;

    const handleSearchTextChange = (e) => {
        console.log(`box typed in: ${e.target.value}`);
        if (!e || !e.target || !e.target.value || searchTimer) {
            console.log(`timer reset for: ${e.target.value}`);
            clearTimeout(searchTimer);
        }

        searchTimer = setTimeout(() => {
            console.log(`timer started for: ${e.target.value}`);
            setSearchText(e?.target?.value ?? "");
        }, 750);
    }

    function openDocumentMobile(e, cardType, item) {
        var card = $(e.currentTarget.querySelector('.openButton'));

        if (!card.is(':visible')) {
            if (cardType === "document") {
                onBookPress(item);
            }
            else {
                onFolderPress(item);
            }
        }
    }


    function _drawPagination() {
        var _items = folders.concat(items);

        if (searchText && searchText != "") {
            _items = _items.filter(x => (removeAccents(x.title ?? x.name ?? x.fileName ?? "")).toLocaleLowerCase().includes(removeAccents(searchText).toLowerCase()));
        }

        var pagesCount = Math.trunc(_items.length / itemsPerPage) +
            ((_items.length) % itemsPerPage > 0 ? 1 : 0);

        var pages = [];

        for (var i = 1; i <= pagesCount; i++) {
            pages.push({ pageNumber: i, active: (_activePage == i) });
        }

        const totalPages = pages.length;
        let startPage, endPage;

        if (totalPages <= 5) {
            // less than 5 total pages so show all pages
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 5 total pages so calculate start and end pages
            if (_activePage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (_activePage + 2 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = _activePage - 2;
                endPage = _activePage + 2;
            }
        }

        const _showPrevious = _activePage > 1;
        const _showNext = _activePage < endPage;

        const paginationItems = Array.from({ length: (endPage - startPage + 1) }, (_, i) => startPage + i);

        return (
            <div className="row font-monospace pagination-row"
                style={
                    {
                        WebkitUserSelectelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                        userSelect: "none"
                    }
                }
            >
                <hr className="hiddenMobile" />

                <div className="pagination">
                    {/* web previous button */}


                    <button className="pagination-button hiddenMobile"
                        onClick={() => {
                            if (_activePage > 1) {
                                _setActivePage(_activePage - 1);
                            }
                        }}
                        disabled={_showPrevious ? null : true}

                    >
                        <img src='/huge-icon/arrow/arrow-left.svg '></img>

                        {labels.getText()["PREVIOUS"]()}
                    </button>
                    {/* mobile previous button */}

                    <button className="pagination-button onlyMobile"
                        onClick={() => {
                            if (_activePage > 1) {
                                _setActivePage(_activePage - 1);
                            }
                        }}
                        disabled={_showPrevious ? null : true}
                        style={{
                            opacity: _showPrevious ? 1 : 0.5
                        }}
                    >
                        <img src='/huge-icon/arrow/arrow-left.svg '></img>

                    </button>




                    <div className="pagination-numbers">

                        {paginationItems[0] > 1 && (
                            <>
                                <button className="pagination-number-base active"
                                    title={`Go to page 1`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => _setActivePage(1)}
                                >1</button>

                            </>
                        )}

                        {paginationItems.map(pageNumber => (
                            <button
                                key={`goToPage_${pageNumber}`}
                                title={`Go to page ${pageNumber}`}
                                className={`col${(_activePage === pageNumber) ? " pagination-number-base-active" : " pagination-number-base"} `}
                                style={{ cursor: _activePage !== pageNumber ? "pointer" : "inherit" }}
                                onClick={() => _setActivePage(pageNumber)}                                
                            >
                                {pageNumber}
                            </button>
                        ))}

                        {paginationItems[paginationItems.length - 1] < pages.length && (
                            <>
                                {paginationItems[paginationItems.length - 1] < pages.length - 1
                                    // && <Pagination.Ellipsis className="col" />
                                }
                                <button
                                    className="col pagination-number-base"
                                    title={`Go to page ${pages.length}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => _setActivePage(pages.length)}
                                >
                                    {pages.length}
                                </button>
                            </>
                        )}

                    </div>



                    <button className="pagination-button hiddenMobile"
                        onClick={() => {
                            if (_activePage < pages.length) {
                                _setActivePage(_activePage + 1);
                            }

                        }}
                        disabled={_showNext ? null : true}
                    >
                        {labels.getText()["NEXT"]()}
                        <img src='/huge-icon/arrow/arrow-right.svg '></img>

                    </button>

                    {/* mobile next button */}
                    <button className="pagination-button onlyMobile"
                        onClick={() => {
                            if (_activePage < pages.length) {
                                _setActivePage(_activePage + 1);
                            }

                        }}
                        disabled={_showNext ? null : true}
                        style={{
                            opacity: _showNext ? 1 : 0.5                            
                        }}
                    >

                        <img src='/huge-icon/arrow/arrow-right.svg '></img>

                    </button>


                </div>
            </div>
        );

    }

    useEffect(() => {
        (async () => {
            _drawItems();
        })();
    }, []);

    useEffect(() => {
        console.log(`useEffect on: ${searchText}`);
        _drawItems();
    }, [searchText]);


    function _drawItems() {
        //list to show
        var _items = folders.concat(items);



        if (_items && _items.length > 0) {

            if (searchText && searchText != "") {
                _items = _items.filter(x => removeAccents(`${x.title ?? ""}${x.name ?? ""}${x.fileName ?? ""}`).toLocaleLowerCase().includes(removeAccents(searchText).toLowerCase()));
            }

            let _itemsToShow = _items.slice((_activePage - 1) * itemsPerPage, _activePage * itemsPerPage);

            return (
                <>

                    <div className='search-bar-container'>
                        <input
                            type='text'
                            placeholder={labels.getText()["SEARCH_DOCUMENTS"]()}
                            value={searchInputText}
                            onChange={(e) => {
                                setSearchInputText(e.target.value);
                                handleSearchTextChange(e);
                                _setActivePage(1);
                            }}
                            className='search-input'
                        />
                        {searchText && (
                            <button type='button' onClick={() => {
                                console.log("clear");
                                setSearchInputText("");
                                handleSearchTextChange({target: {value: ""}});
                                _setActivePage(1);
                            }} className='clear-button'>
                                &#x2715;
                            </button>
                        )}
                    </div>
                    <div className="library-content">


                        {/* <div className="list-group"> */}
                        {_itemsToShow.map(item => {
                            return (_drawItem(item));
                        })}
                        {/* </div> */}

                        {_drawPagination()}
                    </div>
                </>
            );
        }
        else {
            return (
                <div>
                    {labels.getText()["NO_ELEMENTS_TO_SHOW"]()}
                </div>
            );
        }
    }


    function _drawItem(item) {
        let _type = items.find(x => x === item) ? "document" : "folder";




        const getPages = () => {
            if ((item.isVideo || item.isAudio) && item.duration) {
                return labels.getText()["SECONDS"]({ value: item.duration })
            } else if (!item.multiMedia) {
                return labels.getText()["PAGES"]({ pages: item.pagesCount });
            } else {
                return "";
            }
        }

        function _pages() {
            switch (item.status) {
                case "indexing": {
                    return (
                        <small>
                            {labels.getText()["WIP"]({})}

                        </small>)

                }
                default:
                    {
                        return (
                            <small>
                                {getPages()}
                            </small>);
                    }
            }
        }

        function _getImage() {

            if (item.isVideo) {
                return (<img className="list-items-content-icon-color service-icon-sm btn-square" src={videoIcon}></img>
                );
            }
            else if (item.isAudio) {
                return (<img className="list-items-content-icon-color service-icon-sm btn-square" src={audioIcon}></img>
                )
            }
            else {
                if (items.find(x => x._id == item._id)) {
                    return (<img className="list-items-content-icon-color service-icon-sm btn-square" src={fileIcon}></img>
                    );
                } else {
                    return (<img className="list-items-content-icon-color service-icon-sm btn-square" src={folderIcon}></img>);
                }
            }
        }





        return (
            <div key={item._id} className="card-document  card-service-item d-flex flex-column justify-content-center text-center rounded " onClick={(e) => openDocumentMobile(e, _type, item)}>
                <div className="list-items-content">


                    {_getImage()}




                    <div className="list-items-content-text">
                        <div className="title-number ">
                            <p className="Text700__5">{item.name ?? item.title ?? item.fileName}</p>
                            <p className="Text400__6 violet500 hiddenMobile">   {_type == 'document' ?
                                (<small>{_pages()}</small>) : labels.getText()["BOOKS_INSIDE"]({ number: item.relations })}</p>
                        </div>
                        <div className="creation-date hiddenMobile">
                            {_type == 'document' ? (
                                <span className="Text500__5">
                                    {labels.getText()["BOOKLIST_SMALL_MESSAGE"]({
                                        uploadDate: item.uploadTime.toLocaleDateString(),
                                        uploadTime: item.uploadTime.toLocaleTimeString(),
                                    })}
                                </span>
                            ) :
                                (
                                    <span className="Text500__5">
                                        {labels.getText()["FOLDER_CREATION_DATE"]({
                                            uploadDate: item.creationDate.toLocaleDateString(),
                                            uploadTime: item.creationDate.toLocaleTimeString(),
                                        })}
                                    </span>
                                )}

                        </div>

                    </div>

                </div>

                <div className="d-flex align-items-center mt-4 openDocumentButton hiddenMobile">

                    <button className="then-small-button-ghost margin openButton" onClick={() => _type == 'document' ? onBookPress && onBookPress(item) : onFolderPress && onFolderPress(item)}>
                        {labels.getText()["OPEN"]()}
                    </button>

                    <button className="then-small-button-ghost margin" onClick={() => { onDelete(item) }}>
                        {labels.getText()["DELETE"]()}
                    </button>


                </div>


            </div>


        );
    }

    return _drawItems();
}

export default Books_List;