import { React, Link } from 'react';
import { urls } from "./labels.js";
import changeLanguage from "../../imgs/aboutAnna/changeLanguage.png"
import folderExample from "../../imgs/aboutAnna/folderExample.png"
import fileUpload from "../../imgs/aboutAnna/fileUpload.gif"
import imgUpload from "../../imgs/aboutAnna/imgUpload.gif"
import audioUpload from "../../imgs/aboutAnna/audioUpload.png"
import mindMap from "../../imgs/aboutAnna/mindmap.png"


const getText = () => {
    return {
        "SECONDS": (params) => {
            const { value = 0 } = params;
            return `${value}s`;
        },
        "GENERIC_ERROR": (params) => {
            const { error = "Generic error GE_001" } = params;
            return `Ooops, se detectó un error durante la operación.: ${error}`;
        },
        "ERROR": () => {
            return "Error";
        },
        "ERROR_SENDING_MAIL": () => {
            return `Hubo un problema durante el envío del correo. Inténtalo más tarde.`;
        },
        "ERROR_CREATE_TEST": () => {
            return "Por favor seleccione todos los parámetros requeridos!";
        },
        "EMAIL_RESENT": () => {
            return "Correo electrónico reenviado";
        },
        "CANCELLED": () => {
            return "Cancelado"
        },
        "TERMS_AND_CONDITIONS": () => {
            return "Términos y condiciones"
        },
        "USER_EXISTS": () => {
            return "Este correo electrónico ya está tomado!";
        },
        "MISSING_FIELDS": () => {
            return "Por favor, rellene todos los campos!";
        },
        "ERROR_INSERT_VALID_QUESTION": () => {
            return "Por favor, inserte una pregunta válida";
        },
        "ERROR_WHILE_CONNECTING_TO_SERVER": () => {
            return "Se produjo un error al conectar con el server. Inténtelo más tarde...";
        },
        "ERROR_WHILE_CREATING_MINDMAP": () => {
            return "Se produjo un error al crear el mapa mental";
        },
        "CLASSROOM_PROFESSORS": () => {
            return "Profesores invitados en este aula";
        },
        "CLASSROOM_STUDENTS": () => {
            return "Estudiantes solicitados en esta aula";
        },
     
        "DELETING_DOCUMENT": () => {
            return "eliminar documento";
        },
        "DO_YOU_WANT_TO_DELETE": () => {
            return "¿Realmente quieres eliminarlo permanentemente?";
        },
        "I_DONT_KNOW": () => {
            return "No sé";
        },
        "DRAG_DROP_FILES": () => {
            return "Adjunta documentos";
        },
        "NO_FILES_SELECTED": () => {
            return "No files currently selected for upload";
        },
        "TEXT_COPIED_TO_CLIPBOARD": () => {
            return "No hay archivos seleccionados actualmente para cargar!";
        },
        "LOADING": () => {
            return "Cargando...";
        },
        "LEAVE_MESSAGE_HERE": () => {
            return "Escribe tu pregunta aquí";
        },
        "HOME_WELCOME_MESSAGE": (params) => {
            return (<span>
                Bienvenido a la herramienta de estudio definitiva
            </span>);
        },
        "LIBRARY_WELCOME_MESSAGE": (params) => {
            const { user, docs, folders } = params;
            const number = docs?.length || 0 + folders?.length || 0;

            if (number > 0) {
                if (number > 1) {
                    return (<span>Hola {user.name}, tienes {number} documentos en tu biblioteca</span>);
                }
                else {
                    return (<span>Hola {user.name}, tienes {number} documento en tu biblioteca</span>);
                }
            }
            else {
                return (<span>Hola {user.name}, tu biblioteca está vacía</span>);
            }
        },
        "LIBRARY_WELCOME_MESSAGE_CLASS": (params) => {
            const { classroom, docs, folders } = params;
            const number = (docs?.length || 0) + (folders?.length || 0);

            if (number > 0) {
                if (number > 1) {
                    return (<span>La clase {classroom?.name} tiene {number} documentos en esta biblioteca</span>);
                }
                else {
                    return (<span>La clase {classroom?.name} tiene {number} documento en esta biblioteca</span>);
                }
            }
            else {
                return (<span>La clase {classroom?.name}, la biblioteca está realmente vacía</span>);
            }
        },
        "LIBRARY_WELCOME_MESSAGE_2": (params) => {
            return (<span>
                Tus documentos y libros serán leídos por mi motor de <strong>IA</strong> que producirá
                para ti breves resúmenes, responderá a cualquier pregunta y, si lo deseas, puedo
                preparar un test para verificar tu nivel de conocimiento.
            </span>);
        },
        "FOLDER_EXPLANATION": (params) => {
            return (<span>
                Aquí puedes ver todos los documentos disponibles. Los documentos cuya casilla de verificación está marcada
                se encuentran dentro de la carpeta. ¡Siéntete libre de agregar tantos documentos a la carpeta como desees!
            </span>);
        },
        "LIBRARY_WELCOME_MESSAGE_3": (params) => {
            return (<span>
                Sube imágenes de tu galería y las convertiré en un documento PDF. Mi motor de inteligencia artificial lo leerá y producirá
                para ti breves resúmenes, responderé a cualquier pregunta y, si lo deseas, puedo
                preparar un examen para verificar tu nivel de conocimiento.
            </span>);
        },
        "LIBRARY_WELCOME_MESSAGE_4": (params) => {
            return (<span>
                En función de tu entrada de audio, crearé un nuevo libro para la biblioteca. Mi motor de inteligencia artificial lo leerá y producirá
                breves resúmenes para ti, responderé a cualquier pregunta y, si lo deseas, puedo
                preparar una prueba para verificar tu nivel de conocimiento.
            </span>);
        },
        "DOCUMENT_WELCOME_MESSAGE": (params) => {
            const { linkTo } = params;
            return (<span>
                Aquí puede elegir el tipo de sesión de IA para el documento seleccionado o puede <Link to={linkTo}>volver a la lista</Link>.
            </span>);
        },
        "DOCUMENT_QA_SESSION_TITLE": (params) => {

            return "Q&A";
        },
        "DOCUMENT_QA_SESSION": (params) => {

            return (<span className="Text500__5">
                Chatea conmigo y pregúntame cualquier cosa sobre este documento. Te daré cualquier respuesta que necesites.
            </span>);
        },
        "DOCUMENT_QA_SESSION_FOLDER": (params) => {

            return (<span className="Text500__5">
                Chatea conmigo y pregúntame cualquier cosa sobre los documentos de esta carpeta. Te daré la respuesta que necesites.
            </span>);
        },
        "TRAINING_SESSION_TITLE": (params) => {

            return (<span>
                Training
            </span>);
        },
        "TRAINING_SESSION": (params) => {

            return (<span className="Text500__5">
                Pon a prueba tus conocimientos. Te propongo una simulación de un examen y te sugiero dónde encontrar las soluciones correctas en caso de cometer un error.
            </span>);
        },
        "TRAINING_SESSION_FOLDER": (params) => {

            return (<span className="Text500__5">
                Pon a prueba tus conocimientos. Te propongo una simulación de un examen de todos los documentos incluidos en
                esta carpeta y te sugiero dónde encontrar las soluciones adecuadas en caso de que cometas un error.
            </span>);
        },
        "MIND_MAP_SESSION_TITLE": (params) => {

            return "MINDMAP";
        },
        "MIND_MAP_SESSION": (params) => {

            return "Puedo prepararte un mapa mental del documento que te ayudará a pensar, recopilar conocimientos y recordar.";
        },
        "MIND_MAP_SESSION_FOLDER": (params) => {

            return (<span>
                Puedo prepararte un mapa mental de los documentos dentro de esta carpeta para ayudarte a pensar, recopilar conocimientos y recordar.
            </span>);
        },
        "SUMMARY_SESSION_TITLE": (params) => {

            return (<span>
                Resumen y traducción
            </span>);
        },
        "SUMMARY_SESSION": (params) => {

            return (<span>
                Puedo resumir y traducir su documento.
            </span>);
        },
        "GO": (params) => {

            return (<span>
                Ir
            </span>);
        },
        "TRY_IT": (params) => {

            return (<span>
                Vamos a intentarlo
            </span>);
        },
        "TELL_ME_MORE": (params) => {

            return (<span>
                Cuéntame más
            </span>);
        },

        "ASK_ME": (params) => {

            return (<span>
                Pregúnteme
            </span>);
        },
        "DOWNLOAD_PDF": (params) => {

            return (<span>
                Descargar PDF
            </span>);
        },
        "RETURN_TO_DOC": () => {

            return "Volver";
        },
        "GO_TO_PAGE": () => {
            return "Ir a la página";
        },
        "PREVIOUS": () => {
            return "Previo";
        },
        "PREVIOUS_PAGES": () => {
            return "Página anterior";
        },
        "CREATED_BY": () => {
            return "Creado por:";
        },
        "NEXT_PAGES": () => {
            return "Página siguiente";
        },
        "NEXT": () => {
            return "Próxima";
        },
        "DELETE": () => {
            return "Borrar";
        },
        "EDIT": () => {
            return "Editar";
        },
        "DOCUMENT_QA_SESSION_INTRO": (params) => {

            return (<span>
                He leído el documento y ahora puedo responder a cualquier pregunta. Inténtalo. Introduce el texto de la pregunta y espera a que te responda.
            </span>);
        },
        "DOCUMENT_UPLOADED": (params) => {
            const { date } = params;

            return (<span>
                Cargado el: {date.toLocaleDateString()}.
            </span>);
        },
        "FOLDER_CREATED": (params) => {
            const { date } = params;

            return (<span>
                Creado el: {date.toLocaleDateString()}.
            </span>);
        },
        "CLASS_NAME": (params) => {
            const { name } = params;

            return (<span>
                Editar {name}
            </span>);
        },
        "PAGES_LIST": (params) => {
            const { pages = [] } = params;

            try {
                return pages.length > 1 ? "Páginas" : "Página";
            }
            catch (err) {
                return (null);
            }

        },
        "DOWNLOAD": () => {
            return "Descargar";
        },
        "COPY_TO_CLIPBOARD": () => {
            return "Copiar al portapapeles";
        },
        "COPY_SUMMARY": () => {
            return "Copiar";
        },

        "DELETE_QUESTION": () => {
            return "Eliminar este documento";
        },
      
        "ADD_TO_FOLDER": () => {
            return "Agregar a la carpeta";
        },
        "ADD_FOLDER": () => {
            return "Agregar nuevo documento o carpeta";
        },
        "DELETE_FOLDER": () => {
            return "Eliminar esta carpeta";
        },
        "PREPARING_PACKAGE": () => {
            return "Estoy preparando el examen y las preguntas...";
        },
        "PACKAGE_NUMBER": (params) => {
            const { number } = params;
            return `Test #${number}`;
        },
        "PACKAGE": () => {
            return `Test`;
        },
        "PACKAGE_PROCCESS_FINISH": () => {
            return "¡El procesamiento de prueba ha finalizado!";
        },
        "PACKAGE_PROCCESS_FINISH_LESS_QUESTIONS": () => {
            return "No se pudo generar la cantidad de preguntas solicitada. El documento es demasiado pequeño.";
        },
        "PREPARING_DOCUMENT": () => {
            return "Estoy preparando el documento...";
        },
        "DOCUMENT_NUMBER": (params) => {
            const { number } = params;
            return `Documento #${number}`;
        },
        "DOCUMENT_PROCCESS_FINISH": () => {
            return "¡El procesamiento del documento ha finalizado!";
        },
        "DOCUMENT_NAME_MINDMAP": (params) => {
            const { title } = params;
            return `${title}`;
        },
        "PREPARING_MINDMAP": () => {
            return "Estoy preparando el mapa mental...";
        },
        "INDEXING_PROGRESS": () => {
            return "Progreso de indexación:";
        },
        "WAITING_FOR_DATA": () => {
            return "Esperando datos...";
        },
        "WAIT": () => {
            return "Esperar";
        },
        "EXPORT_DOCX": () => {
            return "Exportar a docx";
        },
        "EXPORT_PPTX": () => {
            return "Exportar a pptx";
        },
        //#region breadcrumb
        "BREADCRUMB_HOME": () => {
            return "Home";
        },
        "PASSWORD_REQ": () => {
            return "¡Su contraseña no cumple con los requisitos!";
        },
        "BREADCRUMB_CLASSROOMS": () => {
            return "Aulas";
        },
        "BREADCRUMB_DOCUMENT": (params) => {
            const { name } = params;
            return `${name}`;
        },
        "BREADCRUMB_QA": () => {
            return "Q & A";
        },
        "BREADCRUMB_TRAINING": () => {
            return "Training";
        },
        "BREADCRUMB_SURVEY": () => {
            return "Test";
        },
        "BREADCRUMB_SURVEY_ID": (params) => {
            const { id } = params;
            return `${id}`;
        },
        "BREADCRUMB_MINDMAP": () => {
            return "Mapa mental";
        },
        "BREADCRUMB_SUBMINDMAP": (params) => {
            const { topic } = params;
            return `${topic}`;
        },

        //#endregion

        "ADD": () => {
            return "Agregar ";
        },
        "ABOUT_CLASS": () => {
            return "Acerca de la clase ";
        },
        "DOCUMENT_FOLDERS": () => {
            return "Documentos y carpetas del aula";
        },

        "ADD_DOCUMENT": () => {
            return "Añadir nuevo";
        },

        "DONE": () => {
            return "Hecho";
        },
        "OTP_MODAL_FIRST_PART_RESEND": () => {
            return "¿No has recibido el código? Haz clic ";
        },
        "OTP_MODAL_SECOND_PART_RESEND": () => {
            return "aquí ";
        },
        "OTP_MODAL_THIRD_PART_RESEND": () => {
            return "para reenviar el correo electrónico. ";
        },
        "GENERAL_INFO": () => {
            return "Información general";
        },


        "UPLOAD_NEW_FILE": () => {
            return "Agregar un nuevo documento";
        },

        "UPLOAD_NEW_FILE_SMALL": () => {
            return "Si deseas puedes subir un documento aquí";
        },

        "BOOKLIST_SMALL_MESSAGE": (params) => {
            const { uploadDate, uploadTime } = params;

            return `Enviado en ${uploadDate} at ${uploadTime}`;
        },
        "YOU_HAVE_BEEN_INVITED_IN_CLASS": (params) => {
            const { classroom } = params;

            return `Has sido invitado a clase. ${classroom}`;
        },
        "FOLDER_CREATION_DATE": (params) => {
            const { uploadDate, uploadTime } = params;

            return `Creado en ${uploadDate} at ${uploadTime}`;
        },

        "YOUR_LIBRARY": () => {
            return "TU BIBLIOTECA";
        },
        "CAMERA_UPLOAD": () => {
            return "Carga de cámara";
        },
        "VOICE_UPLOAD": () => {
            return "Subir voz a texto";
        },
        "EMAIL_ADDRESS": () => {
            return "E-Mail";
        },
        "PASSWORD": () => {
            return "Contraseña";
        },
        "CONFIRM_PASSWORD": () => {
            return "Confirmar contraseña";
        },

        "REMEMBER_ME": () => {
            return "Recordar";
        },
        "FORGOT_PASSWORD": () => {
            return "¿Has olvidado tu contraseña?";
        },
        "WHO_ARE_YOU": () => {
            return "¿Quién eres?";
        },
        "OPEN": () => {
            return "Abrir";
        },
        "YOU_NEED_TO_LOGIN_FIRST": () => {
            return "Necesitas iniciar sesión primero";
        },
        "ACCEPT": () => {
            return `Aceptar`;
        },
        "DECLINE": () => {
            return `Rechazar`;
        },
        "REMOVE": () => {
            return `Eliminar`;
        },
        "CLASSROOM_JOINED": () => {
            return `Aula unida`;
        },
        "INVITATION_DECLINED": () => {
            return `Invitación rechazada`;
        },
        "PAGES": (params) => {
            const { pages } = params;
            if (pages == 0) {
                return `No hay páginas para el documento`

            }
            else if (pages == 1) {
                return `${pages} pagina`

            }
            else {
                return `${pages} paginas`

            }
        },
        "CREATE_YOUR_IDENTITY": () => {
            return "Crea tu cuenta gratuita";
        },
        "CREATE_TEST": () => {
            return "Crear nueva prueba";
        },
        "CREATE_FOLDER": () => {
            return "Crear carpeta";
        },
        "FOLDER_NAME": () => {
            return "Nombre de la carpeta";
        },
        "SHOW_MORE": () => {
            return "Mostrar más";
        },
        "SHOW_LESS": () => {
            return "Mostrar menos";
        },
        "NO_ELEMENTS_TO_SHOW": () => {
            return "No hay elementos para mostrar";
        },
        "NO_TEST_TO_SHOW": () => {
            return "No hay ninguna prueba que demostrar";
        },

        "ERROR_DURING_CREATION": () => {
            return "¡Se produjo un error durante la creación de la cuenta!";
        },
        "WRONG_CODE": () => {
            return "El código de confirmación es incorrecto.";
        },
        "USER_CREATED": () => {
            return "¡Su cuenta ha sido creada exitosamente!";
        },
        "REGISTER_CREDENTIAL": () => {
            return "Inserta tus credenciales";
        },
        "OTP_MODAL_BODY": () => {
            return "Por favor revise su bandeja de entrada de correo electrónico, donde le hemos enviado el Código de Confirmación";
        },
        "OTP_MODAL_TITLE": () => {
            return "Por favor confirme su correo electrónico";
        },
        "OTP_MODAL_DELETE_TITLE": () => {
            return "Por favor, confirme la eliminación";
        },
        "NAME": () => {
            return "Nombre";
        },
        "LAST_NAME": () => {
            return "Apellido";
        },
        "SCHOOL_NAME": () => {
            return "Nombre de la escuela";
        },
        "USERNAME": () => {
            return "Nombre de usuario";
        },
        "DATE_OF_BIRTH": () => {
            return "Fecha de nacimiento";
        },
        "SELECT_COUNTRY": () => {
            return "Seleccione su país";
        },
        "CHECK_TERMS_CONDITIONS": () => {
            return "Comprueba si estás de acuerdo con los Términos y Condiciones";
        },
        "TEST_PAGE_LABELS": (params) => {
            const { user, questionsPackages } = params;
            if (questionsPackages > 0) {
                if (questionsPackages == 1) {
                    return `${user}, tenemos una prueba para este documento`
                }
                else {
                    return `${user}, tenemos ${questionsPackages} pruebas para este documento`

                }
            }
            else {
                return `${user}, no tenemos ninguna prueba para este documento`
            }
        },
        "QUESTIONS_COUNT": () => {
            return `Las preguntas cuentan`;
        },
        "GROUP_NAME": () => {
            return `Nombre del grupo`;
        },

        "CREATE_CLASSROOM": () => {
            return `Crear un aula`;
        },
        "CREATE_GROUP": () => {
            return `Crear nuevo grupo`;
        },
        "CLASSROOM_NAME": () => {
            return `Nombre del aula`;
        },
        "SEARCH_MEMBERS": () => {
            return `Buscar miembros`;
        },
        "YOU_CANNOT_ENTER_THIS_CLASS": () => {
            return "¡No podrás ingresar a esta clase hasta que el profesor te acepte!";
        },
        "YOU_ALREADY_ADDED_THIS_CLASS": () => {
            return "El usuario ya existe en esta aula";
        },
        "WAITING_FOR_APPROVAL": () => {
            return "Esperando aprobación...";
        },
        "SEARCH_DOCUMENTS": () => {
            return `Buscar documentos`;
        },
        "CREATE_CARD": () => {
            return `Crear nueva clase`;
        },
        "COLOR": () => {
            return `Elija el color de la tarjeta`;
        },
        "CHOOSE": () => {
            return `Elige tu país`;
        },
        "INVITE_PROFESSOR": () => {
            return `Invitar al profesor`;
        },
        "INVITATION": () => {
            return `Invitación`;
        },
        "INSERT_EMAIL": () => {
            return `Insertar correo electrónico`;
        },
        "NO_OPTIONS": () => {
            return `Sin opciones`;
        },
        "ACCEPT_T&C": () => {
            return `¡Acepte los términos y condiciones!`;
        },
        "NOT_GOOD_EMAIL": () => {
            return `¡Por favor ingrese un correo electrónico válido!`;
        },
        "PASSWORDS_NO_MATCH": () => {
            return `¡Las contraseñas no coinciden!`;
        },
        "PLEASE_FILL_IN_THE_FIELDS": () => {
            return `¡Por favor, rellene todos los campos!`;
        },
        "MEMBERS": () => {
            return `Miembros`;
        },
        "MEMBER": () => {
            return `Miembro`;
        },
        "TEST_DIFFICULTY": () => {
            return `Dificultad de la prueba`;
        },
        "ANSWERS_TYPE": () => {
            return `Respuestas Tipo`;
        },
        "ADD_TO_CLASSROOM": () => {
            return `Agregar al aula`;
        },
        "ADD_NEW_CLASSROOM": () => {
            return `Agregar nueva aula`;
        },
        "ADD_NEW_GROUP": () => {
            return `Agregar nuevo grupo`;
        },
     
        "ANSWERS_QUANTITY": () => {
            //return `Respuestas correctas`;
            return `Number of choices`;
        },
        "SINGLE_CHOICES": () => {
            return "Opción única";
        },
        "MULTIPLE_CHOICES": () => {
            return "Múltiples opciones";
        },
        "CLOSE_ANSWERS": () => {
            return `Respuestas cerradas`;
        },
        "EDIT_CARD": () => {
            return `Guardar`;
        },
        "CLASSROOM_DELETE": () => {
            return `Aula eliminada exitosamente`;
        },
        "CLASSROOM_DELETE_ERROR": () => {
            return `Se produjo un error al intentar eliminar el aula.`;
        },
        "CLASSROOM_ADDED": () => {
            return `Aula agregada exitosamente`;
        },
        "CLASSROOM_ADDED_ERROR": () => {
            return `Se produjo un error al intentar agregar el aula`;
        },
        "OPEN_ANSWERS": () => {
            return `Respuestas abiertas`;
        },
        "EASY_QUESTIONS": () => {
            return `Fácil`;
        },
        "MEDIUM_QUESTIONS": () => {
            return `Medio`;
        },
        "HARD_QUESTIONS": () => {
            return `Difícil`;
        },
        "EXTREME_QUESTIONS": () => {
            return `Extremo`;
        },
        "CLOSE": () => {
            return `Cerrar`;
        },
        "TEST_SMALL_MESSAGE": (params) => {
            const { generatedDate, generatedTime } = params;

            return `Generado el ${generatedDate} a las ${generatedTime}`;
        },
        "NQUESTIONS": (params) => {
            const { numbers } = params;

            return `${numbers} preguntas`;
        },
        "PROGRESS": () => {
            return `Progreso`;
        },
        "SUBMIT_BUTTON": () => {
            return `Entregar`;
        },
        "CREATE_PDF": () => {
            return `Crear PDF`;
        },
        "FACEBOOK_LOGIN": () => {
            return "Iniciar sesión con Facebook";
        },
        "GOOGLE_LOGIN": () => {
            return "Iniciar sesión con Google";
        },
        "ARTIFICIAL_INTELLIGENCE_FOR_STUDIES": () => {
            return "Inteligencia artificial para tus estudios";
        },
        "START_NOW": () => {
            return "Empieza ahora";
        },
        "REGISTER": () => {
            return "Registro";
        },
        "PROBLEM": () => {
            return "Nosotras tenemos algunos problemas";
        },
        "FACEBOOK_PROBLEM": () => {
            return "Tenemos algunos problemas con el inicio de sesión de Facebook";
        },
        "GOOGLE_PROBLEM": () => {
            return "Tenemos algunos problemas con el inicio de sesión de Google... Algo sale mal";
        },
        "LOGIN_CREDENTIAL": () => {
            return "Por favor introduce tus credenciales";
        },
        "LOGIN": () => {
            return "Accede";
        },
        "SELECTED_COLOR": () => {
            return "Color seleccionado:";
        },
        "WRONG_CREDENTIALS": () => {
            return "Credenciales incorrectas";
        },
        "LANGUAGE_MODAL_LABEL": () => {
            return "Seleccione idioma";
        },
        "MUST_INSERT_CLASSROOM_NAME": () => {
            return "Debes agregar el nombre del aula";
        },
        "MANAGE_YOUR_CLASS": () => {
            return "Administra tus clases";
        },
        "INVITE_PROFESSORS": () => {
            return "Invitar a profesores";
        },
        "MY_CLASSESS": () => {
            return "Mis aulas";
        },
        "ADD_CLASSROOM": () => {
            return "Agregar aula";
        },
        "JOIN_NEW_CLASSROOM": () => {
            return "Unete a la clase";
        },
        "ENTER_CODE": () => {
            return "Introducir código de activación";
        },
        "ENTER_CLASS": () => {
            return "Entrar a la clase";
        },
        "MENU_USER_LOGGED": (params) => {
            const { upperCaseLanguage, user, section } = params;
            let classroomsLink = null;

            if (user) {
                //you are a professor
                if (user.isProfessor) {
                    classroomsLink = user.isConfirmedProfessor ? `/es${urls.professor_classrooms}` : `/es${urls.not_confirmed_professor_landing}`
                }
                else {
                    classroomsLink = `/es${urls.student_classrooms}`;
                }
            }

            window.$("#navbarCollapse").html(`<div class="navbar-nav ms-auto Text500__5">
                <a href="/es" class="nav-item nav-link ${(section === "library" ? "active" : "")}">Library</a>
                <a href="/es${classroomsLink}" class="nav-item nav-link ${(section === "classrooms" ? "active" : "")}">Classrooms</a>
                <a href="/es${urls.account}/" class="nav-item nav-link ${(section === "settings" ? "active" : "")}">Account</span>
                <strong><a href="/es${urls.logout}" class="nav-item nav-link">Logout</a></strong>
                ${urls.language_selector.replace("##LANGUAGE##", upperCaseLanguage)}
              </div>`);


        },
        "CHANGE_PASSWORD": () => {
            return "Cambiar la contraseña";
        },
        "OLD_PASSWORD": () => {
            return "Contraseña anterior";
        },
        "NEW_PASSWORD": () => {
            return "Nueva contraseña";
        },
        "ENTER_OLD_PASSWORD": () => {
            return "Ingrese su contraseña anterior";
        },
        "ENTER_NEW_PASSWORD": () => {
            return "Ingrese su nueva contraseña";
        },
        "CHECK_TERMS_CONDITIONS_SOCIAL": () => {
            return "Si te registras con una red social aceptas automáticamente ";
        },
        "CHECK_TERMS_CONDITIONS1": () => {
            return "Marque si está de acuerdo con ";
        },
        "CHECK_TERMS_CONDITIONS2": () => {
            return "Términos y condiciones";
        },
        "MENU_USER_NOT_LOGGED": (params) => {
            const { upperCaseLanguage, section } = params;
            window.$("#navbarCollapse").html(`<div class="navbar-nav ms-auto Text500__5">
<a href="/es" class="nav-item nav-link ${(section == "home" ? "active" : "")}">Inicio</a>
<a href="/en/aboutANNA" class="nav-item nav-link ${(section == "aboutANNA" ? "active" : "")}">About ANNA</a>
<a href="/es/contactus" class="nav-item nav-link" ${(section == "contacts" ? "active" : "")}>Contactos</a>
<strong><a href="/es/login" class="nav-item nav-link" id="LoginButtonNav">Iniciar sesión</a></strong>
${urls.language_selector.replace("##LANGUAGE##", upperCaseLanguage)}
<a href="/es/register" id="try-button-menu" class="then-small-button ml-5" >Probar gratis</a>

</div>`);

        },
        "FOOTER_BOTTOM_MENU_LOGGED": () => {
            window.$("#rightsReserved").html(`
             
                &copy; <a href='https://www.labh9.eu' target='_blank' rel="noopener noreferrer">ANNA</a>, Todos los derechos reservados.
          
            `);
            window.$("#secondFooterColumn").html(`
                                <div class="linkRowFooter">
<a href="/es" class="linkFooter">Biblioteca</a>
<a href="/es/aboutANNA" class="linkFooter" target='_blank' rel="noopener noreferrer">Acerca de</a>
<a href="/es/contactus" class="linkFooter">Contactos</a>
<a href="/es/terms_and_conditions" class="linkFooter">Términos y condiciones</a>
<a href="/es/privacy_policy" class="linkFooter">Política de privacidad</a>
<a href='https://www.labh9.eu'  class="linkFooter" target='_blank' rel="noopener noreferrer">Powered by<img class="footerPoweredLogo"  src="/Labh9logo.png"></img></a>

</div> 
            `);
        },
        "FOOTER_BOTTOM_MENU_NOT_LOGGED": () => {
            window.$("#rightsReserved").html(`
             
                                &copy; <a href='https://www.labh9.eu' target='_blank' rel="noopener noreferrer">ANNA</a>, Todos los derechos reservados.
                          
            `);
            window.$("#secondFooterColumn").html(`
                 <div class="linkRowFooter">
<a href="https://www.labh9.eu" class="linkFooter" target='_blank' rel="noopener noreferrer">Acerca de</a>
<a href="/contactus" class="linkFooter">Contactos</a>
<a href="/es/terms_and_conditions" class="linkFooter">Términos y condiciones</a>
<a href="/es/privacy_policy" class="linkFooter">Política de privacidad</a>
<a href='https://www.labh9.eu'  class="linkFooter" target='_blank' rel="noopener noreferrer">Powered by<img class="footerPoweredLogo"  src="/Labh9logo.png"></img></a>

</div>
            
            `);
        },
        // "GET_IN_TOUCH_FOOTER": () => {
        //     window.$("#getInTouch").html(`
        //         <h5 class="text-white mb-4">Get In Touch</h5>
        //             <p><i class="fa fa-map-marker-alt me-3"></i>Lvivo g. 25, Vilnius 09320, Lithuania</p>
        //             <p><i class="fa fa-phone-alt me-3"></i>+012 345 67890</p>
        //             <p><i class="fa fa-envelope me-3"></i>info@lab-h9.com</p>
        //             <div class="d-flex pt-2">
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-twitter"></i></a>
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-facebook-f"></i></a>
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-youtube"></i></a>
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-instagram"></i></a>
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-linkedin-in"></i></a>
        //             </div>

        //     `);
        // },
        "PASSWORD_REQUIREMENTS": () => {
            window.$("#password-Req").html(`
              <div className="password-requirements" id="password-Req">
<p>La contraseña debe cumplir con los siguientes requisitos:</p>
<ul>
<li>Al menos 8 caracteres</li>
<li>Contiene al menos una letra mayúscula</li>
<li>Contiene al menos una letra minúscula</li>
<li>Contiene al menos un número</li>
<li>Contiene al menos un carácter especial</li>
</ul>
</div>
            
            `);
        },
        "ENTER_NAME": () => {
            return "Por favor, introduzca su nombre.";
        },
        "ENTER_EMAIL": () => {
            return "Por favor, introduzca su correo electrónico.";
        },
        "ENTER_LASTNAME": () => {
            return "Por favor, introduzca su apellido.";
        },
        "ENTER_PASSWORD": () => {
            return "Por favor, introduzca su contraseña.";
        },
        "ENTER_BIRTHDATE": () => {
            return "Ingrese una fecha de nacimiento válida.";
        },
        "ENTER_COUNTRY": () => {
            return "Por favor ingrese su país.";
        },
        "ENTER_CONFIRM_PASSWORD": () => {
            return "Por favor confirme su contraseña.";
        },
        "ENTER_SCHOOL": () => {
            return "Por favor ingrese su escuela.";
        },
        "PASS_REQS": () => {
            return "La contraseña debe cumplir los siguientes requisitos:";
        },
        "PASS_REQS1": () => {
            return "Al menos 8 caracteres de longitud";
        },
        "PASS_REQS2": () => {
            return "Contiene al menos una letra mayúscula";
        },
        "PASS_REQS3": () => {
            return "Contiene al menos una letra minúscula";
        },
        "PASS_REQS4": () => {
            return "Contiene al menos un número";
        },
        "PASS_REQS5": () => {
            return "Contiene al menos un carácter especial.";
        },

        "WHOAREYOU_MODAL": () => {
            return "¿Quién es usted?"
        },
        "NORMAL_USER": () => {
            return "Usuario"
        },
        "USER_PROFILE": () => {
            return "Tu perfil"
        },
        "PROFESSOR_USER": () => {
            return "Profesor"
        },
        "ADD_MODAL_ELEMENT": () => {
            return "¿Qué quieres agregar?"
        },
        "UPLOAD": () => {
            return "Subir"
        },
        "CLEAR": () => {
            return "Borrar"
        },
        "PRESS_MIC_TO_UPLOAD": () => {
            return "Pulsa el micrófono para subir"
        },
        "UPLOAD_TRANSCRIBE": () => {
            return "Subir y transcribir "
        },
        "OR": () => {
            return "oppuro"
        },
        "REMOVE_STUDENT_HEADER": () => {
            return `Eliminando estudiante`;
        },
        "REMOVE_STUDENT_BODY": () => {
            return `El estudiante será eliminado de la clase, ¿estás seguro?`;
        },
        "AUDIO_EXPLANATION": () => {
            return "Presiona el botón para comenzar a grabar y suéltelo cuando desees continuar con el envío."
        },
        "AUDIO_FILE_PROGRESS": () => {
            return "Estoy analizando el audio..."
        },
        "REMOVE_STUDENT_BUTTON": () => {
            return "Retirar al estudiante";
        },
        "STUDENT_DELETE_DONE": () => {
            return "Alumno eliminado";
        },
        "STUDENT_DELETE_SUCCESSFULLY": () => {
            return "Estudiante retirado del aula con éxito";
        },
        "STUDENT_DELETE_PROBLEM": () => {
            return "Hubo algún problema durante la operación, el estudiante no fue retirado.";
        },
        "NO_CLASS_FOR_CODE": () => {
            return "Clase no encontrada con este código";
        },
        "USER_JUST_IN_CLASSROOM": () => {
            return "El usuario está en el aula o está esperando el acceso.";
        },
        "MISSING_CODE": () => {
            return "El código es obligatorio para enviar la solicitud.";
        },
        "DOCUMENT_NAME": () => {
            return "Nombre del documento";
        },
        "UPLOADED_IN": () => {
            return "Subido en";
        },
        "ELEMENT_TYPE": () => {
            return "Tipo";
        },
        "PROFESSOR_NAME": () => {
            return "Professor";
        },
        "EMAIL_PROFESSOR": () => {
            return "E-mail";
        },
        "MOBILE_PHONE": () => {
            return "Número de teléfono";
        },
        "PROFESSOR_ACTION": () => {
            return "Borrar";
        },
        "REMOVE_PROFESSOR_HEADER": () => {
            return `Quitar profesor`;
        },
        "REMOVE_PROFESSOR_BODY": () => {
            return `Estás a punto de sacar al profesor de la clase. ¿Estás seguro de que quieres continuar?`;
        },
        "REMOVE_PROFESSOR_BUTTON": () => {
            return `Borrar`;
        },
        "PROFESSOR_DELETE_DONE": () => {
            return "Profesor eliminado";
        },
        "PROFESSOR_DELETE_SUCCESSFULLY": () => {
            return "Profesor eliminado de la clase con éxito.";
        },
        "PROFESSOR_DELETE_PROBLEM": () => {
            return "Hubo algún problema, el usuario no se retiró del aula";
        },
        "GO_TO_LIBRARY": () => {
            return "Documentos";
        },
        "CLASSROOM_CODE_LABEL_PROFESSOR": (params) => {
            const { code } = params;
            return (<span>
                Código identificador del aula: <strong>{code ?? ""}</strong>
            </span>);

        },
        "NO_DOCUMENTS_GRID": () => {
            return ("La biblioteca de documentos está vacía");
        },
        "NO_PROFESSOR_GRID": () => {
            return ("No hay profesores en clase");
        },
        "NO_STUDENT_GRID": () => {
            return ("No hay alumnos en clase");

        },

        "BOOKS_INSIDE": (params) => {
            const { number } = params;
            if (number > 0) {
                if (number > 1) {
                    return (

                        <small>{`${number} documentos dentro `}</small>)
                }
                else {
                    return (

                        <small>{`${number} documento dentro`}</small>)
                }
            } else {
                return (
                    <small>{`Carpeta vacía`}</small>)
            }
        },
        "CLASSROOM_LIBRARY": () => {
            return "Biblioteca de aula";
        },
        "WELL_DONE": () => {
            return "¡Bien hecho!";
        },
        "ALL_CORRECT": (params) => {
            const { maxScore } = params;

            return `Aww sí, ¡has respondido con éxito a todas las preguntas  ${maxScore}!`;
        },
        "TOPIC_EXPERT": () => {
            return "Ahora eres un experto en este tema.";
        },
        "GOOD_JOB": () => {
            return "¡Buen trabajo!";
        },
        "SOMEONE_CORRECT": (params) => {
            const { actualScore, maxScore } = params;
            return `Enhorabuena, has respondido correctamente a ${actualScore} de ${maxScore} preguntas.`;
        },
        "SOMEONE_CORRECT_SAD": (params) => {
            const { actualScore, maxScore } = params;
            return `Oh no, ¡has contestado correctamente ${actualScore} de ${maxScore} preguntas!`;
        },
        "TEST_TRY_AGAIN": () => {
            return "¡Comprueba las respuestas erróneas e intentémoslo de nuevo!";
        },
        "STUDY_MORE": () => {
            return "Tienes que estudiar más.";
        },
        "ANSWER_NOT_SELECTED": () => {
            return "La respuesta no ha sido seleccionada.";
        },
        "YOU_ANSWERED": () => {
            return "Usted respondió";
        },
        "BACK_TO_QUESTIONS": () => {
            return "Volver a las preguntas";
        },
        "CLASSROOM": () => {
            return "Aula";
        },
        "LEAVE_CLASSROOM": () => {
            return "Abandonar el aula";
        },
        "LEAVE_CLASSROOM_MESSAGE": () => {
            return "¿Estás seguro de que quieres dejar esta clase?";
        },
        "LEAVE_CLASSROOM_BUTTON": () => {
            return "Abandonar la clase";
        },
        "SHARE_RESULT": () => {
            return "Compartir mi resultado";
        },
        "PAGES_LIST_WITHPAGE": (params) => {
            const { pages = [] } = params;

            var pageNumbers = pages ? pages.join(",") : "-"


            return (<span>{pages?.length > 1 ? "Páginas" : "Página"}: {pageNumbers}</span>)

                ;
        },
        "CONTEXT_HELP": () => {
            return "Ayuda: ";
        },
        "ANSWER": () => {
            return "Respuesta: ";
        },
        "ANSWERES_CHECKED": () => {
            return `Respuestas comprobadas correctamente!!`;
        },
        "CHECKING_ANSWERS": () => {
            return "Comprobar las respuestas";
        },
        "USER_PROFILE_SELECT_DESCRIPTION": () => {
            return "Ver y gestionar los datos de su perfil";
        },
        "PAYMENT_DETAILS": () => {
            return "Datos de pago";
        },
        "PAYMENT_DETAILS_DESCRIPTION": () => {
            return "Ver tu historial de pagos de forma detallada";
        },
        "SUBSCRIPTION_DETAILS": () => {
            return "Suscripción";
        },
        "SUBSCRIPTION_DETAILS_DESCRIPTION": () => {
            return "Ver todos los detalles de tu suscripción";
        },
        "USER_PROFILE_SELECT": () => {
            return "Configuración del perfil de usuario";
        },
        "ENTER_BUTTON": () => {
            return "Entre en";
        },
        "ACCOUNT_DETAILS": () => {
            return "Datos de tu cuenta";
        },
        "ACCOUNT_DETAILS_DESC": () => {
            return "Aquí puedes ver los detalles de tu cuenta. También puede editarlos.";
        },
        "USER_SETTINGS": () => {
            return "Configuración del usuario";
        },
        "USER_SETTINGS_DESC": () => {
            return "Aquí puedes ver cualquier información relacionada con tu cuenta y tus suscripciones";
        },
        "SUBSCRIPTION_SETTINGS": () => {
            return "Datos de suscripción";
        },
        "SUBSCRIPTION_SETTINGS_DESC": () => {
            return "Aquí puede ver todos los detalles relacionados con su suscripción: contadores, fechas y mucho más."
        },
        "CANCEL": () => {
            return "Cancelar"
        },
        "DELETE_ACCOUNT": () => {
            return "Eliminar cuenta"
        },
        "FIRST_NAME": () => {
            return "Nombre"
        },
        "ACCOUNT_TYPE": (params) => {
            const { accountType } = params;
            return `${accountType}`;
        },
        "SAVE": () => {
            return "Guardar"
        },
        "ACCOUNT_TYPE_LABEL": () => {
            return "Tipo de cuenta"
        },
        "DELETE_ACCOUNT_BODY": () => {
            return "¿Está seguro de que desea eliminar su cuenta? Esta acción también eliminará todos los documentos, paquetes de preguntas y mapas mentales que haya cargado."
        },
        "DELETE_ACCOUNT_TITLE": () => {
            return "¿Está seguro?"
        },
        "YES": () => {
            return "Sí"
        },
        "NO": () => {
            return "No"
        },
        "TO_CHANGE_EMAIL": () => {
            return "Para cambiar tu correo electrónico, ponte en contacto con nuestro  servicio de asistencia."
        },
        "SUBSCRIPTION_PLAN": () => {
            return "Plan de suscripción:"
        },
        "QUESTIONS_ASKED": () => {
            return "Preguntas formuladas:"
        },
        "TESTS_GENERATED": () => {
            return "Pruebas generadas:"
        },
        "MINUTES_OF_SPEECH": () => {
            return "Transcripción del acta de la intervención:"
        },
        "PAGES_UPLOADED": () => {
            return "Páginas del documento cargadas:"
        },
        "SUBSCRIPTION_EXPIRES": () => {
            return "La suscripción caduca a las:"
        },
        "COUNTER_RESET": () => {
            return "Los contadores se reiniciarán en:"
        },
        "NO_DATA_AVAILABLE": () => {
            return "No hay datos disponibles"
        },
        "ORDER": () => {
            return "OBTIENE PLAN"
        },
        "WEEKLY": () => {
            return "Semanal"
        },
        "MONTHLY": () => {
            return "Mensualmente"
        },
        "YEARLY": () => {
            return "Anualmente"
        },
        "DURATION_SUB": (params) => {
            const { expirationUnit } = params;
            return `${expirationUnit}`;
        },
        "CATALOGUE": () => {
            return `Catálogo de suscripciones`;
        },
        "CATALOGUE_HEADER": () => {
            return `Seleccions su suscripción`;
        },
        "CHECKOUT_HEADER": () => {
            return `Pago de suscripciones`;
        },
        "SELECT_METHOD": () => {
            return `Seleccione el método de pago a continuación:`;
        },
        "PROCEED_CHECKOUT": () => {
            return `Proceder al pago`;
        },
        "LOGIN_PROCEED": () => {
            return `Para continuar es necesarion iniciar sesión.`;
        },
        "DONT_HAVE_ACC": () => {
            return `¿No estás registrado?`;
        },
        "DONT_HAVE_ACC2": () => {
            return `¡Inscríbete!`;
        },
        "DELETE_ELEMENT_MODAL": () => {
            return "Va a eliminar un elemento";
        },
        "REMOVE_ELEMENT_BODY_MODAL": (params) => {
            const { elementName } = params

            return `Está a punto de eliminar permanentemente ${elementName} de su biblioteca.
             Esta acción es irreversible, ¿está seguro de que desea continuar?`;
        },
        "REMOVE_ELEMENT_BUTTON": () => {
            return "Borrar";
        },
        "SUBSCRIPTION_SUCCESSFUL": () => {
            return "Suscripción realizada.";
        },
        "SUBSCRIPTION_ERROR": () => {
            return "Suscripción fallida.";
        },

        "LIBRARY_WELCOME_MESSAGE_NUMEBR": (params) => {
            const { user, docs, folders } = params;
            const number = docs?.length || 0 + folders?.length || 0;

            if (number > 0) {
                if (number > 1) {
                    return (<span> Hay {number} documentos en su biblioteca</span>);
                }
                else {
                    return (<span> Hay {number} documento en su biblioteca</span>);
                }
            }
            else {
                return (<span>Su biblioteca está realmente vacía</span>);
            }
        },

        "WIP": () => {
            return `Trabajar en...`;
        },
        "PAYMENT_DETAILS_DESC": () => {
            return "Aquí puede ver y comprobar el historial de pagos ";
        },
        "PAYPAL_CHECKOUT_ERROR": () => {
            return "¡Error durante el proceso de pago, por favor inténtelo de nuevo!";
        },
        "SUBSCRIPTION_IN_PROGRESS": () => {
            return "Por favor espere, suscripción en curso...";
        },
        "LANGUAGE_NOT_FOUND": () => {
            return "Idioma no encontrado";
        },
        "NO_PAYMENTS": () => {
            return "No se han encontrado pagos";
        },
        "RETURN_TO_TEST": () => {
            return "Volver a la prueba";
        },
        "RETURN_TO_TEST_NEW": () => {
            return "Prueba de reintento";
        },
        "TRAINING_HEADER": (params) => {
            const { type } = params;
            if (type === "document") {
                return "PRUEBAS PARA SU DOCUMENTO";
            }
            if (type === "folder") {
                return "PRUEBAS PARA SU CARPETA";
            }
        },
        "FILE_TYPE_NOT_SUPPORTED": () => {
            return "Tipo de archivo no compatible";
        },
        "CHANGE": () => {
            return "Cambia";
        }, "Q&A_HEADER": () => {
            return "Pregunta y te responderé";
        },
        "QUESTION": () => {
            return "Pregunta";
        },
        "QUESTION_TYPES": () => {
            return "Tipos de preguntas";
        },
        "QUESTION_NUMBER": () => {
            return "Número de preguntas";
        },
        "QUESTIONS": () => {
            return "preguntas";
        },
        "QUESTION_CHOICE": () => {
            return "Número de opciones";
        },
        "YOUR_ANSWER": () => {
            return "SU RESPUESTA";
        },
        "NOT_ANSWERED": () => {
            return "Pregunta sin respuesta";
        },
        "CORRECT_ANSWER": () => {
            return "RESPUESTA CORRECTA";
        },
        "CORRECT_ANSWER_SMALL": () => {
            return "Respuesta correcta";
        },
        "PAGES_ANSWER": () => {
            return "Páginas: ";
        },
        "PAGES_THUMB_TITLE": () => {
            return "página";
        },
        "DOCUMENT_PAGE_HEADER": () => {
            return "IMPULSE SU DOCUMENTO";
        },

        "COUNTRY": () => {
            return "País";
        },
        "BOOKS_TRANSFORM_SUPERPOWERS": () => {
            return "TU COMPAÑERO DE ESTUDIO PERFECTO";
        },
        "BOOKS_TRANSFORM_SUPERPOWERS_ANNA": () => {
            return "Con ANNA tienes las herramientas para avanzar en tus estudios y alcanzar tus objetivos. Es la tutora que te permite interactuar con tus libros y documentos, ayudándote a aprovechar al máximo tu tiempo";
        },
        "TRY_ANNA": () => {
            return "Prueba ANNA";
        },
        "USED_BY_STUDENTS": () => {
            return "utilizado por más de 10.000 estudiantes";
        },
        "THEY_TALKED_ABOUT_US": () => {
            return "Ya han hablado de nosotros";
        },
        "BOOST_STUDY_WITH_AI": () => {
            return "Impulsa tu estudio con la ayuda de la ai";
        },
        "LANDING_FUNCTIONS_DESC": () => {
            return "ANNA hace que estudiar sea más interactivo y divertido. Estas son las tres características principales que le ayudarán a estudiar mejor:";
        },
        "LANDING_FUNCTIONS_DESC_CARD_1": () => {
            return (
                <>¡Chatea con <strong>ANNA </strong>y pregúntale cualquier cosa sobre los documentos que subas!  ¡Te proporcionará todas las respuestas que necesites!</>);
        },
        "LANDING_FUNCTIONS_DESC_CARD_2": () => {
            return (
                <>Crea <strong>un mapa mental</strong> a partir de cualquier documento. Visualiza la información de forma clara y organizada para facilitar tu aprendizaje.</>);
        },
        "LANDING_FUNCTIONS_DESC_CARD_3": () => {
            return (
                <><strong>Ponga a prueba sus conocimientos</strong> con los simulacros de examen de ANNA. Obtén feedback de inmediato y descubre dónde mejorar, para estar siempre preparado para los exámenes reales.</>);
        },
        "LANDING_STUDENTS_TITLE": () => {
            return (<>Por qué a los estudiantes les gusta ANNA</>);
        },
        "LANDING_STUDENTS_SUBTITLE": () => {
            return (<>¡Descubre una nueva forma de estudiar con ANNA! Gracias a nuestras herramientas basadas en IA, podrás:</>);
        },
        "LANDING_STUDENTS_LIST_1": () => {
            return (<>Crear mapas mentales que simplifiquen conceptos complejos.</>);
        },
        "LANDING_STUDENTS_LIST_2": () => {
            return (<>Preguntar cualquier cosa sobre el material de estudio y obtener respuestas inmediatas.</>);
        },
        "LANDING_STUDENTS_LIST_3": () => {
            return (<>Simular exámenes e identificar áreas de mejora.</>);
        },
        "LANDING_STUDENTS_LIST_4": () => {
            return (<>¡Cargar y aprender de libros, documentos, grabaciones de audio y vídeo, imágenes y mucho más!</>);
        },
        "LANDING_STICKER_TEACHER_TEXT": () => {
            return "para profesores";
        },
        "LANDING_STICKER_STUDENT_TEXT": () => {
            return "para estudiantes";
        },
        "LANDING_TEACHER_TITLE": () => {
            return (<>Profesores, aquí tienen su superpoder</>);
        },
        "LANDING_TEACHER_SUBTITLE": () => {
            return (<>ANNA no es sólo una ayuda para los estudiantes, sino también un valioso aliado para los profesores. Con nuestras herramientas podrás:</>);
        },
        "LANDING_TEACHER_LIST_1": () => {
            return (<>Cargar material didáctico y crear actividades interactivas en unos pocos clics.</>);
        },
        "LANDING_TEACHER_LIST_2": () => {
            return (<>Supervisar el progreso de los alumnos y proporcionar comentarios personalizados.</>);
        },
        "LANDING_TEACHER_LIST_3": () => {
            return (<>Ahorrar tiempo en la preparación de las clases gracias a las funciones de automatización.</>);
        },
        "LANDING_SUBSCRIPTIONS_TITLE": () => {
            return (<>¡Estudiar con inteligencia sin vaciar la cartera!</>);
        },
        "LANDING_SUBSCRIPTIONS_SUBTITLE": () => {
            return (<>Con ANNA, estudiar de forma inteligente está al alcance de todos. Descubre nuestros planes asequibles y encuentra el perfecto para ti.</>);
        },
        "LANDING_QUESTIONS_TITLE": () => {
            return (<>¿Tienes preguntas? Tenemos las respuestas</>);
        },
        "LANDING_QUESTIONS_DESCRIPTION": () => {
            return (<>Si tienes preguntas, ¡has venido al lugar adecuado! Hemos recopilado las respuestas a las preguntas más frecuentes para ayudarte a sacar el máximo potencial de ANNA. Echa un vistazo a continuación y descubre cómo ANNA puede hacer que tu estudio sea aún más eficaz y divertido.</>);
        },

        "LANDING_QUESTIONS_LIST_0": () => {
            return (<>¿Cómo funciona la IA de ANNA y cómo puede ayudarme?</>);
        },
        "LANDING_QUESTIONS_LIST_1": () => {
            return (<>¿Es seguro utilizar ANNA y qué documentos puedo cargar?</>);
        },
        "LANDING_QUESTIONS_LIST_2": () => {
            return (<>¿Puede la IA sustituir a un profesor? ¿Es adecuada para todo tipo de exámenes y escuelas?</>);
        },


        "LANDING_QUESTIONS_LIST_0_ANSWER": () => {
            return (<>La IA de ANNA analiza los documentos que subes (libros, apuntes, diapositivas, folletos) y responde a las preguntas que puedas tener sobre conceptos, fechas, definiciones y detalles concretos. Te ayuda a comprender mejor tu material de estudio y organiza la información clave en mapas conceptuales para facilitar la comprensión y la memorización. También puedes crear cuestionarios y pruebas personalizados,
                eligiendo el número de preguntas, el tipo de respuesta (abierta o cerrada), y obtener correcciones detalladas para mejorar tu preparación.</>)
        },
        "LANDING_QUESTIONS_LIST_1_ANSWER": () => {
            return (<>Puede cargar libros, apuntes, diapositivas, folletos u otros materiales de estudio en formato digital. La seguridad de tus  datos es nuestra prioridad: tus documentos se tratan de forma confidencial y no se comparten con terceros. Utilizamos medidas de seguridad avanzadas para proteger tu información, y no se requieren conocimientos técnicos para utilizar la herramienta: es  sencilla e intuitiva</>);
        },
        "LANDING_QUESTIONS_LIST_2_ANSWER": () => {
            return (<>La IA es una herramienta de apoyo al estudio diseñada para mejorar tu método de aprendizaje aclarando dudas, resumiendo conceptos y simulando exámenes.
                Sin embargo, no sustituye el valor de un profesor.
                Es versátil y adecuada para prepararte para cualquier tipo de examen, tanto escrito como oral, ayudándote a optimizar tu estudio y preparación de forma eficaz.</>)
        },

        "CHOOSE_PLAN": () => {
            return (<>Elija el plan</>);
        },
        "LANDING_MESSAGES_TITLE": () => {
            return (<>Todo el mundo habla de ANNA</>);
        },
        "LANDING_MESSAGES_QUOTE1": () => {
            return (<>ANNA ha transformado mi forma de estudiar. Los mapas mentales y los cuestionarios me ayudan a comprender mejor los conceptos y a preparar los exámenes con mayor eficacia. ¡Ya no podría prescindir de ellos!</>);
        },
        "LANDING_MESSAGES_QUOTE1_AUTHOR": () => {
            return (<>Mark, 16 años</>);
        },
        "LANDING_MESSAGES_QUOTE2": () => {
            return (<>Gracias a ANNA, puedo cargar material didáctico y crear actividades interactivas en cuestión de minutos. Supervisar el progreso de los alumnos es ahora mucho más fácil, y los comentarios personalizados han mejorado mucho su aprendizaje.</>);
        },
        "LANDING_MESSAGES_QUOTE2_AUTHOR": () => {
            return (<>Prof. Rossi, Profesor de Matemáticas</>);
        },
        "STUDENT": () => {
            return (<>Estudiante</>);
        },
        "TEACHER": () => {
            return (<>Profesor</>);
        },
        "PRIVACY_POLICY": () => {
            return "Política de privacidad";
        },
        "PAGE_SELECTOR": () => {
            return "Seleccionar páginas";
        },
        "PAGES_RANGE_HELP": () => {
            return "Inserte un número de página (ex 5), un intervalo de páginas (ex 5-10), una lista de páginas (ex 1, 2, 3, 4, 5) o una mezcla (ex 5, 7-10, 12, 14)";
        },
        "PAGES_RANGE": () => {
            return "Elegir páginas";
        },
        "SAVE_CHANGES": () => {
            return "Guardar cambios";
        },

        "PRIVACY_POLICY_REGISTER": () => {
            return (
                <p className='Text500__6'>y <strong><a href="/es/privacy_policy" target='_blank' rel="noopener noreferrer" >Política de privacidad</a></strong></p>
            );
        },
        "PRIVACY_POLICY_TEXT": () => {
            return (
                <div className="container" style={{ height: '100%', textAlign: 'justify' }}>
                    <h1 className="test-boost">{getText()["PRIVACY_POLICY"]()}</h1>
                    <br></br>
                    <h3 className="test-boost">{getText()["PRIVACY_POLICY"]()}:</h3>
                    <p className='Text500__6'>UAB "Lab-H9" (hereinafter - the Data Controller) in this privacy policy (hereinafter - the Privacy Policy) sets the conditions for processing personal data using
                        this website managed by the Data Controller. The terms set out in the Privacy Policy apply every time you access the content and/or services we provide, regardless of the device (computer, mobile phone, tablet, TV, etc.) you are using.
                    </p>
                    <p className='Text500__7'>Details of the data controller:
                    </p>
                    <p className='Text500__7'>UAB "Lab-H9"
                    </p>
                    <p className='Text500__7'>Office address: Laisvės al. 82,
                    </p>
                    <p className='Text500__7'>Kaunas, LT-44250 Lietuva
                    </p>
                    <p className='Text500__7'>E-mail: <a href="mailto:anna@labh9.eu">anna@labh9.eu</a>
                    </p>
                    <p className='Text500__6'>It is very important that you read this Privacy Policy carefully, because every time you visit the <a href="https://www.labh9.ai" target='_blank' rel="noopener noreferrer">www.labh9.ai</a> website, you agree to the terms described in this Privacy Policy. If you do not agree to these terms, we regret to ask you not to visit our website, use our content and/or services. We confirm that the data of visitors to the website of the Data Controller will be collected in accordance with the requirements of the applicable legal acts of the European Union and the Republic of Lithuania and the instructions of the controlling authorities. All reasonable technical and administrative measures are in place to protect the data we collect about website visitors from loss, unauthorized use or alteration.
                        Individuals who are under the age of 16 may not submit any personal data through our website. If you are under the age of 16, you must obtain the consent of your parent or other legal guardian before submitting personal information.
                        The terms used in this privacy policy are understood as they are defined in the General Data Protection Regulation no. 2016/679.
                    </p>
                    <ul className='Text500__6'>
                        <li>name;
                        </li>
                        <li>surname;
                        </li>
                        <li>e-mail address.
                        </li>
                    </ul>
                    <h3 className="test-boost">Browsing data</h3>
                    <p>
                        We use local storage to manage security (at logon time) and to remember Your preferred language.
                    </p>
                    <p>
                        We will not share this information with anyone and it will only be stored on Your browser.
                    </p>
                    <p>
                        For access with third-party authentication systems (single-sign-on), such as Google and Facebook, it is possible that these systems use cookies and local storage and we refer You to the respective policies.
                    </p>
                    <ul className="Text500__5" style={{ textAlign: 'left' }}>
                        <li>
                            <a href="https://policies.google.com/technologies/cookies" target="_blank" rel='noopener noreferrer'>
                                Google Cookie Policy
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/privacy/policies/cookies" target="_blank" rel='noopener noreferrer'>
                                Facebook Cookie Policy
                            </a>
                        </li>
                    </ul>
                    <p>
                        To remind us that You have read the cookies policy, we will store a cookie on Your browser, which we will not share with anyone and which will only serve to prevent this message from appearing automatically every time You connect to the ANNA web.
                    </p>
                    <p>These are the local storage items and cookies managed by ANNA web site on its domain:</p>
                    <table className='cookies-table Text500__5 mb-3 mx-auto'>
                        <tbody>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Purpose</th>
                            </tr>
                            <tr>
                                <td>Cookie</td>
                                <td>cookieConsent</td>
                                <td>Remembering that You’ve read this cookie page</td>
                            </tr>
                            <tr>
                                <td>Local storage</td>
                                <td>_grecaptcha</td>
                                <td>Used for security purposes, during the registration, to prevent non-human (bot) interactions with the page</td>
                            </tr>
                            <tr>
                                <td>Local storage</td>
                                <td>UserAuth</td>
                                <td>Used for security purposes to store on Your browser Your identity data and token after You have logged in.</td>
                            </tr>
                            <tr>
                                <td>Cookie</td>
                                <td>active_language</td>
                                <td>Used to remember Your preferred language for the user interface</td>
                            </tr>
                        </tbody>
                    </table>
                    <h3 className="test-boost">Third party cookies</h3>
                    <p>
                        The Site uses statistical cookies (for advertising and marketing purposes) to monitor visits to the Site.
                        <br />These cookies and their processing are subject to your consent and therefore will be used only if you accept.
                    </p>
                    <p>
                        If you accept third party cookies please refer to this providers and privacy disclosures policies:
                    </p>
                    <ul>
                        <li>Google Analytics
                            <ul>
                                <li><a href="https://www.google.com/policies/privacy/partners/">Google's Privacy & Terms</a></li>
                                <li><a href="https://support.google.com/analytics/answer/6004245">Safeguarding your data</a></li>
                            </ul>
                        </li>
                        <li>Google TAG manager
                            <ul>
                                <li><a href="https://support.google.com/tagmanager/answer/9323295?hl=en">Data privacy and security</a></li>
                            </ul>
                        </li>
                        <li>META PIXEL
                            <ul>
                                <li><a href="https://www.facebook.com/privacy/policy/">Meta Privacy Policy</a></li>
                            </ul>
                        </li>
                    </ul>
                    <h3 className="test-boost">How we use your information</h3>
                    <p className='Text500__6'>We may process your personal data for the following purposes:
                    </p>
                    <ul className='Text500__6'>
                        <li>For the purpose of direct marketing*, send newsletters, provide individually tailored advertising and send messages, evaluate and analyze our market, customers, products and services (including collecting your opinion about products and services and customer organization of surveys).
                        </li>
                        <li>To administer the website;
                        </li>
                        <li>To improve your browsing experience by adapting this website to your personal needs;
                        </li>
                        <li>In other ways with your consent*
                        </li>
                        <p className='Text500__6'><i>*We inform you that you have the right to disagree or withdraw your consent at any time to process your data for the above purposes marked with an asterisk.</i>
                        </p>
                    </ul>
                    <h3 className="test-boost">Other information we collect</h3>
                    <p className='Text500__6'>We may also collect other information about you, your device or your use of our website content with your consent.</p>
                    <p className='Text500__6'>We undertake not to transfer your personal data to any unrelated third parties, except in the following cases:
                    </p>
                    <ul className='Text500__6'>
                        <li>If there is consent to the disclosure of personal data.
                        </li>
                        <li>To law enforcement institutions in accordance with the procedure provided by the legal acts of the Republic of Lithuania.
                        </li>
                    </ul>
                    <h3 className="test-boost">Your rights
                    </h3>
                    <ul className='Text500__6'>
                        <li>To know (to be informed) about the processing of your data (right to know);
                        </li>
                        <li>Familiarize yourself with your data and how they are processed (right of access);
                        </li>
                        <li>Demand correction or, taking into account the purposes of personal data processing, to supplement incomplete personal data (right to correction);
                        </li>
                        <li>To destroy your data or stop the processing of your data (except storage) (right to destruction and right to be forgotten);
                        </li>
                        <li>Demand that the Personal Data Controller restricts the processing of personal data for one of the legitimate reasons (right to restriction);
                        </li>
                        <li>You have the right to transfer data (right to transfer);
                        </li>
                        <li>Opt-out to the processing of personal data when this data is processed or intended to be processed for direct marketing purposes, including profiling as far as it relates to such direct marketing.
                        </li>
                    </ul>
                    <p className='Text500__6'>If you no longer want your personal data to be processed for the purpose of direct marketing, you can write to at the email address <a href="mailto:anna@labh9.eu">anna@labh9.eu</a> and do not agree to your personal data being processed for the purpose of direct marketing.
                        You can submit any request or instruction related to the processing of personal data to the Data Controller by e-mail. to the email address <a href="mailto:anna@labh9.eu">anna@labh9.eu</a>. Upon receiving such a request or instruction, the data controller shall, no later than within one month from the date of the request, provide a response and perform the actions specified in the request or refuse to perform them. If necessary, the specified period can be extended by another two months, depending on the complexity and number of requests. In this case, within one month from the date of receipt of the request, the Data Controller informs the subject of such an extension, together with the reasons for the delay.
                    </p>
                    <h3 className="test-boost">Third-party websites, services and products on our websites
                    </h3>
                    <p className='Text500__6'>The Data Controller's website may contain third-party banners, links to their websites and services over which the Data Controller has no control. The data controller is not responsible for the security and privacy of information collected by third parties. You must read the privacy statements that apply to third-party websites and services you use.
                    </p>

                    <p>If you want to delete all your data you can delete your account in the user profile page, and our system automatically will remove all the personal information</p>

                </div>


            );
        },
        "TERMS_AND_CONDITIONS_TEXT": () => {
            return (
                <div className="container" style={{ height: '100%', textAlign: 'justify' }}>
                    <h1 className="test-boost">{getText()["TERMS_AND_CONDITIONS"]()}</h1>
                    <br></br>
                    <h3 className="test-boost">{getText()["TERMS_AND_CONDITIONS"]()}:</h3>
                    <p className='Text500__6'>These Terms of Use apply to the use of the Platform of Lab-H9 (UAB Lab-H9). Please read these Terms of Use carefully in order to ensure that you are aware of your rights and obligations when using the Platform. You can download and print this document.
                    </p>
                    <p className='Text500__6'>These Terms of Use <i>inter alia</i>, describe the following aspects of the relationship between you and Lab-H9:
                    </p>
                    <ul className='Text500__6'>
                        <li>The provision of Study Material (article 7) and creating a Profile (article 5);</li>
                        <li>The functionalities of the Platform (articles 3- 6);</li>
                        <li>The use of the AI Summaries, Mindmaps and Q&A functionality (article 8);</li>
                        <li>The use of the Group Chats functionality (article 9);</li>
                        <li>The notice and take down procedure (article 14);</li>
                        <li>The limitation of liability of Lab-H9(article 15).</li>
                    </ul>
                    <h3 className="test-boost">Article 1. Definitions</h3>
                    <p className='Text500__6'>In these Terms of Use, the terms listed below have the following meaning:
                    </p>
                    <p className='Text500__6'>1.1. <strong>AI: </strong>software that uses artificial intelligence techniques to generate Summaries, Mindmaps and answers and tests in reference to documents uploaded by the user                </p>
                    <p className='Text500__6'>1.2. <strong>AI Answers:</strong> all the answers and instructions that AI generates and that are made accessible to the user via dedicated section on the Platform, in response to questions submitted by the user.</p>
                    <p className='Text500__6'>1.3. <strong>AI Q&A:</strong> a separate section on the Platform, which enables User to submit questions in reference to documents earlier uploaded, which are then answered by AI.</p>
                    <p className='Text500__6'>1.4. <strong>Mindmaps:</strong> a separate section on the Platform, which enables User to get a mindmap in reference to documents earlier uploaded, which are generated by AI </p>
                    <p className='Text500__6'>1.5. <strong>Test/Quiz:</strong> a separate section on the Platform, which enables User to test their learing skill to a specific document. All the questions are generated by AI</p>

                    <p className='Text500__6'>1.6. <strong>Information:</strong> all information placed on the Platform by Lab-H9 itself, including – but not limited to – the layout and look and feel of the Platform, the logos and trademarks as well as texts. The Information does not include Study Materials.</p>

                    <p className='Text500__6'>1.7. <strong>Intellectual Property Rights:</strong> any and all intellectual property rights and associated rights such as copyrights, trademark rights, patent rights, model rights, trade name rights, database rights and neighbouring rights as well as the rights to know-how and one-line performances.</p>
                    <p className='Text500__6'>1.8. <strong>Platform:</strong> the online environment developed and offered by Lab-H9, which enables User from around the world to upload their documents and interact with them through an AI model. The Platform contains a separate AI, Summaries, Q&A, mindmap and Test sections  The Platform can be accessed through the websites <a href="https://anna.labh9.eu" target='_blank' rel="noopener noreferrer">anna.labh9.eu;</a> including all underlying domains and web pages, as well as mobile versions of the website and the Lab-H9 application for tablets and smartphones (“App”).
                    </p>
                    <p className='Text500__6'>1.9. <strong>Premium Account: </strong>a subscription that gives User access to the most functionalities on the Platform, as well as to specific features </p>
                    <p className='Text500__6'>1.10. <strong>Profile: </strong>an individual section created and maintained by you when you register for the Platform.</p>
                    <p className='Text500__6'>1.11. <strong>USER: </strong>a User of the Platform.</p>
                    <p className='Text500__6'>1.12. <strong>Documents:</strong> all the study documents, summaries, notes, assignments, books and other materials that are uploaded by User in his own account.</p>
                    <p className='Text500__6'>1.13. <strong>Terms of Use:</strong> these Terms of Use</p>
                    <h3 className="test-boost">Article 2. Applicability</h3>
                    <p className='Text500__6'>2.1. These Terms of Use govern every use of the Platform. By using the Platform, you accept these Terms of Use. The applicability of any other terms and conditions is excluded.</p>
                    <p className='Text500__6'>2.2. Lab-H9 is entitled to amend or supplement these Terms of Use. The most up-to-date Terms of Use can be found on the Platform. In the event of an amendment or supplementation that significantly affect your rights or obligations, Lab-H9 will bring these changes to your attention during the use of the Platform.</p>
                    <p className='Text500__6'>2.3. In the event that you continue the use of the Platform after these Terms of Use have been amended or supplemented, you thereby irrevocably accept the amended or supplemented Terms of Use. If you do not agree with the amended or supplemented Terms of Use, the only option you have is to no longer use the Platform and to terminate your Profile.
                    </p>
                    <h3 className="test-boost">Article 3. Availability</h3>
                    <p className='Text500__6'>3.1. You accept that the Platform contains only the functionalities and other characteristics as found on the Platform at the time of use ("as is basis"). Lab-H9 expressly excludes any explicit and tacit guarantees, undertakings and warranties of any nature whatsoever including, but not limited to, with regard to the quality, safety, lawfulness, integrity and accuracy of the Platform, unless explicitly stated otherwise in these Terms of Use.</p>
                    <p className='Text500__6'>3.2. You accept that the functionalities of the Platform may change.
                    </p>
                    <p className='Text500__6'>3.3. Lab-H9 is entitled to put the Platform (temporarily) out of service and/or to reduce the use of it without any prior notification and without being obliged to pay compensation to you, if in the opinion of Lab-H9 this is necessary, for instance, for maintenance of the Platform.
                    </p>
                    <p className='Text500__6'>3.4. Lab-H9 does not guarantee that the Platform or any part thereof will be accessible at all times and without any interruptions or failures. Failures in the Platform can occur as a result of failures in the internet or phone connection or by viruses or faults/defects. Lab-H9 is not liable or obliged to User to pay compensation in any way whatsoever for any damage resulting or arising from the website being (temporarily) unavailable.
                    </p>
                    <p className='Text500__6'>3.5. Lab-H9 is at any time entitled to change, alter or shut down the Platform without becoming liable to you in any way. If you do not agree with the changes and/or amendments made by Lab-H9, your only remedy is to cease the use of the Platform and to delete your Profile.
                    </p>
                    <h3 className="test-boost">Article 4. The Platform </h3>
                    <p className='Text500__6'>4.1. Lab-H9 offers a Platform where User can upload their Materials , such as documents essay, books and so forth and interact with them using AI. The interaction with the AI generates summaries, mindmaps, Q&A and test  8). Lab-H9’s mission is to empower User to excel at their studies by providing the best tools to learn more efficiently.
                    </p>
                    <p className='Text500__6'>4.2. The usage of the platform is based on a “freemium” model.
                    </p>
                    <p className='Text500__6'>4.3. Some Premium functions are only available for User with a Premium Account. Certain functionalities and features of the Platform require a Premium Account.
                    </p>
                    <p className='Text500__6'>4.4. The Platform may contain links to third party websites that are not owned or controlled by Lab-H9. Lab-H9 has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites.
                    </p>
                    <h3 className="test-boost">Article 5. Profile</h3>
                    <p className='Text500__6'>5.1. In order to access to Premium functionalities you have to create a Profile in the way described on the Platform. When creating a Profile, you must give an email address and password which will allow you to access the Platform and your Profile. You can also register using your existing Facebook or Google-account.
                    </p>
                    <p className='Text500__6'>5.2. You are only allowed to use the Platform when aged sixteen (16) or older. In the event that you have not yet reached the age of sixteen (16), you accept that you need the consent of your legal representative(s) (your guardian or (one of) your parents) before creating a Profile and/or taking out a subscription.

                    </p>
                    <p className='Text500__6'>5.3. You are responsible for maintaining secrecy with regard to your registration details. As soon as you know or have reason to assume that your username and/or password has come into the hands of unauthorized persons, you must inform Lab-H9 of this, without prejudice to your own obligation to take immediate effective measures yourself such as changing your password. You accept and acknowledge that you are at all times responsible and liable for the use of the Platform by third parties via your Profile.
                    </p>
                    <p className='Text500__6'>5.4. The personal data you provide during the registration process will be processed in accordance with Lab-H9's Privacy Policy Statement.
                    </p>
                    <p className='Text500__6'>5.5. You acknowledge and agree that by creating a Profile your user name, university and (if provided) profile picture are visible to other User if you post Study Materials and/or place comments, unless you choose to post Study Materials and/or partake in Study Communities anonymously.
                    </p>
                    <p className='Text500__6'>5.6 You acknowledge and confirm that you have all the rights on the document you upload and interact with on the platform
                    </p>
                    <h3 className="test-boost">Article 6. Premium Account</h3>
                    <p className='Text500__6'>6.1. User can obtain Premium access by taking out a dedicated subscription. The prices are communicated on the Platform.
                    </p>
                    <p className='Text500__6'>6.2. Payment is due in the manner described on the Platform.
                    </p>
                    <p className='Text500__6'>6.3. Lab-H9 offers a 7 days free trial subscription, which allows you to use Premium functionalities available on the Platform.. After 7 days, your trial subscription automatically extends into a weekly full Premium subscription, unless you cancel the subscription prior to that date. You can unsubscribe at any time via your Profile and no deductions will be made. After unsubscribing, you will retain access for the remainder of the trial period.
                    </p>
                    <p className='Text500__6'>6.4. It is possible that Lab-H9 offers longer/shorter free trial subscriptions in specific circumstances, such as actions for study societies. The applicable conditions will then be communicated by Lab-H9.
                    </p>
                    <p className='Text500__6'>6.5. Lab-H9 may introduce or change the subscription fees from time to time, for which we will give you advance notice. If your current subscription is still running, the price will remain in force for the fixed term. If you do not agree with the price change, you must cancel your subscription by the end of the then-current subscription term. If you do not cancel your subscription after the price change goes into effect, you agree to pay the changed price.
                    </p>
                    <p className='Text500__6'>6.6. Lab-H9 may suspend or cancel your Premium Account if a payment is not successfully settled.
                    </p>
                    <p className='Text500__6'>6.7. The subscription period will be renewed automatically for the selected subscription period, unless you have cancelled your subscription on time (before the last day of your subscription). In case your subscription is automatically renewed, you have the right to cancel the subscription at any time after such renewal with a cancellation term of one (1) month. In case of cancellation you may request reimbursement of part of the subscription fee. This means that you pay for the period until the cancellation enters into force, based on the standard monthly fee.
                    </p>
                    <h3 className="test-boost">Article 7. Documents/Materials           </h3>
                    <p className='Text500__6'>7.1. By means of the Platform User can upload their documents and material to be used for the learning process.

                    </p>
                    <p className='Text500__6'>7.2. You have the possibility to create folders, containing Study Materials selected by you relating to a certain course or subject. Folders or single documents are visible only to the User. Nothing will be shared with other users
                    </p>
                    <p className='Text500__6'>7.3. You understand and accept that you are responsible and accountable for Material you upload on the Platform. Lab-H9 is not liable to you or any third party for any damages arising out of or in relation to the Material uploaded or used by you within the Platform.
                    </p>
                    <p className='Text500__6'>7.4. You understand and accept that Lab-H9does not, and is under no obligation to, check or (re)view your Material. Lab-H9 only conducts an automated (quality) check to verify whether the Study Material contains sufficient texts and/or pages. Lab-H9has no knowledge of and/or influence on or involvement with the Materials uploaded by User.
                    </p>
                    <p className='Text500__6'>7.5. Lab-H9 reserves the right to convert the Materials you uploaded to another format and to automatically insert a watermark and/or advertisements in the materials.
                    </p>
                    <p className='Text500__6'>7.6. If you can delete your document/material at any time. The action cannot be reversed.
                    </p>
                    <p className='Text500__6'>7.7. Lab-H9 retains the right to decline or remove Material from the Platform, without accepting any responsibility or liability for its contents, without being held liable for any damage and without being obliged to pay any compensation.
                    </p>
                    <h3 className="test-boost">Article 8. AI Summaries, Mindmap, Q&A and Test/Quiz            </h3>
                    <p className='Text500__6'>8.1. By means of the separate AI Summaries, Mindmap, Q&A and Test/Quiz section on the Platform, User can interact with the uploaded document and answered by AI. The AI Answers will be available for User via a database in specific section on the Platform.
                    </p>
                    <p className='Text500__6'>8.2. To interact with all the sections, you will need to create a Profile.
                    </p>
                    <p className='Text500__6'>8.3. You accept and agree Lab-H9 solely facilitates the functionality, which enables User to use AI for their Studies.
                    </p>
                    <p className='Text500__6'>8.4. The Platform will specify the manner in which User can interact with the AI. Lab-H9 reserves the right to limit the availability of functionalities to User with a Premium Account even with Unlimited access or Unlimited usage. The limit, include – but it is not limited to a slow in AI reply or to a limit the number of questions can be submitted per day (or other time unit).
                    </p>
                    <p className='Text500__6'>8.5. AI will generate feedbacks within seconds. Lab-H9, however, does not guarantee that an the feedbacks are always provided within this time frame and does not accept liability in this regard.
                    </p>
                    <p className='Text500__6'>8.6. User agree that clear questions will generate a better AI output. Lab-H9 is not liable for any incorrect feedback
                    </p>
                    <p className='Text500__6'>8.7. The AI feedbacks are to be used as support in the learning process only, not as a substitute for a traditionl learning course. Lab-H9is not liable for the accuracy or completeness of the AI feedbacks.
                    </p>
                    <p className='Text500__6'>8.8. You understand and accept that you are responsible and accountable for use of feedbacks generated by AI. Lab-H9 is not liable to you or any third party for any damages arising out of, or in relation to them
                    </p>
                    <p className='Text500__6'>8.9. You understand and accept that Lab-H9 does not, and is under no obligation to, check or (re)view the AI feedbacks.
                    </p>
                    <h3 className="test-boost">Article 10. Your obligations and use of the Platform
                    </h3>
                    <p className='Text500__6'>10.1. You will only use the documents/materials that you will upload in the Platform
                    </p>
                    <p className='Text500__6'>10.2. You may not resell, distribute, or in any other way make profit of the Platform.
                    </p>
                    <p className='Text500__6'>10.3. You are the only responsible for documents you upload for instance (e-)books or any other material on the Platform.
                    </p>
                    <p className='Text500__6'>10.4. You are not permitted to use the Platform for a purpose as listed below, which is unrelated to the purpose of Lab-H9:
                    </p>
                    <ul className='Text500__6'>
                        <li>as your own work if it is not, as this is in breach of copyright. This is also plagiarism and constitutes academic misconduct at all academic institutions;
                        </li>
                        <li>which, at the discretion of Lab-H9, is discriminating or is otherwise deemed hurtful, offensive or inappropriate;
                        </li>
                        <li>in which personal data of others is made available, other than in compliance with applicable law;
                        </li>
                        <li>which contains scrapers, spiders, viruses, Trojan horses, worms, bots or other software which can damage or delete the Platform or make it unusable or inaccessible, or which or which is intended to circumvent technical protection measures of Lab-H9, the Website and/or the computer systems of Lab-H9;
                        </li>
                        <li>which infringes the rights of Lab-H9 and/or third parties, including but not limited to intellectual property rights or rights concerning the protection of privacy;
                        </li>
                        <li>which qualifies as or transmits unwanted or unsolicited material or content (spam);
                        </li>
                        <li>is contrary to these Terms of Use or any applicable laws or regulations, is in any other manner unlawful or which could prejudice the interests and reputation of lab-H9.
                        </li>
                    </ul>
                    <p className='Text500__6'>10.5. Lab-H9 will not be obliged to perform any payment, taxation, levy or deduction of any nature whatsoever to anyone as a result of the material uploaded by you, including to persons or parties whose work, model, brand or performance is included in the Materials, including to collective rights organizations.
                    </p>
                    <p className='Text500__6'>10.6. Lab-H9 reserves the right, without prior notice, to abridge, alter, refuse and/or remove Materials without this resulting in any liability.
                    </p>
                    <p className='Text500__6'>10.7. Lab-H9 is entitled to (temporarily) restrict or suspend your activities in connection with the Platform and/or to delete your Profile in the event you violate these Terms of Use.
                    </p>
                    <h3 className="test-boost">Article 11. Intellectual property rights
                    </h3>
                    <p className='Text500__6'>11.1. The Intellectual Property Rights in relation to the Platform, including the intellectual property rights with respect to the Information, are held by Lab-H9or its licensors. Nothing in the Terms of Use is intended to entail any transfer of Intellectual Property Rights to you.
                    </p>
                    <p className='Text500__6'>11.2. Under the conditions set out in these Terms of Use, Lab-H9 grants you a limited, personal, revocable, non-exclusive, non-sub licensable and non-transferable right to access and use the Platform, including the Information, insofar as is necessary for the use of the Platform.
                    </p>
                    <p className='Text500__6'>11.3. The collection of Materials or documents, that is collected by Lab-H9 and displayed on the Platform is a legally protected database. Lab-H9 is the producer of the database and therefore has the exclusive right to grant permission for i) the extraction or re-utilization of the entirety or a substantial part, from a quantitative and/or qualitative point of view, of the contents of the database and ii) the repeated and systematic extraction or re-utilization of non-substantial parts, from a quantitative and/or qualitative point of view, of the contents of the database, in so far as this is in conflict with the normal operation of the database or causes unjustified damage to lab-H9’s lawful interests. You may only request or reuse data from the database if and in so far as this is permitted under these Terms and Conditions.
                    </p>
                    <p className='Text500__6'>11.4. You retain all Intellectual Property Rights to your Material.
                    </p>
                    <p className='Text500__6'>11.5. You warrant that there are no (Intellectual Property) Rights of third parties which preclude you from use the Material uploaded on the Platform and/or providing the licence as mentioned in Article 11.4.
                    </p>
                    <p className='Text500__6'>11.6. You shall refrain from any act that infringes the Intellectual Property Rights of Lab-H9, including – but not limited to – the registration of domain names, trademarks or Google Adwords that are identical or similar to any object to which Lab-H9 holds Intellectual Property Rights, as well as the reproduction, modification, reverse engineering or publishing of the Platform, including the Information, for other purposes than those listed in the Terms of Use.
                    </p>
                    <h3 className="test-boost">Article 12. Indemnities and warranties
                    </h3>
                    <p className='Text500__6'>12.1. You guarantee that you will not use the Platform in a way that (i) infringes the rights of Lab-H9 or third parties, including – but not limited to – Intellectual Property Rights or rights in relation to the protection of privacy, (ii) is contrary to any current legislation or regulations, or (iii) is contrary to any provision in these Terms of Use.
                    </p>
                    <p className='Text500__6'>12.2. You are liable and indemnify Lab-H9 against any and all claims of third parties based on the allegation that the Material infringes any Intellectual Property Right or other right of third parties or is otherwise unlawful against third parties as well as against all claims resulting from your actions in violation with these Terms of Use. All costs incurred and damages suffered by Lab-H9 which are in any way related to the abovementioned claims will be remunerated by you.
                    </p>
                    <p className='Text500__6'>12.3. Lab-H9 does not guarantee (i) the Platform’s fitness for a particular purpose or that the Platform shall meet any of your requirements, (ii) that the Platform shall be available to you at all times and without interruptions, limitations or disturbances; (iii) that AI feedbacks on the Platform are accurate, up to date or complete.
                    </p>
                    <h3 className="test-boost">Article 13. Downloading and sharing Study Material
                    </h3>
                    <p className='Text500__6'>13.1. User with a Profile and a Premium Account have the ability to download some Materials from the Platform for personal use only. Your Profile will register what Material you have downloaded.
                    </p>
                    <p className='Text500__6'>13.2. Downloading Material is allowed, provided that you do not violate our Fair Use Policy. This policy states that you can download a maximum of 10 documents per day, with a maximum of 30 documents per month.
                    </p>
                    <p className='Text500__6'>13.3. You accept and acknowledge that sharing Materials generated by the AI is only permitted using a link to the Platform. You are not permitted to share any Materials that you have downloaded by other means that a link.
                    </p>
                    <h3 className="test-boost">Article 14. Notice of alleged unlawful material & repeat infringers
                    </h3>
                    <p className='Text500__6'>14.1. Lab-H9 respects the (Intellectual Property) Rights of others and takes the protection of (Intellectual Property) Rights seriously. Although Lab-H9 is not liable for any damages in connection with the (illegal) use of the Platform by User, Lab-H9 will, under the conditions as set out in this Article, remove Material(s), that is (are) infringing and/or unlawful and stop manifestly unlawful behaviour after receiving a notification that is sufficiently precise and adequately substantiated.
                    </p>
                    <p className='Text500__6'>14.2. If you claim that certain Material AI generated infringe on your (Intellectual Property) Rights, you can report this alleged infringement to Lab-H9 by mail. We will examine the notice and, if possible and necessary, take the measures to clear the issue.
                    </p>
                    <p className='Text500__6'>14.3. Lab-H9 reserves the right to not grant a request to remove Materials, or to stop an activity in case it has reasonable grounds to doubt the accuracy of the notification or in case a balancing of interest requires this. In such a situation, Lab-H9 can require a court order from a competent court, to demonstrate that the material or the activity is manifestly unlawful.
                    </p>
                    <p className='Text500__6'>14.4. Prior to removal of any alleged unlawful Material(s), Lab-H9 may request and the notifying party shall provide, additional information substantiating that the notifying party is indeed the rightsholder, and Lab-H9 may request and the notifying party shall provide, a signed copy of an indemnification declaration for LAB-H9.
                    </p>
                    <p className='Text500__6'>14.5. Lab-H9 will not be a party in a dispute between the person making the report and any third party arising out of or in connection with a notification.
                    </p>
                    <p className='Text500__6'>14.6. Lab-H9 has a policy of terminating accounts of User who repeatedly and intentionally infringe the Intellectual Property Rights of others.
                    </p>
                    <p className='Text500__6'>14.7. The notifying party indemnifies Lab-H9 against all claims of third parties in relation to the blocking or removal of Materials or the stopping of activities as a result of the notification. The indemnification includes all damages and costs Lab-H9 suffers, could suffer or which Lab-H9 must incur in relation to such a claim, including but not limited to the compensation of legal assistance.
                    </p>
                    <p className='Text500__6'>14.8. Lab-H9 respects and protects the privacy of those who provide notifications. All personal data that it receives as part of a notification will only be used to process the notice.
                    </p>
                    <p className='Text500__6'>14.9. Lab-H9 reserves the right to forward the notice to the user responsible for the Material to which the notice refers.
                    </p>
                    <h3 className="test-boost">Article 15. Liability
                    </h3>
                    <p className='Text500__6'>15.1. Lab-H9 accepts no liability whatsoever for damages resulting from the provision of the Platform, including damage resulting from or in connection with the use of the Platform or from wrongful acts or otherwise, in so far as this is permitted pursuant to mandatory law.
                    </p>
                    <p className='Text500__6'>15.2. In the event that Lab-H9– despite the above – is liable, this liability is limited to direct damages the user suffers as a result of a shortcoming or wrongful act that can be attributed to lab-H9. Direct damage only includes material damage to goods, reasonable costs incurred to prevent or limit direct damage and reasonable costs incurred to determine the cause of the damage. Lab-H9 shall in no event be liable for any special, incidental or consequential damages whatsoever (“indirect damages”), including – but not limited to – loss and/or damage of data (including  Material), loss of profits and lost sales, is excluded.
                    </p>
                    <p className='Text500__6'>15.3. Lab-H9’s liability is in any case limited to the highest of the following amounts: (i) the amount actually paid by the user as premium account, or (ii) € 500,00 (five hundred euros/00).
                    </p>
                    <p className='Text500__6'>15.4. The limitations and exclusions of liability mentioned in the preceding paragraphs of this article will lapse if and in so far as the damage is the result of intentional or willful recklessness on the part of Lab-H9 or its managers (“own actions”).
                    </p>
                    <h3 className="test-boost">Article 16. Force Majeure
                    </h3>
                    <p className='Text500__6'>16.1. In the case of force majeure, the non-fulfillment of the agreement between you and Lab-H9cannot be counted as an attributable failure and Lab-H9 is released from all contractual obligations and liability.
                    </p>
                    <p className='Text500__6'>16.2. Force majeure includes, but is not limited to, illness of employees and/or absence of personnel crucial for the provision of the Platform, disruptions of the electricity supply, fire, natural disasters, strikes, floods, failures to perform by third parties hired by Lab-H9, disturbances in the internet connection, hardware disruptions, disruptions in the (telecommunications) networks and other unforeseen circumstances.
                    </p>
                    <h3 className="test-boost">Article 17. Termination
                    </h3>
                    <p className='Text500__6'>17.1. You are entitled to discontinue your use of the Platform and to terminate your Profile.
                    </p>
                    <p className='Text500__6'>17.2. You accept and acknowledge that any Materials uploaded, or any output created and stored, by you beforehand, will be delated from the Platform.
                    </p>
                    <h3 className="test-boost">Article 18. Miscellaneous
                    </h3>
                    <p className='Text500__6'>18.1. These Terms of Use and the use of the Platform are governed by and construed in accordance with the laws of Lithuania
                    </p>
                    <p className='Text500__6'>18.2. Any and all disputes arising from or related to the Platform or the Terms of Use will be brought before the competent court in Vilnius, unless compulsory law mandates otherwise.
                    </p>
                    <p className='Text500__6'>18.3. If at any time any provision of these Terms of Use is or becomes illegal, void or invalid for any reason whatsoever, such invalidity shall not affect the validity of the remainder of the Terms of Use and such invalid provision shall be replaced by another provision which, being valid in all respects, shall have effect as close as possible to that of such replaced one
                    </p>
                    <p className='Text500__6'>18.4. Lab-H9 is entitled to assign any rights and obligations that result from these Terms of Use to third parties, in which case it will notify you. In the event that you cannot agree with the assignment, your only remedy is to cease the use of the Platform and delete your Profile.
                    </p>
                    <br></br>
                </div>
            );
        },
        "DAILY": () => {
            return (<>Diario</>);
        },
        "PLAN_DOESNT_INCLUDE_FOLDERS": () => {
            return ("El plan no incluye carpetas");
        },
        "PLAN_DOESNT_INCLUDE_VOICE": () => {
            return ("El plan no incluye la transcripción de voz");
        },
        "PLAN_DOESNT_INCLUDE_VIDEO": () => {
            return ("El plan no incluye la transcripción de vídeo");
        },
        "SUBSCRIPTION_REQUIRED": () => {
            return ("Suscripción necesaria para esta acción");
        },
        "GO_TO_CATALOGUE": () => {
            return ("Ir al catálogo");
        },
        "COOKIE_POLICY_WELCOME": () => {
            return "Bienvenido a ANNA";
        },
        "LEARN_MORE": () => {
            return "Más información";
        },
        "I_UNDERSTAND": () => {
            return "Guardar preferencia";
        },
        "NECESSARY_COOKIES": () => {
            return `Cookie necesaria`;
        },
        "THIRD_PARTY_COOKIES": () => {
            return `Cookies publicitarias de terceros`;
        },
        "COOKIE_POLICY_TEXT": () => {
            return (<div className="gdpr-modal-list Text500__5" style={{ textAlign: 'justify' }}>
                <p>ANNA está fabricada por UAB LAB-H9, Lituania, Laisvės al. 82, Kaunas, <a href="mailto:anna@labh9.eu">anna@labh9.eu.</a></p>
                <p>
                    Utilizamos el almacenamiento local para gestionar la seguridad (en el momento del inicio de sesión) y para recordar su idioma preferido.
                </p>
                <p>
                    No compartiremos esta información con nadie y sólo se almacenará en su navegador.
                </p>
                <p>
                    Para el acceso con sistemas de autenticación de terceros (single-sign-on), como Google y Facebook, es posible que estos sistemas utilicen cookies y almacenamiento local y le remitimos a las respectivas políticas.
                </p>
                <ul className="Text500__5" style={{ textAlign: 'left' }}>
                    <li>
                        <a href="https://policies.google.com/technologies/cookies" target="_blank" rel='noopener noreferrer'>
                            Política de cookies de Google
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/privacy/policies/cookies" target="_blank" rel='noopener noreferrer'>
                            Política de cookies de Google
                        </a>
                    </li>
                </ul>
                <p>
                    No gestionaremos ni almacenaremos ninguna información de cookies de terceros, pero los tokens de seguridad compartidos con los sistemas de inicio de sesión podrían almacenarse en la base de datos de ANNA por motivos legales o de seguridad.
                </p>
                <p>
                    No gestionaremos ni almacenaremos ninguna información de cookies de terceros, pero los tokens de seguridad compartidos con los sistemas de inicio de sesión podrían almacenarse en la base de datos de ANNA por motivos legales o de seguridad.
                </p>
            </div>
            )
        },
        "COOKIE_POLICY_TABLE": () => {
            return (<div className="gdpr-more-info Text500__5" style={{ alignItems: 'center' }}>
                <p>These are the local storage items and cookies managed by ANNA web site on its domain:</p>
                <table className='cookies-table Text500__5 mb-3 mx-auto'>
                    <tbody>
                        <tr>
                            <th>Type</th>
                            <th>Name</th>
                            <th>Purpose</th>
                        </tr>
                        <tr>
                            <td>Cookie</td>
                            <td>cookieConsent</td>
                            <td>Remembering that You’ve read this cookie page</td>
                        </tr>
                        <tr>
                            <td>Local storage</td>
                            <td>_grecaptcha</td>
                            <td>Used for security purposes, during the registration, to prevent non-human (bot) interactions with the page</td>
                        </tr>
                        <tr>
                            <td>Local storage</td>
                            <td>UserAuth</td>
                            <td>Used for security purposes to store on Your browser Your identity data and token after You have logged in.</td>
                        </tr>
                        <tr>
                            <td>Cookie</td>
                            <td>active_language</td>
                            <td>Used to remember Your preferred language for the user interface</td>
                        </tr>
                    </tbody>
                </table>
                <h3 className="test-boost">Third party cookies</h3>
                <p>
                    The Site uses statistical cookies (for advertising and marketing purposes) to monitor visits to the Site.
                    <br />These cookies and their processing are subject to your consent and therefore will be used only if you accept.
                </p>
                <p>
                    If you accept third party cookies please refer to this providers and privacy disclosures policies:
                </p>
                <ul>
                    <li>Google Analytics
                        <ul>
                            <li><a href="https://www.google.com/policies/privacy/partners/">Google's Privacy & Terms</a></li>
                            <li><a href="https://support.google.com/analytics/answer/6004245">Safeguarding your data</a></li>
                        </ul>
                    </li>
                    <li>Google TAG manager
                        <ul>
                            <li><a href="https://support.google.com/tagmanager/answer/9323295?hl=en">Data privacy and security</a></li>
                        </ul>
                    </li>
                    <li>META PIXEL
                        <ul>
                            <li><a href="https://www.facebook.com/privacy/policy/">Meta Privacy Policy</a></li>
                        </ul>
                    </li>
                </ul>
            </div>);
        },
        "COOKIE_POLICY_CONTACT": () => {
            return (<div className="Text500__5" style={{ textAlign: 'justify' }}>
                <p>Para cualquier consulta puede ponerse en contacto con <a href="mailto:info@labh9.eu">info@labh9.eu</a></p>
            </div>)
        },
        "COUNTRY_NOT_FOUND": () => {
            return `Añada el país a su perfil!`;
        },
        "CONTACT_US": () => {
            return `Contactanos`;
        },
        "WRITE_EMAIL": () => {
            return `Correo electrónico`;
        },
        "LEAVE_MAIL_MESSAGE_HERE": () => {
            return `Dejanos tu mensaje`;
        },
        "OPS": () => {
            return `Opssss...`
        },
        "NAME_MANDATORY": () => {
            return `Debes inserir tu nombre`
        },
        "SURNAME_MANDATORY": () => {
            return `Debes inserir el apellido`
        },
        "MAIL_MANDATORY": () => {
            return `Debes inserir tu correo`
        },
        "TEXT_MANDATORY": () => {
            return `Debes introducir tu texto`
        },
        "INVALID_IMAGE": () => {
            return `Sólo se permiten imágenes JPEG y PNG.`;
        },
        "STATUS": () => {
            return `Estado`;
        },
        "SUBSCRIPTION": () => {
            return `Suscripción`;
        },
        "AMOUNT": () => {
            return `Importe`;
        },
        "UPLOAD_DOC_MOBILE_TITLE": () => {
            return `Cargar un documento`;
        },
        "UPLOAD_DOC_MOBILE_DESC": () => {
            return `Añade cualquier pdf, ebook, word, powerpoint, excel, documento de texto`;
        },
        "CREATE_FOLDER_MOBILE_TITLE": () => {
            return `Crear una carpeta`;
        },
        "CREATE_FOLDER_MOBILE_DESC": () => {
            return `Crea una nueva carpeta para organizar tus documentos`;
        },
        "UPLOAD_VIDEO_MOBILE_TITLE": () => {
            return `Cargar vídeo`;
        },
        "UPLOAD_VIDEO_MOBILE_DESC": () => {
            return `Crea un video en tiempo real o carga il video`;
        },
        "UPLOAD_URL_MOBILE_TITLE": () => {
            return `Subir una página web`;
        },
        "UPLOAD_URL_MOBILE_DESC": () => {
            return `Ingresa la URL de la pagina y obtendras todo su contenido`;
        },
        "UPLOAD_URL_MOBILE_LABEL": () => {
            return `Dirección de la página a cargar`;
        },
        "UPLOAD_URL_CHECK_MOBILE_LABEL": () => {
            return `Sigue el enlace de la página`;
        },
        "UPLOAD_URL_MOBILE_MODAL_DESC": () => {
            return `Se descargará la página web indicada y se utilizará todo su contenido como documento de biblioteca.`;
        },
        "UPLOAD_URL_MOBILE_DOWNLOADING_DESC": () => {
            return `Estoy descargando tu página, espera un momento.`;
        },
        "SUBMIT_PICTURE_MOBILE_TITLE": () => {
            return `Cargar una imagen`;
        },
        "SUBMIT_PICTURE_MOBILE_DESC": () => {
            return `Escanea tu libro o cargue imágenes`;
        },
        "UPLOAD_SPEECH_MOBILE_TITLE": () => {
            return `De voz a texto`;
        },
        "UPLOAD_SPEECH_MOBILE_DESC": () => {
            return `Graba sonido con tu micrófono`;
        },
        "UPLOAD_SPEECH_DESKTOP_DESC": () => {
            return `Utiliza tu voz o sube un audio`;
        },
        "UNABLE_TO_TRANSCRIBE": () => {
            return `No se pueden transcribir las imágenes proporcionadas`;
        },
        "APPROVAL": () => {
            return `Aprobación`;
        },
        "NO_INFORMATION_CLASS": () => {
            return `No se proporciona información sobre la clase`;
        },
        "COUNTRY_REQUIRED": () => {
            return `Debes elegir un país!`;
        },
        "DURATION_SUB_MONTHLY_PLURAL": (params) => {
            var { expirationUnit } = params;
            if (expirationUnit === "year") {
                expirationUnit = "años";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "month") {
                expirationUnit = "meses";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "week") {
                expirationUnit = "semanas";
                return `${expirationUnit}`;
            }
            return `${expirationUnit}`;
        },
        "DATE": () => {
            return `Fecha`;
        },
        "EMAIL_SENT": () => {
            return `Se ha enviado un correo electrónico de cambio de contraseña, compruebe su bandeja de entrada.`;
        },
        "PASSWORD_RESET": () => {
            return `Recuperar contraseña`;
        },
        "ENTER_EMAIL_FORGOT_PASSWORD": () => {
            return (<p>Introduzca la <strong> dirección de correo electrónico </strong> asociada a su cuenta y le enviaremos un enlace para restablecer su <strong>contraseña</strong>.</p>);
        },
        "REQUEST": () => {
            return `Enviar petición`;
        },
        "REQ_PROFESSOR": () => {
            return `¿Está seguro de solicitar la actualización del perfil de su cuenta de usuario estándar a cuenta de profesor? Se iniciará un proceso de verificación y nuestro back-office se pondrá en contacto con usted.`;
        },
        "CONFIRM_ROLE_PROFESSOR": () => {
            return `Cambiar a la cuenta de profesor`;
        },
        "PROMO_CODE": () => {
            return `Código promo`;
        },


        "NOACCOUNT": () => {
            return '¿No tienes cuenta?'
        },

        "REGISTERFREE": () => {
            return 'Regístrate gratis'

        },
        "CANCEL_SUBSCRIPTION_MODAL": () => {
            return "¿Seguro que quiere darse de baja? Podrá utilizar su suscripción hasta la fecha de caducidad."
        },
        "FILE_UPLOADED": () => {
            return "Archivo cargado"
        },
        "FILE_UPLOADED_SUCCESS": () => {
            return "Documento cargado correctamente"
        },
        "ERROR_ACCESSING_MICROPHONE": () => {
            return "Error al acceder al micrófono"
        },
        "FAILED_TO_GET_PAYMENTS": () => {
            return "No se pudieron obtener los pagos"
        },
        "SUCCESS": () => {
            return "Success";
        },



        // About anna screen
        "ABOUTANNA": () => {
            return `Acerca ANNA`;
        },


        "ABOUTANNA_WHOISANNA": () => {
            return `¿Quién es ANNA?`
        },


        "ABOUTANNA_WHOISANNA_ANSWER": () => {
            return (
                <p>
                    <strong>ANNA</strong> es un tutor digital basado en tecnologías avanzadas de inteligencia artificial.
                    <br></br>

                    <strong>ANNA</strong> puede ayudarte a estudiar optimizando tu tiempo y simplificando el aprendizaje con mapas mentales, chats avanzados y simulaciones de exámenes.                    <br></br>

                    <strong>ANNA</strong> sólo utiliza sus propias fuentes (libros, vídeos, documentos) para ayudarle a estudiar, por lo que es fiable y segura.                    <br></br>

                    <strong>ANNA</strong> es un compañero de estudio ideal y puedes probarlo gratis.
                </p>
            );
        },

        "ABOUTANNA_HOWITWORKS_TITLE": () => {
            return `¿Cómo funciona ANNA?`;
        },
        "ABOUTANNA_HOWITWORKS": () => {
            return `¿Cómo funciona?`;
        },
        "ABOUTANNA_HOWITWORKS_ANSWER": () => {
            return (
                <div>
                    <p>Selecciona tu idioma preferido</p>

                    <div className='image_div'>
                        <img className="about_image" alt="Language selector" src={changeLanguage}></img>
                    </div>
                    <p>No te preocupes, puedes cambiarlo en cualquier momento y las lenguas disponibles aumentarán con el tiempo.
                        <br></br>
                        Sube tus documentos a <strong>ANNA</strong>. Puedes utilizar cualquier tipo de archivo, pdf, word, ebook, excel, power point o archivos multimedia como imágenes, vídeo o audio.                    </p>

                    <div className='image_div'>
                        <img className="about_image" alt="File Uploading" src={fileUpload}></img>
                    </div>

                    <p>Puedes hacer fotos de tus libros o apuntes
                    </p>

                    <div className='image_div'>
                        <img className="about_image" alt="ImageUploading" src={imgUpload}></img>
                    </div>


                    <p> o grabar tu voz.</p>

                    <div className='image_div'>
                        <img className="about_image" alt="Audio uploading" src={audioUpload}></img>
                    </div>

                    <p>Puedes organizar tus contenidos en carpetas o extraer de los documentos sólo las páginas que te interesan.</p>

                    <div className='image_div'>
                        <img className="about_image"  alt="Create folder"src={folderExample}></img>
                    </div>

                    <p>No te preocupes por la traducción, <strong>ANNA</strong> usa siempre tu idioma preferido.</p>
                </div>
            );
        },

        "ABOUTANNA_Q&A_TITLE": () => {
            return `Question & Answer`;
        },

        "WHATISQ&A": () => {
            return `En qué consiste la función Question & Answer`;

        },

        "WHATISQ&A_ANSWER": () => {
            return (
                <p>
                    Con esta función puede aprovechar el poder de la <strong>AI Generativa</strong> para chatear con <strong>ANNA</strong> utilizando sus propios documentos como base de conocimientos.
                    <br></br>

                    <strong>ANNA</strong> hace algo más que responder a sus preguntas.

                    <br></br>

                    Utilizando únicamente sus propios archivos (libros, notas, imágenes, vídeo, audio) <strong>ANNA</strong> es capaz de crear resúmenes, extraer y procesar información, elaborar tablas, comparar datos o generar nuevos contenidos según tus necesidades.

                    <br></br>

                    No pongas límites a lo que <strong>ANNA</strong> puede hacer.



                </p>

            );
        },

        "MINDMAP_USE_QUESTION": () => {
            return `¿Qué son los mapas mentales?`;

        },

        "MINDMAP_USE_ANSWER": () => {
            return (
                <p>
                    Los mapas mentales son una gran herramienta para estudiar temas extensos o complejos.

                    <br></br>

                    <strong>ANNA</strong> crea por ti mapas mentales de cada documento o carpeta organizando el contenido de forma gráfica útil para aprender y memorizar rápidamente los elementos clave.                    <br></br>

                    Puedes navegar por el mapa desplegando los nodos y viendo resúmenes de cada tema.
                    <br></br>

                    Puedes compartir, imprimir y exportar tu mapa mental con un solo clic.
                    <div className='image_div'>

                        <img src={mindMap} alt="Mindmap" className="about_image"></img>
                    </div>
                </p>

            );

        },

        "HOW_TO_USE_TEST": () => {
            return `¿Cómo funcionan las pruebas de ANNA?`;
        },

        "HOW_TO_USE_TEST_ANSWER": () => {
            return (
                <p>
                    Con <strong>ANNA </strong>puedes simular un examen para comprobar el estado de tus conocimientos.
                    <br></br>
                    Elija el documento o la carpeta y cree un test de autoevaluación.
                    <br></br>
                    Puedes personalizar tu prueba indicando el número de preguntas y el tipo de examen, con respuestas cerradas o abiertas.
                    <br></br>
                    Responde a las preguntas y comprueba el resultado. <strong> ANNA </strong>te explicará cualquier error indicándote la respuesta correcta y l las páginas que debes releer.
                    <br></br>
                    Practique con los tests <strong>ANNA</strong> y prepárate para tu próxima tarea, pregunta o examen.
                </p>

            )

        },
        "HOW_TRY_ANNA": () => {
            return `¿Cómo puedo probar ANNA?`;
        },

        "HOW_TRY_ANNA_ANSWER": () => {
            return (
                <p>
                    Puedes probar <strong>ANNA</strong> gratis. Solo tienes que registrarte o iniciar sesión con Google o Facebook.
                    <br></br>
                    Podrás probarla con los cinco primeros trabajos, con hasta 50 preguntas y cinco pruebas.
                    <br></br>
                    Si <strong>ANNA</strong> satisface tus necesidades, puede adquirir una suscripción semanal o mensual.
                </p>

            )
        },

        "HOW_MUCH": () => {
            return `¿Cuánto cuesta ANNA?`;
        },

        "HOW_MUCH_ANSWER": () => {
            return (
                <p>
                    <strong>ANNA</strong> es gratuita para los cinco primeros documentos
                    <br></br>
                    Con la prueba gratuita tienes un máximo de 50 preguntas y 5 pruebas.
                    <br></br>
                    Puedes consultar nuestro <a href='/catalogue' >catálogo</a> para ver nuestras ofertas y comparar los distintos planes.
                    <br></br>
                    Puedes adquirir suscripciones mediante PayPal y las principales tarjetas de crédito.
                    <br></br>
                    Puedes interrumpir la renovación automática en cualquier momento.
                    <br></br>
                    Colegios y profesores pueden disfrutar de políticas especiales de descuento, contáctanos para brindarte más detalles..
                </p>

            )
        },

        "ANNA_LAB": () => {
            return `¿Qué es ANNA-LAB?`;
        },

        "ANNA_LAB_ANSWER": () => {
            return (
                <p>
                    Si quieres ser un beta tester <strong>ANNA-LAB</strong> es el lugar ideal.
                    <br></br>
                    Podrás probar nuevas funciones aún en desarrollo e interactuar con nuestros desarrolladores.
                </p>

            )
        },

        "QUICKCARDS_SESSION_TITLE":()=>{
            return `QuickCards`;
        },
        "QUICKCARDS_SESSION":()=>{
            return `Puedo crear para ti tarjetas rápidas que te ayudarán a asimilar los conceptos más importantes con magníficos resúmenes`;
        },

        "HOW_ANNA_WORK": () => {
            return `Vea cómo funciona`;
        },
        "BLOGS":()=>{
            return `Blogs`;
        },
        "CONNECTING_CHAT_AI_ENGINE": () => {
            return `Se está lanzando su chatbot dedicado de ANNA. Espere unos segundos.`;
        },
        "CONNECTED_CHAT_AI_ENGINE": () => {
            return `Tu chatbot de ANNA ya está listo.`;
        },
        "PUBLIC_CARDS_SCIENCE_TOPIC": () => {
            return `Ciencia`;
        },
        "PUBLIC_CARDS_HISTORY_TOPIC": () => {
            return `Historia`;
        },
        "PUBLIC_CARDS_ARTS_TOPIC": () => {
            return `Arte`;
        },
        "PUBLIC_CARDS_SOCIETY_TOPIC": () => {
            return `Sociedad`;
        },

    }
}

export { getText };