import React from 'react';
import * as api from '../../api/apiConnector';

function RichTextToDocx(props){
const { user, fileName , question, richText} = props;
  const convertToDocx = async () => {
    // Convert the rich text content to HTML
    const result = await api.sendMessage("richTextToHtml", {
      body: {
        richText: richText,
        question: question,
        fileName: fileName
      },
      user: user
    });

    if(result.success){
      const url = result.file;
      window.location.href = url;
      //saveAs(url, `${fileName}-QA.docx`);
    }
  };

  return (
    <button className='then-small-button margin'>
      <i 
        className="fa fa-file-word" 
        style={{ cursor: "pointer" }} 
        onClick={() => convertToDocx(richText)}
      ></i>
    </button>
  );
};

export default RichTextToDocx;
