import '../App.css';
import { useContext, useEffect } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import UserContext from '../components/User_Context';
import React, { useState, useRef } from 'react';
import * as labels from "../components/localized/labels";
import { SetMenu } from '../components/Navigation';
import * as api from '../api/apiConnector';
import { useNavigate, useParams } from 'react-router-dom';

function Password_Reset_Screen() {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { randomCode, language } = useParams();
    const [oldPassword, setOldPassword] = useState('');
    const [codeNew, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [confirmedCode, setConfirmedCode] = useState(false);

    function validatePassword(password) {
        const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]{8,999}$/;

        return regex.test(password);
    }
    async function checkCode() {
        var result = (await api.sendMessage("checkCode", {
            body: {
                code: randomCode
            },
        }));
        if (!result.success && result.error) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "error",
                text: result.error,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 5000
            }).then(() => {
                navigate(`/${language}/passwordReset`);
            });
        }
        else if (!result.success) {
            navigate(`/${language}/passwordReset`);
        }
        else if (result.success) {
            setConfirmedCode(true);
            setUserEmail(result.userName);
            setCode(result.randomCode);
        }
    }
    async function resetPassword() {
        var result = (await api.sendMessage("passwordRecovery", {
            body: {
                userName: userEmail,
                newPassword: newPassword,
                oldPassword: oldPassword,
                randomCode: codeNew
            },
        }));
        if (result.success) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "success",
                text: result.message,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 5000
            }).then(() => {
                navigate(`/${language}/login`);
            });
        }
        else {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "error",
                text: result.error,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 5000
            });
        }
    }
    async function forgotPassword() {
        var result = (await api.sendMessage("forgotPassword", {
            body: {
                userName: userEmail
            },
        }));
        if (result.success) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "success",
                text: result.message,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 5000
            });
        }
        else if (result.error) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "error",
                text: result.error,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 5000
            });
        }
    }
    useEffect(() => {
        if (randomCode) { checkCode(); }
    }, [randomCode]);
    return (
        <div className='wrap'>
            {SetMenu({ user: user })}
            <div className="container py-4 h-100 shadow my-5 bg-white rounded justify-content-center">
                <div className="row d-flex justify-content-center h-100">
                    <h3 id="loginHeader" className="mb-4 mx-auto">
                        {labels.getText()["PASSWORD_RESET"]()}
                    </h3>
                </div>
                <div className="row d-flex justify-content-center h-100">
                    <div className="col-lg-7 col-lg-5 col-xl-5 offset-xl-1">
                        <div className="form-outline mb-4 Text500__5">
                            {!confirmedCode && (
                                <div className='d-flex align-items-center flex-column'>
                                    <div>
                                        {labels.getText()["ENTER_EMAIL_FORGOT_PASSWORD"]()}
                                    </div>
                                    <label className="form-label">
                                        {labels.getText()["EMAIL_ADDRESS"]()}
                                    </label>
                                    <input type="text" title={labels.getText()["EMAIL_ADDRESS"]()} className="form-control form-control-lg" onChange={(e) => {
                                        setUserEmail(e.target.value);

                                    }} value={userEmail} required />
                                    <button className="then-small-button mt-3" type="button" onClick={(e) => { forgotPassword() }}>
                                        {labels.getText()["SUBMIT_BUTTON"]()}
                                    </button>
                                </div>
                            )}
                            {confirmedCode && (
                                <>
                                    <div className="password-requirements">
                                        <p>{labels.getText()["PASS_REQS"]()}</p>
                                        <ul>
                                            <li>{labels.getText()["PASS_REQS1"]()}</li>
                                            <li>{labels.getText()["PASS_REQS2"]()}</li>
                                            <li>{labels.getText()["PASS_REQS3"]()}</li>
                                            <li>{labels.getText()["PASS_REQS4"]()}</li>
                                            <li>{labels.getText()["PASS_REQS5"]()}</li>
                                        </ul>
                                    </div>
                                    <div className='d-flex align-items-center flex-column'>
                                        <div className="form-group mt-3">
                                            <label for="oldPassword">{labels.getText()["NEW_PASSWORD"]()}</label>
                                            <input type="password" className="form-control" aria-describedby="emailHelp" placeholder={labels.getText()["ENTER_NEW_PASSWORD"]()} onChange={(e) => {
                                                setOldPassword(e.target.value);
                                            }} />
                                        </div>
                                        <div className="form-group mt-3">
                                            <label for="newPassword">{labels.getText()["CONFIRM_PASSWORD"]()}</label>
                                            <input type="password" className="form-control" placeholder={labels.getText()["CONFIRM_PASSWORD"]()} onChange={(e) => {
                                                setNewPassword(e.target.value);
                                            }} />
                                        </div>
                                        <button className="then-small-button mt-3" onClick={(e) => {
                                            if (!validatePassword(newPassword)) {
                                                alert(labels.getText()["PASSWORD_REQ"]());
                                            }
                                            else if (oldPassword != newPassword) {
                                                alert(labels.getText()["PASSWORDS_NO_MATCH"]());
                                            }
                                            else
                                                resetPassword();
                                        }}>{labels.getText()["CHANGE_PASSWORD"]()}</button>
                                    </div>
                                </>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


}

export default Password_Reset_Screen;