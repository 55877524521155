import { React, useState } from "react";
import { FaArrowLeft, FaLocationArrow } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import * as labels from "../components/localized/labels";


function Question_Packages_List(props) {
    const { items, itemsPerPage = 5, activePage = 1, onPress,
        autoRefresh = true, onNeedRefresh } = props;
    const [_activePage, _setActivePage] = useState(activePage);
    //calculation of the progress percentage
    function calculateQuestionPercentage(questionCount, executedQuestionCount, fixed = 0) {

        return questionCount === 0 ? 0 : (executedQuestionCount / questionCount * 100).toFixed(fixed);
    }

    function _drawPagination() {
        var pagesCount = Math.trunc(items.length / itemsPerPage) +
            (items.length % itemsPerPage > 0 ? 1 : 0);

        var pages = [];

        for (var i = 1; i <= pagesCount; i++) {
            pages.push({ pageNumber: i, active: (_activePage == i) });
        }

        var showPageBeforeLink = false;
        var showPageAfterLink = false;

        //optimize pages list
        if (pages.length > 5) {
            //compute the start page;

            let _firstPageToShow = _activePage - 2 > 1 ? _activePage - 2 : 1;
            let _lastPageToShow = _activePage + 2 < pages.length ? _activePage + 2 : pages.length;
            while (_lastPageToShow - _firstPageToShow < 5 && pages.length > 5) {
                if (_firstPageToShow > 1) {
                    _firstPageToShow--;
                }
                else if (_lastPageToShow < pages.length) {
                    _lastPageToShow++;
                }

            }

            while (pages.find(x => x.pageNumber < _firstPageToShow)) {
                pages.splice(pages.findIndex(x => x.pageNumber < _firstPageToShow), 1);
                showPageBeforeLink = true;
            }
            while (pages.find(x => x.pageNumber > _lastPageToShow)) {
                pages.splice(pages.findIndex(x => x.pageNumber > _lastPageToShow), 1);
                showPageAfterLink = true;
            }
        }

        if (pages.length <= 1) {
            return (null);
        }

        return (
            <div>
                <hr />
                <div className="row font-monospace" style={
                    {
                        WebkitUserSelectelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                        userSelect: "none"
                    }
                }>
                    <div className="col" >Pages &gt;</div>
                    {showPageBeforeLink ? (<div className="col" title={labels.getText()["PREVIOUS_PAGES"]()} style={{ cursor: "pointer" }}
                        onClick={() => {
                            _setActivePage(_activePage - 1);
                        }}><FaArrowLeft></FaArrowLeft></div>) : null}
                    {pages.map(item => {
                        return (<div key={`goToPage_${item.pageNumber}`} title={`${labels.getText()["GO_TO_PAGE"]()} ${item.pageNumber}`} className={`col${(_activePage == item.pageNumber) ? " border border-primary" : ""}`} style={{ cursor: _activePage != item.pageNumber ? "pointer" : "inherit" }}
                            onClick={() => {
                                _setActivePage(item.pageNumber);
                            }}>{item.pageNumber}</div>);
                    })}
                    {showPageAfterLink ? (<div className="col" title={labels.getText()["NEXT_PAGES"]()} style={{ cursor: "pointer" }}
                        onClick={() => {
                            _setActivePage(_activePage + 1);
                        }}><FaArrowRight></FaArrowRight></div>) : null}
                </div>
            </div>
        );
    }

    function _drawItems() {
        if (items && items.length > 0) {

            let _itemsToShow = items.slice((_activePage - 1) * itemsPerPage, _activePage * itemsPerPage);

            return (
                <div>
                    <div className="list-group">
                        {_itemsToShow.map(item => {
                            return (_drawItem(item));
                        })}
                    </div>

                    {_drawPagination()}
                    <hr />
                </div>
            );
        }
        else {
            return (
                <div>

                    {labels.getText()["NO_TEST_TO_SHOW"]()}
                </div>
            );
        }
    }

    function _drawItem(item) {
        function _questions() {
            switch (item.status) {
                case "processing": {
                    // setTimeout(() => {
                    // if (onNeedRefresh) {
                    //     onNeedRefresh();
                    // }
                    // }, 5000);
                    // return (<small>indexing in progress</small>);
                    var percent = calculateQuestionPercentage(item.questionChunks, item.questionExecutedChunks);
                    if (isNaN(percent)) {
                        percent = 5;
                    }
                    return (<div className="mx-auto">
                        <label>{labels.getText()["INDEXING_PROGRESS"]()}</label>
                        <div className="progress mx-auto" style={{ marginBottom: "2em", height: "3em", width: "60%", marginLeft: "auto", marginRight: "auto", marginTop: "1em" }}>
                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${percent}%` }}>{`${percent}%`}</div>
                        </div>
                    </div>);
                }
                    break;
                default:
                    {
                        return ( <small>
                        {labels.getText()["NQUESTIONS"]({numbers:item.questions.length})}
                       </small>);
                    }
                    return;
            }
        }

        return (


            <div style={{ cursor: "pointer" }} onClick={() => {
                if (onPress) {
                    if (item.status == "done") {
                        onPress(item);
                    }
                }
            }} key={item._id} title={item.summary} className="list-view-item list-group-item list-group-item-action mx-auto" aria-current="true" id="questionPackageItem">
                <div className="row">
                    <div className="col">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{labels.getText()["PACKAGE"]()} #{items.findIndex(x => x === item) + 1}</h5>

                        </div>
                        <p id="packageItem" className="mb-1">
                            {
                                labels.getText()["TEST_SMALL_MESSAGE"]
                                    ({
                                        generatedDate: new Date(item.generationTime).toLocaleDateString(),
                                        generatedTime: new Date(item.generationTime).toLocaleTimeString()
                                    })
                            }</p>
                        <small id="packageItem">{_questions()}</small>
                    </div>
                    <div className="col-1 align-self-center" style={{ marginRight: "5%" }}>
                        <span><FaChevronRight style={{ fontSize: "2em" }} /></span>
                    </div>
                </div>
            </div>



        );
    }

    return _drawItems();
}

export default Question_Packages_List;