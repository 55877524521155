import React, { useState, useContext } from 'react';
import * as labels from "../components/localized/labels";
import * as api from '../api/apiConnector';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import UserContext from '../components/User_Context';
import { SetMenu } from '../components/Navigation';

function ContactUS_Screen() {

    // set the variable to use
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mailContent, setMailContent] = useState('');
    const canIsend = true;
    let { user } = useContext(UserContext);

    //for simple user
    const [mailObject, setMailObject] = useState({
        name: '',
        lastName: '',
        email: '',
        mailContent: '',

    });

    async function sendEmail(mailObject) {

        var result = (await api.sendMessage("sendMailFromContact", {
            body: {
                mailObject: mailObject,

            }
        }));

        if (result.success) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "success",
                text: result.message,
                timer: 2000
            });

            setEmail("");
            setName("");
            setLastName("");
            setMailContent("");
        } else {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "error",
                text: result.message,
                timer: 2000
            });
        }


      


    }

    return (
        <div className="wrap">
            <Helmet>
            <script src={process.env.REACT_APP_RECAPTCHA_JS_URL}></script>
            </Helmet>
            <div className="container py-4 h-100 shadow my-5 bg-white rounded">
                <div className="row d-flex justify-content-center h-100">
                    <div className="col-lg-7 col-lg-5 col-xl-5 offset-xl-1">
                        <h1 className="mb-4">
                            {labels.getText()["CONTACT_US"]()}
                        </h1>
                        {SetMenu({ user: user})}

                        <div className="form-outline mb-4 Text500__5">
                            <label className="form-label">
                                {labels.getText()["NAME"]()}
                            </label>
                            <input type="text" id="name" title={labels.getText()["NAME"]()}
                                className="form-control form-control-lg" onChange={(e) => {
                                    mailObject.name = e.target.value;
                                    setName(e.target.value);
                                }} value={name} required />

                        </div>

                        <div className="form-outline mb-4 Text500__5">
                            <label className="form-label">
                                {labels.getText()["LAST_NAME"]()}
                            </label>
                            <input type="text" id="lastName" title={labels.getText()["LAST_NAME"]()}
                                className="form-control form-control-lg" onChange={(e) => {
                                    mailObject.lastName = e.target.value;
                                    setLastName(e.target.value);

                                }} value={lastName} required />

                        </div>

                        <div className="form-outline mb-4 Text500__5">
                            <label className="form-label">
                                {labels.getText()["EMAIL_ADDRESS"]()}
                            </label>
                            <input type="email" id="form1Example13" title={labels.getText()["EMAIL_ADDRESS"]()}
                                className="form-control form-control-lg" onChange={(e) => {
                                    mailObject.email = e.target.value;
                                    setEmail(e.target.value);
                                }} value={email} required />

                        </div>

                        <div className='question-box'>
                            <div className='question-title'>
                                <p className="stretch  Text700__2 violet500">{labels.getText()["WRITE_EMAIL"]()}</p>
                                <textarea className="form-control inputTextArea Text500__7 neutral500"
                                    onChange={(e) => {
                                        mailObject.mailContent = e.target.value;
                                        setMailContent(e.target.value);
                                    }}
                                    value={mailContent}
                                    placeholder={labels.getText()["LEAVE_MAIL_MESSAGE_HERE"]()}
                                    id="message" style={{ height: 350 }}></textarea>
                            </div>

                            <div className='col'>
                                <button className="then-big-button withMarginTop" id='submit-button'
                                    type="submit"

                                    onClick={async () => {
                                      
                                        if (!mailObject.name || mailObject.name == "") {
                                            withReactContent(Swal).fire({
                                                title: <i>{labels.getText()["OPS"]()}</i>,
                                                icon: "error",
                                                text: labels.getText()["NAME_MANDATORY"](),
                                                showCloseButton: true
                                            });
                                            canIsend = false;
                                        }

                                        if (!mailObject.lastName || mailObject.lastName =="") {
                                            withReactContent(Swal).fire({
                                                title: <i>{labels.getText()["OPS"]()}</i>,
                                                icon: "error",
                                                text: labels.getText()["SURNAME_MANDATORY"](),
                                                showCloseButton: true
                                            });
                                            canIsend = false;

                                        }

                                        if (!mailObject.email || mailObject.email =="") {
                                            withReactContent(Swal).fire({
                                                title: <i>{labels.getText()["OPS"]()}</i>,
                                                icon: "error",
                                                text: labels.getText()["MAIL_MANDATORY"](),
                                                showCloseButton: true
                                            });
                                            canIsend = false;

                                        }

                                        if (!mailObject.mailContent || mailObject.mailContent =="") {
                                            withReactContent(Swal).fire({
                                                title: <i>{labels.getText()["OPS"]()}</i>,
                                                icon: "error",
                                                text: labels.getText()["TEXT_MANDATORY"](),
                                                showCloseButton: true
                                            });
                                            canIsend = false;

                                        }


                                        if(canIsend){
                                            window.grecaptcha.enterprise.ready(async () => {
                                                const token = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_REACAPTCHA_EXECUTE, { action: 'submit' });
                                                var result = (await api.sendMessage("reCaptchaCheck", {
                                                    body: {
                                                        token: token,
                                                        recaptchaAction: 'submit',
                                                        userName: mailObject.email
                                                    }
                                                }));
                                    
                                                if (result.recaptchaResponse.valid) {
                                    
                                                    sendEmail(mailObject);

                                    
                                    
                                                    // alert("Email resent");
                                                } else {
                                                    withReactContent(Swal).fire({
                                                        title: <i>{labels.getText()["ERROR"]()}</i>,
                                                        icon: "error",
                                                        text: result.recaptchaResponse.invalidReason,
                                                        showConfirmButton: true,
                                                        allowOutsideClick: false,
                                                        showCloseButton: true,
                                                    });
                                                }
                                    
                                    
                                    
                                            });


                                        }

                                    }}><i className="fa fa-paper-plane fa-2x blackIcon"
                                        style={{ fontSize: "1em" }}></i></button>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>

    )

}

export default ContactUS_Screen;