import '../App.css';
import UserContext from '../components/User_Context';
import { React, useState, useContext, useEffect } from 'react';
import * as labels from "../components/localized/labels";
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import { useNavigate, useParams } from 'react-router-dom';
import * as api from '../api/apiConnector';
import TagManager from "react-gtm-module";
import { useCookies } from 'react-cookie';

function Single_Blog_Screen() {
    let { user } = useContext(UserContext);
    const { blogId, title, language } = useParams();
    const [blog, setBlog] = useState(null);
    const [cookiesThirdParty, setThirdPartyCookie] = useCookies(localStorage.getItem("thirdPartyCookies"));
    user = user ? JSON.parse(user) : null;
    const navigate = useNavigate();

    const getData = async () => {
        const data = await api.sendMessage("getBlogById", {
            body: {
                blogId: blogId,
                title: title,
                user: user,
            }
        });
        if (data.blogData) {
            setBlog(data.blogData);
        }
        else {
            navigate(`/${language}/blogs`)
        }
    }
    useEffect(() => {
        getData();
    }, []);

    if (blog) {
        if (process.env.REACT_APP_ENEABLE_TRACKING && cookiesThirdParty?.cookieConsent) {

            try {
                TagManager.dataLayer({
                    dataLayer: {
                        event: "blogOpen",
                        // category: "category",
                        action: "action",
                        // label: "label",
                        value: blog.title[0].text,
                    },
                });
            } catch (err) {
                console.log(err);
            }
        }


        return (
            <div className="App">
                <div className='wrap'>
                    <div className="py-5">

                        {SetMenu({ user: user })}
                        <div
                            id="registrationBody"
                            className="container my-5 bg-dark rounded shadow-lg img-fluid"
                            style={{
                                backgroundImage: `url(${blog.thumbnail})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                color: 'white',
                                height: '40vh',
                                maxWidth: '900px',
                                width: '100%',
                                margin: 'auto',
                                borderRadius: '8px',
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'flex-end',
                                textAlign:'left',
                                padding: '10px'
                            }}
                        >
                            <div style={{

                                left: '20px',
                                borderRadius: '12px',
                                paddingLeft:'10px', 
                                // backgroundColor: 'rgba(0, 0, 0, 0.2)', // Semi-transparent dark background
                                color: 'white',
                                textShadow: '2px 2px 2px rgba(1, 1, 1, 1)',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <h3 className="title my-3" style={{ color: 'white', fontWeight: 'bold', fontSize: '1.8rem', textShadow: '1px 1px 2px rgba(1,1,1,1)' }}>
                                    {blog.title[0].text}
                                </h3>

                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                                    <i className="fas fa-calendar-alt" style={{ marginRight: '0.5rem' }}></i>
                                    <span style={{ marginRight: '1.5rem' }}>
                                        <b>{new Date(blog.documentCreated).toLocaleDateString("lt-LT", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                        })}</b>
                                    </span>
                                    <i className="fas fa-user" style={{ marginRight: '0.5rem' }}></i>
                                    <span><b>{blog.documentCreatedByName}</b></span>
                                </div>
                            </div>
                        </div>

                        <div id="registrationBody" className="container py-4 h-100 shadow my-5 bg-white rounded" style={{ maxWidth: '900px' }}>
                            <div className="blog-wrapper">
                                <p className="Text500__9" style={{ fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: blog.body[0].text }}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
    else {
        return (
            <div className="wrap">
                {SetMenu({ user: user })}
                <h1>{labels.getText()["WAITING_FOR_DATA"]()}</h1>
            </div>
        );
    }
}

export default Single_Blog_Screen;
