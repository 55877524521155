import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import * as labels from "./localized/labels";
import * as api from "../api/apiConnector";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

function Subscriptions() {
  const [carouselData, setCarouselData] = useState('month');
  var [profile, setProfile] = useState([]);
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
    desktop: { breakpoint: { max: 3000, min: 1420 }, items: 3 },
    smallDesktop: { breakpoint: { max: 1420, min: 1024 }, items: 2 },
    tablet: { breakpoint: { max: 1024, min: 10 }, items: 1 }
  };
  async function getSubscription() {
    var result = await api.sendMessage("getSubscriptionProfiles", {
    });
    if (result.success) {
      console.log(result.subscriptionProfiles);
      setProfile(result.subscriptionProfiles);
    }
  }

  useEffect(() => {
    (async () => {
      await getSubscription();
    })();
  }, []);


  const renderItems = (items) => {
    if (!items || !Array.isArray(items)) {
      return <p>{labels.getText()["NO_DATA_AVAILABLE"]()}</p>;
    }
    return items.map((item, index) => {
      // retrieving the language
      const userLanguage = labels.getLanguage();

      const getLocalizedText = (array) => {
        if (Array.isArray(array)) {
          const textObj = array.find(t => t.iso === userLanguage.toUpperCase()) || array[0];
          return textObj ? textObj.text : '';
        }
        return array || ''; // Fallback if not an array
      };

      const titleText = getLocalizedText(item.title);
      const descriptionText = getLocalizedText(item.description);

      return (
        <div className="subscriptionCardContainer" key={index}>
          {item.badge && (
            <div className="badge bg-success text-white position-absolute" style={{ top: '10px', right: '10px' }}>
              SOLD OUT
            </div>
          )}
          <div className="subscriptionCardBody">
            <div className='cardTopContainer'>
              <div className='cardPriceContainer'>
                <span className='cardPrice'>€{item.price}</span>
                <span className='cardPriceTime'>/ {item.expirationValue > 1 ? `${item.expirationValue}  ${labels.getText()["DURATION_SUB_MONTHLY_PLURAL"]({ expirationUnit: carouselData }) }`: labels.getText()["DURATION_SUB"]({ expirationUnit: carouselData })}</span>
              </div>
              <h2 className='cardTitle' dangerouslySetInnerHTML={{ __html: getLocalizedText(titleText) }}></h2>
              <p className='cardDescription' dangerouslySetInnerHTML={{ __html: getLocalizedText(descriptionText) }}></p>
            </div>
            {item.code && (
              <button className="subscriptionButton Text500__5" onClick={() =>
                withReactContent(Swal).fire({
                  title: <i className="Text700__8">{labels.getText()["LOGIN_PROCEED"]()}</i>,
                  icon: "info",
                  html: (
                    <div className='Text500__5' style={{ overflowX: 'hidden' }}>
                      <a
                        className="then-small-button py-sm-3 px-sm-5 rounded-pill animated slideInRight"
                        href={`/login/${item.code}`}

                      >
                        {labels.getText()["LOGIN"]()}
                      </a>
                      <p className="mt-2 animated slideInRight"><strong>{labels.getText()["DONT_HAVE_ACC"]()}</strong> </p>
                      <a
                        className="then-small-button py-sm-3 px-sm-5 animated slideInRight"
                        style={{ marginLeft: "2%" }}
                        href={`/register/${item.code}`}

                      >
                        {labels.getText()["DONT_HAVE_ACC2"]()}</a>
                    </div>
                  ),

                  showConfirmButton: false,
                  allowOutsideClick: true,
                  showCloseButton: true,

                })
              }>{labels.getText()["CHOOSE_PLAN"]()}</button>
            )}
            <ul className="list-unstyled">
              {item.features && item.features.map((feature, i) => (
                <li key={i} className="text-left">
                  <span className="text-success">&#10003;</span> {feature}
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    });
  };

  const handleButtonClick = (type) => {
    setCarouselData(type);
  };

  const plans = profile[carouselData] || [];
  return (
    <div className="container subscriptionsSection h-100">
      <div className="subscriptionsContainer">
        <div className="tabContainer btn-group btn-group-toggle" data-toggle="buttons">
          {
            profile.day && profile.day.length > 0 && (
              <input type="radio" className="btn btn-outline-primary me-2" onClick={() => handleButtonClick('day')}>{labels.getText()["DAILY"]()}</input>
            )
          }
          {
            profile.week && profile.week.length > 0 && (
              <label className={`btn btn-outline-primary me-2 ${carouselData === "week" ? "active" : ""}`} onClick={() => handleButtonClick('week')}>
                {labels.getText()["WEEKLY"]()}
              </label>
            )
          }
          {
            profile.month && profile.month.length > 0 && (
              <label className={`btn btn-outline-primary me-2 ${carouselData === "month" ? "active" : ""}`} onClick={() => handleButtonClick('month')}>
                {labels.getText()["MONTHLY"]()}
              </label>
            )
          }
          { 
            profile.year && profile.year.length > 0 && (
              <label className={`btn btn-outline-primary me-2 ${carouselData === "year" ? "active" : ""}`} onClick={() => handleButtonClick('year')}>
                {labels.getText()["YEARLY"]()}
              </label>
            )
          }


        </div>
      </div>
      <div className="carouselContainer mt-5">
        <Carousel
          className=""
          swipeable={false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {renderItems(plans)}
        </Carousel>
      </div>
    </div>
  );
}

export default Subscriptions;