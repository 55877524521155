import '../../App.css';
import { React, useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as api from '../../api/apiConnector';
import UserContext from '../../components/User_Context';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as labels from "../../components/localized/labels";
import { getBreadcrumb, SetMenu } from '../../components/Navigation';
import { lab } from 'd3';


function BlogManager_Screen() {
    let { user } = useContext(UserContext);
    user = user ? JSON.parse(user) : null;
    const [visibleArticle, setVisibleArticle] = useState(null);
    const { language } = useParams();

    useEffect(() => {

        (async () => {
            const articles = await api.sendMessage("admin/blog/getSmallArticleList", {

                user: user,
            });

            if (articles) {
                setVisibleArticle(articles.articles);
            }
        })();

    }, []);

    useEffect(() => {
        if (visibleArticle) {
            _drawTable();
        }
    }, [visibleArticle]);


    const _drawTable = () => {




        return (<>
            <table id="empoloyeestbl2" class="table">
                <thead>
                    <tr >

                        <th>Title</th>
                        <th>Creator</th>
                        <th>Creation date</th>
                        <th>Last update by</th>
                        <th>Last update date</th>
                        <th>Visible</th>

                    </tr>
                </thead>
                <tbody>

                    {visibleArticle ? (getTableRows()) : (null)}
                </tbody>

            </table>
        </>);
    }

    const getTableRows = () => {


        return visibleArticle.map((i) => {
            console.log(i);
            return (getTableRow(i))
        });

    }

    const getTableRow = (item) => {

        return (<tr data-id={item._id}
            style={{ "cursor": "pointer" }}
            //  style={{ ...dms_style.table_row }}
            onClick={() => {
                window.location.href = `blogEditor/${item._id}`

            }}
        >
            <td class="col-3">
                {item.title}
            </td>
            <td class="col-2">
                {item.documentCreatedByName ? item.documentCreatedByName : "-"}
            </td>
            <td class="col-2">
                {item.documentCreated ? new Date(item.documentCreated).toLocaleDateString() : "-"}
            </td>
            <td class="col-2">
                {item.documentLastUpdatedByName ? item.documentLastUpdatedByName : "-"}
            </td>
            <td class="col-2">
                {item.documentLastUpdate ? new Date(item.documentLastUpdate).toLocaleDateString() : "-"}
            </td>
            <td class="col-1">
                {item.isVisible ? "Yes" : "No"}
            </td>

        </tr>);
    }

    if (visibleArticle) {
        return (<div className='wrap'>
            <div className="container py-5">
                <div>
                    <div className="wow fadeIn " data-wow-delay="0.1s">
                        <div className="stack-title">
                            {getBreadcrumb({ page: "blogListManager" })}
                            {SetMenu({ user: user, section: "blog" })}
                            <h1 className="document-boost">BLOG LIST MANAGER</h1>
                        </div>
                        <div >
                            {_drawTable()}
                        </div>
                        <div className="d-flex justify-content-center align-items-center mb-4">
                            <button
                                onClick={() => {
                                    window.location.href = `/${language}/blogEditor`;
                                }}
                                className="then-big-button animated slideInRight"
                             
                            >{labels.getText()["NEW_BLOG_ARTICLE"]()}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>);

    } else {
        return (
            <div className="wrap">
                {SetMenu({ user: user, section: "blog" })}
                <h1>{labels.getText()["WAITING_FOR_DATA"]()}</h1>
            </div>
        );
    }


}

export default BlogManager_Screen;
