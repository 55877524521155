import '../App.css';
import BooksList from '../components/Books_List';
import { React, useState, useEffect, useContext, useRef } from 'react';
import * as api from '../api/apiConnector';
import UserContext from '../components/User_Context';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as labels from "../components/localized/labels";
import { SetMenu } from '../components/Navigation';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FileUpload from '../components/upload/upload';
import PageSelector from "../components/PageSelector.js";

const _refreshTime = 5000;

function Library_Screen() {
  const [newBookId, setNewBookId] = useState(null);
  const { professorclassroomId, classroomId } = useParams();
  let { user } = useContext(UserContext);
  user = user ? JSON.parse(user) : null;
  const [docs, setDocs] = useState([]);
  const [folders, setFolders] = useState([]);
  const navigate = useNavigate();
  const [uploadProgress, setUploadProgress] = useState(null);
  const parentRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [classroom, setClass] = useState(null);
  const [showPages, setShowPages] = useState(false);
  const [document, setDocument] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showUrlModal, setShowUrlModal] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [followLinks, setFollowLinks] = useState(false);

  const goToDoc = (document) => {
    if (document) {
      if (professorclassroomId) {

      }
      else if (classroomId) {

      } else {
        window.location.href = `/document/${document._id}`;
      }
    }
    else {
      if (professorclassroomId) {

      }
      else if (classroomId) {

      } else {
        window.location.href = `/document/${document._id}`;
      }
    }
  }

  //#region data-retreive
  const getDocs = async () => {
    let _docs =
      (await api.sendMessage("getDocs", {
        body: {
          professorclassroomId: professorclassroomId,
          classroomId: classroomId
        },
        user: user
      }))?.documents || [];

    for (var d of _docs) {
      d.uploadTime = d.uploadTime ? new Date(d.uploadTime) : null;
    }
    const sortedDocsByDate = _docs.sort((a, b) => b.uploadTime - a.uploadTime);
    return sortedDocsByDate;
  }

  const getFolders = async () => {
    let _folders = (await api.sendMessage("getFolders", {
      body: {
        // userId: user._id,
        professorclassroomId: professorclassroomId,
        classroomId: classroomId
      },
      user: user
    }))?.result ?? [];

    for (var f of _folders) {
      f.creationDate = f.creationDate ? new Date(f.creationDate) : null;
    }
    const sortedFoldersByDate = _folders.sort((a, b) => b.uploadTime - a.creationDate);
    return sortedFoldersByDate;
  }

  const getClass = async () => {
    return (await api.sendMessage("getClassroomFromId", {
      body: {
        id: classroomId ?? professorclassroomId
      },
      user: user,
    }))?.classroom;
  }

  const getSubscription = async () => {
    const result = (await api.sendMessage("getUserSubscription", {
      user: user
    }));
    return result;
  }
  //#endregion

  //#region hooks and hook functions

  //first hook
  useEffect(() => {
    (async () => {
      refreshDocuments();
    })();
  }, []);

  useEffect(() => {
  }, [docs, folders, subscription]);

  const refreshDocuments = async () => {
    let _allDone = false;

    //local menu
    SetMenu({ user: user, section: "library" })

    //until all books are processed (if any still indexing)
    while (!_allDone) {

      const _classroom = professorclassroomId || classroomId ? await getClass() : null;
      if (_classroom) {
        setClass(_classroom);
      }

      //get docs
      const _docs = await getDocs() || [];
      //get folders
      const _folders = await getFolders() || [];

      const _subscription = await getSubscription() || [];
      //if we are in class-room mode, get class-room data and set state

      //set state
      setDocs(_docs);
      setFolders(_folders);
      setSubscription(_subscription);

      //any document still indexing?
      if (!_docs.find(x => x.status === "indexing")) {
        _allDone = true;
        //console.log("all documents ok");
      }
      else {
        //console.log("wait for indexing in progress");
        await new Promise(resolve => setTimeout(resolve, _refreshTime));
      }
    }
  }
  //#endregion

  const createNewFolderModal = () => {
    return (
      <>
        <Modal show={showModal} onHide={() => { setShowModal(false) }}>
          <Modal.Header closeButton>
            <Modal.Title className='Text700__5'>{labels.getText()["CREATE_FOLDER"]()}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='Text500__5'>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>{labels.getText()["FOLDER_NAME"]()}</Form.Label>
                <Form.Control onChange={(event) => setFolderName(event.target.value)}
                  autoFocus
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className='Text500___5'>
            <div className='w-100 d-flex justify-content-between flex-row'>
              <Button className='then-small-button' onClick={() => { setShowModal(false) }}>
                {labels.getText()["CLOSE"]()}
              </Button>
              <Button className='then-small-button' onClick={() => {
                //console.log(folderName);
                createFolder(folderName);
                //refreshing after add something...for update the page with the current new element inside.
                refreshDocuments();
                setShowModal(false);
              }}>
                {labels.getText()["CREATE_FOLDER"]()}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }


  async function deleteElement() {
    withReactContent(Swal).fire({
      title: <i>{labels.getText()["WAIT"]()}</i>,
      icon: "info",
      text: `${labels.getText()["DELETING_DOCUMENT"]()}`,
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: false
    });

    let result = {};

    if (professorclassroomId) {

      if (docs.find(x => x === elementToDelete)) {
        result = (await api.sendMessage("deleteDoc", {
          body: {
            id: elementToDelete._id,
            classroomId: professorclassroomId
          },
          user: user,
        }));
      }
      else {
        result = (await api.sendMessage("deleteFolder", {
          body: {
            folderId: elementToDelete._id,
            classroomId: professorclassroomId

          },
          user: user,
        }));
      }
    } else if (classroomId) {
      if (docs.find(x => x === elementToDelete)) {
        result = (await api.sendMessage("deleteDoc", {
          body: {
            id: elementToDelete._id,
            classroomId: classroomId
          },
          user: user,
        }));
      }
      else {
        result = (await api.sendMessage("deleteFolder", {
          body: {
            folderId: elementToDelete._id,
            classroomId: classroomId

          },
          user: user,
        }));
      }
    }
    else {

      if (docs.find(x => x === elementToDelete)) {
        result = (await api.sendMessage("deleteDoc", {
          body: {
            id: elementToDelete._id,
          },
          user: user,
        }));
      }
      else {
        result = (await api.sendMessage("deleteFolder", {
          body: {
            folderId: elementToDelete._id,
          },
          user: user,
        }));
      }


    }
    if (result.success) {
      setElementToDelete(null);
      setShowDeleteModal(false);
      withReactContent(Swal).fire({
        title: <i>{labels.getText()["DONE"]()}</i>,
        icon: "success",
        text: result.message
      });
      refreshDocuments();

    } else {
      setElementToDelete(null);
      setShowDeleteModal(false);
      withReactContent(Swal).fire({
        title: <i>{labels.getText()["ERROR"]()}</i>,
        icon: "error",
        text: result.message
      });
    }

  }


  const createDeleteModal = () => {
    return (
      <Modal show={showDeleteModal} onHide={() => { setShowDeleteModal(false); setElementToDelete(null) }}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className='Text700__9'>
            {labels.getText()["DELETE_ELEMENT_MODAL"]()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='Text500__6'>
          {labels.getText()["REMOVE_ELEMENT_BODY_MODAL"]({ elementName: elementToDelete?.fileName ?? elementToDelete?.name })}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'space-between' }}>
          <button className="then-small-button" type="button" onClick={() => { setShowDeleteModal(false); setElementToDelete(null) }}>
            {
              labels.getText()["CLOSE"]()
            }
          </button>
          <button className="then-small-button" type="button" onClick={() => deleteElement()}>
            {
              labels.getText()["REMOVE_ELEMENT_BUTTON"]()
            }
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  const createUrlModal = () => {
    return (
      <Modal show={showUrlModal} onHide={() => { setShowUrlModal(false); }}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className='Text700__9'>
            {labels.getText()["UPLOAD_URL_MOBILE_TITLE"]()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='Text500__6'>
          <div className="form-outline mb-4 Text500__5">
            <label className="form-label" >
              {labels.getText()["UPLOAD_URL_MOBILE_LABEL"]()}
            </label>
            <input type="url" id="web_page_url" title={labels.getText()["UPLOAD_URL_MOBILE_LABEL"]()} className="form-control form-control-lg" onChange={(e) => {
              setUploadUrl(e.target.value);
            }} value={uploadUrl} required />

          </div>
          <div className="form-check mb-4 Text500__5">

            <input type="checkbox" id="web_page_follow_links" className="form-check-input " onChange={(e) => {
              setFollowLinks(window.$(e.target).is(":checked"));
            }} />
            <label className="form-check-label" htmlFor="web_page_follow_links">
              <p className='Text500__6'>{labels.getText()["UPLOAD_URL_CHECK_MOBILE_LABEL"]()}</p>
            </label>

          </div>
          {labels.getText()["UPLOAD_URL_MOBILE_MODAL_DESC"]()}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'space-between' }}>
          <button className="then-small-button" type="button" onClick={() => { setShowUrlModal(false) }}>
            {
              labels.getText()["CLOSE"]()
            }
          </button>
          <button className="then-small-button" type="button" onClick={async () => {
            withReactContent(Swal).fire({
              title: <i>{labels.getText()["WAIT"]()}</i>,
              icon: "info",
              text: labels.getText()["UPLOAD_URL_MOBILE_DOWNLOADING_DESC"](),
              showConfirmButton: false,
              allowOutsideClick: false,
              showCloseButton: false,
            });

            //window.alert(`url: ${uploadUrl} follow: ${followLinks}`);

            var uploadResult = await api.sendMessage("uploadDocGemini", {
              body: {
                mimeType: "application/url",
                url: uploadUrl,
                followLinks: followLinks
              },
              user: user,
            });

            let uploaded = false;
            while (!uploaded) {
              var infoResult = await api.sendMessage("getUploadUrlStatus", {
                body: {
                  id: uploadResult.document
                },
                user: user,
              });

              uploaded = infoResult?.document?.done;

              if (!uploaded) await new Promise(resolve => setTimeout(resolve, 5000));
            }

            setShowUrlModal(false);
            refreshDocuments();

            withReactContent(Swal).fire({
              title: <i>{labels.getText()["FILE_UPLOADED"]()}</i>,
              icon: "success",
              text: labels.getText()["FILE_UPLOADED_SUCCESS"](),
              showConfirmButton: true,
              allowOutsideClick: false,
              showCloseButton: true,
              timer: 10000
            }).then((result) => {

            });
          }}>
            {
              labels.getText()["TRY_IT"]()
            }
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  /* Modal with doc, folder, camera and mic buttons */
  const createNewDocAddModal = () => {
    return (
      <>
        <Modal show={showAddModal} onHide={() => { setShowAddModal(false) }}>
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg" className='Text700__6'>
              {labels.getText()["ADD_MODAL_ELEMENT"]()}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mx-auto hiddenMobile">
              <ul className="list-group" style={{ width: '100%' }}>
                <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => {
                  // Trigger the click event on the input element
                  window.$("#input-file-upload").trigger("click");
                  setShowAddModal(false);
                }}>
                  <div className="me-auto">
                    <div className="Text700__4">{labels.getText()["UPLOAD_DOC_MOBILE_TITLE"]()}</div>
                    <div className="Text500__2">{labels.getText()["UPLOAD_DOC_MOBILE_DESC"]()}</div>
                  </div>
                  <button
                    className="then-small-button-ghost"
                  >
                    <i style={{ fontSize: "1em" }} className="fa fa-book fa-2x"></i>
                  </button>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => {
                  if (subscription && subscription.success) {
                    if (subscription.sudo || subscription.subscriptionProfile.folders) {
                      setShowModal(true);
                      setShowAddModal(false);
                    } else {
                      withReactContent(Swal).fire({
                        title: <i>{labels.getText()["ERROR"]()}</i>,
                        icon: "error",
                        text: labels.getText()["PLAN_DOESNT_INCLUDE_FOLDERS"](),
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        showCloseButton: true,
                      })
                    }
                  } else {
                    withReactContent(Swal).fire({
                      title: subscription.message,
                      icon: 'warning',
                      confirmButtonText: labels.getText()["GO_TO_CATALOGUE"](),
                      cancelButtonText: 'OK',
                      showCancelButton: true
                    }).then((result) => {
                      if (result.isConfirmed) {
                        window.location.href = `/catalogue`;

                      }
                    });
                  }
                }}>
                  <div className="me-auto">
                    <div className="Text700__4">{labels.getText()["CREATE_FOLDER_MOBILE_TITLE"]()}</div>
                    <div className="Text500__2">{labels.getText()["CREATE_FOLDER_MOBILE_DESC"]()}</div>
                  </div>
                  <button
                    style={{ marginLeft: '20px' }}
                    className="then-small-button-ghost"
                    onClick={() => {
                      if (subscription && subscription.success) {
                        if (subscription.sudo || subscription.subscriptionProfile.folders) {
                          setShowModal(true);
                          setShowAddModal(false);
                        } else {
                          withReactContent(Swal).fire({
                            title: <i>{labels.getText()["ERROR"]()}</i>,
                            icon: "error",
                            text: labels.getText()["PLAN_DOESNT_INCLUDE_FOLDERS"](),
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            showCloseButton: true,
                          })
                        }
                      } else {
                        withReactContent(Swal).fire({
                          title: subscription.message,
                          icon: 'warning',
                          confirmButtonText: labels.getText()["GO_TO_CATALOGUE"](),
                          cancelButtonText: 'OK',
                          showCancelButton: true
                        }).then((result) => {
                          if (result.isConfirmed) {
                            window.location.href = `/catalogue`;

                          }
                        });
                      }
                    }}
                  >
                    <i style={{ fontSize: "1em" }} className="fa fa-folder-open fa-2x"></i>
                  </button>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => { navigate('/camera') }}>
                  <div className="me-auto">
                    <div className="Text700__4">{labels.getText()["SUBMIT_PICTURE_MOBILE_TITLE"]()}</div>
                    <div className="Text500__2">{labels.getText()["SUBMIT_PICTURE_MOBILE_DESC"]()}</div>
                  </div>
                  <a
                    style={{ marginLeft: '20px' }}
                    role='button' className="then-small-button-ghost"
                    href='/camera' >
                    <i style={{ fontSize: "1em" }} className="fa fa-camera fa-2x blackIcon"></i>
                  </a>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => {
                  if (subscription && subscription.success) {
                    if (subscription.sudo || subscription.subscriptionProfile.audio) {
                      professorclassroomId ? navigate(`/professor/classroom/${professorclassroomId}/speech`) : navigate(`/speech`);
                    } else {
                      withReactContent(Swal).fire({
                        title: <i>{labels.getText()["ERROR"]()}</i>,
                        icon: "error",
                        text: labels.getText()["PLAN_DOESNT_INCLUDE_VOICE"](),
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        showCloseButton: true,
                      })
                    }
                  } else {
                    withReactContent(Swal).fire({
                      title: subscription.message,
                      icon: 'warning',
                      confirmButtonText: labels.getText()["GO_TO_CATALOGUE"](),
                      cancelButtonText: 'OK',
                      showCancelButton: true
                    }).then((result) => {
                      if (result.isConfirmed) {
                        window.location.href = `/catalogue`;

                      }
                    });
                  }
                }}>
                  <div className="me-auto">
                    <div className="Text700__4">{labels.getText()["UPLOAD_SPEECH_MOBILE_TITLE"]()}</div>
                    <div className="Text500__2">{labels.getText()["UPLOAD_SPEECH_DESKTOP_DESC"]()}</div>
                  </div>
                  <button
                    style={{ marginLeft: '20px' }}
                    className="then-small-button-ghost"
                    onClick={() => {
                      if (subscription && subscription.success) {
                        if (subscription.sudo || subscription.subscriptionProfile.audio) {
                          professorclassroomId ? navigate(`/professor/classroom/${professorclassroomId}/speech`) : navigate(`/speech`);
                        } else {
                          withReactContent(Swal).fire({
                            title: <i>{labels.getText()["ERROR"]()}</i>,
                            icon: "error",
                            text: labels.getText()["PLAN_DOESNT_INCLUDE_VOICE"](),
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            showCloseButton: true,
                          })
                        }
                      } else {
                        withReactContent(Swal).fire({
                          title: subscription.message,
                          icon: 'warning',
                          confirmButtonText: labels.getText()["GO_TO_CATALOGUE"](),
                          cancelButtonText: 'OK',
                          showCancelButton: true
                        }).then((result) => {
                          if (result.isConfirmed) {
                            window.location.href = `/catalogue`;

                          }
                        });
                      }
                    }}>
                    <i style={{ fontSize: "1em" }} className="fa fa-microphone fa-2x blackIcon" ></i>
                  </button>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => {
                  // Trigger the click event on the input element
                  window.$("#input-file-upload").trigger("click");
                  setShowAddModal(false);
                }}>
                  <div className="me-auto">
                    <div className="Text700__4">{labels.getText()["UPLOAD_VIDEO_MOBILE_TITLE"]()}</div>
                    <div className="Text500__2">{labels.getText()["UPLOAD_VIDEO_MOBILE_DESC"]()}</div>
                  </div>
                  <button
                    className="then-small-button-ghost"
                    onClick={() => {
                      // Trigger the click event on the input element
                      window.$("#input-file-upload").trigger("click");
                      setShowAddModal(false);
                    }}
                  >
                    <i style={{ fontSize: "1em" }} className="fa fa-video fa-2x"></i>
                  </button>
                </li>
                <li data-upload-type="url" className="list-group-item d-flex justify-content-between align-items-center" onClick={() => {
                  // Trigger the click event on the input element

                  setShowAddModal(false);
                }}>
                  <div className="me-auto">
                    <div className="Text700__4">{labels.getText()["UPLOAD_URL_MOBILE_TITLE"]()}</div>
                    <div className="Text500__2">{labels.getText()["UPLOAD_URL_MOBILE_DESC"]()}</div>
                  </div>
                  <button
                    className="then-small-button-ghost"
                    onClick={() => {
                      // Trigger the click event on the input element
                      setShowUrlModal(true);
                      setShowAddModal(false);
                    }}
                  >
                    <i style={{ fontSize: "1em" }} className="fa fa-globe fa-2x"></i>
                  </button>
                </li>
              </ul>
            </div>

            <div className="onlyMobile">
              <ul className="list-group" style={{ width: '100%' }}>
                <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => {
                  // Trigger the click event on the input element
                  window.$("#input-file-upload").trigger("click");
                  setShowAddModal(false);
                }}>
                  <div className="me-auto">
                    <div className="Text700__4">{labels.getText()["UPLOAD_DOC_MOBILE_TITLE"]()}</div>
                    <div className="Text500__2">{labels.getText()["UPLOAD_DOC_MOBILE_DESC"]()}</div>
                  </div>
                  <button
                    className="then-small-button-ghost"
                  >
                    <i style={{ fontSize: "1em" }} className="fa fa-book fa-2x"></i>
                  </button>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => {
                  if (subscription && subscription.success) {
                    if (subscription.sudo || subscription.subscriptionProfile.folders) {
                      setShowModal(true);
                      setShowAddModal(false);
                    } else {
                      withReactContent(Swal).fire({
                        title: <i>{labels.getText()["ERROR"]()}</i>,
                        icon: "error",
                        text: labels.getText()["PLAN_DOESNT_INCLUDE_FOLDERS"](),
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        showCloseButton: true,
                      })
                    }
                  } else {
                    withReactContent(Swal).fire({
                      title: subscription.message,
                      icon: 'warning',
                      confirmButtonText: labels.getText()["GO_TO_CATALOGUE"](),
                      cancelButtonText: 'OK',
                      showCancelButton: true
                    }).then((result) => {
                      if (result.isConfirmed) {
                        window.location.href = `/catalogue`;

                      }
                    });
                  }
                }}>
                  <div className="me-auto">
                    <div className="Text700__4">{labels.getText()["CREATE_FOLDER_MOBILE_TITLE"]()}</div>
                    <div className="Text500__2">{labels.getText()["CREATE_FOLDER_MOBILE_DESC"]()}</div>
                  </div>
                  <button
                    style={{ marginLeft: '20px' }}
                    className="then-small-button-ghost"
                    onClick={() => {
                      if (subscription && subscription.success) {
                        if (subscription.sudo || subscription.subscriptionProfile.folders) {
                          setShowModal(true);
                          setShowAddModal(false);
                        } else {
                          withReactContent(Swal).fire({
                            title: <i>{labels.getText()["ERROR"]()}</i>,
                            icon: "error",
                            text: labels.getText()["PLAN_DOESNT_INCLUDE_FOLDERS"](),
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            showCloseButton: true,
                          })
                        }
                      } else {
                        withReactContent(Swal).fire({
                          title: subscription.message,
                          icon: 'warning',
                          confirmButtonText: labels.getText()["GO_TO_CATALOGUE"](),
                          cancelButtonText: 'OK',
                          showCancelButton: true
                        }).then((result) => {
                          if (result.isConfirmed) {
                            window.location.href = `/catalogue`;

                          }
                        });
                      }
                    }}
                  >
                    <i style={{ fontSize: "1em" }} className="fa fa-folder-open fa-2x"></i>
                  </button>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => { navigate('/camera') }}>
                  <div className="me-auto">
                    <div className="Text700__4">{labels.getText()["SUBMIT_PICTURE_MOBILE_TITLE"]()}</div>
                    <div className="Text500__2">{labels.getText()["SUBMIT_PICTURE_MOBILE_DESC"]()}</div>
                  </div>
                  <a
                    style={{ marginLeft: '20px' }}
                    role='button' className="then-small-button-ghost"
                    href='/camera' >
                    <i style={{ fontSize: "1em" }} className="fa fa-camera fa-2x blackIcon"></i>
                  </a>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => {
                  if (subscription && subscription.success) {
                    if (subscription.sudo || subscription.subscriptionProfile.audio) {
                      professorclassroomId ? navigate(`/professor/classroom/${professorclassroomId}/speech`) : navigate(`/speech`);
                    } else {
                      withReactContent(Swal).fire({
                        title: <i>{labels.getText()["ERROR"]()}</i>,
                        icon: "error",
                        text: labels.getText()["PLAN_DOESNT_INCLUDE_VOICE"](),
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        showCloseButton: true,
                      })
                    }
                  } else {
                    withReactContent(Swal).fire({
                      title: subscription.message,
                      icon: 'warning',
                      confirmButtonText: labels.getText()["GO_TO_CATALOGUE"](),
                      cancelButtonText: 'OK',
                      showCancelButton: true
                    }).then((result) => {
                      if (result.isConfirmed) {
                        window.location.href = `/catalogue`;

                      }
                    });
                  }
                }}>
                  <div className="me-auto">
                    <div className="Text700__4">{labels.getText()["UPLOAD_SPEECH_MOBILE_TITLE"]()}</div>
                    <div className="Text500__2">{labels.getText()["UPLOAD_SPEECH_MOBILE_DESC"]()}</div>
                  </div>
                  <button
                    style={{ marginLeft: '20px' }}
                    className="then-small-button-ghost"
                    onClick={() => {
                      if (subscription && subscription.success) {
                        if (subscription.sudo || subscription.subscriptionProfile.audio) {
                          professorclassroomId ? navigate(`/professor/classroom/${professorclassroomId}/speech`) : navigate(`/speech`);
                        } else {
                          withReactContent(Swal).fire({
                            title: <i>{labels.getText()["ERROR"]()}</i>,
                            icon: "error",
                            text: labels.getText()["PLAN_DOESNT_INCLUDE_VOICE"](),
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            showCloseButton: true,
                          })
                        }
                      } else {
                        withReactContent(Swal).fire({
                          title: subscription.message,
                          icon: 'warning',
                          confirmButtonText: labels.getText()["GO_TO_CATALOGUE"](),
                          cancelButtonText: 'OK',
                          showCancelButton: true
                        }).then((result) => {
                          if (result.isConfirmed) {
                            window.location.href = `/catalogue`;

                          }
                        });
                      }
                    }}>
                    <i style={{ fontSize: "1em" }} className="fa fa-microphone fa-2x blackIcon" ></i>
                  </button>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => {
                  // Trigger the click event on the input element
                  window.$("#input-file-upload").trigger("click");
                  setShowAddModal(false);
                }}>
                  <div className="me-auto">
                    <div className="Text700__4">{labels.getText()["UPLOAD_VIDEO_MOBILE_TITLE"]()}</div>
                    <div className="Text500__2">{labels.getText()["UPLOAD_VIDEO_MOBILE_DESC"]()}</div>
                  </div>
                  <button
                    className="then-small-button-ghost"
                    onClick={() => {
                      // Trigger the click event on the input element
                      window.$("#input-file-upload").trigger("click");
                      setShowAddModal(false);
                    }}
                  >
                    <i style={{ fontSize: "1em" }} className="fa fa-video fa-2x"></i>
                  </button>
                </li>
                <li data-upload-type="url" className="list-group-item d-flex justify-content-between align-items-center" onClick={() => {
                  // Trigger the click event on the input element

                  setShowAddModal(false);
                }}>
                  <div className="me-auto">
                    <div className="Text700__4">{labels.getText()["UPLOAD_URL_MOBILE_TITLE"]()}</div>
                    <div className="Text500__2">{labels.getText()["UPLOAD_URL_MOBILE_DESC"]()}</div>
                  </div>
                  <button
                    className="then-small-button-ghost"
                    onClick={() => {
                      // Trigger the click event on the input element
                      setShowUrlModal(true);
                      setShowAddModal(false);
                    }}
                  >
                    <i style={{ fontSize: "1em" }} className="fa fa-globe fa-2x"></i>
                  </button>
                </li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  async function createFolder(name) {
    var result = (await api.sendMessage("createFolder", {
      body: {
        name: name,
        classroomId: professorclassroomId,
        creationDate: new Date(),
      },
      user: user
    }));

    if (result.success) {
      withReactContent(Swal).fire({
        title: <i>{labels.getText()["DONE"]()}</i>,
        icon: "success",
        text: result.message
      });

      if (professorclassroomId) {
        navigate(`/professor/folder/classroom/${result.folderId.toString()}/${professorclassroomId}`);
      }
      else if (classroomId) {
        navigate(`/folder/classroom/${result.folderId.toString()}/${classroomId}`);
      }
      else {
        navigate(`folder/${result.folderId.toString()}`);
      }
    }
    else {
      withReactContent(Swal).fire({
        title: <i>{labels.getText()["ERROR"]()}</i>,
        icon: "error",
        text: result.message
      });
    }
  }

  const onBookSelected = async function (item) {
    if (professorclassroomId) {
      navigate(`/professor/document/classroom/${item._id.toString()}/${professorclassroomId}`);
    }
    else if (classroomId) {
      navigate(`/document/classroom/${item._id.toString()}/${classroomId}`);
    }
    else {
      navigate(`document/${item._id.toString()}`);
    }

  }

  const onFolderSelect = async function (folder) {
    if (professorclassroomId) {
      navigate(`/professor/folder/classroom/${folder._id.toString()}/${professorclassroomId}`);
    }
    else if (classroomId) {
      navigate(`/folder/classroom/${folder._id.toString()}/${classroomId}`);
    }
    else {
      navigate(`folder/${folder._id.toString()}`);
    }

  }





  function showUploadProgress() {
    if (parentRef.current && uploadProgress) {
      parentRef.current.scrollIntoView({ behavior: 'instant', block: 'nearest' });
    }
    if (uploadProgress) {
      return (
        <div className='container py-5 d-flex flex-column col-12'>
          <label className='pb-2'>
            {labels.getText()["PROGRESS"]()}
          </label>
          <div className="progress" style={{ marginBottom: "2em", height: "3em" }}>
            <div className="progress-bar" role="progressbar" style={{ width: `${uploadProgress}%`, backgroundColor: 'var(--Violet__500, #662FE7)' }}>{`${uploadProgress}%`}</div>
          </div>
        </div>
      );
    }
    else {
      return (null);
    }
  }

  return (
    <div className="wrap">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-5 wow fadeIn column-sx library-drop-zone" data-wow-delay="0.1s">

            <div className="stack-title">
              {/* <div className="breadcrumb">Your Library</div> */}
              <h1 className="document-boost">{labels.getText()["YOUR_LIBRARY"]()}</h1>

              <p className='Text700__9 neutral800'>
                {(classroomId || professorclassroomId) ? labels.getText()["LIBRARY_WELCOME_MESSAGE_CLASS"]({ docs, folders, classroom }) : labels.getText()["LIBRARY_WELCOME_MESSAGE"]({ docs, user: user })}
              </p>

              <p className='Text500__4 neutral800'>
                {labels.getText()["LIBRARY_WELCOME_MESSAGE_2"]({ docs, user: user })}
              </p>
            </div>
            {classroomId ? (null) :
              (
                <div ref={parentRef}>
                  {!uploadProgress ? (<FileUpload classroomId={professorclassroomId}
                    noOverrides={true}
                    onAddModal={() => {
                      setShowAddModal(true);
                    }}

                    onChunkSent={(event) => {
                      //console.log(event);
                      //update the progress
                      setUploadProgress(event.totalChunks > 0 ? (event.chunkNumber * 100 / event.totalChunks).toFixed(0) : 0);
                      if (event && event.result && (event.result.success === false || event.result.error)) {
                        setUploadProgress(null);
                        if (!event.result.catalogButton) {
                          withReactContent(Swal).fire({
                            text: event.result.message ?? event.result.error,
                            icon: 'error',
                            confirmButtonText: 'Ok',

                          });
                        }
                        else {
                          withReactContent(Swal).fire({
                            text: event.result.message ?? event.result.error,
                            icon: 'warning',
                            confirmButtonText: labels.getText()["GO_TO_CATALOGUE"](),
                            cancelButtonText: 'OK',
                            showCancelButton: true
                          }).then((result) => {
                            if (result.isConfirmed) {
                              window.location.href = `/catalogue`;

                            }
                          });


                        }
                      }
                      if (event.chunkNumber === event.totalChunks) {
                        if (event && event.result && event.result.document) {
                          refreshDocuments();
                          setDocument(event.result.document);
                          setNewBookId(event.result.document._id);
                          //upload completed, close the progress and refresh

                          setUploadProgress(null);

                          withReactContent(Swal).fire({
                            title: <i>{labels.getText()["FILE_UPLOADED"]()}</i>,
                            icon: "success",
                            text: labels.getText()["FILE_UPLOADED_SUCCESS"](),
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            showCloseButton: true,
                            timer: 10000
                          }).then((result) => {
                            if (!event.result.document.multiMedia) {
                              setShowPages(true);
                            }

                          });
                        }
                        else {
                          if (event && event.result && event.result.error) {

                            withReactContent(Swal).fire({
                              title: <i>{labels.getText()["ERROR"]()}</i>,
                              icon: "error",
                              text: labels.getText()["GENERIC_ERROR"]({ error: event.result.error }),
                              showConfirmButton: true,
                              allowOutsideClick: false,
                              showCloseButton: true,
                              timer: 10000
                            });

                            setUploadProgress(null);
                          }
                        }
                      }
                    }
                    }></FileUpload>) : (null)}
                </div>
              )
            }
            {showUploadProgress()}
            {
              showPages && document ? (<div>
                <PageSelector document={document} screenWidth={window.screen.width} screenHeight={window.screen.height} onClose={async (selected, pagesDescription) => {
                  setShowPages(false);
                  if (selected && selected.length > 0 && selected.length !== document.pagesCount) {
                    (await api.sendMessage("generateDocumentFromPages", {
                      body: {
                        id: document._id,
                        pages: selected,
                        pagesDescription: pagesDescription
                      },
                      user: user
                    }));

                    goToDoc(document);
                  }
                }}></PageSelector>
              </div>) : (null)
            }

          </div>


          <div className="col-lg-7 column-dx-bg">
            <div className="library-content">

              {(docs?.length > 0 || folders?.length > 0) ? (<BooksList
                onDelete={
                  async (item) => {
                    setShowDeleteModal(true);
                    setElementToDelete(item);
                  }}
                professor={professorclassroomId} student={classroomId} items={docs} folders={folders} onBookPress={onBookSelected} onFolderPress={onFolderSelect}></BooksList>)
                : (null)
              }

            </div>


          </div>

        </div>
      </div>
      {createNewFolderModal()}
      {createNewDocAddModal()}
      {createDeleteModal()}
      {createUrlModal()}
    </div>

  );
}

export default Library_Screen;
