import { useEffect } from 'react';

function Language_Default_Screen(params) {
    useEffect(() => {
        const _preferredLanguage = localStorage.getItem("preferred.language");
        if (_preferredLanguage) {
            window.document.location.href = _preferredLanguage;
        }
        _hallo();
    }, []);

    let _language = 0;

    const _hallo = () => {
        window.$(`[data-language]`).hide();
        window.$(`[data-language="${_language}"]`).show();
        window.$(`[data-language="${_language}"`).animate({
            opacity: 1,
        }, 2500, function () {
            window.$(`[data-language="${_language}"`).animate({
                opacity: 0,
            }, 1000, function () {
                _language++;
                if (_language > 4) {
                    _language = 0;
                }
                _hallo();
            });
        });
    }

    return (<>
        <div style={{
            padding: 25,
            textAlign: "center"
        }}>
            <div className="stack-title">
                <h1 className="document-boost" data-language="0" style={{ opacity: 0 }}>Ciao, sono ANNA</h1>
                <h1 className="document-boost" data-language="1" style={{ opacity: 0 }}>Hi, I'm ANNA</h1>
                <h1 className="document-boost" data-language="2" style={{ opacity: 0 }}>Salut, je suis ANNA</h1>
                <h1 className="document-boost" data-language="3" style={{ opacity: 0 }}>Hola soy ANNA</h1>
                <h1 className="document-boost" data-language="4" style={{ opacity: 0 }}>Sveiki, aš ANNA</h1>
            </div>
            <div class="list-group" style={{ textAlign: "center" }}>
                <a href="/en" class="list-group-item list-group-item-action country-link">
                    <img src="/UK.png" alt="United Kingdom Flag" class="flag" />
                    English (EN)
                </a>

                <a href="/it" class="list-group-item list-group-item-action country-link">
                    <img src="/IT.webp" alt="Italy Flag" class="flag" />
                    Italiano (IT)
                </a>

                <a href="/lt" class="list-group-item list-group-item-action country-link">
                    <img src="/LT.png" alt="Lithuania Flag" class="flag" />
                    Lietuvių (LT)
                </a>
                <a href="/fr" class="list-group-item list-group-item-action country-link">
                    <img src="/flag/Flag_of_France.png" alt="French Flag" class="flag" />
                    Française (FR)
                </a>

                <a href="/es" class="list-group-item list-group-item-action country-link">
                    <img src="/flag/Flag_of_Spain.png" alt="Spain Flag" class="flag" />
                    Español (ES)
                </a>
            </div>
        </div>
    </>)
}

export default Language_Default_Screen;
