import logo from '../logo.svg';
import '../App.css';
import UserContext from '../components/User_Context';
import { React, useContext, useEffect } from "react";
import { SetMenu  } from '../components/Navigation';

function Settings_Screen() {
    var { user } = useContext(UserContext);
    user = user ? JSON.parse(user) : null;
    useEffect(() => {

    }, []);

    return (
        <div className="App">
            {SetMenu({ user: user })}
        

            <header className="App-header">
                <p>
                    Hallo {user.name}, here you can change all the settings of your account.
                </p>
                <div className="card bg-dark" data-bs-theme="dark">
                    <div className="card-header">
                        Account
                    </div>
                    <div className="card-body">

                        <a href="#" className="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Settings_Screen;
