import React from "react";
import Image from 'react-bootstrap/Image';

const ImageSlider = ({url}) => {

    return (
        <>
            <div className="image-slide-container">
                <Image className="image-slide"
                src={url}
                 fluid />
            </div>
            {/**  {isShowModal && <Modal imageUrl={imgClicked} closeModal={closeModal} />}*/}
        </>
    );
};

export default ImageSlider;