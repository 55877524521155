import { useTransition, animated, useSpringRef } from '@react-spring/web';
import React, { useEffect, useState } from 'react';
import mp3_fighter from '../assets/music/Aylex - Fighter (freetouse.com).mp3';
import mp3_living from '../assets/music/Aylex - Living (freetouse.com).mp3';
import mp3_tooHot from '../assets/music/Aylex - Too Hot (freetouse.com).mp3';
import mp3_nothing from '../assets/music/Damtaro - Nothing (freetouse.com).mp3';

const mp3 = [
    mp3_fighter,
    mp3_living,
    mp3_tooHot,
    mp3_nothing
].sort(() => Math.random() - 0.5);

function MindMap_Presentation(props, ref) {
    const [index, setIndex] = useState(0);
    const [volume, setVolume] = useState(0);

    const onClick = () => {
        let _newIndex = index + 1;
        if (_newIndex > pages.length - 1) {
            _newIndex = 0;
        }
        setIndex(_newIndex);
        _resetTimer();
        _top();
    };
    const { map, onMap, documentLink } = props;

    const transRef = useSpringRef()

    const transitions = useTransition(index, {
        ref: transRef,
        keys: null,
        from: { opacity: 0, transform: 'translate3d(100%,0,0) rotateX(180deg)' },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0) rotateX(0deg)' },
        leave: { opacity: 0, transform: 'translate3d(-50%,0,0) rotateX(180deg)' },
    });

    //slide variables
    const pages = [];
    let _buttonTimer = null;

    function _startTimer() {
        _buttonTimer = setTimeout(() => {
            window.$("[data-button]").hide();
        }, 5000);
    }

    function _top() {
        window.$("#mindmap_presentation_container").animate({ scrollTop: 0 }, "slow");
    }

    function _resetTimer() {
        
        if (_buttonTimer) {
            clearTimeout(_buttonTimer);
            _buttonTimer = null;
        }
        window.$("[data-button").show();
        _startTimer();

    }

    useEffect(() => {
        transRef.start();

        _resetTimer();

        window.$(window).on("mousemove", function () {
            _resetTimer();
        });
    }, [index]);

    function _generatePages() {
        for (var t of map.topics) {
            pages.push(_generatePage(t));
        }
    }

    function _generatePage(node) {
        if (node.linkedTopics && node.linkedTopics.length > 0) {
            pages.push(_getDiv(node));
            for (var c of node.linkedTopics) {
                _generatePage(c);
            }
        }
    }

    function _getAnimatedDiv(style, page) {
        return (<animated.div className="col" style={{ ...style }}>{page}</animated.div>)
    }

    //single animated div
    function _getDiv(topic) {
        return (<div className='presentation'>
            <h1>{topic.topic}</h1>
            <small>
                {topic.resume}
            </small>
            <hr />
            <ul style={{
                fontSize: "2vw"
            }}>
                {topic.linkedTopics.map((c) => {
                    return (<li key={topic.topic} >{c.topic}</li>);
                })}
            </ul>
        </div>);
    }

    _generatePages();


    return (
        <div id="mindmap_presentation_container" style={{
            backgroundColor: "white",
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 10000,
            overflowY: "scroll",
            paddingTop: "5em",
            paddingLeft: "5em",
            paddingRight: "5em"/*,
            
            paddingBottom: 25*/
        }}>
            <div className='row' onClick={onClick} >
                {transitions((style, i) => {
                    return _getAnimatedDiv(style, pages[i]);
                })}
            </div>

            <div data-button="true" style={{
                position: "fixed",
                right: 50,
                top: 20,
                zIndex: 10001
            }}>
                <a href={documentLink} className="btn btn-lg btn-primary btn-lg-square"><i className="fa fa-times-circle"></i></a>
            </div>

            {index < pages.length - 1 ? (<div data-button="true" style={{
                position: "fixed",
                right: 50,
                bottom: 20,
                zIndex: 10001
            }}
                onClick={onClick}>
                <button href="#" className="btn btn-lg btn-warning btn-lg-square"><i className="fa fa-chevron-right"
                ></i></button>
            </div>) : (null)}

            {index > 0 ? (<div data-button="true" style={{
                position: "fixed",
                right: 110,
                bottom: 20,
                zIndex: 10001
            }}
                onClick={() => {
                    if (index > 0) {
                        setIndex(index - 1);
                        _resetTimer();
                        _top();
                    }
                }}>
                <button className="btn btn-lg btn-warning btn-lg-square"><i className="fa fa-chevron-left"
                ></i></button>
            </div>) : (null)}

            {volume === 0 ? (
                <div data-button="true" style={{
                    position: "fixed",
                    right: 170,
                    bottom: 20,
                    zIndex: 10001
                }}
                    onClick={() => {
                        setVolume(1);
                        window.$("#player")[0].play();
                        _resetTimer();
                    }}>
                    <button className="btn btn-lg btn-warning btn-lg-square"><i className="fa fa-volume-up"
                    ></i></button>
                </div>
            ) : (
                <div data-button="true" style={{
                    position: "fixed",
                    right: 170,
                    bottom: 20,
                    zIndex: 10001
                }}
                    onClick={() => {
                        setVolume(0);
                        window.$("#player")[0].pause();
                    }}>
                    <button className="btn btn-lg btn-warning btn-lg-square"><i className="fa fa-volume-off"
                    ></i></button>
                </div>
            )}

            <audio id="player">
                <source src={mp3[0]} type="audio/ogg" />
                <source src={mp3[1]} type="audio/ogg" />
                <source src={mp3[2]} type="audio/ogg" />
                <source src={mp3[3]} type="audio/ogg" />
            </audio>

           

            <div data-button="true" style={{
                position: "fixed",
                right: 110,
                top: 20,
                zIndex: 10001
            }}
                onClick={() => {
                    if (onMap) {
                        onMap();
                    }
                }}>
                <button className="btn btn-lg btn-primary btn-lg-square"><i className="fa fa-network-wired" ></i></button>
            </div>
            
        </div>
    )
}

export default MindMap_Presentation;