import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.REACT_APP_NODE_ENV === 'production') {
  disableReactDevTools();
}

// import { GoogleOAuthProvider } from '@react-oauth/google';



const root = ReactDOM.createRoot(document.getElementById('root'));
const googleId = process.env.GOOGLE_OAUTH_CLIENT_ID;
const payPalID = process.env.PAYPAL_CLIENT_ID;
root.render(

  // <GoogleOAuthProvider clientId="414009719510-gi1iio3vkld8aqmo0pv4pceguodgjho2.apps.googleusercontent.com">

    <React.StrictMode>
        <App />        
    </React.StrictMode>
  // </GoogleOAuthProvider>
);
window.$("#filler").css("height", "0vh");


