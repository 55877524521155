import React from "react";
import * as labels from "../localized/labels";
const FileUpload = ({ handleFileChange, handleOpenModal }) => {


    const handleOpenModalAction = () => {
        return handleOpenModal();
    }

    return (
        <div >
            <div className="onlyMobile uploadDocDivButton"> 
                <button className="then-small-button"
                    onClick={() => {
                        handleOpenModalAction();
                    }}
                >{labels.getText()["ADD"]()}

                </button>
            </div>

            <div onDrop={handleFileChange} className="hiddenMobile">
                <div className="d-flex flex-column justify-content-center text-center rounded col-12 wow fadeIn dropzone drag-zone" data-wow-delay="0.1s">
                    <img src='/huge-icon/Generic/Upload.svg' alt="alt text" className="drag-image"></img>

                    <label htmlFor="input-file-upload">
                        <input id="input-file-upload" type="file" className="inputFile" onChange={handleFileChange} />

                        <div className="d-flex flex-column justify-content-center text-center rounded dropzone-text">
                            <p className="Text700__4">{labels.getText()["DRAG_DROP_FILES"]()}</p>
                        </div>
                        <button className="then-small-button"
                            onClick={() => {
                                handleOpenModalAction();
                            }}
                        >{labels.getText()["ADD"]()}

                        </button>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default FileUpload