import '../App.css';
import { React, useState, useContext } from 'react';
import UserContext from '../components/User_Context';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import * as labels from "../components/localized/labels";
import { SetMenu, } from '../components/Navigation';
import FileUpload from '../components/upload/upload';


function Camera_Screen() {
  const [newBookId, setNewBookId] = useState(null);
  const { user } = useContext(UserContext);
  const _user = JSON.parse(user);
  const [uploadProgress, setUploadProgress] = useState(null);

  function showUploadProgress() {
    if (uploadProgress) {

      return (
        <div className='container py-5 d-flex flex-column col-12'>
          <label className='pb-2'>
            {labels.getText()["PROGRESS"]()}
          </label>
          <div className="progress" style={{ marginBottom: "2em", height: "3em" }}>
            <div className="progressbar" role="progressbar" style={{ width: `${uploadProgress}%`, backgroundColor: 'var(--Violet__500, #662FE7)' }}>{`${uploadProgress}%`}</div>
          </div>
        </div>
      );
    }
    else {
      return (null);
    }
  }

  return (
    <div className="App wrap">
      {SetMenu({ user: _user })}
      <div className="container-fluid py-5">

        <div className="container py-2">
          <div className="wow fadeIn" data-wow-delay="0.1s" style={{ visibility: "visible", "animationDelay": "0.1s", "animationName": "fadeIn" }}>
            <p className="mb-4">{
              labels.getText()["LIBRARY_WELCOME_MESSAGE_3"]({ user: _user })
            }</p>
          </div>
          <hr />
        </div>

        <a id="library_upload"></a>
        {!uploadProgress ? (<FileUpload fileType="image/*" onChunkSent={(event) => {
          //update the progress
          setUploadProgress(event.totalChunks > 0 ? (event.chunkNumber * 100 / event.totalChunks).toFixed(0) : 0);
            if (event && event.result && event.result.success === false) {
              setUploadProgress(null);
              if (!event.result.catalogButton) {
                withReactContent(Swal).fire({
                  text: event.result.message,
                  icon: 'error',
                  confirmButtonText: 'Ok',

                });
              }
              else {
                withReactContent(Swal).fire({
                  text: event.result.message,
                  icon: 'warning',
                  confirmButtonText: labels.getText()["GO_TO_CATALOGUE"](),
                  cancelButtonText: 'OK',
                  showCancelButton: true
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.href = `/catalogue`;

                  }
                });


              }
            }
          if (event.chunkNumber === event.totalChunks) {
            if (event && event.result && event.result.document) {
              setNewBookId(event.result.document._id);
              //upload completed, close the progress and refresh

              setUploadProgress(null);

              withReactContent(Swal).fire({
                title: <i>{labels.getText()["FILE_UPLOADED"]()}</i>,
                icon: "success",
                text: labels.getText()["FILE_UPLOADED_SUCCESS"](),
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 10000
              });
            }
            else {
              if (event && event.result && event.result.error) {

                withReactContent(Swal).fire({
                  title: <i>{labels.getText()["ERROR"]()}</i>,
                  icon: "error",
                  text: labels.getText()["GENERIC_ERROR"]({ error: event.result.error }),
                  showConfirmButton: true,
                  allowOutsideClick: false,
                  showCloseButton: true,
                  timer: 10000
                });

                setUploadProgress(null);
              }
            }
          }
        }}></FileUpload>) : (null)}

        {showUploadProgress()}
      </div>
    </div>
  );
}

export default Camera_Screen;