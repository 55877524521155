import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState, useEffect, useContext } from "react";
import * as api from '../api/apiConnector';
import UserContext from '../components/User_Context';
import * as labels from "../components/localized/labels";


function PagePreview(props) {
    const { document, page, onClose, user } = props;

    useEffect(() => {
        try {
            (async () => {
                api.sendMessage("getThumbs", {
                    body: {
                        id:  document._id ? document._id : document,
                        pages: [page]
                    },
                    user: user
                }).then(thumbs => {
                    window.$.each(thumbs.thumbs, (index, t) => {
                        var imageData = `data:image/png;base64,${t.thumb}`;
                        var img = window.$(`#previewImage`);
                        img.attr("src", imageData);
                    });
                });
            })();
        } catch (error) {
            console.error('Error:', error);
        }
    }, []);

    return (
        <div

        >
            <Modal show={true} size="lg" fullscreen={true}>
                <Modal.Header closeButton onClick={() => {
                    if (onClose) {
                        onClose();
                    }
                }}>
                    <Modal.Title className="Text700__9">{document.title ?? document.fileName} {labels.getText()["PAGES_THUMB_TITLE"]()} {page}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img alt='Preview' id="previewImage" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/Ajax_loader_metal_512.gif/300px-Ajax_loader_metal_512.gif" className="image-fluid"></img>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="then-small-button" onClick={() => {
                    if (onClose) {
                        onClose();
                    }
                }}>OK</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default PagePreview;