import * as labels from "../components/localized/labels";
import { React, useState, useContext, useEffect } from 'react';
import UserContext from '../components/User_Context';
import { getBreadcrumb, SetMenu } from '../components/Navigation';

function AboutAnna_Screen() {

    let { user } = useContext(UserContext);


    return (
        <div className='wrap'>
            <div className="container py-5">
            {SetMenu({ user: user,
                section:'aboutANNA'
             })}
            {/* {getBreadcrumb({ page: "classRoomScreen", })} */}
                <div className="row g-5">
                    <div className="col-lg-12 wow fadeIn " data-wow-delay="0.1s">
                        {/* about anna general question */}
                        <div className="stack-title-about">

                            <h1 className="title"> {labels.getText()["ABOUTANNA"]()}</h1>
                        </div>

                        <div className="about-list-body">
                            {/* zero qa container */}
                            <div className="about-question-container">
                                <div className="about-question-title" type="button" data-bs-toggle="collapse" data-bs-target="#qa0" aria-expanded="false" aria-controls="collapseExample">
                                    <p>{labels.getText()["ABOUTANNA_WHOISANNA"]()}</p>
                                    <span>+</span>
                                </div>
                                <div className="collapse Text500__5" id="qa0">
                                    <p className="about-question-text">{labels.getText()["ABOUTANNA_WHOISANNA_ANSWER"]()}</p>
                                </div>
                            </div>
                            {/* zero first  qa container  */}


                            {/* first qa container */}
                            <div className="about-question-container">
                                <div className="about-question-title" type="button" data-bs-toggle="collapse" data-bs-target="#qa1" aria-expanded="false" aria-controls="collapseExample">
                                    <p>{labels.getText()["LANDING_QUESTIONS_LIST_1"]()}</p>
                                    <span>+</span>
                                </div>
                                <div className="collapse Text500__5" id="qa1">
                                    <p className="about-question-text">{labels.getText()["LANDING_QUESTIONS_LIST_1_ANSWER"]()}</p>
                                </div>
                            </div>
                            {/* end first  qa container  */}


                            {/* second qa container */}
                            <div className="about-question-container">
                                <div className="about-question-title" type="button" data-bs-toggle="collapse" data-bs-target="#qa2" aria-expanded="false" aria-controls="collapseExample">
                                    <p>{labels.getText()["LANDING_QUESTIONS_LIST_2"]()}</p>
                                    <span>+</span>
                                </div>
                                <div className="collapse Text500__5" id="qa2">
                                    <p className="about-question-text">{labels.getText()["LANDING_QUESTIONS_LIST_2_ANSWER"]()}</p>
                                </div>
                            </div>
                            {/* end second  qa container  */}


                            {/* third qa container */}
                            <div className="about-question-container">
                                <div className="about-question-title" type="button" data-bs-toggle="collapse" data-bs-target="#tryAnna" aria-expanded="false" aria-controls="collapseExample">
                                    <p>{labels.getText()["HOW_TRY_ANNA"]()}</p>
                                    <span>+</span>
                                </div>
                                <div className="collapse Text500__5" id="tryAnna">
                                    <p className="about-question-text">{labels.getText()["HOW_TRY_ANNA_ANSWER"]()}</p>
                                </div>
                            </div>
                            {/* third second  qa container  */}


                            {/* 4th qa container */}
                            <div className="about-question-container">
                                <div className="about-question-title" type="button" data-bs-toggle="collapse" data-bs-target="#howMuch" aria-expanded="false" aria-controls="collapseExample">
                                    <p>{labels.getText()["HOW_MUCH"]()}</p>
                                    <span>+</span>
                                </div>
                                <div className="collapse Text500__5" id="howMuch">
                                    <p className="about-question-text">{labels.getText()["HOW_MUCH_ANSWER"]()}</p>
                                </div>
                            </div>
                            {/* 4th second  qa container  */}

              {/* 5th qa container */}
              <div className="about-question-container">
                                <div className="about-question-title" type="button" data-bs-toggle="collapse" data-bs-target="#annaLab" aria-expanded="false" aria-controls="collapseExample">
                                    <p>{labels.getText()["ANNA_LAB"]()}</p>
                                    <span>+</span>
                                </div>
                                <div className="collapse Text500__5" id="annaLab">
                                    <p className="about-question-text">{labels.getText()["ANNA_LAB_ANSWER"]()}</p>
                                </div>
                            </div>
                            {/* 5th second  qa container  */}


                        </div>

              

                        {/* about anna question and answer */}
                        <div className="stack-title-about">

                            <h1 className="title"> {labels.getText()["ABOUTANNA_HOWITWORKS_TITLE"]()}</h1>
                        </div>

                        <div className="about-list-body">
                            {/* zero qa container */}
                            <div className="about-question-container">
                                <div className="about-question-title" type="button" data-bs-toggle="collapse" data-bs-target="#howAnnaWorks" aria-expanded="false" aria-controls="collapseExample">
                                    <p>{labels.getText()["ABOUTANNA_HOWITWORKS"]()}</p>
                                    <span>+</span>
                                </div>
                                <div className="collapse Text500__5" id="howAnnaWorks">
                                    <p className="about-question-text">{labels.getText()["ABOUTANNA_HOWITWORKS_ANSWER"]()}</p>
                                </div>
                            </div>
                            {/* zero first  qa container  */}

                        </div>



                        {/* about anna question and answer */}
                        <div className="stack-title-about">

                            <h1 className="title"> {labels.getText()["ABOUTANNA_Q&A_TITLE"]()}</h1>
                        </div>

                        <div className="about-list-body">


                            <div className="about-question-container">
                                <div className="about-question-title" type="button" data-bs-toggle="collapse" data-bs-target="#whatIsQuestionAnswer" aria-expanded="false" aria-controls="collapseExample">
                                    <p>{labels.getText()["WHATISQ&A"]()}</p>
                                    <span>+</span>
                                </div>
                                <div className="collapse Text500__5" id="whatIsQuestionAnswer">
                                    <p className="about-question-text">{labels.getText()["WHATISQ&A_ANSWER"]()}</p>
                                </div>
                            </div>




                        </div>


                        {/* about anna mindmap */}
                        <div className="stack-title-about">

                            <h1 className="title"> {labels.getText()["MIND_MAP_SESSION_TITLE"]()}</h1>
                        </div>

                        <div className="about-list-body">
                            {/* zero qa container */}
                            <div className="about-question-container">
                                <div className="about-question-title" type="button" data-bs-toggle="collapse" data-bs-target="#mindmap" aria-expanded="false" aria-controls="collapseExample">
                                    <p>{labels.getText()["MINDMAP_USE_QUESTION"]()}</p>
                                    <span>+</span>
                                </div>
                                <div className="collapse Text500__5" id="mindmap">
                                    <p className="about-question-text">{labels.getText()["MINDMAP_USE_ANSWER"]()}</p>
                                </div>
                            </div>
                            {/* zero first  qa container  */}
                        </div>



                        {/* about anna Test */}
                        <div className="stack-title-about">

                            <h1 className="title"> {labels.getText()["TRAINING_SESSION_TITLE"]()}</h1>
                        </div>

                        <div className="about-list-body">
                            {/* zero qa container */}
                            <div className="about-question-container">
                                <div className="about-question-title" type="button" data-bs-toggle="collapse" data-bs-target="#htuTest" aria-expanded="false" aria-controls="collapseExample">
                                    <p>{labels.getText()["HOW_TO_USE_TEST"]()}</p>
                                    <span>+</span>
                                </div>
                                <div className="collapse Text500__5" id="htuTest">
                                    <p className="about-question-text">{labels.getText()["HOW_TO_USE_TEST_ANSWER"]()}</p>
                                </div>
                            </div>
                            {/* zero first  qa container  */}
                        </div>



                    </div>


                </div>
            </div>
        </div >


    )

}

export default AboutAnna_Screen;
