import { React, Link } from 'react';
import { urls } from "./labels.js";
import changeLanguage from "../../imgs/aboutAnna/changeLanguage.png"
import folderExample from "../../imgs/aboutAnna/folderExample.png"
import fileUpload from "../../imgs/aboutAnna/fileUpload.gif"
import imgUpload from "../../imgs/aboutAnna/imgUpload.gif"
import audioUpload from "../../imgs/aboutAnna/audioUpload.png"
import mindMap from "../../imgs/aboutAnna/mindmap.png"

const getText = () => {
    return {
        "SECONDS": (params) => {
            const { value = 0 } = params;
            return `${value}s`;
        },
        "GENERIC_ERROR": (params) => {
            const { error = "Generic error GE_001" } = params;
            return `Oooops, an error was detected during the operation: ${error}`;
        },
        "ERROR": () => {
            return "Error";
        },
        "ERROR_SENDING_MAIL": () => {
            return `THere was a problem during the send of the mail. Pleas try later`;
        },
        "ERROR_CREATE_TEST": () => {
            return "Please select all the required parameters!";
        },
        "EMAIL_RESENT": () => {
            return "Email resent";
        },
        "CANCELLED": () => {
            return "Cancelled"
        },
        "TERMS_AND_CONDITIONS": () => {
            return "Terms and Conditions"
        },
        "USER_EXISTS": () => {
            return "This email is already taken!";
        },
        "MISSING_FIELDS": () => {
            return "Please fill in all the fields!";
        },
        "ERROR_INSERT_VALID_QUESTION": () => {
            return "Please, insert a valid question";
        },
        "ERROR_WHILE_CONNECTING_TO_SERVER": () => {
            return "Error occured connecting to server. Please try later...";
        },
        "ERROR_WHILE_CREATING_MINDMAP": () => {
            return "Error occured while creating mindmap";
        },
        "CLASSROOM_PROFESSORS": () => {
            return "Professors invited in this classroom";
        },
        "CLASSROOM_STUDENTS": () => {
            return "Students requested in this classroom";
        },

        "DELETING_DOCUMENT": () => {
            return "deleting document";
        },
        "DO_YOU_WANT_TO_DELETE": () => {
            return "Do you really want to permanently delete ";
        },
        "I_DONT_KNOW": () => {
            return "I don't know";
        },
        "DRAG_DROP_FILES": () => {
            return "Drag and drop here your files";
        },
        "NO_FILES_SELECTED": () => {
            return "No files currently selected for upload";
        },
        "TEXT_COPIED_TO_CLIPBOARD": () => {
            return "Text copied to clipboard!";
        },
        "LOADING": () => {
            return "Loading...";
        },
        "LEAVE_MESSAGE_HERE": () => {
            return "Write your question here";
        },
        "HOME_WELCOME_MESSAGE": (params) => {
            return (<span>
                Welcome to the ultimate study tool
            </span>);
        },
        "LIBRARY_WELCOME_MESSAGE": (params) => {
            const { user, docs, folders } = params;
            const number = docs?.length || 0 + folders?.length || 0;

            if (number > 0) {
                if (number > 1) {
                    return (<span>Hi {user.name}, you have {number} documents in your library</span>);
                }
                else {
                    return (<span>Hi {user.name}, you have {number} document in your library</span>);
                }
            }
            else {
                return (<span>Hi {user.name}, your library is actually empty</span>);
            }
        },
        "LIBRARY_WELCOME_MESSAGE_CLASS": (params) => {
            const { classroom, docs, folders } = params;
            const number = (docs?.length || 0) + (folders?.length || 0);

            if (number > 0) {
                if (number > 1) {
                    return (<span>Class {classroom?.name}, has {number} documents in this library</span>);
                }
                else {
                    return (<span>Class {classroom?.name}, has {number} document in this library</span>);
                }
            }
            else {
                return (<span>Class {classroom?.name}, library is actually empty</span>);
            }
        },
        "LIBRARY_WELCOME_MESSAGE_2": (params) => {
            return (<span>
                Your documents? I'll process them for you, I'm <strong>ANNA</strong>! I'll create a summary, answer all your questions, and if you like, I can even make tests to check your knowledge.
            </span>);
        },
        "FOLDER_EXPLANATION": (params) => {
            return (<span>
                Here you can see all available documents. Documents which checkbox are checked are inside the folder.
                Feel free to add as much documents to the folder as you wish!
            </span>);
        },
        "LIBRARY_WELCOME_MESSAGE_3": (params) => {
            return (<span>
                Upload images from your gallery and I will convert them to a PDF document. It will be read by my <strong>AI</strong> engine producing
                for you short summaries, answer to any questions and if you want I could
                prepare a test to verify your state of knowledge.
            </span>);
        },
        "LIBRARY_WELCOME_MESSAGE_4": (params) => {
            return (<span>
                Based on your audio input I will create a new book to the library. It will be read by my <strong>AI</strong> engine producing
                for you short summaries, answer to any questions and if you want I could
                prepare a test to verify your state of knowledge.
            </span>);
        },
        "DOCUMENT_WELCOME_MESSAGE": (params) => {
            const { linkTo } = params;
            return (<span>
                Here you can choose the AI session type for the selected document or you can <Link to={linkTo}>go back to the list</Link>.
            </span>);
        },
        "DOCUMENT_QA_SESSION_TITLE": (params) => {

            return "Q&A";
        },
        "DOCUMENT_QA_SESSION": (params) => {

            return (<span className="Text500__5">
                Chat with me asking anything about this document. I'll give you any answer you need.
            </span>);
        },
        "DOCUMENT_QA_SESSION_FOLDER": (params) => {

            return (<span className="Text500__5">
                Chat with me asking anything about documents in this folder. I'll give you any answer you need.
            </span>);
        },
        "TRAINING_SESSION_TITLE": (params) => {

            return (<span>
                Training
            </span>);
        },
        "TRAINING_SESSION": (params) => {

            return (<span className="Text500__5">
                Test your knowledge. I will offer you a simulation of an exam and suggest where to find the right solutions in case of a mistake.
            </span>);
        },
        "TRAINING_SESSION_FOLDER": (params) => {

            return (<span className="Text500__5">
                Test your knowledge. I will offer you a simulation of an exam of all documents within this folder and suggest where to find the right solutions in case of a mistake.
            </span>);
        },
        "MIND_MAP_SESSION_TITLE": (params) => {

            return "MINDMAP";
        },
        "MIND_MAP_SESSION": (params) => {

            return (<span>
                I can prepare a mind map of your document for you to help you reflect, assimilate the contents and remember them better
            </span>);
        },
        "MIND_MAP_SESSION_FOLDER": (params) => {

            return (<span>
                I can prepare a mind map of your documents for you to help you reflect, assimilate the contents and remember them better</span>);
        },
        "SUMMARY_SESSION_TITLE": (params) => {

            return (<span>
                Summary &amp; translate
            </span>);
        },
        "SUMMARY_SESSION": (params) => {

            return (<span>
                I can summarize and translate your document.
            </span>);
        },
        "GO": (params) => {

            return (<span>
                Go
            </span>);
        },
        "TRY_IT": (params) => {

            return (<span>
                Let's try
            </span>);
        },
        "TELL_ME_MORE": (params) => {

            return (<span>
                Tell me more
            </span>);
        },

        "ASK_ME": (params) => {

            return (<span>
                Ask me
            </span>);
        },
        "DOWNLOAD_PDF": (params) => {

            return (<span>
                Download Pdf
            </span>);
        },
        "RETURN_TO_DOC": () => {

            return "Go back";
        },
        "GO_TO_PAGE": () => {
            return "Go to page ";
        },
        "PREVIOUS": () => {
            return "Previous";
        },
        "PREVIOUS_PAGES": () => {
            return "Previous page";
        },
        "CREATED_BY": () => {
            return "created by:";
        },
        "NEXT_PAGES": () => {
            return "Next page";
        },
        "NEXT": () => {
            return "Next";
        },
        "DELETE": () => {
            return "Delete";
        },
        "EDIT": () => {
            return "Edit";
        },
        "DOCUMENT_QA_SESSION_INTRO": (params) => {

            return (<span>
                I have read the document and can now answer any questions. Try me. Enter the text of the question and wait for me to answer you.
            </span>);
        },
        "DOCUMENT_UPLOADED": (params) => {
            const { date } = params;

            return (<span>
                Uploaded on: {date.toLocaleDateString()}.
            </span>);
        },
        "FOLDER_CREATED": (params) => {
            const { date } = params;

            return (<span>
                Created on: {date.toLocaleDateString()}.
            </span>);
        },
        "CLASS_NAME": (params) => {
            const { name } = params;

            return (<span>
                Edit {name}
            </span>);
        },
        "PAGES_LIST": (params) => {
            const { pages = [] } = params;

            try {
                return pages.length > 1 ? "Pages" : "Page";
            }
            catch (err) {
                return (null);
            }

        },
        "DOWNLOAD": () => {
            return "Download";
        },
        "COPY_TO_CLIPBOARD": () => {
            return "Copy to clipboard";
        },
        "COPY_SUMMARY": () => {
            return "Copy";
        },

        "DELETE_QUESTION": () => {
            return "Delete this document";
        },

        "ADD_TO_FOLDER": () => {
            return "Add to folder";
        },
        "ADD_FOLDER": () => {
            return "Add new document or folder";
        },
        "DELETE_FOLDER": () => {
            return "Remove this folder";
        },
        "PREPARING_PACKAGE": () => {
            return "I am preparing the test and the questions...";
        },
        "PACKAGE_NUMBER": (params) => {
            const { number } = params;
            return `Test #${number}`;
        },
        "PACKAGE": () => {
            return `Test`;
        },
        "PACKAGE_PROCCESS_FINISH": () => {
            return "Test processing has been finished!";
        },
        "PACKAGE_PROCCESS_FINISH_LESS_QUESTIONS": () => {
            return "Could not generate requested amount of questions. Document is too small.";
        },
        "PREPARING_DOCUMENT": () => {
            return "I am preparing the document...";
        },
        "DOCUMENT_NUMBER": (params) => {
            const { number } = params;
            return `Document #${number}`;
        },
        "DOCUMENT_PROCCESS_FINISH": () => {
            return "Document processing has been finished!";
        },
        "DOCUMENT_NAME_MINDMAP": (params) => {
            const { title } = params;
            return `${title}`;
        },
        "PREPARING_MINDMAP": () => {
            return "I am preparing the mindmap...";
        },
        "INDEXING_PROGRESS": () => {
            return "Indexing progress:";
        },
        "WAITING_FOR_DATA": () => {
            return "Waiting for data...";
        },
        "WAIT": () => {
            return "Wait";
        },
        "EXPORT_DOCX": () => {
            return "Export to docx";
        },
        "EXPORT_PPTX": () => {
            return "Export to pptx";
        },
        //#region breadcrumb
        "BREADCRUMB_HOME": () => {
            return "Home";
        },
        "PASSWORD_REQ": () => {
            return "Your password does not meet the requirements!";
        },
        "BREADCRUMB_CLASSROOMS": () => {
            return "ClassRooms";
        },
        "BREADCRUMB_DOCUMENT": (params) => {
            const { name } = params;
            return `${name}`;
        },
        "BREADCRUMB_QA": () => {
            return "Q & A";
        },
        "BREADCRUMB_TRAINING": () => {
            return "Training";
        },
        "BREADCRUMB_SURVEY": () => {
            return "Survey";
        },
        "BREADCRUMB_SURVEY_ID": (params) => {
            const { id } = params;
            return `${id}`;
        },
        "BREADCRUMB_MINDMAP": () => {
            return "Mindmap";
        },
        "BREADCRUMB_SUBMINDMAP": (params) => {
            const { topic } = params;
            return `${topic}`;
        },

        //#endregion

        "ADD": () => {
            return "Add ";
        },
        "ABOUT_CLASS": () => {
            return "About the class ";
        },
        "DOCUMENT_FOLDERS": () => {
            return "Documents and folderof the classroom";
        },

        "ADD_DOCUMENT": () => {
            return "Add new";
        },

        "DONE": () => {
            return "Done";
        },
        "OTP_MODAL_FIRST_PART_RESEND": () => {
            return "Did not get the code? Click ";
        },
        "OTP_MODAL_SECOND_PART_RESEND": () => {
            return "here ";
        },
        "OTP_MODAL_THIRD_PART_RESEND": () => {
            return "to resend the email. ";
        },
        "GENERAL_INFO": () => {
            return "General information";
        },


        "UPLOAD_NEW_FILE": () => {
            return "Add a new document";
        },

        "UPLOAD_NEW_FILE_SMALL": () => {
            return "If you want you can upload a document here";
        },

        "BOOKLIST_SMALL_MESSAGE": (params) => {
            const { uploadDate, uploadTime } = params;

            return `Submitted in   ${uploadDate} at ${uploadTime}`;
        },
        "YOU_HAVE_BEEN_INVITED_IN_CLASS": (params) => {
            const { classroom } = params;

            return `You have been invited in class ${classroom}`;
        },
        "FOLDER_CREATION_DATE": (params) => {
            const { uploadDate, uploadTime } = params;

            return `Created in  ${uploadDate} at ${uploadTime}`;
        },

        "YOUR_LIBRARY": () => {
            return "YOUR LIBRARY";
        },
        "CAMERA_UPLOAD": () => {
            return "Camera upload";
        },
        "VOICE_UPLOAD": () => {
            return "Speech to text upload";
        },
        "EMAIL_ADDRESS": () => {
            return "E-Mail";
        },
        "PASSWORD": () => {
            return "Password";
        },
        "CONFIRM_PASSWORD": () => {
            return "Confirm password";
        },

        "REMEMBER_ME": () => {
            return "Remember me";
        },
        "FORGOT_PASSWORD": () => {
            return "Forgot password?";
        },
        "WHO_ARE_YOU": () => {
            return "Who are you?";
        },
        "OPEN": () => {
            return "Open";
        },
        "YOU_NEED_TO_LOGIN_FIRST": () => {
            return "You need to login first";
        },
        "ACCEPT": () => {
            return `Accept`;
        },
        "DECLINE": () => {
            return `Decline`;
        },
        "REMOVE": () => {
            return `Remove`;
        },
        "CLASSROOM_JOINED": () => {
            return `Classroom joined`;
        },
        "INVITATION_DECLINED": () => {
            return `Invitation declined`;
        },
        "PAGES": (params) => {
            const { pages } = params;
            if (pages == 0) {
                return `No pages for the document`

            }
            else if (pages == 1) {
                return `${pages} page`

            }
            else {
                return `${pages} pages`

            }
        },
        "CREATE_YOUR_IDENTITY": () => {
            return "Create your free account";
        },
        "CREATE_TEST": () => {
            return "Create new test";
        },
        "CREATE_FOLDER": () => {
            return "Create Folder";
        },
        "FOLDER_NAME": () => {
            return "Folder Name";
        },
        "SHOW_MORE": () => {
            return "Show more";
        },
        "SHOW_LESS": () => {
            return "Show less";
        },
        "NO_ELEMENTS_TO_SHOW": () => {
            return "No elements to show";
        },
        "NO_TEST_TO_SHOW": () => {
            return "No test to show";
        },

        "ERROR_DURING_CREATION": () => {
            return "An error occured during account creation!";
        },
        "WRONG_CODE": () => {
            return "Confirmation code is wrong.";
        },
        "USER_CREATED": () => {
            return "Your account has been created successfully!";
        },
        "REGISTER_CREDENTIAL": () => {
            return "Insert your credentials";
        },
        "OTP_MODAL_BODY": () => {
            return "Please check Your inbox in email, where we have sent the Confirmation Code";
        },
        "OTP_MODAL_TITLE": () => {
            return "Please confirm Your e-mail";
        },
        "OTP_MODAL_DELETE_TITLE": () => {
            return "Please confirm deletion";
        },
        "NAME": () => {
            return "Name";
        },
        "LAST_NAME": () => {
            return "Last name";
        },
        "SCHOOL_NAME": () => {
            return "School name";
        },
        "USERNAME": () => {
            return "Username";
        },
        "DATE_OF_BIRTH": () => {
            return "Date of birth";
        },
        "SELECT_COUNTRY": () => {
            return "Select Your Country";
        },
        "CHECK_TERMS_CONDITIONS": () => {
            return "Check if you agree with Terms & Conditions";
        },
        "TEST_PAGE_LABELS": (params) => {
            const { user, questionsPackages } = params;
            if (questionsPackages > 0) {
                if (questionsPackages == 1) {
                    return `${user}, we have one test for this document`
                }
                else {
                    return `${user}, we have  ${questionsPackages} tests for this document`

                }
            }
            else {
                return `${user}, we don't have any test for this document`
            }
        },
        "QUESTIONS_COUNT": () => {
            return `Questions Count`;
        },
        "GROUP_NAME": () => {
            return `Group Name`;
        },

        "CREATE_CLASSROOM": () => {
            return `Create a Classroom`;
        },
        "CREATE_GROUP": () => {
            return `Create new group`;
        },
        "CLASSROOM_NAME": () => {
            return `Classroom Name`;
        },
        "SEARCH_MEMBERS": () => {
            return `Search Members`;
        },
        "YOU_CANNOT_ENTER_THIS_CLASS": () => {
            return "You cannot enter in this class until professor accepts you!";
        },
        "YOU_ALREADY_ADDED_THIS_CLASS": () => {
            return "User already exists in this classroom!";
        },
        "WAITING_FOR_APPROVAL": () => {
            return "Waiting for approval...";
        },
        "SEARCH_DOCUMENTS": () => {
            return `Search Documents`;
        },
        "CREATE_CARD": () => {
            return `Create new class`;
        },
        "COLOR": () => {
            return `Choose Card Color`;
        },
        "CHOOSE": () => {
            return `Choose Your Country`;
        },
        "INVITE_PROFESSOR": () => {
            return `Invite professor`;
        },
        "INVITATION": () => {
            return `Invitation`;
        },
        "INSERT_EMAIL": () => {
            return `Insert email`;
        },
        "NO_OPTIONS": () => {
            return `No options`;
        },
        "ACCEPT_T&C": () => {
            return `Please accept terms & conditions!`;
        },
        "NOT_GOOD_EMAIL": () => {
            return `Please enter valid email!`;
        },
        "PASSWORDS_NO_MATCH": () => {
            return `Passwords do not match!`;
        },
        "PLEASE_FILL_IN_THE_FIELDS": () => {
            return `Please fill in all the fields!`;
        },
        "MEMBERS": () => {
            return `Members`;
        },
        "MEMBER": () => {
            return `Member`;
        },
        "TEST_DIFFICULTY": () => {
            return `Test Difficulty`;
        },
        "ANSWERS_TYPE": () => {
            return `Answers Type`;
        },
        "ADD_TO_CLASSROOM": () => {
            return `Add To Classroom`;
        },
        "ADD_NEW_CLASSROOM": () => {
            return `Add new classroom`;
        },
        "ADD_NEW_GROUP": () => {
            return `Add new group`;
        },


        "ANSWERS_QUANTITY": () => {
            //return `Correct Answers`;
            return `Number of choices`;
        },
        "SINGLE_CHOICES": () => {
            return "Single choice";
        },
        "MULTIPLE_CHOICES": () => {
            return "Multiple choices";
        },
        "CLOSE_ANSWERS": () => {
            return `Close answers`;
        },
        "EDIT_CARD": () => {
            return `Save`;
        },
        "CLASSROOM_DELETE": () => {
            return `Classroom deleted successfully`;
        },
        "CLASSROOM_DELETE_ERROR": () => {
            return `Error occured while trying to delete the classroom`;
        },
        "CLASSROOM_ADDED": () => {
            return `Classroom added successfully`;
        },
        "CLASSROOM_ADDED_ERROR": () => {
            return `Error occured while trying to add the classroom`;
        },
        "OPEN_ANSWERS": () => {
            return `Open answers`;
        },
        "EASY_QUESTIONS": () => {
            return `Easy`;
        },
        "MEDIUM_QUESTIONS": () => {
            return `Medium`;
        },
        "HARD_QUESTIONS": () => {
            return `Hard`;
        },
        "EXTREME_QUESTIONS": () => {
            return `Extreme`;
        },
        "CLOSE": () => {
            return `Close`;
        },
        "TEST_SMALL_MESSAGE": (params) => {
            const { generatedDate, generatedTime } = params;

            return `Generated on  ${generatedDate} at ${generatedTime}`;
        },
        "NQUESTIONS": (params) => {
            const { numbers } = params;

            return `${numbers} questions`;
        },
        "PROGRESS": () => {
            return `Progress`;
        },
        "SUBMIT_BUTTON": () => {
            return `Submit`;
        },
        "CREATE_PDF": () => {
            return `Create PDF`;
        },
        "FACEBOOK_LOGIN": () => {
            return "Login with Facebook";
        },
        "GOOGLE_LOGIN": () => {
            return "Sign In with Google";
        },
        "ARTIFICIAL_INTELLIGENCE_FOR_STUDIES": () => {
            return "Artificial Intelligence for Your Studies";
        },
        "START_NOW": () => {
            return "Start now";
        },
        "REGISTER": () => {
            return "Register";
        },
        "PROBLEM": () => {
            return "We have some problems";
        },
        "FACEBOOK_PROBLEM": () => {
            return "We have some problems with facebook login";
        },
        "GOOGLE_PROBLEM": () => {
            return "We have some problems with Google Login....Somethings goes wrong";
        },
        "LOGIN_CREDENTIAL": () => {
            return "Please insert your credentials";
        },
        "LOGIN": () => {
            return "Login";
        },
        "SELECTED_COLOR": () => {
            return "Selected color:";
        },
        "WRONG_CREDENTIALS": () => {
            return "Wrong credentials";
        },
        "LANGUAGE_MODAL_LABEL": () => {
            return "Select language";
        },
        "MUST_INSERT_CLASSROOM_NAME": () => {
            return "You must need add the name for the classrom";
        },
        "MANAGE_YOUR_CLASS": () => {
            return "Manage your classes";
        },
        "INVITE_PROFESSORS": () => {
            return "Invite professors";
        },
        "MY_CLASSESS": () => {
            return "My classrooms";
        },
        "ADD_CLASSROOM": () => {
            return "Add classroom";
        },
        "JOIN_NEW_CLASSROOM": () => {
            return "Join new classroom";
        },
        "ENTER_CODE": () => {
            return "Enter Activation Code";
        },
        "ENTER_CLASS": () => {
            return "Enter class";
        },
        "MENU_USER_LOGGED": (params) => {
            const { upperCaseLanguage, user, section } = params;
            let classroomsLink = null;

            if (user) {
                //you are a professor
                if (user.isProfessor) {
                    classroomsLink = user.isConfirmedProfessor ? `/en${urls.professor_classrooms}` : `/en${urls.not_confirmed_professor_landing}`
                }
                else {
                    classroomsLink = `/en${urls.student_classrooms}`;
                }
            }

            window.$("#navbarCollapse").html(`<div class="navbar-nav ms-auto Text500__5">
                <a href="/en" class="nav-item nav-link ${(section === "library" ? "active" : "")}">Library</a>
                <a href="/en/public/quickcards/list" class="nav-item nav-link ${(section === "cards" ? "active" : "")}">Quick cards</a>
                <a href="/en/aboutANNA" class="nav-item nav-link ${(section == "aboutANNA" ? "active" : "")}">About ANNA</a>
                <a href="/en${urls.account}/" class="nav-item nav-link ${(section === "settings" ? "active" : "")}">Account</span>
                <strong><a href="/en${urls.logout}" class="nav-item nav-link">Logout</a></strong>
                ${urls.language_selector.replace("##LANGUAGE##", upperCaseLanguage)}
              </div>`);


        },
        "CHANGE_PASSWORD": () => {
            return "Change the password";
        },
        "OLD_PASSWORD": () => {
            return "Old password";
        },
        "NEW_PASSWORD": () => {
            return "New password";
        },
        "ENTER_OLD_PASSWORD": () => {
            return "Enter Your Old Password";
        },
        "ENTER_NEW_PASSWORD": () => {
            return "Enter Your New Password";
        },
        "CHECK_TERMS_CONDITIONS_SOCIAL": () => {
            return "If you use the social login automatically agree with  ";
        },
        "CHECK_TERMS_CONDITIONS1": () => {
            return "Check if you agree with ";
        },
        "CHECK_TERMS_CONDITIONS2": () => {
            return "Terms & Conditions";
        },
        "MENU_USER_NOT_LOGGED": (params) => {
            const { upperCaseLanguage, section } = params;
            window.$("#navbarCollapse").html(`<div class="navbar-nav ms-auto Text500__5">
                <a href="/en/public/quickcards/list" class="nav-item nav-link active">Quick cards</a>
                <a href="/en/aboutANNA" class="nav-item nav-link ${(section == "aboutANNA" ? "active" : "")}">About ANNA</a>
                <a href="/en/contactus" class="nav-item nav-link" ${(section == "contacts" ? "active" : "")}>Contacts</a>
                <strong><a href="/en/login" class="nav-item nav-link" id="LoginButtonNav">Log in</a></strong>
                 ${urls.language_selector.replace("##LANGUAGE##", upperCaseLanguage)}
                <a href="/en/register" id="try-button-menu" class="then-small-button ml-5" >Try FREE</a>
            </div>`);

        },
        "FOOTER_BOTTOM_MENU_LOGGED": () => {
            window.$("#rightsReserved").html(`
             
                &copy; <a href='https://www.labh9.eu' target='_blank' rel="noopener noreferrer">ANNA</a>, All Rights Reserved.
          
            `);
            window.$("#secondFooterColumn").html(`
                                <div class="linkRowFooter">
                                    <a href="/en" class="linkFooter">Library</a>
                                    <a href="/en/aboutANNA" class="linkFooter">About</a>
                                    <a href="/en/contactus" class="linkFooter">Contacts</a>
                                    <a href="/en/terms_and_conditions" class="linkFooter">Terms & Conditions</a>
                                    <a href="/en/privacy_policy" class="linkFooter">Privacy Policy</a>
                                    <a href='https://www.labh9.eu'  class="linkFooter" target='_blank' rel="noopener noreferrer">Powered by<img class="footerPoweredLogo"  src="/Labh9logo.png"></img></a>
                                </div>  
            `);
        },
        "FOOTER_BOTTOM_MENU_NOT_LOGGED": () => {
            window.$("#rightsReserved").html(`
             
                                &copy; <a href='https://www.labh9.eu' target='_blank' rel="noopener noreferrer">ANNA</a>, All Rights Reserved.
                          
            `);
            window.$("#secondFooterColumn").html(`
                 <div class="linkRowFooter">
                                     <a href="/en/aboutANNA" class="linkFooter" target='_blank' rel="noopener noreferrer">About</a>
                                    <a href="/en/contactus" class="linkFooter">Contacts</a>
                                    <a href="/en/terms_and_conditions" class="linkFooter">Terms & Conditions</a>
                                    <a href="/en/privacy_policy" class="linkFooter">Privacy Policy</a>
                                    <a href='https://www.labh9.eu'  class="linkFooter" target='_blank' rel="noopener noreferrer">Powered by<img class="footerPoweredLogo"  src="/Labh9logo.png"></img></a>
                                </div>  
            
            `);
        },
        // "GET_IN_TOUCH_FOOTER": () => {
        //     window.$("#getInTouch").html(`
        //         <h5 class="text-white mb-4">Get In Touch</h5>
        //             <p><i class="fa fa-map-marker-alt me-3"></i>Lvivo g. 25, Vilnius 09320, Lithuania</p>
        //             <p><i class="fa fa-phone-alt me-3"></i>+012 345 67890</p>
        //             <p><i class="fa fa-envelope me-3"></i>info@lab-h9.com</p>
        //             <div class="d-flex pt-2">
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-twitter"></i></a>
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-facebook-f"></i></a>
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-youtube"></i></a>
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-instagram"></i></a>
        //                 <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-linkedin-in"></i></a>
        //             </div>

        //     `);
        // },
        "PASSWORD_REQUIREMENTS": () => {
            window.$("#password-Req").html(`
              <div className="password-requirements" id="password-Req">
                                        <p className='Text700__6'>Password must meet the following requirements:</p>
                                        <ul className='Text500__5'>
                                            <li>At least 8 characters long</li>
                                            <li>Contains at least one uppercase letter</li>
                                            <li>Contains at least one lowercase letter</li>
                                            <li>Contains at least one number</li>
                                            <li>Contains at least one special character</li>
                                        </ul>
                                    </div>
            
            `);
        },
        "ENTER_NAME": () => {
            return "Please enter Your name.";
        },
        "ENTER_EMAIL": () => {
            return "Please enter Your email.";
        },
        "ENTER_LASTNAME": () => {
            return "Please enter Your last name.";
        },
        "ENTER_PASSWORD": () => {
            return "Please enter Your password.";
        },
        "ENTER_BIRTHDATE": () => {
            return "Please enter valid birth date.";
        },
        "ENTER_COUNTRY": () => {
            return "Please enter Your country.";
        },
        "ENTER_CONFIRM_PASSWORD": () => {
            return "Please confirm Your password.";
        },
        "ENTER_SCHOOL": () => {
            return "Please enter Your school.";
        },
        "PASS_REQS": () => {
            return "Password must meet the following requirements:";
        },
        "PASS_REQS1": () => {
            return "At least 8 characters long";
        },
        "PASS_REQS2": () => {
            return "Contains at least one uppercase letter";
        },
        "PASS_REQS3": () => {
            return "Contains at least one lowercase letter";
        },
        "PASS_REQS4": () => {
            return "Contains at least one number";
        },
        "PASS_REQS5": () => {
            return "Contains at least one special character";
        },

        "WHOAREYOU_MODAL": () => {
            return "Who are you?"
        },
        "NORMAL_USER": () => {
            return "User"
        },
        "USER_PROFILE": () => {
            return "Your profile"
        },
        "PROFESSOR_USER": () => {
            return "Professor"
        },
        "ADD_MODAL_ELEMENT": () => {
            return "What do You want to add?"
        },
        "UPLOAD": () => {
            return "Upload"
        },
        "CLEAR": () => {
            return "Clear"
        },
        "PRESS_MIC_TO_UPLOAD": () => {
            return "Press microphone to upload"
        },
        "UPLOAD_TRANSCRIBE": () => {
            return "Upload and transcribe "
        },
        "OR": () => {
            return "or"
        },
        "REMOVE_STUDENT_HEADER": () => {
            return `Deleting student`;
        },
        "REMOVE_STUDENT_BODY": () => {
            return `The student will be removed from the class,are you sure?`;
        },
        "AUDIO_EXPLANATION": () => {
            return "Press the button to start recording and release it when you want to proceed with sending"
        },
        "AUDIO_FILE_PROGRESS": () => {
            return "I'm analyzing the audio..."
        },
        "REMOVE_STUDENT_BUTTON": () => {
            return "Remove the student";
        },
        "STUDENT_DELETE_DONE": () => {
            return "Student removed";
        },
        "STUDENT_DELETE_SUCCESSFULLY": () => {
            return "Student removed from the classroom succesfully";
        },
        "STUDENT_DELETE_PROBLEM": () => {
            return "There was some problem during the operation, student not removed";
        },
        "NO_CLASS_FOR_CODE": () => {
            return "Class not found with this code";
        },
        "USER_JUST_IN_CLASSROOM": () => {
            return "The user is just in the classroom or is waiting for the access";
        },
        "MISSING_CODE": () => {
            return "Code is mandatory to send the request";
        },
        "DOCUMENT_NAME": () => {
            return "Document name";
        },
        "UPLOADED_IN": () => {
            return "Uploaded on";
        },
        "ELEMENT_TYPE": () => {
            return "Type";
        },
        "PROFESSOR_NAME": () => {
            return "Professor";
        },
        "EMAIL_PROFESSOR": () => {
            return "E-mail";
        },
        "MOBILE_PHONE": () => {
            return "Phone number";
        },
        "PROFESSOR_ACTION": () => {
            return "Delete";
        },
        "REMOVE_PROFESSOR_HEADER": () => {
            return `Remove professor`;
        },
        "REMOVE_PROFESSOR_BODY": () => {
            return `You are about to remove the teacher from the class, are you sure you want to proceed?`;
        },
        "REMOVE_PROFESSOR_BUTTON": () => {
            return `Delete`;
        },
        "PROFESSOR_DELETE_DONE": () => {
            return "Professor deleted";
        },
        "PROFESSOR_DELETE_SUCCESSFULLY": () => {
            return "Professor delete from the cllass with success";
        },
        "PROFESSOR_DELETE_PROBLEM": () => {
            return "There was some problem, user not removed from the classroom";
        },
        "GO_TO_LIBRARY": () => {
            return "Documents";
        },
        "CLASSROOM_CODE_LABEL_PROFESSOR": (params) => {
            const { code } = params;
            return (<span>
                Classroom identifier code: <strong>{code ?? ""}</strong>
            </span>);

        },
        "NO_DOCUMENTS_GRID": () => {
            return ("Document library is empty");
        },
        "NO_PROFESSOR_GRID": () => {
            return ("No teachers in the class");
        },
        "NO_STUDENT_GRID": () => {
            return ("No students in the class");

        },

        "BOOKS_INSIDE": (params) => {
            const { number } = params;
            if (number > 0) {
                if (number > 1) {
                    return (

                        <small>{`${number} documents`}</small>)
                }
                else {
                    return (

                        <small>{`${number} document`}</small>)
                }
            } else {
                return (
                    <small>{`Empty folder`}</small>)
            }
        },
        "CLASSROOM_LIBRARY": () => {
            return "Classroom library";
        },
        "WELL_DONE": () => {
            return "Well done!";
        },
        "ALL_CORRECT": (params) => {
            const { maxScore } = params;

            return `Aww yeah, you successfully answered to all the ${maxScore} questions!`;
        },
        "TOPIC_EXPERT": () => {
            return "You are now an expert for this topic.";
        },
        "GOOD_JOB": () => {
            return "Good job!";
        },
        "SOMEONE_CORRECT": (params) => {
            const { actualScore, maxScore } = params;
            return `Congratulations, you correctly answered ${actualScore} out of ${maxScore} questions!`;
        },
        "SOMEONE_CORRECT_SAD": (params) => {
            const { actualScore, maxScore } = params;
            return `Oh no, you correctly answered ${actualScore} out of ${maxScore} questions!`;
        },
        "TEST_TRY_AGAIN": () => {
            return "Check the wrong answers and let's try again!";
        },
        "STUDY_MORE": () => {
            return "You need to study more!";
        },
        "ANSWER_NOT_SELECTED": () => {
            return "Answer was not selected!";
        },
        "YOU_ANSWERED": () => {
            return "You answered";
        },
        "BACK_TO_QUESTIONS": () => {
            return "Back to the questions";
        },
        "CLASSROOM": () => {
            return "Classroom";
        },
        "LEAVE_CLASSROOM": () => {
            return "Leave the classroom";
        },
        "LEAVE_CLASSROOM_MESSAGE": () => {
            return "Are you sure that you want to leave this class?";
        },
        "LEAVE_CLASSROOM_BUTTON": () => {
            return "Leave the class";
        },
        "SHARE_RESULT": () => {
            return "Share my result";
        },
        "PAGES_LIST_WITHPAGE": (params) => {
            const { pages = [] } = params;

            var pageNumbers = pages ? pages.join(",") : "-"


            return (<span>{pages?.length > 1 ? "Pages" : "Page"}: {pageNumbers}</span>)

                ;
        },
        "CONTEXT_HELP": () => {
            return "Help: ";
        },
        "ANSWER": () => {
            return "Answer: ";
        },
        "ANSWERES_CHECKED": () => {
            return `Answers checked successfully!!`;
        },
        "CHECKING_ANSWERS": () => {
            return "Checking answers";
        },
        "USER_PROFILE_SELECT_DESCRIPTION": () => {
            return "View and manage Your profile details";
        },
        "PAYMENT_DETAILS": () => {
            return "Payment Details";
        },
        "PAYMENT_DETAILS_DESCRIPTION": () => {
            return "View Your payment history and all details related";
        },
        "SUBSCRIPTION_DETAILS": () => {
            return "Subscription";
        },
        "SUBSCRIPTION_DETAILS_DESCRIPTION": () => {
            return "View all the details regarding Your subscription plan";
        },
        "USER_PROFILE_SELECT": () => {
            return "User profile settings";
        },
        "ENTER_BUTTON": () => {
            return "Enter";
        },
        "ACCOUNT_DETAILS": () => {
            return "Account details";
        },
        "ACCOUNT_DETAILS_DESC": () => {
            return "Here you can view your Account Details. Also you can edit them.";
        },
        "USER_SETTINGS": () => {
            return "User Settings";
        },
        "USER_SETTINGS_DESC": () => {
            return "Here you can view any information related to Your account and Your subscriptions.";
        },
        "SUBSCRIPTION_SETTINGS": () => {
            return "Subscription details";
        },
        "SUBSCRIPTION_SETTINGS_DESC": () => {
            return "Here You can view all the details related to Your subscription - counters, dates and more!"
        },
        "CANCEL": () => {
            return "Cancel"
        },
        "DELETE_ACCOUNT": () => {
            return "Delete account"
        },
        "FIRST_NAME": () => {
            return "First name"
        },
        "ACCOUNT_TYPE": (params) => {
            const { accountType } = params;
            return `${accountType}`;
        },
        "SAVE": () => {
            return "Save"
        },
        "ACCOUNT_TYPE_LABEL": () => {
            return "Account Type"
        },
        "DELETE_ACCOUNT_BODY": () => {
            return "Are You sure You want to delete your account? This action will also delete all Your uploaded documents, question packages and mindmaps!"
        },
        "DELETE_ACCOUNT_TITLE": () => {
            return "Are You sure?"
        },
        "YES": () => {
            return "Yes"
        },
        "NO": () => {
            return "No"
        },
        "TO_CHANGE_EMAIL": () => {
            return "To change email, please contact support!"
        },
        "SUBSCRIPTION_PLAN": () => {
            return "Subcription Plan:"
        },
        "QUESTIONS_ASKED": () => {
            return "Questions asked:"
        },
        "TESTS_GENERATED": () => {
            return "Tests generated:"
        },
        "MINUTES_OF_SPEECH": () => {
            return "Minutes of speech transcribed:"
        },
        "PAGES_UPLOADED": () => {
            return "Document pages uploaded:"
        },
        "SUBSCRIPTION_EXPIRES": () => {
            return "Subscription expires at:"
        },
        "COUNTER_RESET": () => {
            return "Counters will be reset at:"
        },
        "NO_DATA_AVAILABLE": () => {
            return "No data available"
        },
        "ORDER": () => {
            return "ORDER"
        },
        "WEEKLY": () => {
            return "Weekly"
        },
        "MONTHLY": () => {
            return "Monthly"
        },
        "YEARLY": () => {
            return "Yearly"
        },
        "DURATION_SUB": (params) => {
            const { expirationUnit } = params;
            return `${expirationUnit}`;
        },
        "CATALOGUE": () => {
            return `Subscription Catalogue`;
        },
        "CATALOGUE_HEADER": () => {
            return `Select Your Subscription`;
        },
        "CHECKOUT_HEADER": () => {
            return `Subscription Checkout`;
        },
        "SELECT_METHOD": () => {
            return `Select payment method below:`;
        },
        "PROCEED_CHECKOUT": () => {
            return `Proceed With Checkout`;
        },
        "LOGIN_PROCEED": () => {
            return `You have to log in to procceed to checkout!`;
        },
        "DONT_HAVE_ACC": () => {
            return `Don't have an account?`;
        },
        "DONT_HAVE_ACC2": () => {
            return `Sign up!`;
        },
        "DELETE_ELEMENT_MODAL": () => {
            return "You are going to delete an element";
        },
        "REMOVE_ELEMENT_BODY_MODAL": (params) => {
            const { elementName } = params

            return `You are about to permanently remove ${elementName} from your library.
             This action is irreversible, are you sure you want to proceed?`;
        },
        "REMOVE_ELEMENT_BUTTON": () => {
            return "Delete";
        },
        "SUBSCRIPTION_SUCCESSFUL": () => {
            return "Subscription successful!";
        },
        "SUBSCRIPTION_ERROR": () => {
            return "Subscription unsuccessful!";
        },

        "LIBRARY_WELCOME_MESSAGE_NUMEBR": (params) => {
            const { user, docs, folders } = params;
            const number = docs?.length || 0 + folders?.length || 0;

            if (number > 0) {
                if (number > 1) {
                    return (<span> There are {number} documents in your library</span>);
                }
                else {
                    return (<span> There is {number} document in your library</span>);
                }
            }
            else {
                return (<span>Your library is actually empty</span>);
            }
        },

        "WIP": () => {
            return `Working...`;
        },
        "PAYMENT_DETAILS_DESC": () => {
            return "Here you can see and check the history payment ";
        },
        "PAYPAL_CHECKOUT_ERROR": () => {
            return "Error during the payment process, please try again!";
        },
        "SUBSCRIPTION_IN_PROGRESS": () => {
            return "Please wait, subscription in progress...";
        },
        "LANGUAGE_NOT_FOUND": () => {
            return "Language not found";
        },
        "NO_PAYMENTS": () => {
            return "No payments found";
        },
        "RETURN_TO_TEST": () => {
            return "Return to the Test";
        },
        "RETURN_TO_TEST_NEW": () => {
            return "Retry Test";
        },
        "TRAINING_HEADER": (params) => {
            const { type } = params;
            if (type === "document") {
                return "TESTS FOR YOUR DOCUMENT";
            }
            if (type === "folder") {
                return "TESTS FOR YOUR FOLDER";
            }
        },
        "FILE_TYPE_NOT_SUPPORTED": () => {
            return "File type not supported";
        },
        "CHANGE": () => {
            return "Change";
        }, "Q&A_HEADER": () => {
            return "Ask and I will answer";
        },
        "QUESTION": () => {
            return "Question";
        },
        "QUESTION_TYPES": () => {
            return "Question Types";
        },
        "QUESTION_NUMBER": () => {
            return "Number of Questions";
        },
        "QUESTIONS": () => {
            return "questions";
        },
        "QUESTION_CHOICE": () => {
            return "Number of Choices";
        },


        "YOUR_ANSWER": () => {
            return "YOUR ANSWER";
        },
        "NOT_ANSWERED": () => {
            return "Question is not answered";
        },
        "CORRECT_ANSWER": () => {
            return "CORRECT ANSWER";
        },
        "CORRECT_ANSWER_SMALL": () => {
            return "Correct answer";
        },
        "PAGES_ANSWER": () => {
            return "Pages: ";
        },
        "PAGES_THUMB_TITLE": () => {
            return "page";
        },
        "DOCUMENT_PAGE_HEADER": () => {
            return "BOOST YOUR DOCUMENT";
        },

        "COUNTRY": () => {
            return "Country";
        },
        "BOOKS_TRANSFORM_SUPERPOWERS": () => {
            return "YOUR PERFECT STUDY BUDDY!";
        },
        "BOOKS_TRANSFORM_SUPERPOWERS_ANNA": () => {
            return "With ANNA you've got the tools to crush your studies and hit your goals. She's the tutor that lets you interact with your books and docs, helping you make the most of your time";
        },
        "TRY_ANNA": () => {
            return "Try ANNA free";
        },
        "USED_BY_STUDENTS": () => {
            return "used by + 10.000 students";
        },
        "THEY_TALKED_ABOUT_US": () => {
            return "They have already talked about us";
        },
        "BOOST_STUDY_WITH_AI": () => {
            return "Boost your study with the help of ai";
        },
        "LANDING_FUNCTIONS_DESC": () => {
            return "ANNA makes studying more interactive and fun. Here are the three main features that will help you study better:";
        },
        "LANDING_FUNCTIONS_DESC_CARD_1": () => {
            return (
                <>Chat with <strong> ANNA </strong> and ask her anything about the documents you upload!  She will provide you with all the answers you need!</>);
        },
        "LANDING_FUNCTIONS_DESC_CARD_2": () => {
            return (
                <>Create <strong> a mind map </strong> from any document. Visualize information in a clear and organized way to facilitate your learning</>);
        },
        "LANDING_FUNCTIONS_DESC_CARD_3": () => {
            return (
                <><strong>Test your knowledge </strong> with ANNA's mock exams. Get feedback right away and find out where to improve, so you'll always be ready for real tests.</>);
        },
        "LANDING_FUNCTIONS_DESC_CARD_4": () => {
            return (
                <><strong>ANNA </strong> can create  quickcards for you that will help you assimilate the most important concepts with impressive summaries</>);
        },
        "LANDING_STUDENTS_TITLE": () => {
            return (<>Why students love ANNA</>);
        },
        "LANDING_STUDENTS_SUBTITLE": () => {
            return (<>Discover a new way to study with ANNA! Thanks to our AI-based tools, You will be able to:</>);
        },
        "LANDING_STUDENTS_LIST_1": () => {
            return (<>Create mind maps that simplify complex concepts.</>);
        },
        "LANDING_STUDENTS_LIST_2": () => {
            return (<>Ask anything about the study material and get immediate answers.</>);
        },
        "LANDING_STUDENTS_LIST_3": () => {
            return (<>Simulate exams and identify areas for improvement.</>);
        },
        "LANDING_STUDENTS_LIST_4": () => {
            return (<>Upload and learn from books, documents, audio and video recordings, images and more!</>);
        },
        "LANDING_STICKER_TEACHER_TEXT": () => {
            return "for teachers";
        },
        "LANDING_STICKER_STUDENT_TEXT": () => {
            return "for students";
        },
        "LANDING_TEACHER_TITLE": () => {
            return (<>Teachers, here is Your Superpower</>);
        },
        "LANDING_TEACHER_SUBTITLE": () => {
            return (<>ANNA is not only an aid for students, but also a valuable ally for teachers. With our tools You will be able to:</>);
        },
        "LANDING_TEACHER_LIST_1": () => {
            return (<>Upload teaching materials and create interactive activities in just a few clicks.</>);
        },
        "LANDING_TEACHER_LIST_2": () => {
            return (<>Monitor student progress and provide personalized feedback.</>);
        },
        "LANDING_TEACHER_LIST_3": () => {
            return (<>Save time in lesson preparation with automation functions.</>);
        },
        "LANDING_SUBSCRIPTIONS_TITLE": () => {
            return (<>Study Smart without Breaking through the Wallet!</>);
        },
        "LANDING_SUBSCRIPTIONS_SUBTITLE": () => {
            return (<>With ANNA, studying smart is affordable for everyone. Discover our affordable plans and find the perfect one for you!</>);
        },
        "LANDING_QUESTIONS_TITLE": () => {
            return (<>Questions? We have the Answers!</>);
        },
        "LANDING_QUESTIONS_DESCRIPTION": () => {
            return (<>If you have questions, you've come to the right place! We have collected answers to the most common questions to help you make the most of ANNA. Take a look below and learn how ANNA can make your study even more effective and fun!</>);
        },

        "LANDING_QUESTIONS_LIST_0": () => {
            return (<>How does ANNA's AI work and how can it help me?</>);
        },
        "LANDING_QUESTIONS_LIST_1": () => {
            return (<>Is it safe to use ANNA and what documents can I upload?</>);
        },
        "LANDING_QUESTIONS_LIST_2": () => {
            return (<>Can AI replace a teacher? Is it suitable for all types of exams and schools?</>);
        },


        "LANDING_QUESTIONS_LIST_0_ANSWER": () => {
            return (<>ANNA's AI analyzes the documents you upload (books, notes, slides, handouts) and answers any questions you may have about concepts, dates, definitions, and specific details. It helps you better understand your study material and organizes key information into concept maps to facilitate comprehension and memorization. You can also create custom quizzes and tests,
                choosing the number of questions, type of answer (open or closed), and get detailed corrections to improve your preparation.</>)
        },
        "LANDING_QUESTIONS_LIST_1_ANSWER": () => {
            return (<>You can upload books, notes, slides, handouts or other study materials in digital format. The security of your data is a priority:
                your documents are treated confidentially and not shared with third parties. We use advanced security measures to protect your information,
                and no technical skills are required to use the tool:
                it is simple and intuitive.</>);
        },
        "LANDING_QUESTIONS_LIST_2_ANSWER": () => {
            return (<>AI is a study support tool designed to enhance your learning method by clarifying doubts, summarizing concepts, and simulating tests.
                However, it does not replace the value of a teacher.
                It is versatile and suitable for preparing you for any type of exam, both written and oral, helping you to optimize your study and preparation in an effectively.</>)
        },

        "CHOOSE_PLAN": () => {
            return (<>Choose the plan</>);
        },
        "LANDING_MESSAGES_TITLE": () => {
            return (<>Everybody Talks About ANNA</>);
        },
        "LANDING_MESSAGES_QUOTE1": () => {
            return (<>ANNA has transformed the way I study! The mind maps and quizzes help me understand concepts better and prepare for exams more effectively. I could no longer do without them!</>);
        },
        "LANDING_MESSAGES_QUOTE1_AUTHOR": () => {
            return (<>Mark, 16 years old</>);
        },
        "LANDING_MESSAGES_QUOTE2": () => {
            return (<>Thanks to ANNA, I can upload teaching materials and create interactive activities in minutes. Monitoring students' progress has become much easier, and personalized feedback has greatly improved their learning</>);
        },
        "LANDING_MESSAGES_QUOTE2_AUTHOR": () => {
            return (<>Prof. Rossi, Mathematics Teacher</>);
        },
        "STUDENT": () => {
            return (<>Student</>);
        },
        "TEACHER": () => {
            return (<>Teacher</>);
        },
        "PRIVACY_POLICY": () => {
            return "Privacy Policy";
        },
        "PAGE_SELECTOR": () => {
            return "Select Pages";
        },
        "PAGES_RANGE_HELP": () => {
            return "Insert a page number (ex 5), a page interval (ex 5-10), a page list (ex 1, 2, 3, 4, 5) or a mix (ex 5, 7-10, 12, 14)";
        },
        "PAGES_RANGE": () => {
            return "Choose Pages";
        },
        "SAVE_CHANGES": () => {
            return "Save Changes";
        },

        "PRIVACY_POLICY_REGISTER": () => {
            return (
                <p className='Text500__6'>and <strong><a href="/en/privacy_policy" target='_blank' rel="noopener noreferrer" >Privacy Policy</a></strong></p>
            );
        },
        "PRIVACY_POLICY_TEXT": () => {
            return (
                <div className="container" style={{ height: '100%', textAlign: 'justify' }}>
                    <h1 className="test-boost">{getText()["PRIVACY_POLICY"]()}</h1>
                    <br></br>
                    <h3 className="test-boost">{getText()["PRIVACY_POLICY"]()}:</h3>
                    <p className='Text500__6'>UAB "Lab-H9" (hereinafter - the Data Controller) in this privacy policy (hereinafter - the Privacy Policy) sets the conditions for processing personal data using
                        this website managed by the Data Controller. The terms set out in the Privacy Policy apply every time you access the content and/or services we provide, regardless of the device (computer, mobile phone, tablet, TV, etc.) you are using.
                    </p>
                    <p className='Text500__7'>Details of the data controller:
                    </p>
                    <p className='Text500__7'>UAB "Lab-H9"
                    </p>
                    <p className='Text500__7'>Office address: Laisvės al. 82,
                    </p>
                    <p className='Text500__7'>Kaunas, LT-44250 Lietuva
                    </p>
                    <p className='Text500__7'>E-mail: <a href="mailto:anna@labh9.eu">anna@labh9.eu</a>
                    </p>
                    <p className='Text500__6'>It is very important that you read this Privacy Policy carefully, because every time you visit the <a href="https://www.labh9.ai" target='_blank' rel="noopener noreferrer">www.labh9.ai</a> website, you agree to the terms described in this Privacy Policy. If you do not agree to these terms, we regret to ask you not to visit our website, use our content and/or services. We confirm that the data of visitors to the website of the Data Controller will be collected in accordance with the requirements of the applicable legal acts of the European Union and the Republic of Lithuania and the instructions of the controlling authorities. All reasonable technical and administrative measures are in place to protect the data we collect about website visitors from loss, unauthorized use or alteration.
                        Individuals who are under the age of 16 may not submit any personal data through our website. If you are under the age of 16, you must obtain the consent of your parent or other legal guardian before submitting personal information.
                        The terms used in this privacy policy are understood as they are defined in the General Data Protection Regulation no. 2016/679.
                    </p>
                    <ul className='Text500__6'>
                        <li>name;
                        </li>
                        <li>surname;
                        </li>
                        <li>e-mail address.
                        </li>
                    </ul>
                    <h3 className="test-boost">Browsing data</h3>
                    <p>
                        We use local storage to manage security (at logon time) and to remember Your preferred language.
                    </p>
                    <p>
                        We will not share this information with anyone and it will only be stored on Your browser.
                    </p>
                    <p>
                        For access with third-party authentication systems (single-sign-on), such as Google and Facebook, it is possible that these systems use cookies and local storage and we refer You to the respective policies.
                    </p>
                    <ul className="Text500__5" style={{ textAlign: 'left' }}>
                        <li>
                            <a href="https://policies.google.com/technologies/cookies" target="_blank" rel='noopener noreferrer'>
                                Google Cookie Policy
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/privacy/policies/cookies" target="_blank" rel='noopener noreferrer'>
                                Facebook Cookie Policy
                            </a>
                        </li>
                    </ul>
                    <p>
                        To remind us that You have read the cookies policy, we will store a cookie on Your browser, which we will not share with anyone and which will only serve to prevent this message from appearing automatically every time You connect to the ANNA web.
                    </p>
                    <p>These are the local storage items and cookies managed by ANNA web site on its domain:</p>
                    <table className='cookies-table Text500__5 mb-3 mx-auto'>
                        <tbody>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Purpose</th>
                            </tr>
                            <tr>
                                <td>Cookie</td>
                                <td>cookieConsent</td>
                                <td>Remembering that You’ve read this cookie page</td>
                            </tr>
                            <tr>
                                <td>Local storage</td>
                                <td>_grecaptcha</td>
                                <td>Used for security purposes, during the registration, to prevent non-human (bot) interactions with the page</td>
                            </tr>
                            <tr>
                                <td>Local storage</td>
                                <td>UserAuth</td>
                                <td>Used for security purposes to store on Your browser Your identity data and token after You have logged in.</td>
                            </tr>
                            <tr>
                                <td>Cookie</td>
                                <td>active_language</td>
                                <td>Used to remember Your preferred language for the user interface</td>
                            </tr>
                        </tbody>
                    </table>
                    <h3 className="test-boost">Third party cookies</h3>
                    <p>
                        The Site uses statistical cookies (for advertising and marketing purposes) to monitor visits to the Site.
                        <br />These cookies and their processing are subject to your consent and therefore will be used only if you accept.
                    </p>
                    <p>
                        If you accept third party cookies please refer to this providers and privacy disclosures policies:
                    </p>
                    <ul>
                        <li>Google Analytics
                            <ul>
                                <li><a href="https://www.google.com/policies/privacy/partners/">Google's Privacy & Terms</a></li>
                                <li><a href="https://support.google.com/analytics/answer/6004245">Safeguarding your data</a></li>
                            </ul>
                        </li>
                        <li>Google TAG manager
                            <ul>
                                <li><a href="https://support.google.com/tagmanager/answer/9323295?hl=en">Data privacy and security</a></li>
                            </ul>
                        </li>
                        <li>META PIXEL
                            <ul>
                                <li><a href="https://www.facebook.com/privacy/policy/">Meta Privacy Policy</a></li>
                            </ul>
                        </li>
                    </ul>
                    <h3 className="test-boost">How we use your information</h3>
                    <p className='Text500__6'>We may process your personal data for the following purposes:
                    </p>
                    <ul className='Text500__6'>
                        <li>For the purpose of direct marketing*, send newsletters, provide individually tailored advertising and send messages, evaluate and analyze our market, customers, products and services (including collecting your opinion about products and services and customer organization of surveys).
                        </li>
                        <li>To administer the website;
                        </li>
                        <li>To improve your browsing experience by adapting this website to your personal needs;
                        </li>
                        <li>In other ways with your consent*
                        </li>
                        <p className='Text500__6'><i>*We inform you that you have the right to disagree or withdraw your consent at any time to process your data for the above purposes marked with an asterisk.</i>
                        </p>
                    </ul>
                    <h3 className="test-boost">Other information we collect</h3>
                    <p className='Text500__6'>We may also collect other information about you, your device or your use of our website content with your consent.</p>
                    <p className='Text500__6'>We undertake not to transfer your personal data to any unrelated third parties, except in the following cases:
                    </p>
                    <ul className='Text500__6'>
                        <li>If there is consent to the disclosure of personal data.
                        </li>
                        <li>To law enforcement institutions in accordance with the procedure provided by the legal acts of the Republic of Lithuania.
                        </li>
                    </ul>
                    <h3 className="test-boost">Your rights
                    </h3>
                    <ul className='Text500__6'>
                        <li>To know (to be informed) about the processing of your data (right to know);
                        </li>
                        <li>Familiarize yourself with your data and how they are processed (right of access);
                        </li>
                        <li>Demand correction or, taking into account the purposes of personal data processing, to supplement incomplete personal data (right to correction);
                        </li>
                        <li>To destroy your data or stop the processing of your data (except storage) (right to destruction and right to be forgotten);
                        </li>
                        <li>Demand that the Personal Data Controller restricts the processing of personal data for one of the legitimate reasons (right to restriction);
                        </li>
                        <li>You have the right to transfer data (right to transfer);
                        </li>
                        <li>Opt-out to the processing of personal data when this data is processed or intended to be processed for direct marketing purposes, including profiling as far as it relates to such direct marketing.
                        </li>
                    </ul>
                    <p className='Text500__6'>If you no longer want your personal data to be processed for the purpose of direct marketing, you can write to at the email address <a href="mailto:anna@labh9.eu">anna@labh9.eu</a> and do not agree to your personal data being processed for the purpose of direct marketing.
                        You can submit any request or instruction related to the processing of personal data to the Data Controller by e-mail. to the email address <a href="mailto:anna@labh9.eu">anna@labh9.eu</a>. Upon receiving such a request or instruction, the data controller shall, no later than within one month from the date of the request, provide a response and perform the actions specified in the request or refuse to perform them. If necessary, the specified period can be extended by another two months, depending on the complexity and number of requests. In this case, within one month from the date of receipt of the request, the Data Controller informs the subject of such an extension, together with the reasons for the delay.
                    </p>
                    <h3 className="test-boost">Third-party websites, services and products on our websites
                    </h3>
                    <p className='Text500__6'>The Data Controller's website may contain third-party banners, links to their websites and services over which the Data Controller has no control. The data controller is not responsible for the security and privacy of information collected by third parties. You must read the privacy statements that apply to third-party websites and services you use.
                    </p>

                    <p>If you want to delete all your data you can delete your account in the user profile page, and our system automatically will remove all the personal information</p>

                </div>


            );
        },
        "TERMS_AND_CONDITIONS_TEXT": () => {
            return (
                <div className="container" style={{ height: '100%', textAlign: 'justify' }}>
                    <h1 className="test-boost">{getText()["TERMS_AND_CONDITIONS"]()}</h1>
                    <br></br>
                    <h3 className="test-boost">{getText()["TERMS_AND_CONDITIONS"]()}:</h3>
                    <p className='Text500__6'>These Terms of Use apply to the use of the Platform of Lab-H9 (UAB Lab-H9). Please read these Terms of Use carefully in order to ensure that you are aware of your rights and obligations when using the Platform. You can download and print this document.
                    </p>
                    <p className='Text500__6'>These Terms of Use <i>inter alia</i>, describe the following aspects of the relationship between you and Lab-H9:
                    </p>
                    <ul className='Text500__6'>
                        <li>The provision of Study Material (article 7) and creating a Profile (article 5);</li>
                        <li>The functionalities of the Platform (articles 3- 6);</li>
                        <li>The use of the AI Summaries, Mindmaps and Q&A functionality (article 8);</li>
                        <li>The use of the Group Chats functionality (article 9);</li>
                        <li>The notice and take down procedure (article 14);</li>
                        <li>The limitation of liability of Lab-H9(article 15).</li>
                    </ul>
                    <h3 className="test-boost">Article 1. Definitions</h3>
                    <p className='Text500__6'>In these Terms of Use, the terms listed below have the following meaning:
                    </p>
                    <p className='Text500__6'>1.1. <strong>AI: </strong>software that uses artificial intelligence techniques to generate Summaries, Mindmaps and answers and tests in reference to documents uploaded by the user                </p>
                    <p className='Text500__6'>1.2. <strong>AI Answers:</strong> all the answers and instructions that AI generates and that are made accessible to the user via dedicated section on the Platform, in response to questions submitted by the user.</p>
                    <p className='Text500__6'>1.3. <strong>AI Q&A:</strong> a separate section on the Platform, which enables User to submit questions in reference to documents earlier uploaded, which are then answered by AI.</p>
                    <p className='Text500__6'>1.4. <strong>Mindmaps:</strong> a separate section on the Platform, which enables User to get a mindmap in reference to documents earlier uploaded, which are generated by AI </p>
                    <p className='Text500__6'>1.5. <strong>Test/Quiz:</strong> a separate section on the Platform, which enables User to test their learing skill to a specific document. All the questions are generated by AI</p>

                    <p className='Text500__6'>1.6. <strong>Information:</strong> all information placed on the Platform by Lab-H9 itself, including – but not limited to – the layout and look and feel of the Platform, the logos and trademarks as well as texts. The Information does not include Study Materials.</p>

                    <p className='Text500__6'>1.7. <strong>Intellectual Property Rights:</strong> any and all intellectual property rights and associated rights such as copyrights, trademark rights, patent rights, model rights, trade name rights, database rights and neighbouring rights as well as the rights to know-how and one-line performances.</p>
                    <p className='Text500__6'>1.8. <strong>Platform:</strong> the online environment developed and offered by Lab-H9, which enables User from around the world to upload their documents and interact with them through an AI model. The Platform contains a separate AI, Summaries, Q&A, mindmap and Test sections  The Platform can be accessed through the websites <a href="https://anna.labh9.eu" target='_blank' rel="noopener noreferrer">anna.labh9.eu;</a> including all underlying domains and web pages, as well as mobile versions of the website and the Lab-H9 application for tablets and smartphones (“App”).
                    </p>
                    <p className='Text500__6'>1.9. <strong>Premium Account: </strong>a subscription that gives User access to the most functionalities on the Platform, as well as to specific features </p>
                    <p className='Text500__6'>1.10. <strong>Profile: </strong>an individual section created and maintained by you when you register for the Platform.</p>
                    <p className='Text500__6'>1.11. <strong>USER: </strong>a User of the Platform.</p>
                    <p className='Text500__6'>1.12. <strong>Documents:</strong> all the study documents, summaries, notes, assignments, books and other materials that are uploaded by User in his own account.</p>
                    <p className='Text500__6'>1.13. <strong>Terms of Use:</strong> these Terms of Use</p>
                    <h3 className="test-boost">Article 2. Applicability</h3>
                    <p className='Text500__6'>2.1. These Terms of Use govern every use of the Platform. By using the Platform, you accept these Terms of Use. The applicability of any other terms and conditions is excluded.</p>
                    <p className='Text500__6'>2.2. Lab-H9 is entitled to amend or supplement these Terms of Use. The most up-to-date Terms of Use can be found on the Platform. In the event of an amendment or supplementation that significantly affect your rights or obligations, Lab-H9 will bring these changes to your attention during the use of the Platform.</p>
                    <p className='Text500__6'>2.3. In the event that you continue the use of the Platform after these Terms of Use have been amended or supplemented, you thereby irrevocably accept the amended or supplemented Terms of Use. If you do not agree with the amended or supplemented Terms of Use, the only option you have is to no longer use the Platform and to terminate your Profile.
                    </p>
                    <h3 className="test-boost">Article 3. Availability</h3>
                    <p className='Text500__6'>3.1. You accept that the Platform contains only the functionalities and other characteristics as found on the Platform at the time of use ("as is basis"). Lab-H9 expressly excludes any explicit and tacit guarantees, undertakings and warranties of any nature whatsoever including, but not limited to, with regard to the quality, safety, lawfulness, integrity and accuracy of the Platform, unless explicitly stated otherwise in these Terms of Use.</p>
                    <p className='Text500__6'>3.2. You accept that the functionalities of the Platform may change.
                    </p>
                    <p className='Text500__6'>3.3. Lab-H9 is entitled to put the Platform (temporarily) out of service and/or to reduce the use of it without any prior notification and without being obliged to pay compensation to you, if in the opinion of Lab-H9 this is necessary, for instance, for maintenance of the Platform.
                    </p>
                    <p className='Text500__6'>3.4. Lab-H9 does not guarantee that the Platform or any part thereof will be accessible at all times and without any interruptions or failures. Failures in the Platform can occur as a result of failures in the internet or phone connection or by viruses or faults/defects. Lab-H9 is not liable or obliged to User to pay compensation in any way whatsoever for any damage resulting or arising from the website being (temporarily) unavailable.
                    </p>
                    <p className='Text500__6'>3.5. Lab-H9 is at any time entitled to change, alter or shut down the Platform without becoming liable to you in any way. If you do not agree with the changes and/or amendments made by Lab-H9, your only remedy is to cease the use of the Platform and to delete your Profile.
                    </p>
                    <h3 className="test-boost">Article 4. The Platform </h3>
                    <p className='Text500__6'>4.1. Lab-H9 offers a Platform where User can upload their Materials , such as documents essay, books and so forth and interact with them using AI. The interaction with the AI generates summaries, mindmaps, Q&A and test  8). Lab-H9’s mission is to empower User to excel at their studies by providing the best tools to learn more efficiently.
                    </p>
                    <p className='Text500__6'>4.2. The usage of the platform is based on a “freemium” model.
                    </p>
                    <p className='Text500__6'>4.3. Some Premium functions are only available for User with a Premium Account. Certain functionalities and features of the Platform require a Premium Account.
                    </p>
                    <p className='Text500__6'>4.4. The Platform may contain links to third party websites that are not owned or controlled by Lab-H9. Lab-H9 has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites.
                    </p>
                    <h3 className="test-boost">Article 5. Profile</h3>
                    <p className='Text500__6'>5.1. In order to access to Premium functionalities you have to create a Profile in the way described on the Platform. When creating a Profile, you must give an email address and password which will allow you to access the Platform and your Profile. You can also register using your existing Facebook or Google-account.
                    </p>
                    <p className='Text500__6'>5.2. You are only allowed to use the Platform when aged sixteen (16) or older. In the event that you have not yet reached the age of sixteen (16), you accept that you need the consent of your legal representative(s) (your guardian or (one of) your parents) before creating a Profile and/or taking out a subscription.

                    </p>
                    <p className='Text500__6'>5.3. You are responsible for maintaining secrecy with regard to your registration details. As soon as you know or have reason to assume that your username and/or password has come into the hands of unauthorized persons, you must inform Lab-H9 of this, without prejudice to your own obligation to take immediate effective measures yourself such as changing your password. You accept and acknowledge that you are at all times responsible and liable for the use of the Platform by third parties via your Profile.
                    </p>
                    <p className='Text500__6'>5.4. The personal data you provide during the registration process will be processed in accordance with Lab-H9's Privacy Policy Statement.
                    </p>
                    <p className='Text500__6'>5.5. You acknowledge and agree that by creating a Profile your user name, university and (if provided) profile picture are visible to other User if you post Study Materials and/or place comments, unless you choose to post Study Materials and/or partake in Study Communities anonymously.
                    </p>
                    <p className='Text500__6'>5.6 You acknowledge and confirm that you have all the rights on the document you upload and interact with on the platform
                    </p>
                    <h3 className="test-boost">Article 6. Premium Account</h3>
                    <p className='Text500__6'>6.1. User can obtain Premium access by taking out a dedicated subscription. The prices are communicated on the Platform.
                    </p>
                    <p className='Text500__6'>6.2. Payment is due in the manner described on the Platform.
                    </p>
                    <p className='Text500__6'>6.3 Lab-H9 offers free and premium subscription. The free subscription can be limited for time, functions or number of documents and conditions can change any time. You can buy premium subscriptions at any time via your Profile. For details about the different premium subscriptions see the product catalog.
                    </p>
                    <p className='Text500__6'>6.4. It is possible that Lab-H9 offers longer/shorter free trial subscriptions in specific circumstances, such as actions for study societies. The applicable conditions will then be communicated by Lab-H9.
                    </p>
                    <p className='Text500__6'>6.5. Lab-H9 may introduce or change the subscription fees from time to time, for which we will give you advance notice. If your current subscription is still running, the price will remain in force for the fixed term. If you do not agree with the price change, you must cancel your subscription by the end of the then-current subscription term. If you do not cancel your subscription after the price change goes into effect, you agree to pay the changed price.
                    </p>
                    <p className='Text500__6'>6.6. Lab-H9 may suspend or cancel your Premium Account if a payment is not successfully settled.
                    </p>
                    <p className='Text500__6'>6.7. The subscription period will be renewed automatically for the selected subscription period, unless you have cancelled your subscription on time (before the last day of your subscription). In case your subscription is automatically renewed, you have the right to cancel the subscription at any time after such renewal with a cancellation term of one (1) month. In case of cancellation you may request reimbursement of part of the subscription fee. This means that you pay for the period until the cancellation enters into force, based on the standard monthly fee.
                    </p>
                    <h3 className="test-boost">Article 7. Documents/Materials           </h3>
                    <p className='Text500__6'>7.1. By means of the Platform User can upload their documents and material to be used for the learning process.

                    </p>
                    <p className='Text500__6'>7.2. You have the possibility to create folders, containing Study Materials selected by you relating to a certain course or subject. Folders or single documents are visible only to the User. Nothing will be shared with other users
                    </p>
                    <p className='Text500__6'>7.3. You understand and accept that you are responsible and accountable for Material you upload on the Platform. Lab-H9 is not liable to you or any third party for any damages arising out of or in relation to the Material uploaded or used by you within the Platform.
                    </p>
                    <p className='Text500__6'>7.4. You understand and accept that Lab-H9does not, and is under no obligation to, check or (re)view your Material. Lab-H9 only conducts an automated (quality) check to verify whether the Study Material contains sufficient texts and/or pages. Lab-H9has no knowledge of and/or influence on or involvement with the Materials uploaded by User.
                    </p>
                    <p className='Text500__6'>7.5. Lab-H9 reserves the right to convert the Materials you uploaded to another format and to automatically insert a watermark and/or advertisements in the materials.
                    </p>
                    <p className='Text500__6'>7.6. If you can delete your document/material at any time. The action cannot be reversed.
                    </p>
                    <p className='Text500__6'>7.7. Lab-H9 retains the right to decline or remove Material from the Platform, without accepting any responsibility or liability for its contents, without being held liable for any damage and without being obliged to pay any compensation.
                    </p>
                    <h3 className="test-boost">Article 8. AI Summaries, Mindmap, Q&A and Test/Quiz            </h3>
                    <p className='Text500__6'>8.1. By means of the separate AI Summaries, Mindmap, Q&A and Test/Quiz section on the Platform, User can interact with the uploaded document and answered by AI. The AI Answers will be available for User via a database in specific section on the Platform.
                    </p>
                    <p className='Text500__6'>8.2. To interact with all the sections, you will need to create a Profile.
                    </p>
                    <p className='Text500__6'>8.3. You accept and agree Lab-H9 solely facilitates the functionality, which enables User to use AI for their Studies.
                    </p>
                    <p className='Text500__6'>8.4. The Platform will specify the manner in which User can interact with the AI. Lab-H9 reserves the right to limit the availability of functionalities to User with a Premium Account even with Unlimited access or Unlimited usage. The limit, include – but it is not limited to a slow in AI reply or to a limit the number of questions can be submitted per day (or other time unit).
                    </p>
                    <p className='Text500__6'>8.5. AI will generate feedbacks within seconds. Lab-H9, however, does not guarantee that an the feedbacks are always provided within this time frame and does not accept liability in this regard.
                    </p>
                    <p className='Text500__6'>8.6. User agree that clear questions will generate a better AI output. Lab-H9 is not liable for any incorrect feedback
                    </p>
                    <p className='Text500__6'>8.7. The AI feedbacks are to be used as support in the learning process only, not as a substitute for a traditionl learning course. Lab-H9is not liable for the accuracy or completeness of the AI feedbacks.
                    </p>
                    <p className='Text500__6'>8.8. You understand and accept that you are responsible and accountable for use of feedbacks generated by AI. Lab-H9 is not liable to you or any third party for any damages arising out of, or in relation to them
                    </p>
                    <p className='Text500__6'>8.9. You understand and accept that Lab-H9 does not, and is under no obligation to, check or (re)view the AI feedbacks.
                    </p>
                    <h3 className="test-boost">Article 10. Your obligations and use of the Platform
                    </h3>
                    <p className='Text500__6'>10.1. You will only use the documents/materials that you will upload in the Platform
                    </p>
                    <p className='Text500__6'>10.2. You may not resell, distribute, or in any other way make profit of the Platform.
                    </p>
                    <p className='Text500__6'>10.3. You are the only responsible for documents you upload for instance (e-)books or any other material on the Platform.
                    </p>
                    <p className='Text500__6'>10.4. You are not permitted to use the Platform for a purpose as listed below, which is unrelated to the purpose of Lab-H9:
                    </p>
                    <ul className='Text500__6'>
                        <li>as your own work if it is not, as this is in breach of copyright. This is also plagiarism and constitutes academic misconduct at all academic institutions;
                        </li>
                        <li>which, at the discretion of Lab-H9, is discriminating or is otherwise deemed hurtful, offensive or inappropriate;
                        </li>
                        <li>in which personal data of others is made available, other than in compliance with applicable law;
                        </li>
                        <li>which contains scrapers, spiders, viruses, Trojan horses, worms, bots or other software which can damage or delete the Platform or make it unusable or inaccessible, or which or which is intended to circumvent technical protection measures of Lab-H9, the Website and/or the computer systems of Lab-H9;
                        </li>
                        <li>which infringes the rights of Lab-H9 and/or third parties, including but not limited to intellectual property rights or rights concerning the protection of privacy;
                        </li>
                        <li>which qualifies as or transmits unwanted or unsolicited material or content (spam);
                        </li>
                        <li>is contrary to these Terms of Use or any applicable laws or regulations, is in any other manner unlawful or which could prejudice the interests and reputation of lab-H9.
                        </li>
                    </ul>
                    <p className='Text500__6'>10.5. Lab-H9 will not be obliged to perform any payment, taxation, levy or deduction of any nature whatsoever to anyone as a result of the material uploaded by you, including to persons or parties whose work, model, brand or performance is included in the Materials, including to collective rights organizations.
                    </p>
                    <p className='Text500__6'>10.6. Lab-H9 reserves the right, without prior notice, to abridge, alter, refuse and/or remove Materials without this resulting in any liability.
                    </p>
                    <p className='Text500__6'>10.7. Lab-H9 is entitled to (temporarily) restrict or suspend your activities in connection with the Platform and/or to delete your Profile in the event you violate these Terms of Use.
                    </p>
                    <h3 className="test-boost">Article 11. Intellectual property rights
                    </h3>
                    <p className='Text500__6'>11.1. The Intellectual Property Rights in relation to the Platform, including the intellectual property rights with respect to the Information, are held by Lab-H9or its licensors. Nothing in the Terms of Use is intended to entail any transfer of Intellectual Property Rights to you.
                    </p>
                    <p className='Text500__6'>11.2. Under the conditions set out in these Terms of Use, Lab-H9 grants you a limited, personal, revocable, non-exclusive, non-sub licensable and non-transferable right to access and use the Platform, including the Information, insofar as is necessary for the use of the Platform.
                    </p>
                    <p className='Text500__6'>11.3. The collection of Materials or documents, that is collected by Lab-H9 and displayed on the Platform is a legally protected database. Lab-H9 is the producer of the database and therefore has the exclusive right to grant permission for i) the extraction or re-utilization of the entirety or a substantial part, from a quantitative and/or qualitative point of view, of the contents of the database and ii) the repeated and systematic extraction or re-utilization of non-substantial parts, from a quantitative and/or qualitative point of view, of the contents of the database, in so far as this is in conflict with the normal operation of the database or causes unjustified damage to lab-H9’s lawful interests. You may only request or reuse data from the database if and in so far as this is permitted under these Terms and Conditions.
                    </p>
                    <p className='Text500__6'>11.4. You retain all Intellectual Property Rights to your Material.
                    </p>
                    <p className='Text500__6'>11.5. You warrant that there are no (Intellectual Property) Rights of third parties which preclude you from use the Material uploaded on the Platform and/or providing the licence as mentioned in Article 11.4.
                    </p>
                    <p className='Text500__6'>11.6. You shall refrain from any act that infringes the Intellectual Property Rights of Lab-H9, including – but not limited to – the registration of domain names, trademarks or Google Adwords that are identical or similar to any object to which Lab-H9 holds Intellectual Property Rights, as well as the reproduction, modification, reverse engineering or publishing of the Platform, including the Information, for other purposes than those listed in the Terms of Use.
                    </p>
                    <h3 className="test-boost">Article 12. Indemnities and warranties
                    </h3>
                    <p className='Text500__6'>12.1. You guarantee that you will not use the Platform in a way that (i) infringes the rights of Lab-H9 or third parties, including – but not limited to – Intellectual Property Rights or rights in relation to the protection of privacy, (ii) is contrary to any current legislation or regulations, or (iii) is contrary to any provision in these Terms of Use.
                    </p>
                    <p className='Text500__6'>12.2. You are liable and indemnify Lab-H9 against any and all claims of third parties based on the allegation that the Material infringes any Intellectual Property Right or other right of third parties or is otherwise unlawful against third parties as well as against all claims resulting from your actions in violation with these Terms of Use. All costs incurred and damages suffered by Lab-H9 which are in any way related to the abovementioned claims will be remunerated by you.
                    </p>
                    <p className='Text500__6'>12.3. Lab-H9 does not guarantee (i) the Platform’s fitness for a particular purpose or that the Platform shall meet any of your requirements, (ii) that the Platform shall be available to you at all times and without interruptions, limitations or disturbances; (iii) that AI feedbacks on the Platform are accurate, up to date or complete.
                    </p>
                    <h3 className="test-boost">Article 13. Downloading and sharing Study Material
                    </h3>
                    <p className='Text500__6'>13.1. User with a Profile and a Premium Account have the ability to download some Materials from the Platform for personal use only. Your Profile will register what Material you have downloaded.
                    </p>
                    <p className='Text500__6'>13.2. Downloading Material is allowed, provided that you do not violate our Fair Use Policy. This policy states that you can download a maximum of 10 documents per day, with a maximum of 30 documents per month.
                    </p>
                    <p className='Text500__6'>13.3. You accept and acknowledge that sharing Materials generated by the AI is only permitted using a link to the Platform. You are not permitted to share any Materials that you have downloaded by other means that a link.
                    </p>
                    <h3 className="test-boost">Article 14. Notice of alleged unlawful material & repeat infringers
                    </h3>
                    <p className='Text500__6'>14.1. Lab-H9 respects the (Intellectual Property) Rights of others and takes the protection of (Intellectual Property) Rights seriously. Although Lab-H9 is not liable for any damages in connection with the (illegal) use of the Platform by User, Lab-H9 will, under the conditions as set out in this Article, remove Material(s), that is (are) infringing and/or unlawful and stop manifestly unlawful behaviour after receiving a notification that is sufficiently precise and adequately substantiated.
                    </p>
                    <p className='Text500__6'>14.2. If you claim that certain Material AI generated infringe on your (Intellectual Property) Rights, you can report this alleged infringement to Lab-H9 by mail. We will examine the notice and, if possible and necessary, take the measures to clear the issue.
                    </p>
                    <p className='Text500__6'>14.3. Lab-H9 reserves the right to not grant a request to remove Materials, or to stop an activity in case it has reasonable grounds to doubt the accuracy of the notification or in case a balancing of interest requires this. In such a situation, Lab-H9 can require a court order from a competent court, to demonstrate that the material or the activity is manifestly unlawful.
                    </p>
                    <p className='Text500__6'>14.4. Prior to removal of any alleged unlawful Material(s), Lab-H9 may request and the notifying party shall provide, additional information substantiating that the notifying party is indeed the rightsholder, and Lab-H9 may request and the notifying party shall provide, a signed copy of an indemnification declaration for LAB-H9.
                    </p>
                    <p className='Text500__6'>14.5. Lab-H9 will not be a party in a dispute between the person making the report and any third party arising out of or in connection with a notification.
                    </p>
                    <p className='Text500__6'>14.6. Lab-H9 has a policy of terminating accounts of User who repeatedly and intentionally infringe the Intellectual Property Rights of others.
                    </p>
                    <p className='Text500__6'>14.7. The notifying party indemnifies Lab-H9 against all claims of third parties in relation to the blocking or removal of Materials or the stopping of activities as a result of the notification. The indemnification includes all damages and costs Lab-H9 suffers, could suffer or which Lab-H9 must incur in relation to such a claim, including but not limited to the compensation of legal assistance.
                    </p>
                    <p className='Text500__6'>14.8. Lab-H9 respects and protects the privacy of those who provide notifications. All personal data that it receives as part of a notification will only be used to process the notice.
                    </p>
                    <p className='Text500__6'>14.9. Lab-H9 reserves the right to forward the notice to the user responsible for the Material to which the notice refers.
                    </p>
                    <h3 className="test-boost">Article 15. Liability
                    </h3>
                    <p className='Text500__6'>15.1. Lab-H9 accepts no liability whatsoever for damages resulting from the provision of the Platform, including damage resulting from or in connection with the use of the Platform or from wrongful acts or otherwise, in so far as this is permitted pursuant to mandatory law.
                    </p>
                    <p className='Text500__6'>15.2. In the event that Lab-H9– despite the above – is liable, this liability is limited to direct damages the user suffers as a result of a shortcoming or wrongful act that can be attributed to lab-H9. Direct damage only includes material damage to goods, reasonable costs incurred to prevent or limit direct damage and reasonable costs incurred to determine the cause of the damage. Lab-H9 shall in no event be liable for any special, incidental or consequential damages whatsoever (“indirect damages”), including – but not limited to – loss and/or damage of data (including  Material), loss of profits and lost sales, is excluded.
                    </p>
                    <p className='Text500__6'>15.3. Lab-H9’s liability is in any case limited to the highest of the following amounts: (i) the amount actually paid by the user as premium account, or (ii) € 500,00 (five hundred euros/00).
                    </p>
                    <p className='Text500__6'>15.4. The limitations and exclusions of liability mentioned in the preceding paragraphs of this article will lapse if and in so far as the damage is the result of intentional or willful recklessness on the part of Lab-H9 or its managers (“own actions”).
                    </p>
                    <h3 className="test-boost">Article 16. Force Majeure
                    </h3>
                    <p className='Text500__6'>16.1. In the case of force majeure, the non-fulfillment of the agreement between you and Lab-H9cannot be counted as an attributable failure and Lab-H9 is released from all contractual obligations and liability.
                    </p>
                    <p className='Text500__6'>16.2. Force majeure includes, but is not limited to, illness of employees and/or absence of personnel crucial for the provision of the Platform, disruptions of the electricity supply, fire, natural disasters, strikes, floods, failures to perform by third parties hired by Lab-H9, disturbances in the internet connection, hardware disruptions, disruptions in the (telecommunications) networks and other unforeseen circumstances.
                    </p>
                    <h3 className="test-boost">Article 17. Termination
                    </h3>
                    <p className='Text500__6'>17.1. You are entitled to discontinue your use of the Platform and to terminate your Profile.
                    </p>
                    <p className='Text500__6'>17.2. You accept and acknowledge that any Materials uploaded, or any output created and stored, by you beforehand, will be delated from the Platform.
                    </p>
                    <h3 className="test-boost">Article 18. Miscellaneous
                    </h3>
                    <p className='Text500__6'>18.1. These Terms of Use and the use of the Platform are governed by and construed in accordance with the laws of Lithuania
                    </p>
                    <p className='Text500__6'>18.2. Any and all disputes arising from or related to the Platform or the Terms of Use will be brought before the competent court in Vilnius, unless compulsory law mandates otherwise.
                    </p>
                    <p className='Text500__6'>18.3. If at any time any provision of these Terms of Use is or becomes illegal, void or invalid for any reason whatsoever, such invalidity shall not affect the validity of the remainder of the Terms of Use and such invalid provision shall be replaced by another provision which, being valid in all respects, shall have effect as close as possible to that of such replaced one
                    </p>
                    <p className='Text500__6'>18.4. Lab-H9 is entitled to assign any rights and obligations that result from these Terms of Use to third parties, in which case it will notify you. In the event that you cannot agree with the assignment, your only remedy is to cease the use of the Platform and delete your Profile.
                    </p>
                    <br></br>
                </div>
            );
        },
        "DAILY": () => {
            return (<>Daily</>);
        },
        "PLAN_DOESNT_INCLUDE_FOLDERS": () => {
            return ("Plan doesn't include folders");
        },
        "PLAN_DOESNT_INCLUDE_VOICE": () => {
            return ("Plan doesn't include voice transcription");
        },
        "PLAN_DOESNT_INCLUDE_VIDEO": () => {
            return ("Plan doesn't include video transcription");
        },
        "SUBSCRIPTION_REQUIRED": () => {
            return ("Subscription required for this action");
        },
        "GO_TO_CATALOGUE": () => {
            return ("Go to the catalogue");
        },
        "COOKIE_POLICY_WELCOME": () => {
            return "Welcome to ANNA";
        },
        "LEARN_MORE": () => {
            return "Learn More";
        },
        "I_UNDERSTAND": () => {
            return "Save preferences";
        },
        "COOKIE_POLICY_TEXT": () => {
            return (<div className="gdpr-modal-list Text500__5" style={{ textAlign: 'justify' }}>
                <p>ANNA is made by UAB LAB-H9, Lithuania, Laisvės al. 82, Kaunas,  <a href="mailto:anna@labh9.eu">anna@labh9.eu.</a></p>
                <p>
                    We use local storage to manage security (at logon time) and to remember Your preferred language.
                </p>
                <p>
                    We will not share this information with anyone and it will only be stored on Your browser.
                </p>
                <p>
                    For access with third-party authentication systems (single-sign-on), such as Google and Facebook, it is possible that these systems use cookies and local storage and we refer You to the respective policies.
                </p>
                <ul className="Text500__5" style={{ textAlign: 'left' }}>
                    <li>
                        <a href="https://policies.google.com/technologies/cookies" target="_blank" rel='noopener noreferrer'>
                            Google Cookie Policy
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/privacy/policies/cookies" target="_blank" rel='noopener noreferrer'>
                            Facebook Cookie Policy
                        </a>
                    </li>
                </ul>
                <p>
                    We will not manage or store any third-party cookie information but security tokens shared with sign on systems could be stored on ANNA database for security or legal purposes.
                </p>
                <p>
                    To remind us that You have read this policy, we will store a cookie on Your browser, which we will not share with anyone and which will only serve to prevent this message from appearing automatically every time You connect to the ANNA web.
                </p>
            </div>
            )
        },
        "COOKIE_POLICY_TABLE": () => {
            return (<div className="gdpr-more-info Text500__5" style={{ alignItems: 'center' }}>
                <p>These are the local storage items and cookies managed by ANNA web site on its domain:</p>
                <table className='cookies-table Text500__5 mb-3 mx-auto'>
                    <tbody>
                        <tr>
                            <th>Type</th>
                            <th>Name</th>
                            <th>Purpose</th>
                        </tr>
                        <tr>
                            <td>Cookie</td>
                            <td>cookieConsent</td>
                            <td>Remembering that You’ve read this cookie page</td>
                        </tr>
                        <tr>
                            <td>Local storage</td>
                            <td>_grecaptcha</td>
                            <td>Used for security purposes, during the registration, to prevent non-human (bot) interactions with the page</td>
                        </tr>
                        <tr>
                            <td>Local storage</td>
                            <td>UserAuth</td>
                            <td>Used for security purposes to store on Your browser Your identity data and token after You have logged in.</td>
                        </tr>
                        <tr>
                            <td>Cookie</td>
                            <td>active_language</td>
                            <td>Used to remember Your preferred language for the user interface</td>
                        </tr>
                    </tbody>
                </table>
                <h3 className="test-boost">Third party cookies</h3>
                <p>
                    The Site uses statistical cookies (for advertising and marketing purposes) to monitor visits to the Site.
                    <br />These cookies and their processing are subject to your consent and therefore will be used only if you accept.
                </p>
                <p>
                    If you accept third party cookies please refer to this providers and privacy disclosures policies:
                </p>
                <ul>
                    <li>Google Analytics
                        <ul>
                            <li><a href="https://www.google.com/policies/privacy/partners/">Google's Privacy & Terms</a></li>
                            <li><a href="https://support.google.com/analytics/answer/6004245">Safeguarding your data</a></li>
                        </ul>
                    </li>
                    <li>Google TAG manager
                        <ul>
                            <li><a href="https://support.google.com/tagmanager/answer/9323295?hl=en">Data privacy and security</a></li>
                        </ul>
                    </li>
                    <li>META PIXEL
                        <ul>
                            <li><a href="https://www.facebook.com/privacy/policy/">Meta Privacy Policy</a></li>
                        </ul>
                    </li>
                </ul>
            </div>);
        },
        "COOKIE_POLICY_CONTACT": () => {
            return (<div className="Text500__5" style={{ textAlign: 'justify' }}>
                <p>For any questions You can contact: <a href="mailto:info@labh9.eu">info@labh9.eu</a></p>
            </div>)
        },
        "COUNTRY_NOT_FOUND": () => {
            return `Please add country to your profile!`;
        },
        "CONTACT_US": () => {
            return `Contact us`;
        },
        "WRITE_EMAIL": () => {
            return `E-Mail`;
        },
        "LEAVE_MAIL_MESSAGE_HERE": () => {
            return `Leave your message here`;
        },
        "OPS": () => {
            return `Opssss...`
        },
        "NAME_MANDATORY": () => {
            return `You must insert the name`
        },
        "SURNAME_MANDATORY": () => {
            return `You must insert the lastname`
        },
        "MAIL_MANDATORY": () => {
            return `You must insert your mail`
        },
        "TEXT_MANDATORY": () => {
            return `You can't send an empty mail`
        },
        "INVALID_IMAGE": () => {
            return `Only JPEG and PNG images are allowed!`;
        },
        "STATUS": () => {
            return `Status`;
        },
        "SUBSCRIPTION": () => {
            return `Subscription`;
        },
        "AMOUNT": () => {
            return `Amount`;
        },
        "UPLOAD_DOC_MOBILE_TITLE": () => {
            return `Upload a document`;
        },
        "UPLOAD_DOC_MOBILE_DESC": () => {
            return `Add any pdf, ebook, word, powerpoint, excel, text document`;
        },
        "CREATE_FOLDER_MOBILE_TITLE": () => {
            return `Create a folder`;
        },
        "CREATE_FOLDER_MOBILE_DESC": () => {
            return `Create a new folder to organize your documents!`;
        },
        "UPLOAD_VIDEO_MOBILE_TITLE": () => {
            return `Upload video`;
        },
        "UPLOAD_VIDEO_MOBILE_DESC": () => {
            return `Use live video or upload it`;
        },
        "UPLOAD_URL_MOBILE_TITLE": () => {
            return `Upload a web page`;
        },
        "UPLOAD_URL_MOBILE_DESC": () => {
            return `Dame la url de la página y obtendré todo el contenido`;
        },
        "UPLOAD_URL_MOBILE_LABEL": () => {
            return `Address of the page to be uploaded`;
        },
        "UPLOAD_URL_CHECK_MOBILE_LABEL": () => {
            return `Follow the links in the page`;
        },
        "UPLOAD_URL_MOBILE_MODAL_DESC": () => {
            return `The indicated web page will be downloaded and all its contents will be used as a library document.`;
        },
        "UPLOAD_URL_MOBILE_DOWNLOADING_DESC": () => {
            return `I'm downloading your page. Please wait.`;
        },
        "SUBMIT_PICTURE_MOBILE_TITLE": () => {
            return `Upload an Image`;
        },
        "SUBMIT_PICTURE_MOBILE_DESC": () => {
            return `Scan your book or upload images`;
        },
        "UPLOAD_SPEECH_MOBILE_TITLE": () => {
            return `Speech to Text`;
        },
        "UPLOAD_SPEECH_MOBILE_DESC": () => {
            return `Record sound using Your microphone!`;
        },
        "UPLOAD_SPEECH_DESKTOP_DESC": () => {
            return `Use your voice or upload an audio`;
        },
        "UNABLE_TO_TRANSCRIBE": () => {
            return `Unable to transcribe provided images`;
        },
        "APPROVAL": () => {
            return `Approval`;
        },
        "NO_INFORMATION_CLASS": () => {
            return `No information about class provided`;
        },
        "COUNTRY_REQUIRED": () => {
            return `You need to choose Your country!`;
        },
        "NECESSARY_COOKIES": () => {
            return `Necessary cookies`;
        },
        "THIRD_PARTY_COOKIES": () => {
            return `Third party advertising cookies`;
        },
        "DURATION_SUB_MONTHLY_PLURAL": (params) => {
            var { expirationUnit } = params;
            if (expirationUnit === "year") {
                expirationUnit = "years";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "month") {
                expirationUnit = "months";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "week") {
                expirationUnit = "weeks";
                return `${expirationUnit}`;
            }
            return `${expirationUnit}`;
        },
        "DATE": () => {
            return `Date`;
        },
        "EMAIL_SENT": () => {
            return `Password change email sent, please check Your inbox`;
        },
        "PASSWORD_RESET": () => {
            return `Password Recovery`;
        },
        "ENTER_EMAIL_FORGOT_PASSWORD": () => {
            return (<p>Enter the <strong>email address</strong> associated with Your account and we'll send You a link to reset Your <strong>password</strong>.</p>);
        },
        "REQUEST": () => {
            return `Send request`;
        },
        "REQ_PROFESSOR": () => {
            return `Are you sure to ask for the update of your account profile from standard user to professor account? A verification process will be started and you will be contacted by our back-office.`;
        },
        "CONFIRM_ROLE_PROFESSOR": () => {
            return `Switch to Professor Account`;
        },
        "PROMO_CODE": () => {
            return `Promo Code`;
        },

        // About anna screen
        "ABOUTANNA": () => {
            return `About ANNA`;
        },

        "ABOUTANNA_WHOISANNA": () => {
            return `Who is  ANNA?`;
        },

        "ABOUTANNA_WHOISANNA_ANSWER": () => {
            return (
                <p>
                    <strong>ANNA</strong> is a digital tutor based on advanced artificial intelligence technologies.
                    <br></br>

                    <strong>ANNA</strong> can help you in your study by optimizing your time and simplifying learning with mind maps, advanced chats and exam simulations.
                    <br></br>

                    <strong>ANNA</strong> uses only your own sources (books, videos, documents) to help you study, so it is reliable and safe.
                    <br></br>

                    <strong>ANNA</strong> is an ideal study companion and you can try it for free.

                </p>
            );
        },
        "ABOUTANNA_HOWITWORKS_TITLE": () => {
            return `How does ANNA work?`;
        },
        "ABOUTANNA_HOWITWORKS": () => {
            return `How does it work?`;
        },

        "ABOUTANNA_HOWITWORKS_ANSWER": () => {
            return (
                <div>
                    <p>Choose your preferred language</p>

                    <div className='image_div'>
                        <img className="about_image img-fluid" alt="Language selector" src={changeLanguage}></img>
                    </div>
                    <p>
                        Don't worry, you can change it at any time, and the available languages will increase over time.

                        <br></br>

                        Upload your documents to <strong>ANNA</strong>. You can use any type of file, pdf, word, ebook, excel, power point or multimedia files such as images, video or audio.                    </p>

                    <div className='image_div'>
                        <img className="about_image" alt="File Uploading" src={fileUpload}></img>
                    </div>

                    <p> You can take pictures of your books or notes </p>

                    <div className='image_div'>
                        <img className="about_image" alt="ImageUploading" src={imgUpload}></img>
                    </div>


                    <p>Or record your  voice.</p>

                    <div className='image_div'>
                        <img className="about_image" alt="Audio uploading" src={audioUpload}></img>
                    </div>

                    <p> You can organize your content into folders or extract only the pages you are interested in from the documents.</p>

                    <div className='image_div'>
                        <img className="about_image" alt="Create folder" src={folderExample}></img>
                    </div>

                    <p>Don't worry about the translation, <strong>ANNA</strong> always use your preferred language.</p>
                </div>
            );
        },

        "ABOUTANNA_Q&A_TITLE": () => {
            return `What is Q&A?`;
        },

        "WHATISQ&A": () => {
            return `What is the Question and answer function?`;

        },

        "WHATISQ&A_ANSWER": () => {
            return (
                <p>

                    With this feature you can harness the power of <strong>generative AI</strong> to chat with <strong>ANNA</strong> using your own documents as your knowledge base.


                    <br></br>

                    <strong>ANNA</strong> doesn't just answer your questions, it does much more.

                    <br></br>

                    Using only your files (books, notes, images, video, audio) <strong>ANNA</strong> can create summaries, extract and reprocess information, produce tables, compare data, or generate new content based on your requests.
                    <br></br>

                    Don't put limits on what <strong>ANNA</strong> can do.


                </p>

            );
        },

        "MINDMAP_USE_QUESTION": () => {
            return `What are Mindmaps?`;

        },

        "MINDMAP_USE_ANSWER": () => {
            return (
                <p>
                    Mind maps are a great tool for studying vast or complex topics.

                    <br></br>

                    <strong>ANNA</strong> creates mind maps of each document or folder for you by organizing the contents in a graphical form useful for quick learning and memorization of key elements.
                    <br></br>

                    You can navigate the map by expanding the nodes and viewing summaries of each topic.
                    <br></br>

                    You can share, print and export your map with one click.
                    <div className='image_div'>

                        <img src={mindMap} alt="Mindmap" className="about_image"></img>
                    </div>
                </p>

            );

        },

        "HOW_TO_USE_TEST": () => {
            return `How do ANNA's tests work?`;
        },

        "HOW_TO_USE_TEST_ANSWER": () => {
            return (
                <p>
                    With <strong> ANNA </strong>you can simulate an exam to check the status of your knowledge.
                    <br></br>
                    Choose the document or folder and create a self-assessment test.
                    <br></br>
                    You can customize your test by indicating the number of questions and the type of exam, with closed or open-ended answers.
                    <br></br>
                    Answer the questions and check the result. <strong> ANNA </strong>will explain any mistakes by pointing you to the correct answer and the pages of your texts to reread.
                    <br></br>
                    Practice with <strong>ANNA</strong> tests and prepare for your next class assignment, question or exam.
                </p>

            )

        },
        "HOW_TRY_ANNA": () => {
            return `How can I try ANNA?`;
        },

        "HOW_TRY_ANNA_ANSWER": () => {
            return (
                <p>
                    You can try <strong>ANNA</strong> for free. Just register or log in with Google or Facebook.
                    <br></br>
                    You will be able to try it with the first five papers, with up to 50 questions and five tests.
                    <br></br>
                    If <strong>ANNA</strong> meets your needs you can purchase a weekly or monthly subscription.
                </p>

            )
        },

        "HOW_MUCH": () => {
            return `How much does ANNA cost?`;
        },

        "HOW_MUCH_ANSWER": () => {
            return (
                <p>
                    <strong>ANNA</strong> Is free
                    <br></br>
                    You have at your disposal, simply by creating an account, a maximum of 50 questions and 5 tests per month
                    <br></br>
                    You can check out our <a href='/en/catalogue' >catalogue</a> to see our offerings and compare different subscription plans
                    <br></br>

                    If you like ANNA, you can support us by purchasing one of the subscriptions using PayPal and major credit cards.
                    <br></br>

                    You can stop automatic renewal at any time.

                    <br></br>

                    Schools and teachers can enjoy special discount policies, <a href='/en/contactus' >contact us </a> for more details.
                    <br></br>
                    The terms of the free account may also be subject to change with regard to functionality and limitations.
                </p>

            )
        },

        "ANNA_LAB": () => {
            return `What is ANNA-LAB?`;
        },

        "ANNA_LAB_ANSWER": () => {
            return (
                <p>
                    If you want to be a beta tester <strong>ANNA-LAB</strong> is the place to be.
                    <br></br>
                    You will be able to try new features still in development and interact with our developers.
                </p>

            )
        },

        "NOACCOUNT": () => {
            return `Don't you have an account?`
        },

        "REGISTERFREE": () => {
            return 'Register for free'

        },
        "CANCEL_SUBSCRIPTION_MODAL": () => {
            return "Are you sure you want to cancel? You will be able to use your subscription until the expiration date."
        },
        "FILE_UPLOADED": () => {
            return "File uploaded"
        },
        "FILE_UPLOADED_SUCCESS": () => {
            return "Document uploaded succesfully"
        },
        "ERROR_ACCESSING_MICROPHONE": () => {
            return "Error while accessing the microphone"
        },
        "FAILED_TO_GET_PAYMENTS": () => {
            return "Failed to get payments"
        },
        "SUCCESS": () => {
            return "Success";
        },

        "QUICKCARDS_SESSION_TITLE": () => {
            return `QuickCards`;
        },
        "QUICKCARDS_SESSION": () => {
            return `I can create quickcards for you that will help you assimilate the most important concepts with wonderful summaries`;
        },

        "HOW_ANNA_WORK": () => {
            return `How it works`;
        },
        "BLOGS": () => {
            return `Blogs`;
        },
        "CONNECTING_CHAT_AI_ENGINE": () => {
            return `Your dedicated ANNA chat bot is starting. Please wait a few seconds.`;
        },
        "CONNECTED_CHAT_AI_ENGINE": () => {
            return `Your ANNA chat bot is now ready.`;
        },
        "PUBLIC_CARDS_SCIENCE_TOPIC": () => {
            return `Science`;
        },
        "PUBLIC_CARDS_HISTORY_TOPIC": () => {
            return `History`;
        },
        "PUBLIC_CARDS_ARTS_TOPIC": () => {
            return `Arts`;
        },
        "PUBLIC_CARDS_SPORT_TOPIC": () => {
            return `Sport`;
        },
        "PUBLIC_CARDS_SOCIETY_TOPIC": () => {
            return `Society`;
        },
        "PUBLIC_CARDS_SOURCES": (params) => {
            const { sources } = params;

            return (<div><p>This page was automatically generated by ANNA by accessing the following sources:
            </p>
                <ul>
                    {sources.map((s) => (<li>{s}</li>))}
                </ul>
                <p>
                    If you want to generate more, <a href="/en/login">register for free</a>.</p></div>);
        },
        "PUBLIC_CARDS_WANT_TO_TRY_MIND_MAPS": (params) => {
            const { id } = params;

            return (<>
                This summary was automatically generated by ANNA using the sources listed at the bottom of the page. Want to try mind maps too? <a href={`/it/public/mindmaps/${id}`}>Follow this link</a>. Or register for free and try all the features with your favorite documents.
            </>);
        },
        "PUBLIC_CARDS_INTRO": (params) => {
            return (<>
                <h5>Learn with ANNA's quick cards.</h5>
                <p>New quick cards on topics of interest are added daily.
                    If you don't see what you're looking for, <a href="/en" title="access to ANNA">create your own cards now.</a></p>
            </>);
        },
        "UNAUTHORIZED": (params) => {
            return `Unauthorized action`
        },
        "ARTICLE_SAVED": () => {
            return `Article saved correctly`
        },
        "SAVING_IN_PROGRESS": () => {
            return `Saving data`
        },
        "ARTICLE_NOT_FOUND": () => {
            return `Article not found`
        },
        "MAKE_VISIBILE": () => {
            return `Make the article visible`

        },
        "BLOG_INTRO": () => {
            return `Stay up to date on ANNA and our AI-powered learning solutions!`

        },
        "NEW_BLOG_ARTICLE": () => {
            return `New article`
        },
        "SHARE_QUICKCARDS_MODAL_TITLE": () => {
            return `Share your quickards`
        },
        "SHARED_CHECKBOX_TEXT": () => {
            return `Share`
        },
        "SHARED_INFO_TEXT": () => {
            return `Before you can share your quickards, you must enable the tick to share them with whomever you want`
        },
        "LINK_COPIED_TO_CLIPBOARD": () => {
            return `Link copied`
        },
        "SHARED_LABEL_BUTTON": () => {
            return `Share with social or share the link`
        },
        "SHARED_QUICKARDS_MESSAGE": () => {
            return `Hey, I created these quickcards with ANNA. Take a look and let me know what you think.`

        }
    }
}

export { getText };