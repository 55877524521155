import '../App.css';
import { useContext } from "react";
import UserContext from '../components/User_Context';
import { Navigate } from 'react-router-dom';
import * as api from '../api/apiConnector';
import React, { useState, useEffect } from 'react';
import * as labels from "../components/localized/labels";
import { SetMenu } from '../components/Navigation';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getBreadcrumb } from '../components/Navigation';
import ProfileCards from '../components/ProfileCards';
import { Form } from 'react-bootstrap';

function User_Subscription_Screen() {
    const navigate = useNavigate();
    const [subscription, setSubscription] = useState(null);
    const [newUserSubscription, setNewUserSubscription] = useState(null);
    const [profile, setProfile] = useState(null);
    const [pagesUploaded, setPagesUploaded] = useState(null);
    let { user } = useContext(UserContext);
    user = user ? JSON.parse(user) : null;
    async function getSubscription() {
        var result = (await api.sendMessage("getUserSubscription", {
            user: user
        }));
        if (result.success) {
            if (result.nonexist) {
                navigate("/catalogue");
            }
            else {
                setSubscription(result.userStatus);
                console.log(result.userStatus);
                setNewUserSubscription(result.userStatus);
                setProfile(result.subscriptionProfile);
                setPagesUploaded(result.uploadedPages);
            }
        }
        else if (!result.success && result.nonexist) {
            navigate("/catalogue");
        }
    }
    useEffect(() => {
        (async () => {
            getSubscription();
        })();
    }, []);

    const Change = async () => {
        navigate("/catalogue");
    }

    const Cancel = async () => {
        withReactContent(Swal).fire({
            title: <i>{labels.getText()["DELETE_ACCOUNT_TITLE"]()}</i>,
            icon: "question",
            text: labels.getText()["CANCEL_SUBSCRIPTION_MODAL"](),
            showConfirmButton: true,
            showCancelButton: true,
            showCloseButton: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                var cancelResult = (await api.sendMessage("cancelSubscription", {
                    user: user
                }));
                if (cancelResult.success) {
                    withReactContent(Swal).fire({
                        title: <i>{labels.getText()["SUCCESS"]()}</i>,
                        icon: "success",
                        text: cancelResult.message,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        showCloseButton: true,
                    });
                    //navigate("/catalogue");
                    getSubscription();
                }
                else {
                    withReactContent(Swal).fire({
                        title: <i>{labels.getText()["ERROR"]()}</i>,
                        icon: "error",
                        text: cancelResult.message,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        showCloseButton: true,
                    });
                }
            }
        })
        
    }

    const handleRenewChange = async (event) => {
        const checked = event.target.checked;
        const name = event.target.name;
        setNewUserSubscription({ ...newUserSubscription, [name]: checked });
        const result = await api.sendMessage("renewSubscription", {
            body: {
                renew: checked
            },
            user: user
        })
        console.log(result);
        if(result.success){
            withReactContent(Swal).fire({
                title: <i>Success</i>,
                icon: "success",
                text: result.message,
                showConfirmButton: false,
                allowOutsideClick: false,
                showCloseButton: true,
            });
        } else {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: result.message,
                showConfirmButton: false,
                allowOutsideClick: false,
                showCloseButton: true,
            });
        }
    }

    if (user) {
        return (
            <div className='wrap'>
                {SetMenu({ user: user })}
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-5 wow fadeIn column-sx" data-wow-delay="0.1s">
                            <div className="stack-title">
                                {getBreadcrumb({
                                    page: "user_subscription_profile",
                                })}
                                <h1 className="document-boost"> {labels.getText()["SUBSCRIPTION_SETTINGS"]()}</h1>
                            </div>
                            <div className="row">
                                <p className="text-start Text500__5">{labels.getText()["SUBSCRIPTION_SETTINGS_DESC"]()}</p>
                                <div className="row">
                                    <div className="col-md-12 mb-4">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <h5 className="card-title"> {labels.getText()["SUBSCRIPTION_PLAN"]()}</h5>
                                                <p className="card-text display-5"> {profile && profile.name !== null ? `${profile.name}` : "LOADING..."}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <h5 className="card-title">{labels.getText()["QUESTIONS_ASKED"]()}</h5>
                                                <p className="card-text Text400__6"> {profile && profile.maxQA !== null ? `${subscription.qA} / ${profile.maxQA}` : "0/0"}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <h5 className="card-title">{labels.getText()["TESTS_GENERATED"]()}</h5>
                                                <p className="card-text Text400__6">{profile && profile.maxTests !== null ? `${subscription.tests} / ${profile.maxTests}` : "0/0"}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <h5 className="card-title">{labels.getText()["MINUTES_OF_SPEECH"]()}</h5>
                                                <p className="card-text Text400__6">{profile && profile.maxMinutesSpeechToText !== null
                                                    ? `${subscription.speechToText != null ? subscription.speechToText.toFixed(2) : 0} / ${profile.maxMinutesSpeechToText}`
                                                    : "0/0"}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <h5 className="card-title">{labels.getText()["PAGES_UPLOADED"]()}</h5>
                                                <p className="card-text Text400__6">{profile && profile.maxPages !== null ? `${pagesUploaded} / ${profile.maxPages}` : "0/0"}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <h5 className="card-title">{labels.getText()["COUNTER_RESET"]()}</h5>
                                                <p className="card-text Text400__6">{subscription && subscription.expiration !== null ? `${subscription.accountabilityEnd.split('T')[0]}` : "0/0"}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <h5 className="card-title">{labels.getText()["SUBSCRIPTION_EXPIRES"]()}</h5>
                                                <p className="card-text Text400__6">{subscription && subscription.expiration !== null ? `${subscription.expiration.split('T')[0]}` : "0/0"}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {((subscription && profile.name !== "FREE") && subscription.cancelled !== true) && (
                                        <div className="col-md-12">
                                            <div className='text-center p-2'>
                                            <Form.Label>Autorenew</Form.Label>
                                            <Form.Check
                                                type="switch"
                                                name="autoRenew"
                                                id="autoRenew"
                                                disabled={subscription && (subscription.autoRenew !== null) ? false : true}
                                                checked={newUserSubscription && newUserSubscription.autoRenew !== null ? newUserSubscription.autoRenew : false}
                                                onChange={handleRenewChange}
                                                style={{ scale: '1.5' }}
                                            />
                                            </div>
                                        </div>
                                    ) }
                                    
                                    <div className="col-md-12">
                                        <div className='text-center p-2'>
                                            {((subscription && profile.name !== "FREE") && subscription.cancelled !== true) ? (
                                                <>
                                                    <button className="then-small-button-ghost margin openButton mt-2" onClick={Change}>{labels.getText()["CHANGE"]()}</button>
                                                    <button className="then-small-button-ghost margin openButton" onClick={Cancel}>{labels.getText()["CANCEL"]()}</button>
                                                </>
                                            ) : (
                                                <button className="then-small-button-ghost margin openButton mt-2" onClick={Change}>{labels.getText()["CHANGE"]()}</button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ProfileCards />
                    </div>
                </div>
            </div>
        );
    }
    else {
        SetMenu({ user: user });
        return (<Navigate to="/" />);
    }

}

export default User_Subscription_Screen;