import { useState, useEffect, useContext } from 'react';
import UserContext from '../components/User_Context';
import * as api from '../api/apiConnector';
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import * as labels from "../components/localized/labels";
import ThenQPackageLibrary from '../components/thenQPackageLibrary';
import ThenQPackageQuestions from '../components/thenQPackageQuestions';
import { useNavigate, useParams } from 'react-router-dom';
import fileIcon  from '../assets/img/huge-icon/FilesAndFolder/bulk/file03.svg';
import folderIcon from '../assets/img/huge-icon/FilesAndFolder/bulk/folder.svg'
import audioIcon from '../assets/img/Generic/mic.svg';
import videoIcon from '../assets/img/Generic/clapperboard.svg';

function Then_Training_Test_Screen() {
    const [selectedCard, setSelectedCard] = useState(null);
    const [mobilePage, setMobilePage] = useState(null);
    const [questionPackages, setQuestionPackages] = useState(null);
    const [__document, setDocument] = useState(null);
    const [_document, set_Document] = useState(null);
    const [folder, setFolder] = useState(null);
    const [isDivVisible, setIsDivVisible] = useState(true);
    let { user } = useContext(UserContext);
    const { id, folderId, professorclassroomId, classroomId, language } = useParams();
    const [classroom, setClassroom] = useState(null);
    user = user ? JSON.parse(user) : null;
    const navigate = useNavigate();
    const handleCardClick = (card) => {
        setSelectedCard(card);
        console.log("Card clicked:", selectedCard);
    };
    const handleButtonClick = () => {
        setSelectedCard(null);
    };

    const toggleDivVisibility = (value) => {
        setIsDivVisible(value);
    };
    const handleRefresh = () => {
        refreshPackages();
    };
    useEffect(() => {
        window.$(window).on( "resize", function() {
            setMobileView();
          } );
    }, []);
    const setMobileView = () => {
        if (mobilePage == null) {
            if (window.innerWidth >= 768) {
                setMobilePage(false);
            }
            else {
                setMobilePage(true);
            }
        }
    }
    const download = async (response) => {
        try {
            console.log(response);
            if (response) {

                const fileUrl = URL.createObjectURL(response);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank';
                link.click();
            } else {
                console.error('Error downloading PDF:', response.error);
            }
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    }
    const getDoc = async () => {
        return (await api.sendMessage("getDoc", {
            body: {
                id: id,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).document;
    }
    function getImages() {
        if (__document.isAudio) {
           return( <img className="list-items-content-icon-color" src={audioIcon}></img>)
        } else if (__document.isVideo) {
            return( <img className="list-items-content-icon-color" src={videoIcon}></img>)
        }
        else {
            return( <img className="list-items-content-icon-color" src={fileIcon}></img>)
        }

    }
    const getClassroom = async () => {
        return (await api.sendMessage("getClassroomFromId", {
            body: {
                id: professorclassroomId || classroomId,
            },
            user: user,
        })).classroom;
    }


    const getFolder = async () => {
        return (await api.sendMessage("getFolder", {
            body: {
                id: folderId,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).folder;
    }

    const getPackages = async () => {
        return (await api.sendMessage("getQuestionsPackages", {
            body: {
                folderId: folderId,
                docId: id,
                // docId: "66a0f61988086b41855c509b",
                classroomId: professorclassroomId || classroomId
            },
            user: user,
        })).questionsPackages;
    }

    const refreshPackages = async () => {
        let _allDone = false;

        while (!_allDone) {
            try {
                // Get the packages from the API

                var _packages = null;

                //TODO CLEANING
                if (id) {
                    _packages = await getPackages();
                }
                else {
                    _packages = await getPackages();
                }

                // Refresh the packages state variable, bounded by the list component
                setQuestionPackages(_packages);

                // Check if any document is still indexing
                if (!_packages.find(x => x.status === "processing")) {
                    _allDone = true;
                    console.log("All documents are OK");
                } else {
                    console.log("Waiting for indexing in progress");
                    await new Promise(resolve => setTimeout(resolve, 2000));
                }
            } catch (error) {
                console.error("Error while refreshing packages:", error);
                _allDone = true;  // To break out of the loop in case of error
            }
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const _book = await getDoc();
                const _folder = await getFolder();
                const _classroom = await getClassroom();


                // We call setDocument and setFolder outside of `getPackages`
                setDocument(_book);
                setFolder(_folder);
                setClassroom(_classroom);

                await refreshPackages();
            } catch (error) {
                console.error("Error in useEffect:", error);
            }
        })();
    }, []);

    if ((!questionPackages && !__document) || (!questionPackages && !folder)) {

        //navigate("/");
        return (
            <div className="wrap">
                {/* {SetMenu({ user: user, professorclassroomId, classroomId })} */}
                <h1>{labels.getText()["WAITING_FOR_DATA"]()}</h1>
            </div>
        );
    }
    else if ((__document && questionPackages) || (folder && questionPackages)) {
        return (
            <div className="wrap">
                {setMobileView()}
                <div className="container" style={{height:'100%'}}>
                    <div className="row g-5">
                        <div className="col-lg-5 wow fadeIn column-sx first-test-column" data-wow-delay="0.1s">
                            <div className="stack-title">
                                {SetMenu({ user: user, professorclassroomId, classroomId })}
                                <div className="breadcrumb">
                                    {id ?
                                        (getBreadcrumb({ page: "training", id: id, name: __document.fileName, professorclassroomId, classroomId })) :
                                        (getBreadcrumb({ page: "training_folder", folderId: folderId, name: folder?.name, professorclassroomId: professorclassroomId, classroomId: classroomId, classroomName: classroom?.name }))}
                                </div>
                                <h1 className="test-boost">{id ? (labels.getText()["TRAINING_HEADER"]({ type: "document" })) : (labels.getText()["TRAINING_HEADER"]({ type: "folder" }))}</h1>
                            </div>
                            <br />
                            {!mobilePage &&
                                (<div className="list-items">
                                    <div className="list-items-content">
                                    {(__document) ?
                                            getImages():
                                            (<img className="list-items-content-icon-color" src={folderIcon}></img>)

                                        }
                                        <div className="list-items-content-text">
                                            <p className="title-number Text700__5"><strong>{id ? (__document.fileName) : folder?.name}</strong></p>
                                            <p className="Text500__1">{id ? (labels.getText()["DOCUMENT_UPLOADED"]({ date: new Date(__document.uploadTime) })) : (labels.getText()["FOLDER_CREATED"]({ date: new Date(folder.creationDate) }))}</p>
                                        </div>
                                    </div>
                                </div>)
                            }
                            {mobilePage && isDivVisible &&
                                (<div className="list-items">
                                    <div className="list-items-content">
                                        <img className="list-items-content-icon-color" src={fileIcon} alt="Icon" />
                                        <div className="list-items-content-text">
                                            <p className="title-number Text700__5"><strong>{id ? (__document.fileName) : folder?.name}</strong></p>
                                            <p className="Text500__1">{id ? (labels.getText()["DOCUMENT_UPLOADED"]({ date: new Date(__document.uploadTime) })) : (labels.getText()["FOLDER_CREATED"]({ date: new Date(folder.creationDate) }))}</p>
                                        </div>
                                    </div>
                                </div>)
                            }
                            {!mobilePage && (<ThenQPackageLibrary questionPackages={questionPackages} onCardClick={handleCardClick} isDivVisible={isDivVisible} toggleDivVisibility={toggleDivVisibility} ></ThenQPackageLibrary>)}
                            {mobilePage && isDivVisible && (<ThenQPackageLibrary questionPackages={questionPackages} onCardClick={handleCardClick} isDivVisible={isDivVisible} toggleDivVisibility={toggleDivVisibility} ></ThenQPackageLibrary>)}
                        </div>
                        <ThenQPackageQuestions questionPackages={questionPackages} mobilePage={mobilePage} onPackageCreated={handleRefresh} selectedCard={selectedCard} onButtonClick={handleButtonClick} toggleDivVisibility={toggleDivVisibility}
                            onSubmitted={(questionsPackage) => {
                                api.sendMessage("addQuestionPackageResult", {
                                    body: {
                                        questionsPackage: questionsPackage,
                                    },
                                    user: user,
                                })
                            }}
                            onDownload={async (questions, submitted) => {
                                download(await api.downloadFile("downloadPdf", {
                                    body: {
                                        questionsPackage: questions,
                                        submitted: submitted
                                    },
                                    user: user
                                }));
                            }}
                            onDownloadOpen={async (questions) => {
                                download(await api.downloadFile("downloadPdfOpen", {
                                    body: {
                                        questionsPackage: questions
                                    },
                                    user: user
                                }));
                            }}

                        ></ThenQPackageQuestions>
                    </div>

                </div>
            </div>





        )

    }
    else {
        navigate(`/${language}/`);
    }
}

export default Then_Training_Test_Screen