import '../App.css';
import UserContext from '../components/User_Context';
import { React, useState, useContext, useEffect } from 'react';
import * as labels from "../components/localized/labels";
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import { useNavigate, useParams } from 'react-router-dom';
import * as api from '../api/apiConnector';

function Blog_Screen() {
    let { user } = useContext(UserContext);
    const navigate = useNavigate();
    const [blogs, setBlogs] = useState(null);
    const userLanguage = labels.getLanguage();
    user = user ? JSON.parse(user) : null;
    const { language} = useParams();

    
    const getData = async () => {
        const data = await api.sendMessage("getBlogs", {
            body: {
                user: user,
            }
        });
        if (data) {
            setBlogs(data.blogs);
        }
    }

    const openBlog = (blog, title) =>{        
        blog ? navigate(`/${language}/blogs/${blog}/${encodeURI(title.toLowerCase())}`) : navigate(`/blogs/`);
    }

    useEffect(() => {
        getData();
    }, []);
    if (blogs) {
        return (
            <div className="App">
                <div className='wrap'>
                    <div className="py-5">
                        <h1 className="title">{labels.getText()["BLOGS"]()}</h1>
                        {SetMenu({ user: user })}
                        {getBreadcrumb({ page: "blogScreen" })}
                        <div className="py-5">
                            <div className="blog-container">
                                {blogs
                                    .sort((a, b) => new Date(b.documentCreated) - new Date(a.documentCreated))
                                    .map((blog, index) => (
                                        <div
                                            className="blog-card" 
                                            key={blog._id} onClick={ (e) => openBlog(blog._id, blog.title.find(title => title.iso === userLanguage)?.text)}
                                        >
                                            <img src={blog.thumbnail} className="img-thumbnail" alt="thumbnail" />
                                            <div className="blog-content text-start Text500__5">
                                                <div className="blog-date Text500__5">
                                                    {new Date(blog.documentCreated).toLocaleDateString()}
                                                </div>
                                                <div className="blog-title Text500__9">
                                                    {blog.title.find(title => title.iso === userLanguage)?.text}
                                                </div>
                                                <div className="blog-author Text500__7">
                                                    <span>{blog.documentCreatedByName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
    else {
        return (
            <div className="wrap">
                {/* {SetMenu({ user: user, professorclassroomId, classroomId })} */}
                <h1>{labels.getText()["WAITING_FOR_DATA"]()}</h1>
            </div>
        );
    }
}

export default Blog_Screen;
