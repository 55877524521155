import { React, Link } from 'react';
import { urls } from "./labels.js";
import changeLanguage from "../../imgs/aboutAnna/changeLanguage.png"
import folderExample from "../../imgs/aboutAnna/folderExample.png"
import fileUpload from "../../imgs/aboutAnna/fileUpload.gif"
import imgUpload from "../../imgs/aboutAnna/imgUpload.gif"
import audioUpload from "../../imgs/aboutAnna/audioUpload.png"
import mindMap from "../../imgs/aboutAnna/mindmap.png"

const getText = () => {
    return {
        "SECONDS": (params) => {
            const { value = 0 } = params;
            return `${value}sek`;
        },
        "GENERIC_ERROR": (params) => {
            const { error = "Generic error GE_001" } = params;
            return `Aptikta klaida operacijos metu: ${error}`;
        },
        "PRIVACY_POLICY": () => {
            return "Privatumo politika";
        },
        "ERROR_CREATE_TEST": () => {
            return "Prašome pasirinkti visus reikalingus laukelius!";
        },
        "USER_EXISTS": () => {
            return "Šis el. paštas jau panaudotas!";
        },
        "MISSING_FIELDS": () => {
            return "Prašome užpildyti visus laukus!";
        },
        "HOME_WELCOME_MESSAGE": (params) => {
            return (<span>
                {/*Welcome to the ultimate study tool*/}
                {/*Sveiki prisijungę prie geriausios/pažangiausios studijų priemonės*/}
                Sveiki prisijungę prie geriausios studijų priemonės
            </span>);
        },
        "EMAIL_RESENT": () => {
            return "Laiškas buvo išsiųstas";
        },
        "LIBRARY_WELCOME_MESSAGE": (params) => {
            const { user, docs, folders } = params;
            const number = docs?.length || 0 + folders?.length || 0;

            if (number > 0) {
                if (number > 1) {
                    return (<span>Labas {user.name}, Tu turi {number} dokumentus savo bibliotekoje</span>);
                }
                else {

                    return (<span>Labas {user.name}, Tu turi {number} dokumentą savo bibliotekoje</span>);
                }
            }
            else {
                return (<span>Labas {user.name}, Tavo biblioteka tuščia</span>);
            }
        },
        "MY_CLASSESS": () => {
            return "Mano klasės";
        },
        "ADD_CLASSROOM": () => {
            return "Pridėti klasę";
        },
        "ENTER_CODE": () => {
            return "Įveskite aktivacijos kodą";
        },
        "ENTER_CLASS": () => {
            return "Įeiti į klasę";
        },
        "JOIN_NEW_CLASSROOM": () => {
            return "Prisijungti į naują klasę";
        },
        "YOU_CANNOT_ENTER_THIS_CLASS": () => {
            return "Negalite pasiekti šios klasės, kol mokytojas nepatvirtino!";
        },
        "YOU_ALREADY_ADDED_THIS_CLASS": () => {
            return "Jūs jau pridėjote šią klasę!";
        },
        "CLASS_NAME": (params) => {
            const { name } = params;

            return (<span>
                Redaguoti {name}
            </span>);
        },
        "LIBRARY_WELCOME_MESSAGE_2": () => {
            return (<span>
                Jūsų dokumentus ir knygas perskaitysiu aš <strong>ANNA</strong>! Jums sukursiu trumpą apibendrinimą, atsakysiu į visus Jūsų klausimus ir jeigu norite, galėsiu sukurti testus žinioms patikrinti!
            </span>);
        },
        "LIBRARY_WELCOME_MESSAGE_3": () => {
            return (<span>
                Įkelk nuotraukas, kurias aš konvertuosiu į PDF dokumentą. Jį perskaitys mano <strong>Dirbtinio Intelekto</strong> variklis, kuris sugeneruos
                trumpas santraukas, atsakymus į bet kokius klausimus ir, jei norite,
                parengs testą, kad pasitikrintumėte savo žinių lygį.
            </span>);
        },
        "LIBRARY_WELCOME_MESSAGE_4": () => {
            return (<span>
                Based on your audio input I will create a new book to the library. It will be read by my <strong>AI</strong> engine producing
                for you short summaries, answer to any questions and if you want I could
                prepare a test to verify your state of knowledge.
            </span>);
        },
        "DOCUMENT_WELCOME_MESSAGE": (params) => {
            const { linkTo } = params;
            return (<span>
                Čia galite pasirinkti Dirbtinio Intelekto sesijos tipą arba <Link to={linkTo}>grįžti atgal į sąrašą</Link>.
            </span>);
        },
        "DOCUMENT_QA_SESSION_TITLE": (params) => {

            return "K&A";
        },
        "DOCUMENT_QA_SESSION_FOLDER": (params) => {

            return (<span>
                Bendraukite su manimi klausdami apie dokumentus šiame aplanke. Aš jums atsakysiu į bet kokį reikalingą klausimą.
            </span>);
        },
        "DOCUMENT_QA_SESSION": (params) => {

            return (<span>
                {/* Chat with me asking anything about this document. I'll give you any answer you need. */}
                Bendraukite su manimi klausdami apie šį dokumentą. Aš jums atsakysiu į bet kokį reikalingą klausimą.
            </span>);
        },
        "TRAINING_SESSION_TITLE": (params) => {

            return (<span>
                {/* Training */}
                Treniravimas
            </span>);
        },
        "MANAGE_YOUR_CLASS": () => {
            return "Klasių valdymas";
        },
        "TRAINING_SESSION": (params) => {

            return (<span>
                {/* Test your knowledge. I will offer you a simulation of an exam and suggest where to find the right solutions in case of a mistake. */}
                Pasitikrink žinias. Iš dokumente pateiktos informacijos aš sukursiu egzamino simuliaciją bei nurodysiu, kur ieškoti teisingų sprendimų, jei padarysite klaidą.
            </span>);
        },

        "TRAINING_SESSION_FOLDER": (params) => {

            return (<span>
                {/* Test your knowledge. I will offer you a simulation of an exam and suggest where to find the right solutions in case of a mistake. */}
                Pasitikrink žinias. Iš aplanke esančių dokumentų aš pateiksiu egzamino simuliaciją bei nurodysiu, kur ieškoti teisingų sprendimų, jei padarysite klaidą.
            </span>);
        },

        "MIND_MAP_SESSION_TITLE": (params) => {

            return " Minčių žemėlapis";
        },
        "MIND_MAP_SESSION": (params) => {

            return (<span>
                {/* I can prepare for you a mind map of the document helping you to think, collect knowledge, and remember. */}
                Galiu paruošti tavo dokumento minčių žemėlapį, kuris padės geriau įsisavinti turinį bei geriau jį įsisavinti
            </span>);
        },

        "MIND_MAP_SESSION_FOLDER": (params) => {

            return (<span>
                {/* I can prepare for you a mind map of the document helping you to think, collect knowledge, and remember. */}
                Galiu paruošti tavo dokumentų minčių žemėlapius, kuris padės geriau įsisavinti turinį bei geriau jį įsisavinti
            </span>);
        },


        "MEMBERS": () => {
            return `Narių`;
        },
        "MEMBER": () => {
            return `Narys`;
        },
        "GO_TO_PAGE": () => {
            return "Eiti į puslapį ";
        },
        "PREVIOUS": () => {
            return "Ankstesnis";
        },
        "PREVIOUS_PAGES": () => {
            return "Ankstesnis puslapis";
        },
        "NEXT_PAGES": () => {
            return "Kitas puslapis";
        },
        "NEXT": () => {
            return "Kitas";
        },
        "DELETE": () => {
            return "Panaikinti";
        },
        "EDIT": () => {
            return "Redaguoti";
        },
        "SUMMARY_SESSION_TITLE": (params) => {

            return (<span>
                {/* Summary &amp; translate */}
                Santrauka ir vertimas
            </span>);
        },
        "SUMMARY_SESSION": (params) => {

            return (<span>
                {/* I can summarize and translate your document. */}
                Aš galiu parašyti bei išversti dokumento santrauką.
            </span>);
        },
        "GO": (params) => {

            return (<span>
                {/* Go */}
                Eiti
            </span>);
        },
        "TRY_IT": (params) => {

            return (<span>
                {/* Let's try */}
                Išbandyti
            </span>);
        },
        "TELL_ME_MORE": (params) => {

            return (<span>
                {/* Tell me more */}
                Papasakok man daugiau
            </span>);
        },

        "ASK_ME": (params) => {

            return (<span>
                {/* Ask me */}
                Užduoti klausimą
            </span>);
        },
        "DOWNLOAD_PDF": (params) => {

            return (<span>
                {/* Download Pdf */}
                Atsisiųsti Pdf
            </span>);
        },
        "RETURN_TO_DOC": () => {

            //return "Go back";
            return "Grįžti atgal";
        },
        "DOCUMENT_QA_SESSION_INTRO": (params) => {

            return (<span>
                {/* I have read the document and can now answer any questions. Try me. Enter the text of the question and wait for me to answer you. */}
                Aš perskaičiau dokumentą bei galiu atsakyti klausimus. Išbandyk. Įvesk klausimo tekstą ir sulauk mano atsakymo.
            </span>);
        },
        "DOCUMENT_UPLOADED": (params) => {
            const { date } = params;

            return (<span>
                {/* This document was uploaded on: {date.toLocaleDateString()}. */}
                Dokumentas įkeltas: {date.toLocaleDateString()}.
            </span>);
        },
        "FOLDER_CREATED": (params) => {
            const { date } = params;

            return (<span>
                Aplankas sukurtas: {date.toLocaleDateString()}.
            </span>);
        },
        "PAGES_LIST": (params) => {
            const { pages } = params;

            return pages.length > 1 ? "Puslapiai" : "Puslapis";
        },
        "DOWNLOAD": () => {
            return "Atsisiųsti";
        },
        "COPY_TO_CLIPBOARD": () => {
            //return "Copy to clipboard";
            return "Kopijuoti į iškarpinę";
        },
        "QUESTION": () => {
            return "Klausimas";
        },
        "DELETE_QUESTION": () => {
            //return "Delete this document";
            return "Panaikinti dokumentą";
        },
        "PREPARING_PACKAGE": () => {
            //return "I am preparing the package...";
            return "Ruošiu testą ir klausimus...";
        },
        "PACKAGE_NUMBER": (params) => {
            const { number } = params;
            //return `Package #${number}`;
            return `Testas #${number}`;
        },
        "PACKAGE": () => {
            //return `Package`;
            return `Testas`;
        },
        "PACKAGE_PROCCESS_FINISH": () => {
            //return "Package processing has been finished!";
            return "Testas apdorojimas baigtas";
        },
        "PACKAGE_PROCCESS_FINISH_LESS_QUESTIONS": () => {
            //return "Could not generate requested amount of questions. Document is too small.";
            return "Nepavyko sugeneruoti visų reikiamų klausimų. Dokumentas per mažas.";
        },
        "PREPARING_DOCUMENT": () => {
            //return "I am preparing the document...";
            return "Aš ruošiu dokumentą...";
        },
        "DOCUMENT_NUMBER": (params) => {
            const { number } = params;
            return `Dokumentas #${number}`;
        },
        "DOCUMENT_PROCCESS_FINISH": () => {
            //return "Document processing has been finished!";
            return "Dokumento apdorojimas baigtas!";
        },
        "DOCUMENT_NAME_MINDMAP": (params) => {
            const { title } = params;
            return `${title}`;
        },
        "PREPARING_MINDMAP": () => {
            //return "I am preparing the mindmap...";
            return "Aš ruošiu minčių žemėlapį...";
        },

        //#region breadcrumb
        "BREADCRUMB_HOME": () => {
            return "Pagrindinis";
        },
        "BREADCRUMB_CLASSROOMS": () => {
            //return "ClassRooms";
            return "Klasės";
        },
      
        "CLASSROOM_DELETE_ERROR": () => {
            return `Bandant ištrinti klasę įvyko klaida`;
        },
        "BREADCRUMB_DOCUMENT": (params) => {
            const { name } = params;
            return `${name}`;
        },
        "BREADCRUMB_QA": () => {
            //return "Q & A";
            return "K&A";
        },
        "BREADCRUMB_TRAINING": () => {
            //return "Training";
            return "Treniravimas";
        },
        "BREADCRUMB_SURVEY": () => {
            //return "Survey";
            return "Apklausa";
        },
        "BREADCRUMB_SURVEY_ID": (params) => {
            const { id } = params;
            return `${id}`;
        },
        "BREADCRUMB_MINDMAP": () => {
            //return "Mindmap";
            return "Minčių žemėlapis";
        },
        "BREADCRUMB_SUBMINDMAP": (params) => {
            const { topic } = params;
            return `${topic}`;
        },
        //#endregion

        "ADD": () => {
            return "Pridėti ";
        },

        "GENERAL_INFO": () => {
            return "Pagrindinė informacija";
        },
        "ABOUT_CLASS": () => {
            return "Informacija apie klasę";
        },
        "DOCUMENT_FOLDERS": () => {
            return "dokumentai ir aplankai klasėje";
        },

        "ADD_DOCUMENT": () => {
            //return "Add new";
            return "Pridėti naują";
        },

        "UPLOAD_NEW_FILE": () => {
            //return "Add a new document";
            return "Pridėti naują dokumentą";
        },

        "UPLOAD_NEW_FILE_SMALL": () => {
            //return "If you want you can uplaod a document here";
            return "Jeigu nori, gali įkelti dokumentą čia";
        },

        "BOOKLIST_SMALL_MESSAGE": (params) => {
            const { uploadDate, uploadTime } = params;

            //return `Submittet in   ${uploadDate} at ${uploadTime}`;
            return `Pateikta ${uploadDate} ${uploadTime}`;
        },

        "YOUR_LIBRARY": () => {
            //return "Your library";
            return "Tavo biblioteka";
        },
        "CAMERA_UPLOAD": () => {
            return "Camera upload";
        },
        "VOICE_UPLOAD": () => {
            return "Speech to text upload";
        },
        "EMAIL_ADDRESS": () => {
            //return "E-Mail";
            return "El. paštas";
        },
        "SCHOOL_NAME": () => {
            return "Įstaigos pavadinimas";
        },
        "PASSWORD": () => {
            //return "Password";
            return "Slaptažodis";
        },
        "CONFIRM_PASSWORD": () => {
            return "Pakartokite slaptažodį";
        },
        "CREATE_YOUR_IDENTITY": () => {
            return "Susikurk savo nemokamą paskyrą";
        },
        "REMEMBER_ME": () => {
            //return "Rememebr me";
            return "Prisiminti mane";
        },
        "FORGOT_PASSWORD": () => {
            //return "forgot password?";
            return "Pamiršai slaptažodį?";
        },
        "WHO_ARE_YOU": () => {
            //return "Who are you?";
            return "Kas Tu?";
        },
        "OPEN": () => {
            //return "Open";
            return "Atidaryti";
        },
        "CREATE_FOLDER": () => {
            return "Sukurti aplanką";
        },
        "FOLDER_NAME": () => {
            return "Aplanko pavadinimas";
        },

        "PAGES": (params) => {
            const { pages } = params;
            if (pages === 0) {
                //return `No pages for the document`
                return `Dokumento puslapių nėra`
            }
            else if (pages === 1) {
                //return `${pages} page`
                return `${pages} puslapis`
            }
            else {
                //return `${pages} pages`
                return `${pages} puslapiai`
            }
        },
        "CREATE_TEST": () => {
            //return "Create new test";
            return "Kurti naują testą";
        },
        "NO_TEST_TO_SHOW": () => {
            //return "No test to show";
            return "Nėra jokio testo, kurį būtų galima rodyti.";
        },

        "TEST_PAGE_LABELS": (params) => {
            const { user, questionsPackages } = params;
            if (questionsPackages > 0) {
                if (questionsPackages === 1) {
                    //return `${user}, we have one test for this document`
                    return `${user}, turime vieną testą šiam dokumentui`
                }
                else {
                    //return `${user}, we have  ${questionsPackages} tests for this document`
                    return `${user}, turime  ${questionsPackages} testų šiam dokumentui`
                }
            }
            else {
                //return `${user}, we don't have any test for this document`
                return `${user}, neturime jokių testų šiam dokumentui`
            }
        },
        "LIBRARY_WELCOME_MESSAGE_CLASS": (params) => {
            const { classroom, docs, folders } = params;
            const number = (docs?.length || 0) + (folders?.length || 0);

            if (number > 0) {
                if (number > 1) {
                    return (<span>Klasės {classroom?.name}, bibliotekoje yra  {number} elementų</span>);
                }
                else {
                    return (<span>Klasėje {classroom?.name}, bibliotekoje yra elementas {number} </span>);
                }
            }
            else {
                return (<span>Klasėje {classroom?.name},biblioteka yra tuščia</span>);
            }

        },
        "QUESTIONS_COUNT": () => {
            //return `Questions Count`;
            return `Klausimų Kiekis`;
        },
        "CREATED_BY": () => {
            return "sukurta:";
        },
        "DONE": () => {
            return "Baigta";
        },
        "OTP_MODAL_FIRST_PART_RESEND": () => {
            return "Negavote kodo? Spauskite ";
        },
        "OTP_MODAL_SECOND_PART_RESEND": () => {
            return "čia, ";
        },
        "OTP_MODAL_THIRD_PART_RESEND": () => {
            return "kad dar kartą gautumėte laišką su kodu.";
        },
        "ADD_NEW_CLASSROOM": () => {
            return `Pridėti naują klasę`;
        },
        "ADD_NEW_GROUP": () => {
            return `Pridėti naują grupę`;
        },
        "CREATE_GROUP": () => {
            return `Sukurti Grupę`;
        },
        "CREATE_CLASSROOM": () => {
            return `Sukurti Klasę`;
        },
        "CLASSROOM_NAME": () => {
            return `Klasės Pavadinimas`;
        },
        "GROUP_NAME": () => {
            //return `Group Name`;
            return `Grupės Pavadinimas`;
        },
        "SEARCH_MEMBERS": () => {
            //return `Search Members`;
            return `Ieškoti Narių`;
        },
        "SEARCH_DOCUMENTS": () => {
            return `Ieškoti dokumentų`;
        },
        "CREATE_CARD": () => {
            //return `Create New Card`;
            return `Sukurti naują klasę`;
        },
        "COLOR": () => {
            //return `Choose Card Color`;
            return `Pasirink klasės kortelės spalvą:`;
        },
        "CHOOSE": () => {
            return `Pasirinkite valstybę`;
        },
        "NO_OPTIONS": () => {
            return `Nerasta rezultatų`;
        },
        "ACCEPT_T&C": () => {
            return `Prašome pažymėti, jog sutinkate su privatumo ir naudojimosi taisyklėmis!`;
        },
        "ACCEPT": () => {
            return `Patvirtinti`;
        },
        "DECLINE": () => {
            return `Atmesti`;
        },
        "REMOVE": () => {
            return `Pašalinti`;
        },
        "CLASSROOM_JOINED": () => {
            return `Sėkmingai prisijungėte`;
        },
        "INVITATION_DECLINED": () => {
            return `Kvietimas atšauktas`;
        },
        "PASSWORDS_NO_MATCH": () => {
            return `Nesutampa slaptažodžiai!`;
        },
        "NOT_GOOD_EMAIL": () => {
            return `Prašome įvesti teisingą el. paštą!`;
        },
        "PLEASE_FILL_IN_THE_FIELDS": () => {
            return `Prašome užpildyti visus laukus!`;
        },
        "TEST_DIFFICULTY": () => {
            //return `Test Difficulty`;
            return `Testo Sudėtingumas`;
        },
        "ANSWERS_TYPE": () => {
            //return `Answers Type`;
            return `Atsakymo Tipas`;
        },
        "ADD_TO_CLASSROOM": () => {
            //return `Add To Classroom`;
            return `Pridėti Į Klasę`;
        },
        "MUST_INSERT_CLASSROOM_NAME": () => {
            return "Prašome įvesti pavadinimą klasei!";
        },
        "ANSWERS_QUANTITY": () => {
            //return `Correct Answers`;
            return `Atsakymų kiekis`;
        },
        "SINGLE_CHOICES": () => {
            return "Vienas teisingas";
        },
        "MULTIPLE_CHOICES": () => {
            return "Keli teisingi";
        },
        "CLOSE_ANSWERS": () => {
            //return `Close answers`;
            return `Uždari atsakymai`;
        },
        "OPEN_ANSWERS": () => {
            //return `Open answers`;
            return `Atviri atsakymai`;
        },
        "EASY_QUESTIONS": () => {
            //return `Easy`;
            return `Lengvi`;
        },
        "MEDIUM_QUESTIONS": () => {
            //return `Medium`;
            return `Vidutinio sudėtingumo`;
        },
        "HARD_QUESTIONS": () => {
            return `Sudėtingi`;
        },
        "EXTREME_QUESTIONS": () => {
            //return `Extreme`;
            return `Itin sudėtingi`;
        },
        "CLOSE": () => {
            //return `Close`;
            return `Uždaryti`;
        },
        "TEST_SMALL_MESSAGE": (params) => {
            const { generatedDate, generatedTime } = params;

            return `sugeneruota  ${generatedDate} ${generatedTime}`;
        },
        "NQUESTIONS": (params) => {
            const { numbers } = params;

            //return `${numbers} questions`;
            return `${numbers} klausimai`;
        },
        "PAGES_THUMB_TITLE": () => {
            return "puslapis";
        },
        "PROGRESS": () => {
            //return `Progress`;
            return `Progresas`;
        },
        "ERROR": () => {
            return "Klaida";
        },
        "ERROR_SENDING_MAIL": () => {
            return `Mes turėjome problemų siunčiant laišką, pabandykite dar kartą vėliau`;
        },
        "YOU_NEED_TO_LOGIN_FIRST": () => {
            return "Negalite pasiekti šio lango, kol esate neprisijungęs";
        },
        "INVITE_PROFESSOR": () => {
            return `Pakviesti mokytoją`;
        },
        "INVITATION": () => {
            return `Kvietimas`;
        },
        "INSERT_EMAIL": () => {
            return `Įveskite elektroninį paštą`;
        },
        "ERROR_INSERT_VALID_QUESTION": () => {
            return "Klausimas negali būti tuščias";
        },
        "ERROR_WHILE_CONNECTING_TO_SERVER": () => {
            return "Įvyko klaida jungiantis prie serverio. Bandykite vėliau...";
        },
        "WAITING_FOR_APPROVAL": () => {
            return "Laukiama patvirtinimo...";
        },
        "ERROR_WHILE_CREATING_MINDMAP": () => {
            return "Kuriant minčių žemėlapį įvyko klaida";
        },
        "WRONG_CREDENTIALS": () => {
            return "Neteisingi duomenys";
        },
        "DELETING_DOCUMENT": () => {
            return "Dokumentas trinamas";
        },
        "DO_YOU_WANT_TO_DELETE": () => {
            return "Ar tikrai norite panaikinti?";
        },
        "SHOW_MORE": () => {
            return "Rodyti daugiau";
        },
        "SHOW_LESS": () => {
            return "Rodyti mažiau";
        },
        "FOLDER_EXPLANATION": (params) => {
            return (<span>
                Čia galite matyti visus įkeltus dokumentus. Visi pažymėti dokumentai yra pridėti į aplanką.
                Į aplanką galite pridėti kiek norite dokumentų!
            </span>);
        },
        "NO_ELEMENTS_TO_SHOW": () => {
            return "Biblioteka tuščia";
        },
        "I_DONT_KNOW": () => {
            return "Negaliu atsakyti į šį klausmą";
        },
        "TEXT_COPIED_TO_CLIPBOARD": () => {
            return "Tekstas nukopijuotas į iškarpinę!";
        },
        "LOADING": () => {
            return "Kraunama...";
        },
        "ADD_TO_FOLDER": () => {
            return "Pridėti į aplanką";
        },
        "FOLDER_CREATION_DATE": (params) => {
            const { uploadDate, uploadTime } = params;

            return `Sukurtas ${uploadDate} ${uploadTime}`;
        },
        "PASSWORD_REQ": () => {
            return "Jūsų slaptažodis neatitinka reikalavimų!";
        },
        "DELETE_FOLDER": () => {
            return "Panaikinti aplanką";
        },
        "LEAVE_MESSAGE_HERE": () => {
            return "Įrašykite klausimą čia";
        },
        "INDEXING_PROGRESS": () => {
            return "Indeksavimo progresas:";
        },
        "WAITING_FOR_DATA": () => {
            return "Laukiama duomenų...";
        },
        "WAIT": () => {
            return "Palaukite";
        },
        "EXPORT_DOCX": () => {
            return "Eksportuoti į docx";
        },
        "EXPORT_PPTX": () => {
            return "Eksportuoti į pptx";
        },
        "SUBMIT_BUTTON": () => {
            //return `Submit`;
            return `Pateikti`;
        },
        "CREATE_PDF": () => {
            //return `Download PDF`;
            return `Sukurti PDF`;
        },
        "FACEBOOK_LOGIN": () => {
            //return "Login with Facebook";
            return "Prisijungti su Facebook";
        },
        "GOOGLE_LOGIN": () => {
            return "Prisijungti su Google";
        },
        "LOGIN": () => {
            return "Prisijungti";
        },
        "ARTIFICIAL_INTELLIGENCE_FOR_STUDIES": () => {
            //return "Artificial Intelligence for Your Studies";
            return "Dirbtinis Intelektas Jūsų Studijoms";
        },
        "START_NOW": () => {
            //return "Start now";
            return "Pradėti dabar";
        },
        "REGISTER": () => {
            //return "Register";
            return "Registruotis";
        },
        "REGISTER_CREDENTIAL": () => {
            return "Įveskite duomenis";
        },
        "ERROR_DURING_CREATION": () => {
            return "Klaida kuriant paskyrą!";
        },
        "WRONG_CODE": () => {
            return "Neteisingas patvirtinimo kodas.";
        },
        "USER_CREATED": () => {
            return "Jūsų paskyra sėkmingai sukurta ir aktyvuota!";
        },
        "OTP_MODAL_BODY": () => {
            return "Prašome patikrinti savo el. pašto dežutę, kur Jums atsiuntėme patvirtinimo kodą.";
        },
        "OTP_MODAL_TITLE": () => {
            return "Prašome patvirtinti savo el. paštą";
        },
        "NAME": () => {
            return "Vardas";
        },
        "LAST_NAME": () => {
            return "Pavardė";
        },
        "USERNAME": () => {
            return "Vartotojo vardas";
        },
        "SELECT_COUNTRY": () => {
            return "Pasirinkite valstybę";
        },
        "OLD_PASSWORD": () => {
            return "Senas slaptažodis";
        },
        "NEW_PASSWORD": () => {
            return "Naujas slaptažodis";
        },
        "ENTER_OLD_PASSWORD": () => {
            return "Įveskite seną slaptažodį";
        },
        "ENTER_NEW_PASSWORD": () => {
            return "Įveskite naują slaptažodį";
        },
        "CHECK_TERMS_CONDITIONS": () => {
            return "Patikrinkite, ar sutinkate su taisyklėmis ir sąlygomis";
        },
        "CHECK_TERMS_CONDITIONS_SOCIAL": () => {
            return "Jei užsiregistruojate socialiniame tinkle, sutinkate. ";
        },
        "CHECK_TERMS_CONDITIONS1": () => {
            return "Pažymėkite, jeigu sutinkate su ";
        },
        "CHECK_TERMS_CONDITIONS2": () => {
            return "naudojimosi sąlygomis";
        },
        "PRIVACY_POLICY_REGISTER": () => {
            return (
                <p className='Text500__6'>ir <strong><a href="/privacy_policy" target='_blank' rel="noopener noreferrer" >privatumo politika</a></strong></p>
            );
        },
        "CHANGE_PASSWORD": () => {
            return "Pakeisti slaptažodį";
        },
        "PAGE_SELECTOR": () => {
            return "Pasirinkti puslapius";
        },
        "PAGES_RANGE_HELP": () => {
            return "Įvesk puslapio numerį (pvz.: 5), puslapių intervalą (pvz.: 5-10), puslapių eilę (pvz.: 1, 2, 3, 4, 5) arba jų mišinį (pvz.: 5, 7-10, 12, 14)";
        },
        "DATE_OF_BIRTH": () => {
            return "Gimimo data";
        },
        "PAGES_RANGE": () => {
            return "Pasirinkite puslapius";
        },
        "SAVE_CHANGES": () => {
            return "Išsaugoti pakeitimus";
        },
        "PROBLEM": () => {
            return "Įvyko klaida";
        },
        "FACEBOOK_PROBLEM": () => {
            //return "We have some problems with facebook login";
            return "Turime problemų su facebook prisijungimu";
        },
        "GOOGLE_PROBLEM": () => {
            //return "We have some problems with Google Login....Somethings goes wrong";
            return "Turime problemų su Google prisijungimu";
        },
        "SELECTED_COLOR": () => {
            return "Pasirinkta spalva:";
        },
        "CLASSROOM_PROFESSORS": () => {
            return "Pakviesti mokytojai";
        },
        "CLASSROOM_STUDENTS": () => {
            return "Pakviesti mokiniai";
        },
        "DRAG_DROP_FILES": () => {
            return "Nuvilkti failus čia";
        },
        "INVITE_PROFESSORS": () => {
            return "Pridėti mokytoją";
        },
        "ADD_FOLDER": () => {
            return "Pridėkite naują dokumentą arba aplanką";
        },
        "NO_FILES_SELECTED": () => {
            return "Šiuo metu nepasirinkta įkelti jokių failų";
        },
        //DYNAMIC MENU
        "LANGUAGE_MODAL_LABEL": () => {
            return "Pasirinkite kalbą";
        },
        "CLASSROOM_ADDED_ERROR": () => {
            return `Įvyko klaida kuriant naują klasę`
        },
        "CLASSROOM_ADDED": () => {
            return `Klasė sukurta sėkmingai`;
        },
        "CLASSROOM_DELETE": () => {
            return `Klasė panaikinta sėkmingai`;
        },
        "QUESTIONS": () => {
            return "klausimų";
        },
        "MENU_USER_LOGGED": (params) => {
            const { upperCaseLanguage, user, section } = params;
            let classroomsLink = null;
            if (user) {
                //you are a professor
                if (user.isProfessor) {
                    classroomsLink = user.isConfirmedProfessor ? `${urls.professor_classrooms}` : `${urls.not_confirmed_professor_landing}`
                }
                else {
                    classroomsLink = `${urls.student_classrooms}`;
                }
            }

            window.$("#navbarCollapse").html(`<div class="navbar-nav ms-auto Text500__5">
                <a href="/" class="nav-item nav-link ${(section === "library" ? "active" : "")}">Biblioteka</a>
                <a href="${classroomsLink}" class="nav-item nav-link ${(section === "classrooms" ? "active" : "")}">Klasės</a>
                                <a href="/aboutANNA" class="nav-item nav-link ${(section == "aboutANNA" ? "active" : "")}">Apie ANNA</a>

                <a href="${urls.account}/" class="nav-item nav-link ${(section === "settings" ? "active" : "")}">Paskyra</span>
                <strong><a href="${urls.logout}" class="nav-item nav-link">Atsijungti</a></strong>
                ${urls.language_selector.replace("##LANGUAGE##", upperCaseLanguage)}
              </div>`);

        },
        "MENU_USER_NOT_LOGGED": (params) => {
            const { upperCaseLanguage, section } = params;

            window.$("#navbarCollapse").html(`<div class="navbar-nav ms-auto Text500__5">
                <a href="/" class="nav-item nav-link ${(section === "home" ? "active" : "")}">Pagrindinis</a>
                <a href="/aboutANNA" class="nav-item nav-link">Apie ANNA</a>
                <a href="/contactus" class="nav-item nav-link" ${(section === "contacts" ? "active" : "")}>Kontaktai</a>
                <strong><a href="/login" class="nav-item nav-link" id="LoginButtonNav">Prisijungti</a></strong>
                <strong><a href="/register" class="nav-item nav-link" id="RegisterButtonNav">Registracija</a></strong>
                ${urls.language_selector.replace("##LANGUAGE##", upperCaseLanguage)}
                <a href="/register" id="try-button-menu" class="then-small-button ml-5" >Išmėgink nemokamai</a>
            </div>`);



        },
        "FOOTER_BOTTOM_MENU_LOGGED": () => {
            window.$("#rightsReserved").html(`
               &copy; <a href='https://www.labh9.eu' target='_blank' rel="noopener noreferrer">ANNA</a>, Visos teisės saugomos.
            `);
            window.$("#secondFooterColumn").html(`
                                <div class="linkRowFooter">
                                    <a href="/" class="linkFooter">Biblioteka</a>
                                    <a href="https://www.labh9.eu" target='_blank' rel="noopener noreferrer" class="linkFooter">Apie</a>
                                    <a href="/contactus" class="linkFooter">Kontaktai</a>
                                    <a href="/terms_and_conditions" class="linkFooter">Naudojimosi taisyklės</a>
                                    <a href="/privacy_policy" class="linkFooter">Privatumo politika</a>
                                    <a href='https://www.labh9.eu'  class="linkFooter" target='_blank' rel="noopener noreferrer">Powered by<img class="footerPoweredLogo"  src="/Labh9logo.png"></img></a>
                                </div>  
            `);
        },
        "FOOTER_BOTTOM_MENU_NOT_LOGGED": () => {
            window.$("#rightsReserved").html(`
             
                                &copy; <a href='https://www.labh9.eu' target='_blank' rel="noopener noreferrer">ANNA</a>, Visos teisės saugomos.
                          
            `);
            window.$("#secondFooterColumn").html(`
                 <div class="linkRowFooter">
                                    <a href="https://www.labh9.eu" class="linkFooter" target='_blank' rel="noopener noreferrer">Apie</a>
                                    <a href="/contactus" class="linkFooter">Kontaktai</a>
                                    <a href="/terms_and_conditions" class="linkFooter">Naudojimosi taisyklės</a>
                                    <a href="/privacy_policy" class="linkFooter">Privatumo politika</a>
                                    <a href='https://www.labh9.eu'  class="linkFooter" target='_blank' rel="noopener noreferrer">Powered by<img class="footerPoweredLogo"  src="/Labh9logo.png"></img></a>

                                </div>  
            
            `);
        },

        "PASSWORD_REQUIREMENTS": () => {
            window.$("#password-requirements").html(`
               
                                        <p>Slaptažodis turi atitikti šiuos reikalavimus:</p>
                                        <ul>
                                            <li>Bent 8 simboliai</li>
                                            <li>Bent 1 didžioji raidė</li>
                                            <li>Bent 1 mažoji raidė</li>
                                            <li>Bent 1 skaičius</li>
                                            <li>Bent vienas spec. simbolis</li>
                                        </ul>
                                    </div>
            
            `);
        },
        "ENTER_NAME": () => {
            return "Prašome įvesti savo vardą.";
        },
        "ENTER_EMAIL": () => {
            return "Prašome įvesti savo el. paštą.";
        },
        "ENTER_LASTNAME": () => {
            return "Prašome įvesti savo pavardę.";
        },
        "ENTER_PASSWORD": () => {
            return "Prašome įvesti savo slaptažodį.";
        },
        "ENTER_BIRTHDATE": () => {
            return "Prašome įvesti tinkamą gimimo datą.";
        },
        "ENTER_COUNTRY": () => {
            return "Prašome įvesti savo šalį.";
        },
        "ENTER_CONFIRM_PASSWORD": () => {
            return "Prašome patvirtinti savo slaptažodį.";
        },
        "ENTER_SCHOOL": () => {
            return "Prašome įvesti savo mokyklą.";
        },
        "PASS_REQS": () => {
            return "Slaptažodis turi atitikti šiuos reikalavimus:";
        },
        "PASS_REQS1": () => {
            return "Bent 8 simboliai";
        },
        "PASS_REQS2": () => {
            return "Bent 1 didžioji raidė";
        },
        "PASS_REQS3": () => {
            return "Bent 1 mažoji raidė";
        },
        "PASS_REQS4": () => {
            return "Bent 1 skaitmuo";
        },
        "PASS_REQS5": () => {
            return "Bent 1 specialusis simbolis";
        },

        "WHOAREYOU_MODAL": () => {
            return "Registruotis kaip: "
        },
        "NORMAL_USER": () => {
            return "Vartotojui"
        },
        "PROFESSOR_USER": () => {
            return "Mokytojui"
        },
        "USER_PROFILE": () => {
            return "Tavo profilis"
        },
        "ADD_MODAL_ELEMENT": () => {
            return "Ką norite pridėti?"
        },
        "UPLOAD": () => {
            return "Įkelti "
        },
        "CLEAR": () => {
            return "Atšaukti "
        },
        "PRESS_MIC_TO_UPLOAD": () => {
            return "Spauskite mikrofono ikoną, kad įrašytumėte audio"
        },
        "UPLOAD_TRANSCRIBE": () => {
            return "Įkelti"
        },
        "OR": () => {
            return "arba"
        },
        "YOU_HAVE_BEEN_INVITED_IN_CLASS": (params) => {
            const { classroom } = params;

            return `gavote kvietimą į klasę ${classroom}`;
        },
        "REMOVE_STUDENT_HEADER": () => {
            return `Studentų atšaukimas`;
        },
        "REMOVE_STUDENT_BODY": () => {
            return `Mokinys bus pašalintas iš klasės,ar tikrai?`;
        },
        "AUDIO_EXPLANATION": () => {
            return "Paspauskite mygtuką, kad pradėtumėte įrašymą, ir atleiskite, kai norite tęsti siuntimą"
        },
        "AUDIO_FILE_PROGRESS": () => {
            return "Aš analizuoju garsą..."
        },
        "REMOVE_STUDENT_BUTTON": () => {
            return "Pašalinti studentą";
        },
        "STUDENT_DELETE_DONE": () => {
            return "atšauktas studentas";
        },
        "STUDENT_DELETE_SUCCESSFULLY": () => {
            return "Mokinys sėkmingai pašalintas iš klasės";
        },
        "STUDENT_DELETE_PROBLEM": () => {
            return "Operacijos metu kilo tam tikrų problemų, studentas nepašalintas";
        },
        "NO_CLASS_FOR_CODE": () => {
            return "Su šiuo kodu nerandama klasė";
        },
        "USER_JUST_IN_CLASSROOM": () => {
            return "Naudotojas kaip tik yra klasėje arba laukia prieigos";
        },
        "MISSING_CODE": () => {
            return "Kodas yra privalomas norint išsiųsti užklausą";
        },
        "DOCUMENT_NAME": () => {
            return "Dokumento pavadinimas";
        },
        "UPLOADED_IN": () => {
            return "Įkelta";
        },
        "ELEMENT_TYPE": () => {
            return "Tipas";
        },

        "PROFESSOR_NAME": () => {
            return "Profesorius";
        },
        "EMAIL_PROFESSOR": () => {
            return "El. paštas";
        },
        "MOBILE_PHONE": () => {
            return "Telefonas";
        },
        "PROFESSOR_ACTION": () => {
            return "Pašalinti";
        },
        "REMOVE_PROFESSOR_HEADER": () => {
            return `Pašalinti profesorių`;
        },
        "REMOVE_PROFESSOR_BODY": () => {
            return `Ketinate pašalinti mokytoją iš klasės, ar tikrai norite tai daryti?`;
        },
        "REMOVE_PROFESSOR_BUTTON": () => {
            return `Pašalinti`;
        },
        "PROFESSOR_DELETE_DONE": () => {
            return "Išbrauktas profesorius";
        },
        "PROFESSOR_DELETE_SUCCESSFULLY": () => {
            return "Mokytojas sėkmingai pašalintas iš klasės";
        },
        "PROFESSOR_DELETE_PROBLEM": () => {
            return "Šalinant įvyko klaida, naudotojas nebuvo pašalintas";
        },
        "GO_TO_LIBRARY": () => {
            return "Dokumentas";
        },
        "CLASSROOM_CODE_LABEL_PROFESSOR": (params) => {

            const { code } = params;
            return (<span>
                Klasės identifikavimo kodas<strong>{code ?? ""}</strong>
            </span>);
        },
        "NO_DOCUMENTS_GRID": () => {
            return ("Klasėje nėra dokumentų");
        },
        "NO_PROFESSOR_GRID": () => {
            return ("Klasėje nėra mokytojo");
        },
        "NO_STUDENT_GRID": () => {
            return ("Klasėje nėra mokinių");

        },
        "CLASSROOM_LIBRARY": () => {
            return "Klasės biblioteka";
        },
        "OTP_MODAL_DELETE_TITLE": () => {
            return "Ar tikrai norite ištrinti naudotoją?";
        },
        "EDIT_CARD": () => {
            return `Išsaugoti `;
        },
        "WELL_DONE": () => {
            return "Šauniai padirbėta!";
        },
        "ALL_CORRECT": (params) => {
            const { maxScore } = params;

            return `Sveikiname, teisingai atsakėte į visus ${maxScore} klausimus!`;
        },
        "TOPIC_EXPERT": () => {
            return "Dabar esate šios temos ekspertas";
        },
        "GOOD_JOB": () => {
            return "Šaunu!";
        },
        "SOMEONE_CORRECT": (params) => {
            const { actualScore, maxScore } = params;
            return `Sveikiname, jūs teisingai atsakėte į ${actualScore} iš ${maxScore} klausimų!`;
        },
        "SOMEONE_CORRECT_SAD": (params) => {
            const { actualScore, maxScore } = params;
            return `Jūs teisingai atsakėte į ${actualScore} iš ${maxScore} klausimų!`;
        },
        "TEST_TRY_AGAIN": () => {
            return "Patikrinkite neteisingus atsakymus ir bandykite dar kartą!";
        },
        "STUDY_MORE": () => {
            return "Jums reikia daugiau mokytis";
        },
        "ANSWER_NOT_SELECTED": () => {
            return "Atsakymas nebuvo pasirinktas!";
        },
        "YOU_ANSWERED": () => {
            return "Jūs atsakėte";
        },
        "BACK_TO_QUESTIONS": () => {
            return "Atgal į klausimus";
        },
        "LEAVE_CLASSROOM": () => {
            return "Leave the classroom";
        },
        "LEAVE_CLASSROOM_MESSAGE": () => {
            return "Are you sure that you want to leave this class?";
        },
        "LEAVE_CLASSROOM_BUTTON": () => {
            return "Leave the class";
        },
        "PAGES_LIST_WITHPAGE": (params) => {
            const { pages = [] } = params;
            var pageNumbers = pages ? pages.join(",") : "-"

            return (<span>{pages?.length > 1 ? "Puslapiai" : "Puslapyje"}: {pageNumbers}</span>)

        },
        "SHARE_RESULT": () => {
            return "Pasidalykite savo rezultatais";
        },
        "CONTEXT_HELP": () => {
            return "Patarimas: ";
        },
        "ANSWER": () => {
            return "Atsakyti: ";
        },
        "ANSWERES_CHECKED": () => {
            return `Patikrinti atsakymai!`;
        },
        "CHECKING_ANSWERS": () => {
            return "Tikrinu atsakymus";
        },
        "DELETE_ELEMENT_MODAL": () => {
            return "Ketinate ištrinti elementą";
        },
        "REMOVE_ELEMENT_BODY_MODAL": (params) => {
            const { elementName } = params

            return `Jūs ketinate visam laikui pašalinti ${elementName} iš savo bibliotekos.
             Šis veiksmas negrįžtamas, ar tikrai norite tęsti?`;
        },
        "REMOVE_ELEMENT_BUTTON": () => {
            return "Ištrinti";
        },
        "USER_PROFILE_SELECT_DESCRIPTION": () => {
            return "Peržiūrėkite ir tvarkykite savo paskyros duomenis";
        },
        "PAYMENT_DETAILS": () => {
            return "Apmokėjimo istorija";
        },
        "PAYMENT_DETAILS_DESCRIPTION": () => {
            return "Peržiūrėkite visus su apmokėjimais susijusius duomenis";
        },
        "SUBSCRIPTION_DETAILS": () => {
            return "Prenumerata";
        },
        "SUBSCRIPTION_DETAILS_DESCRIPTION": () => {
            return "Peržiūrėkite visus su prenumerata susijusius duomenis - trukmę, galiojimo laiką, kiek paslaugų išnaudojote";
        },
        "USER_PROFILE_SELECT": () => {
            return "Paskyros nustatymai";
        },
        "ENTER_BUTTON": () => {
            return "Eiti";
        },
        "ACCOUNT_DETAILS": () => {
            return "Paskyra";
        },
        "ACCOUNT_DETAILS_DESC": () => {
            return "Čia galite pamatyti visus Jūsų paskyros duomenis. Tai pat juos galite ir redaguoti.";
        },
        "USER_SETTINGS": () => {
            return "Paskyros nustatymai";
        },
        "USER_SETTINGS_DESC": () => {
            return "Čia galite pamatyti visą informaciją, susijusią su Jūsų paskyra - duomenys, prenumeratos, apmokėjimai";
        },
        "SUBSCRIPTION_SETTINGS": () => {
            return "Prenumerata";
        },
        "SUBSCRIPTION_SETTINGS_DESC": () => {
            return "Čia galite sekti visą su prenumerata susijusią informaciją - paslaugas, datas ir t.t.!"
        },
        "CANCEL": () => {
            return "Atšaukti"
        },
        "CANCELLED": () => {
            return "Atšaukta"
        },
        "TERMS_AND_CONDITIONS": () => {
            return "Naudojimosi taisyklės"
        },
        "DELETE_ACCOUNT": () => {
            return "Ištrinti paskyrą"
        },
        "FIRST_NAME": () => {
            return "Vardas"
        },
        "ACCOUNT_TYPE": (params) => {
            var { accountType } = params;
            if (accountType === "Student") {
                accountType = "Studentas";
            }
            else if (accountType === "Professor") {
                accountType = "Mokytojas";
            }
            return `${accountType}`;
        },
        "SAVE": () => {
            return "Išsaugoti"
        },
        "ACCOUNT_TYPE_LABEL": () => {
            return "Paskyros Tipas"
        },
        "DELETE_ACCOUNT_BODY": () => {
            return "Ar tikrai norite ištrinti savo paskyrą? Ištrindami paskyrą, tai pat ir ištrinsite visus įkeltus dokumentus, klausimų rinkinius ir minčių žemėlapius!"
        },
        "DELETE_ACCOUNT_TITLE": () => {
            return "Ar esate įsitikinę?"
        },
        "YES": () => {
            return "Taip"
        },
        "NO": () => {
            return "Ne"
        },
        "TO_CHANGE_EMAIL": () => {
            return "Jeigu norite pakeisti el. paštą, prašome kreiptis į svetainės pagalbą!"
        },
        "SUBSCRIPTION_PLAN": () => {
            return "Prenumeratos planas:"
        },
        "QUESTIONS_ASKED": () => {
            return "Užduoti klausimai:"
        },
        "TESTS_GENERATED": () => {
            return "Sugeneruoti testai:"
        },
        "MINUTES_OF_SPEECH": () => {
            return "Kalbos į tekstą išnaudotos minutės:"
        },
        "PAGES_UPLOADED": () => {
            return "Dokumentų puslapių įkelta:"
        },
        "SUBSCRIPTION_EXPIRES": () => {
            return "Prenumerata galioti baigia:"
        },
        "COUNTER_RESET": () => {
            return "Skaitikliai nustatomi iš naujo:"
        },
        "NO_DATA_AVAILABLE": () => {
            return "Nėra tokio tipo prenumeratos"
        },
        "ORDER": () => {
            return "UŽSAKYTI"
        },
        "WEEKLY": () => {
            return "Savaitinė"
        },
        "MONTHLY": () => {
            return "Mėnesinė"
        },
        "YEARLY": () => {
            return "Metinė"
        },
        "DURATION_SUB": (params) => {
            var { expirationUnit } = params;
            if (expirationUnit === "year") {
                expirationUnit = "metams";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "month") {
                expirationUnit = "mėnesiui";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "week") {
                expirationUnit = "savaitei";
                return `${expirationUnit}`;
            }
            return `${expirationUnit}`;
        },
        "CATALOGUE": () => {
            return `Prenumeratų katalogas`;
        },
        "CATALOGUE_HEADER": () => {
            return `Pasirinkite norimą prenumeratą`;
        },
        "CHECKOUT_HEADER": () => {
            return `Apmokėjimas`;
        },
        "SELECT_METHOD": () => {
            return `Pasirinkite apmokėjimo būdą:`;
        },
        "PROCEED_CHECKOUT": () => {
            return `Pradėkite apmokėjimą`;
        },
        "LOGIN_PROCEED": () => {
            return `Privalote būti prisijungęs, norint apmokėti!`;
        },
        "DONT_HAVE_ACC": () => {
            return `Neturite paskyros?`;
        },
        "DONT_HAVE_ACC2": () => {
            return `Užsiregistruok!`;
        },
        "SUBSCRIPTION_SUCCESSFUL": () => {
            return "Prenumerata sėkminga!";
        },
        "SUBSCRIPTION_ERROR": () => {
            return "Prenumerata nesėkminga!";
        },
        "LIBRARY_WELCOME_MESSAGE_NUMEBR": (params) => {
            const { user, docs, folders } = params;
            const number = docs?.length || 0 + folders?.length || 0;

            if (number > 0) {
                if (number > 1) {
                    return (<span> Jūsų bibliotekoje yra  {number} dokumentų</span>);
                }
                else {
                    return (<span> Jūsų bibliotekoje yra {number} dokumentųas</span>);
                }
            }
            else {
                return (<span>Jūsų biblioteka yra tuščia</span>);
            }
        },
        "WIP": () => {
            return `Apdorojimas...`;
        },
        "PAYMENT_DETAILS_DESC": () => {
            return "Čia galite matyti ir valdyti savo mokėjimus";
        },
        "PAYPAL_CHECKOUT_ERROR": () => {
            return "Mokėjimo proceso metu įvyko klaida, bandykite dar kartą!";
        },
        "SUBSCRIPTION_IN_PROGRESS": () => {
            return "Prašome palaukti, prenumerata apdorojama...s";
        },
        "LANGUAGE_NOT_FOUND": () => {
            return "Kalba nerasta";
        },
        "NO_PAYMENTS": () => {
            return "Mokėjimų nerasta";
        },
        "RETURN_TO_TEST": () => {
            return "Grįžti į testą";
        },
        "TRAINING_HEADER": (params) => {
            const { type } = params;
            if (type === "document") {
                return "DOKUMENTO TESTAI";
            }
            if (type === "folder") {
                return "APLANKO TESTAI";
            }
        },
        "FILE_TYPE_NOT_SUPPORTED": () => {
            return "Failo tipas neleistinas";
        },
        "CHANGE": () => {
            return "Pakeisti";
        },
        "Q&A_HEADER": () => {
            return "Klausk ir atsakysiu";
        },
        "QUESTION_TYPES": () => {
            return "Klausimų tipas";
        },
        "QUESTION_NUMBER": () => {
            return "Klausimų skaičius";
        },
        "QUESTION_CHOICE": () => {
            return "Atsakymų pasirinkimas";
        },
        "YOUR_ANSWER": () => {
            return "JŪSŲ ATSAKYMAS";
        },
        "NOT_ANSWERED": () => {
            return "Klausimas nebuvo atsakytas";
        },
        "CORRECT_ANSWER": () => {
            return "TEISINGAS ATSAKYMAS";
        },
        "CORRECT_ANSWER_SMALL": () => {
            return "Teisingas atsakymas";
        },
        "PAGES_ANSWER": () => {
            return "Puslapiai: ";
        },
        "RETURN_TO_TEST_NEW": () => {
            return "Bandyti iš naujo";
        },
        "DOCUMENT_PAGE_HEADER": () => {
            return "PAKELK SAVO DOKUMENTĄ Į KITĄ LYGĮ";
        },
        "CLASSROOM": () => {
            return "Klasės";
        },
        "COPY_SUMMARY": () => {
            return "Copy";
        },
        "COUNTRY": () => {
            return "Šalis";
        },
        "BOOKS_TRANSFORM_SUPERPOWERS": () => {
            return "IDEALI STUDIJŲ DRAUGĖ!";
        },
        "BOOKS_TRANSFORM_SUPERPOWERS_ANNA": () => {
            return "ANNA suteikia įrankius, kurie padės Tau pasiekti užsibrėžtų tikslų. ANNA - mokytoja, kuri suteikia galimybę bendrauti su knygomis ir dokumentais, taip padėdant Tau mokytis geriau!";
        },
        "TRY_ANNA": () => {
            return "Išbandyk ANNA nemokamai";
        },
        "USED_BY_STUDENTS": () => {
            return "naudojasi + 10 000 studentų";
        },
        "THEY_TALKED_ABOUT_US": () => {
            return "Jie jau kalbėjo apie mus";
        },
        "BOOST_STUDY_WITH_AI": () => {
            return "Mokykis paprasčiau su AI";
        },
        "LANDING_FUNCTIONS_DESC": () => {
            return "Su ANNA mokslas bus lengvesnis. Štai trys pagrindinės funkcijos, su kuriomis mokytis - patogiau:";
        },
        "LANDING_FUNCTIONS_DESC_CARD_1": () => {
            return (<>Bendrauk su <strong>ANNA</strong> ir klausk visko apie įkeltus dokumentus! Jis pateiks Jums visus reikiamus atsakymus!</>);
        },
        "LANDING_FUNCTIONS_DESC_CARD_2": () => {
            return (<>Sukurk <strong>minčių žemėlapį</strong> iš bet kokio dokumento. Aiškiai ir organizuotai vizualizuotos temos padės Jums mokytis lengviau.</>);
        },
        "LANDING_FUNCTIONS_DESC_CARD_3": () => {
            return (<><strong>Patikrink savo žinias</strong> su ANNA egzaminų ir testų generavimu. Gauk grįžtamąjį ryšį ir sužinok, kur tobulėti, kad visada būtum pasirengęs tikriems testams.</>);
        },
        "LANDING_STUDENTS_TITLE": () => {
            return (<>Kodėl mokiniai myli ANNA?</>);
        },
        "LANDING_STUDENTS_SUBTITLE": () => {
            return (<>Atrask naujus mokymosi būdus su ANNA! Naudodamiesi mūsų dirbtiniu intelektu paremtais įrankiais galite:</>);
        },
        "LANDING_STUDENTS_LIST_1": () => {
            return (<>Kurti minčių žemėlapius, kurie supaprastina sudėtingas temas.</>);
        },
        "LANDING_STUDENTS_LIST_2": () => {
            return (<>Klausti apie mokymosi medžiagą ir gauti atsakymus tiesiogiai.</>);
        },
        "LANDING_STUDENTS_LIST_3": () => {
            return (<>Kurti egzaminų imitacijas ir atrasti kur galima tobulėti.</>);
        },
        "LANDING_STUDENTS_LIST_4": () => {
            return (<>Įkelti ir mokytis iš knygų, dokumentų, garso ir vaizdo įrašų, nuotraukų ir daugiau!</>);
        },
        "LANDING_STICKER_TEACHER_TEXT": () => {
            return "mokytojams";
        },
        "LANDING_STICKER_STUDENT_TEXT": () => {
            return "mokiniams";
        },
        "LANDING_TEACHER_TITLE": () => {
            return (<>Mokytojai, štai jūsų supergalia!</>);
        },
        "LANDING_TEACHER_SUBTITLE": () => {
            return (<>ANNA yra ne tik pagalbininkė mokiniams, bet ir vertinga sąjungininkė mokytojams. Naudodamiesi mūsų įrankiais galite:</>);
        },
        "LANDING_TEACHER_LIST_1": () => {
            return (<>Vos keliais spustelėjimais įkelti mokomąją medžiagą ir kurti interaktyvią veiklą.</>);
        },
        "LANDING_TEACHER_LIST_2": () => {
            return (<>Stebėti mokinių pažangą ir teikti asmeninį grįžtamąjį ryšį.</>);
        },
        "LANDING_TEACHER_LIST_3": () => {
            return (<>Sutaupyti laiko ruošiant pamokas su automatizuotomis funkcijomis.</>);
        },
        "LANDING_SUBSCRIPTIONS_TITLE": () => {
            return (<>Mokykis išmaniai, neiškratant piniginės!</>);
        },
        "LANDING_SUBSCRIPTIONS_SUBTITLE": () => {
            return (<>Su ANNA mokytis protingai gali kiekvienas. Peržiūrėk mūsų planus ir pasiimk sau patraukliausią!</>);
        },
        "LANDING_QUESTIONS_TITLE": () => {
            return (<>Turi klausimų? Mes turime atsakymus!</>);
        },
        "LANDING_QUESTIONS_DESCRIPTION": () => {
            return (<>Turi klausimų? Patekai ten kur reikia! Mes surinkome atsakymus į dažniausiai užduodamus klausimus, kurie padės Jums kuo geriau suprasti ANNA. Peržiūrėkite klausimus ir sužinokite, kaip ANNA gali paversti jūsų mokymąsi dar paprastesniu ir įdomesniu!</>);
        },
        "LANDING_QUESTIONS_LIST_0": () => {
            return (<>Kaip veikia Annos dirbtinis intelektas ir kaip jis gali man padėti?</>);
        },
        "LANDING_QUESTIONS_LIST_1": () => {
            return (<>Ar saugu naudoti ANNA ir kokius dokumentus galiu įkelti?</>);
        },
        "LANDING_QUESTIONS_LIST_2": () => {
            return (<>Ar dirbtinis intelektas gali pakeisti mokytojus? Ar ši priemonė tinkama visų tipų egzaminams bei mokykloms?</>);
        },

        "LANDING_QUESTIONS_LIST_0_ANSWER": () => {
            return (<>ANNA dirbtinis intelektas analizuoja įkeltus dokumentus (knygas, užrašus, skaidres, dalomąją medžiagą) ir atsako į visus jūsų klausimus apie sąvokas, datas, apibrėžimus ir konkrečias detales. Ji padeda Jums geriau suprasti mokymosi medžiagą ir susistemina svarbiausią informaciją, kad būtų lengviau suprasti ir įsiminti (pavyzdžiui į minčių žemėlapius). Taip pat galite kurti viktorinas ir testus,
                pasirinkti klausimų skaičių, atsakymo tipą (atviri ar uždari). Gauti išsamius atsakymų pataisymus, kad geriau įsisavintumėte informaciją.</>);
        },
        "LANDING_QUESTIONS_LIST_1_ANSWER": () => {
            return (<>Galite įkelti skaitmeninio formato knygas, užrašus, skaidres, konspektus ar kitą mokymosi medžiagą. Jūsų duomenų saugumas mums yra prioritetas:
                Jūsų dokumentai laikomi konfidencialiai ir nėra perduodami trečiosioms šalims. Taip pat norint naudotis šia priemone nereikia jokių techninių įgūdžių:
                tai paprasta ir intuityvu.</>);
        },
        "LANDING_QUESTIONS_LIST_2_ANSWER": () => {
            return (<>ANNA yra pagalbinė mokymosi priemonė, skirta mokymuisi tobulinti: padeda išsiaiškinti spragas, apibendrinti sąvokas ir imituoti testus.
                Tačiau ji nepakeičia mokytojo.
                Priemonė yra universali, tinka pasirengti bet kokio tipo egzaminams, tiek raštu, tiek žodžiu, padedanti efektyviai optimizuoti mokymąsi ir pasiruošimą.</>)
        },
       
        "CHOOSE_PLAN": () => {
            return (<>Pasirinkite planą</>);
        },
        "LANDING_MESSAGES_TITLE": () => {
            return (<>Visi kalba apie Aną</>);
        },
        "LANDING_MESSAGES_QUOTE1": () => {
            return (<>ANNA pakeitė mano mokymąsi! Minčių žemėlapiai ir testai padeda man geriau suprasti sąvokas ir geriau pasiruošti egzaminams. ANNA - svarbi mano mokslų dalis!</>);
        },
        "LANDING_MESSAGES_QUOTE1_AUTHOR": () => {
            return (<>Markas, 16 metų</>);
        },
        "LANDING_MESSAGES_QUOTE2": () => {
            return (<>ANNA dėka galiu įkelti mokomąją medžiagą ir sukurti interaktyvią veiklą per kelias minutes. Stebėti mokinių pažangą tapo daug lengviau, o grįžtamasis ryšys labai pagerino jų mokymąsi.</>);
        },
        "LANDING_MESSAGES_QUOTE2_AUTHOR": () => {
            return (<>Prof. Rossi, matematikos mokytoja</>);
        },
        "STUDENT": () => {
            return (<>Mokinys</>);
        },
        "TEACHER": () => {
            return (<>Mokytojas</>);
        },

        "PRIVACY_POLICY_TEXT": () => {
            return (
                <div className="container" style={{ height: '100%', textAlign: 'justify' }}>
                    <h1 className="test-boost">{getText()["PRIVACY_POLICY"]()}</h1>
                    <br></br>
                    <h3 className="test-boost">{getText()["PRIVACY_POLICY"]()}:</h3>
                    <p className='Text500__6'>UAB "Lab-H9" (hereinafter - the Data Controller) in this privacy policy (hereinafter - the Privacy Policy) sets the conditions for processing personal data using
                        this website managed by the Data Controller. The terms set out in the Privacy Policy apply every time you access the content and/or services we provide, regardless of the device (computer, mobile phone, tablet, TV, etc.) you are using.
                    </p>
                    <p className='Text500__7'>Details of the data controller:
                    </p>
                    <p className='Text500__7'>UAB "Lab-H9"
                    </p>
                    <p className='Text500__7'>Office address: Laisvės al. 82,
                    </p>
                    <p className='Text500__7'>Kaunas, LT-44250 Lietuva
                    </p>
                    <p className='Text500__7'>E-mail: <a href="mailto:anna@labh9.eu">anna@labh9.eu</a>
                    </p>
                    <p className='Text500__6'>It is very important that you read this Privacy Policy carefully, because every time you visit the <a href="https://www.labh9.ai" target='_blank' rel="noopener noreferrer">www.labh9.ai</a> website, you agree to the terms described in this Privacy Policy. If you do not agree to these terms, we regret to ask you not to visit our website, use our content and/or services. We confirm that the data of visitors to the website of the Data Controller will be collected in accordance with the requirements of the applicable legal acts of the European Union and the Republic of Lithuania and the instructions of the controlling authorities. All reasonable technical and administrative measures are in place to protect the data we collect about website visitors from loss, unauthorized use or alteration.
                        Individuals who are under the age of 16 may not submit any personal data through our website. If you are under the age of 16, you must obtain the consent of your parent or other legal guardian before submitting personal information.
                        The terms used in this privacy policy are understood as they are defined in the General Data Protection Regulation no. 2016/679.
                    </p>
                    <ul className='Text500__6'>
                        <li>name;
                        </li>
                        <li>surname;
                        </li>
                        <li>e-mail address.
                        </li>
                    </ul>
                    <h3 className="test-boost">Browsing data</h3>
                    <p>
                        We use local storage to manage security (at logon time) and to remember Your preferred language.
                    </p>
                    <p>
                        We will not share this information with anyone and it will only be stored on Your browser.
                    </p>
                    <p>
                        For access with third-party authentication systems (single-sign-on), such as Google and Facebook, it is possible that these systems use cookies and local storage and we refer You to the respective policies.
                    </p>
                    <ul className="Text500__5" style={{ textAlign: 'left' }}>
                        <li>
                            <a href="https://policies.google.com/technologies/cookies" target="_blank" rel='noopener noreferrer'>
                                Google Cookie Policy
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/privacy/policies/cookies" target="_blank" rel='noopener noreferrer'>
                                Facebook Cookie Policy
                            </a>
                        </li>
                    </ul>
                    <p>
                        To remind us that You have read the cookies policy, we will store a cookie on Your browser, which we will not share with anyone and which will only serve to prevent this message from appearing automatically every time You connect to the ANNA web.
                    </p>
                    <p>These are the local storage items and cookies managed by ANNA web site on its domain:</p>
                    <table className='cookies-table Text500__5 mb-3 mx-auto'>
                        <tbody>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Purpose</th>
                            </tr>
                            <tr>
                                <td>Cookie</td>
                                <td>cookieConsent</td>
                                <td>Remembering that You’ve read this cookie page</td>
                            </tr>
                            <tr>
                                <td>Local storage</td>
                                <td>_grecaptcha</td>
                                <td>Used for security purposes, during the registration, to prevent non-human (bot) interactions with the page</td>
                            </tr>
                            <tr>
                                <td>Local storage</td>
                                <td>UserAuth</td>
                                <td>Used for security purposes to store on Your browser Your identity data and token after You have logged in.</td>
                            </tr>
                            <tr>
                                <td>Cookie</td>
                                <td>active_language</td>
                                <td>Used to remember Your preferred language for the user interface</td>
                            </tr>
                        </tbody>
                    </table>
                    <h3 className="test-boost">Third party cookies</h3>
                    <p>
                        The Site uses statistical cookies (for advertising and marketing purposes) to monitor visits to the Site.
                        <br />These cookies and their processing are subject to your consent and therefore will be used only if you accept.
                    </p>
                    <p>
                        If you accept third party cookies please refer to this providers and privacy disclosures policies:
                    </p>
                    <ul>
                        <li>Google Analytics
                            <ul>
                                <li><a href="https://www.google.com/policies/privacy/partners/">Google's Privacy & Terms</a></li>
                                <li><a href="https://support.google.com/analytics/answer/6004245">Safeguarding your data</a></li>
                            </ul>
                        </li>
                        <li>Google TAG manager
                            <ul>
                                <li><a href="https://support.google.com/tagmanager/answer/9323295?hl=en">Data privacy and security</a></li>
                            </ul>
                        </li>
                        <li>META PIXEL
                            <ul>
                                <li><a href="https://www.facebook.com/privacy/policy/">Meta Privacy Policy</a></li>
                            </ul>
                        </li>
                    </ul>
                    <h3 className="test-boost">How we use your information</h3>
                    <p className='Text500__6'>We may process your personal data for the following purposes:
                    </p>
                    <ul className='Text500__6'>
                        <li>For the purpose of direct marketing*, send newsletters, provide individually tailored advertising and send messages, evaluate and analyze our market, customers, products and services (including collecting your opinion about products and services and customer organization of surveys).
                        </li>
                        <li>To administer the website;
                        </li>
                        <li>To improve your browsing experience by adapting this website to your personal needs;
                        </li>
                        <li>In other ways with your consent*
                        </li>
                        <p className='Text500__6'><i>*We inform you that you have the right to disagree or withdraw your consent at any time to process your data for the above purposes marked with an asterisk.</i>
                        </p>
                    </ul>
                    <h3 className="test-boost">Other information we collect</h3>
                    <p className='Text500__6'>We may also collect other information about you, your device or your use of our website content with your consent.</p>
                    <p className='Text500__6'>We undertake not to transfer your personal data to any unrelated third parties, except in the following cases:
                    </p>
                    <ul className='Text500__6'>
                        <li>If there is consent to the disclosure of personal data.
                        </li>
                        <li>To law enforcement institutions in accordance with the procedure provided by the legal acts of the Republic of Lithuania.
                        </li>
                    </ul>
                    <h3 className="test-boost">Your rights
                    </h3>
                    <ul className='Text500__6'>
                        <li>To know (to be informed) about the processing of your data (right to know);
                        </li>
                        <li>Familiarize yourself with your data and how they are processed (right of access);
                        </li>
                        <li>Demand correction or, taking into account the purposes of personal data processing, to supplement incomplete personal data (right to correction);
                        </li>
                        <li>To destroy your data or stop the processing of your data (except storage) (right to destruction and right to be forgotten);
                        </li>
                        <li>Demand that the Personal Data Controller restricts the processing of personal data for one of the legitimate reasons (right to restriction);
                        </li>
                        <li>You have the right to transfer data (right to transfer);
                        </li>
                        <li>Opt-out to the processing of personal data when this data is processed or intended to be processed for direct marketing purposes, including profiling as far as it relates to such direct marketing.
                        </li>
                    </ul>
                    <p className='Text500__6'>If you no longer want your personal data to be processed for the purpose of direct marketing, you can write to at the email address <a href="mailto:anna@labh9.eu">anna@labh9.eu</a> and do not agree to your personal data being processed for the purpose of direct marketing.
                        You can submit any request or instruction related to the processing of personal data to the Data Controller by e-mail. to the email address <a href="mailto:anna@labh9.eu">anna@labh9.eu</a>. Upon receiving such a request or instruction, the data controller shall, no later than within one month from the date of the request, provide a response and perform the actions specified in the request or refuse to perform them. If necessary, the specified period can be extended by another two months, depending on the complexity and number of requests. In this case, within one month from the date of receipt of the request, the Data Controller informs the subject of such an extension, together with the reasons for the delay.
                    </p>
                    <h3 className="test-boost">Third-party websites, services and products on our websites
                    </h3>
                    <p className='Text500__6'>The Data Controller's website may contain third-party banners, links to their websites and services over which the Data Controller has no control. The data controller is not responsible for the security and privacy of information collected by third parties. You must read the privacy statements that apply to third-party websites and services you use.
                    </p>

                    <p>If you want to delete all your data you can delete your account in the user profile page, and our system automatically will remove all the personal information</p>

                </div>


            );
        },
        "TERMS_AND_CONDITIONS_TEXT": () => {
            return (
                <div className="container" style={{ height: '100%', textAlign: 'justify' }}>
                    <h1 className="test-boost">{getText()["TERMS_AND_CONDITIONS"]()}</h1>
                    <br></br>
                    <h3 className="test-boost">{getText()["TERMS_AND_CONDITIONS"]()}:</h3>
                    <p className='Text500__6'>These Terms of Use apply to the use of the Platform of Lab-H9 (UAB Lab-H9). Please read these Terms of Use carefully in order to ensure that you are aware of your rights and obligations when using the Platform. You can download and print this document.
                    </p>
                    <p className='Text500__6'>These Terms of Use <i>inter alia</i>, describe the following aspects of the relationship between you and Lab-H9:
                    </p>
                    <ul className='Text500__6'>
                        <li>The provision of Study Material (article 7) and creating a Profile (article 5);</li>
                        <li>The functionalities of the Platform (articles 3- 6);</li>
                        <li>The use of the AI Summaries, Mindmaps and Q&A functionality (article 8);</li>
                        <li>The use of the Group Chats functionality (article 9);</li>
                        <li>The notice and take down procedure (article 14);</li>
                        <li>The limitation of liability of Lab-H9(article 15).</li>
                    </ul>
                    <h3 className="test-boost">Article 1. Definitions</h3>
                    <p className='Text500__6'>In these Terms of Use, the terms listed below have the following meaning:
                    </p>
                    <p className='Text500__6'>1.1. <strong>AI: </strong>software that uses artificial intelligence techniques to generate Summaries, Mindmaps and answers and tests in reference to documents uploaded by the user                </p>
                    <p className='Text500__6'>1.2. <strong>AI Answers:</strong> all the answers and instructions that AI generates and that are made accessible to the user via dedicated section on the Platform, in response to questions submitted by the user.</p>
                    <p className='Text500__6'>1.3. <strong>AI Q&A:</strong> a separate section on the Platform, which enables User to submit questions in reference to documents earlier uploaded, which are then answered by AI.</p>
                    <p className='Text500__6'>1.4. <strong>Mindmaps:</strong> a separate section on the Platform, which enables User to get a mindmap in reference to documents earlier uploaded, which are generated by AI </p>
                    <p className='Text500__6'>1.5. <strong>Test/Quiz:</strong> a separate section on the Platform, which enables User to test their learing skill to a specific document. All the questions are generated by AI</p>

                    <p className='Text500__6'>1.6. <strong>Information:</strong> all information placed on the Platform by Lab-H9 itself, including – but not limited to – the layout and look and feel of the Platform, the logos and trademarks as well as texts. The Information does not include Study Materials.</p>

                    <p className='Text500__6'>1.7. <strong>Intellectual Property Rights:</strong> any and all intellectual property rights and associated rights such as copyrights, trademark rights, patent rights, model rights, trade name rights, database rights and neighbouring rights as well as the rights to know-how and one-line performances.</p>
                    <p className='Text500__6'>1.8. <strong>Platform:</strong> the online environment developed and offered by Lab-H9, which enables User from around the world to upload their documents and interact with them through an AI model. The Platform contains a separate AI, Summaries, Q&A, mindmap and Test sections  The Platform can be accessed through the websites <a href="https://anna.labh9.eu" target='_blank' rel="noopener noreferrer">anna.labh9.eu;</a> including all underlying domains and web pages, as well as mobile versions of the website and the Lab-H9 application for tablets and smartphones (“App”).
                    </p>
                    <p className='Text500__6'>1.9. <strong>Premium Account: </strong>a subscription that gives User access to the most functionalities on the Platform, as well as to specific features </p>
                    <p className='Text500__6'>1.10. <strong>Profile: </strong>an individual section created and maintained by you when you register for the Platform.</p>
                    <p className='Text500__6'>1.11. <strong>USER: </strong>a User of the Platform.</p>
                    <p className='Text500__6'>1.12. <strong>Documents:</strong> all the study documents, summaries, notes, assignments, books and other materials that are uploaded by User in his own account.</p>
                    <p className='Text500__6'>1.13. <strong>Terms of Use:</strong> these Terms of Use</p>
                    <h3 className="test-boost">Article 2. Applicability</h3>
                    <p className='Text500__6'>2.1. These Terms of Use govern every use of the Platform. By using the Platform, you accept these Terms of Use. The applicability of any other terms and conditions is excluded.</p>
                    <p className='Text500__6'>2.2. Lab-H9 is entitled to amend or supplement these Terms of Use. The most up-to-date Terms of Use can be found on the Platform. In the event of an amendment or supplementation that significantly affect your rights or obligations, Lab-H9 will bring these changes to your attention during the use of the Platform.</p>
                    <p className='Text500__6'>2.3. In the event that you continue the use of the Platform after these Terms of Use have been amended or supplemented, you thereby irrevocably accept the amended or supplemented Terms of Use. If you do not agree with the amended or supplemented Terms of Use, the only option you have is to no longer use the Platform and to terminate your Profile.
                    </p>
                    <h3 className="test-boost">Article 3. Availability</h3>
                    <p className='Text500__6'>3.1. You accept that the Platform contains only the functionalities and other characteristics as found on the Platform at the time of use ("as is basis"). Lab-H9 expressly excludes any explicit and tacit guarantees, undertakings and warranties of any nature whatsoever including, but not limited to, with regard to the quality, safety, lawfulness, integrity and accuracy of the Platform, unless explicitly stated otherwise in these Terms of Use.</p>
                    <p className='Text500__6'>3.2. You accept that the functionalities of the Platform may change.
                    </p>
                    <p className='Text500__6'>3.3. Lab-H9 is entitled to put the Platform (temporarily) out of service and/or to reduce the use of it without any prior notification and without being obliged to pay compensation to you, if in the opinion of Lab-H9 this is necessary, for instance, for maintenance of the Platform.
                    </p>
                    <p className='Text500__6'>3.4. Lab-H9 does not guarantee that the Platform or any part thereof will be accessible at all times and without any interruptions or failures. Failures in the Platform can occur as a result of failures in the internet or phone connection or by viruses or faults/defects. Lab-H9 is not liable or obliged to User to pay compensation in any way whatsoever for any damage resulting or arising from the website being (temporarily) unavailable.
                    </p>
                    <p className='Text500__6'>3.5. Lab-H9 is at any time entitled to change, alter or shut down the Platform without becoming liable to you in any way. If you do not agree with the changes and/or amendments made by Lab-H9, your only remedy is to cease the use of the Platform and to delete your Profile.
                    </p>
                    <h3 className="test-boost">Article 4. The Platform </h3>
                    <p className='Text500__6'>4.1. Lab-H9 offers a Platform where User can upload their Materials , such as documents essay, books and so forth and interact with them using AI. The interaction with the AI generates summaries, mindmaps, Q&A and test  8). Lab-H9’s mission is to empower User to excel at their studies by providing the best tools to learn more efficiently.
                    </p>
                    <p className='Text500__6'>4.2. The usage of the platform is based on a “freemium” model.
                    </p>
                    <p className='Text500__6'>4.3. Some Premium functions are only available for User with a Premium Account. Certain functionalities and features of the Platform require a Premium Account.
                    </p>
                    <p className='Text500__6'>4.4. The Platform may contain links to third party websites that are not owned or controlled by Lab-H9. Lab-H9 has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites.
                    </p>
                    <h3 className="test-boost">Article 5. Profile</h3>
                    <p className='Text500__6'>5.1. In order to access to Premium functionalities you have to create a Profile in the way described on the Platform. When creating a Profile, you must give an email address and password which will allow you to access the Platform and your Profile. You can also register using your existing Facebook or Google-account.
                    </p>
                    <p className='Text500__6'>5.2. You are only allowed to use the Platform when aged sixteen (16) or older. In the event that you have not yet reached the age of sixteen (16), you accept that you need the consent of your legal representative(s) (your guardian or (one of) your parents) before creating a Profile and/or taking out a subscription.

                    </p>
                    <p className='Text500__6'>5.3. You are responsible for maintaining secrecy with regard to your registration details. As soon as you know or have reason to assume that your username and/or password has come into the hands of unauthorized persons, you must inform Lab-H9 of this, without prejudice to your own obligation to take immediate effective measures yourself such as changing your password. You accept and acknowledge that you are at all times responsible and liable for the use of the Platform by third parties via your Profile.
                    </p>
                    <p className='Text500__6'>5.4. The personal data you provide during the registration process will be processed in accordance with Lab-H9's Privacy Policy Statement.
                    </p>
                    <p className='Text500__6'>5.5. You acknowledge and agree that by creating a Profile your user name, university and (if provided) profile picture are visible to other User if you post Study Materials and/or place comments, unless you choose to post Study Materials and/or partake in Study Communities anonymously.
                    </p>
                    <p className='Text500__6'>5.6 You acknowledge and confirm that you have all the rights on the document you upload and interact with on the platform
                    </p>
                    <h3 className="test-boost">Article 6. Premium Account</h3>
                    <p className='Text500__6'>6.1. User can obtain Premium access by taking out a dedicated subscription. The prices are communicated on the Platform.
                    </p>
                    <p className='Text500__6'>6.2. Payment is due in the manner described on the Platform.
                    </p>
                    <p className='Text500__6'>6.3. Lab-H9 offers a 7 days free trial subscription, which allows you to use Premium functionalities available on the Platform.. After 7 days, your trial subscription automatically extends into a weekly full Premium subscription, unless you cancel the subscription prior to that date. You can unsubscribe at any time via your Profile and no deductions will be made. After unsubscribing, you will retain access for the remainder of the trial period.
                    </p>
                    <p className='Text500__6'>6.4. It is possible that Lab-H9 offers longer/shorter free trial subscriptions in specific circumstances, such as actions for study societies. The applicable conditions will then be communicated by Lab-H9.
                    </p>
                    <p className='Text500__6'>6.5. Lab-H9 may introduce or change the subscription fees from time to time, for which we will give you advance notice. If your current subscription is still running, the price will remain in force for the fixed term. If you do not agree with the price change, you must cancel your subscription by the end of the then-current subscription term. If you do not cancel your subscription after the price change goes into effect, you agree to pay the changed price.
                    </p>
                    <p className='Text500__6'>6.6. Lab-H9 may suspend or cancel your Premium Account if a payment is not successfully settled.
                    </p>
                    <p className='Text500__6'>6.7. The subscription period will be renewed automatically for the selected subscription period, unless you have cancelled your subscription on time (before the last day of your subscription). In case your subscription is automatically renewed, you have the right to cancel the subscription at any time after such renewal with a cancellation term of one (1) month. In case of cancellation you may request reimbursement of part of the subscription fee. This means that you pay for the period until the cancellation enters into force, based on the standard monthly fee.
                    </p>
                    <h3 className="test-boost">Article 7. Documents/Materials           </h3>
                    <p className='Text500__6'>7.1. By means of the Platform User can upload their documents and material to be used for the learning process.

                    </p>
                    <p className='Text500__6'>7.2. You have the possibility to create folders, containing Study Materials selected by you relating to a certain course or subject. Folders or single documents are visible only to the User. Nothing will be shared with other users
                    </p>
                    <p className='Text500__6'>7.3. You understand and accept that you are responsible and accountable for Material you upload on the Platform. Lab-H9 is not liable to you or any third party for any damages arising out of or in relation to the Material uploaded or used by you within the Platform.
                    </p>
                    <p className='Text500__6'>7.4. You understand and accept that Lab-H9does not, and is under no obligation to, check or (re)view your Material. Lab-H9 only conducts an automated (quality) check to verify whether the Study Material contains sufficient texts and/or pages. Lab-H9has no knowledge of and/or influence on or involvement with the Materials uploaded by User.
                    </p>
                    <p className='Text500__6'>7.5. Lab-H9 reserves the right to convert the Materials you uploaded to another format and to automatically insert a watermark and/or advertisements in the materials.
                    </p>
                    <p className='Text500__6'>7.6. If you can delete your document/material at any time. The action cannot be reversed.
                    </p>
                    <p className='Text500__6'>7.7. Lab-H9 retains the right to decline or remove Material from the Platform, without accepting any responsibility or liability for its contents, without being held liable for any damage and without being obliged to pay any compensation.
                    </p>
                    <h3 className="test-boost">Article 8. AI Summaries, Mindmap, Q&A and Test/Quiz            </h3>
                    <p className='Text500__6'>8.1. By means of the separate AI Summaries, Mindmap, Q&A and Test/Quiz section on the Platform, User can interact with the uploaded document and answered by AI. The AI Answers will be available for User via a database in specific section on the Platform.
                    </p>
                    <p className='Text500__6'>8.2. To interact with all the sections, you will need to create a Profile.
                    </p>
                    <p className='Text500__6'>8.3. You accept and agree Lab-H9 solely facilitates the functionality, which enables User to use AI for their Studies.
                    </p>
                    <p className='Text500__6'>8.4. The Platform will specify the manner in which User can interact with the AI. Lab-H9 reserves the right to limit the availability of functionalities to User with a Premium Account even with Unlimited access or Unlimited usage. The limit, include – but it is not limited to a slow in AI reply or to a limit the number of questions can be submitted per day (or other time unit).
                    </p>
                    <p className='Text500__6'>8.5. AI will generate feedbacks within seconds. Lab-H9, however, does not guarantee that an the feedbacks are always provided within this time frame and does not accept liability in this regard.
                    </p>
                    <p className='Text500__6'>8.6. User agree that clear questions will generate a better AI output. Lab-H9 is not liable for any incorrect feedback
                    </p>
                    <p className='Text500__6'>8.7. The AI feedbacks are to be used as support in the learning process only, not as a substitute for a traditionl learning course. Lab-H9is not liable for the accuracy or completeness of the AI feedbacks.
                    </p>
                    <p className='Text500__6'>8.8. You understand and accept that you are responsible and accountable for use of feedbacks generated by AI. Lab-H9 is not liable to you or any third party for any damages arising out of, or in relation to them
                    </p>
                    <p className='Text500__6'>8.9. You understand and accept that Lab-H9 does not, and is under no obligation to, check or (re)view the AI feedbacks.
                    </p>
                    <h3 className="test-boost">Article 10. Your obligations and use of the Platform
                    </h3>
                    <p className='Text500__6'>10.1. You will only use the documents/materials that you will upload in the Platform
                    </p>
                    <p className='Text500__6'>10.2. You may not resell, distribute, or in any other way make profit of the Platform.
                    </p>
                    <p className='Text500__6'>10.3. You are the only responsible for documents you upload for instance (e-)books or any other material on the Platform.
                    </p>
                    <p className='Text500__6'>10.4. You are not permitted to use the Platform for a purpose as listed below, which is unrelated to the purpose of Lab-H9:
                    </p>
                    <ul className='Text500__6'>
                        <li>as your own work if it is not, as this is in breach of copyright. This is also plagiarism and constitutes academic misconduct at all academic institutions;
                        </li>
                        <li>which, at the discretion of Lab-H9, is discriminating or is otherwise deemed hurtful, offensive or inappropriate;
                        </li>
                        <li>in which personal data of others is made available, other than in compliance with applicable law;
                        </li>
                        <li>which contains scrapers, spiders, viruses, Trojan horses, worms, bots or other software which can damage or delete the Platform or make it unusable or inaccessible, or which or which is intended to circumvent technical protection measures of Lab-H9, the Website and/or the computer systems of Lab-H9;
                        </li>
                        <li>which infringes the rights of Lab-H9 and/or third parties, including but not limited to intellectual property rights or rights concerning the protection of privacy;
                        </li>
                        <li>which qualifies as or transmits unwanted or unsolicited material or content (spam);
                        </li>
                        <li>is contrary to these Terms of Use or any applicable laws or regulations, is in any other manner unlawful or which could prejudice the interests and reputation of lab-H9.
                        </li>
                    </ul>
                    <p className='Text500__6'>10.5. Lab-H9 will not be obliged to perform any payment, taxation, levy or deduction of any nature whatsoever to anyone as a result of the material uploaded by you, including to persons or parties whose work, model, brand or performance is included in the Materials, including to collective rights organizations.
                    </p>
                    <p className='Text500__6'>10.6. Lab-H9 reserves the right, without prior notice, to abridge, alter, refuse and/or remove Materials without this resulting in any liability.
                    </p>
                    <p className='Text500__6'>10.7. Lab-H9 is entitled to (temporarily) restrict or suspend your activities in connection with the Platform and/or to delete your Profile in the event you violate these Terms of Use.
                    </p>
                    <h3 className="test-boost">Article 11. Intellectual property rights
                    </h3>
                    <p className='Text500__6'>11.1. The Intellectual Property Rights in relation to the Platform, including the intellectual property rights with respect to the Information, are held by Lab-H9or its licensors. Nothing in the Terms of Use is intended to entail any transfer of Intellectual Property Rights to you.
                    </p>
                    <p className='Text500__6'>11.2. Under the conditions set out in these Terms of Use, Lab-H9 grants you a limited, personal, revocable, non-exclusive, non-sub licensable and non-transferable right to access and use the Platform, including the Information, insofar as is necessary for the use of the Platform.
                    </p>
                    <p className='Text500__6'>11.3. The collection of Materials or documents, that is collected by Lab-H9 and displayed on the Platform is a legally protected database. Lab-H9 is the producer of the database and therefore has the exclusive right to grant permission for i) the extraction or re-utilization of the entirety or a substantial part, from a quantitative and/or qualitative point of view, of the contents of the database and ii) the repeated and systematic extraction or re-utilization of non-substantial parts, from a quantitative and/or qualitative point of view, of the contents of the database, in so far as this is in conflict with the normal operation of the database or causes unjustified damage to lab-H9’s lawful interests. You may only request or reuse data from the database if and in so far as this is permitted under these Terms and Conditions.
                    </p>
                    <p className='Text500__6'>11.4. You retain all Intellectual Property Rights to your Material.
                    </p>
                    <p className='Text500__6'>11.5. You warrant that there are no (Intellectual Property) Rights of third parties which preclude you from use the Material uploaded on the Platform and/or providing the licence as mentioned in Article 11.4.
                    </p>
                    <p className='Text500__6'>11.6. You shall refrain from any act that infringes the Intellectual Property Rights of Lab-H9, including – but not limited to – the registration of domain names, trademarks or Google Adwords that are identical or similar to any object to which Lab-H9 holds Intellectual Property Rights, as well as the reproduction, modification, reverse engineering or publishing of the Platform, including the Information, for other purposes than those listed in the Terms of Use.
                    </p>
                    <h3 className="test-boost">Article 12. Indemnities and warranties
                    </h3>
                    <p className='Text500__6'>12.1. You guarantee that you will not use the Platform in a way that (i) infringes the rights of Lab-H9 or third parties, including – but not limited to – Intellectual Property Rights or rights in relation to the protection of privacy, (ii) is contrary to any current legislation or regulations, or (iii) is contrary to any provision in these Terms of Use.
                    </p>
                    <p className='Text500__6'>12.2. You are liable and indemnify Lab-H9 against any and all claims of third parties based on the allegation that the Material infringes any Intellectual Property Right or other right of third parties or is otherwise unlawful against third parties as well as against all claims resulting from your actions in violation with these Terms of Use. All costs incurred and damages suffered by Lab-H9 which are in any way related to the abovementioned claims will be remunerated by you.
                    </p>
                    <p className='Text500__6'>12.3. Lab-H9 does not guarantee (i) the Platform’s fitness for a particular purpose or that the Platform shall meet any of your requirements, (ii) that the Platform shall be available to you at all times and without interruptions, limitations or disturbances; (iii) that AI feedbacks on the Platform are accurate, up to date or complete.
                    </p>
                    <h3 className="test-boost">Article 13. Downloading and sharing Study Material
                    </h3>
                    <p className='Text500__6'>13.1. User with a Profile and a Premium Account have the ability to download some Materials from the Platform for personal use only. Your Profile will register what Material you have downloaded.
                    </p>
                    <p className='Text500__6'>13.2. Downloading Material is allowed, provided that you do not violate our Fair Use Policy. This policy states that you can download a maximum of 10 documents per day, with a maximum of 30 documents per month.
                    </p>
                    <p className='Text500__6'>13.3. You accept and acknowledge that sharing Materials generated by the AI is only permitted using a link to the Platform. You are not permitted to share any Materials that you have downloaded by other means that a link.
                    </p>
                    <h3 className="test-boost">Article 14. Notice of alleged unlawful material & repeat infringers
                    </h3>
                    <p className='Text500__6'>14.1. Lab-H9 respects the (Intellectual Property) Rights of others and takes the protection of (Intellectual Property) Rights seriously. Although Lab-H9 is not liable for any damages in connection with the (illegal) use of the Platform by User, Lab-H9 will, under the conditions as set out in this Article, remove Material(s), that is (are) infringing and/or unlawful and stop manifestly unlawful behaviour after receiving a notification that is sufficiently precise and adequately substantiated.
                    </p>
                    <p className='Text500__6'>14.2. If you claim that certain Material AI generated infringe on your (Intellectual Property) Rights, you can report this alleged infringement to Lab-H9 by mail. We will examine the notice and, if possible and necessary, take the measures to clear the issue.
                    </p>
                    <p className='Text500__6'>14.3. Lab-H9 reserves the right to not grant a request to remove Materials, or to stop an activity in case it has reasonable grounds to doubt the accuracy of the notification or in case a balancing of interest requires this. In such a situation, Lab-H9 can require a court order from a competent court, to demonstrate that the material or the activity is manifestly unlawful.
                    </p>
                    <p className='Text500__6'>14.4. Prior to removal of any alleged unlawful Material(s), Lab-H9 may request and the notifying party shall provide, additional information substantiating that the notifying party is indeed the rightsholder, and Lab-H9 may request and the notifying party shall provide, a signed copy of an indemnification declaration for LAB-H9.
                    </p>
                    <p className='Text500__6'>14.5. Lab-H9 will not be a party in a dispute between the person making the report and any third party arising out of or in connection with a notification.
                    </p>
                    <p className='Text500__6'>14.6. Lab-H9 has a policy of terminating accounts of User who repeatedly and intentionally infringe the Intellectual Property Rights of others.
                    </p>
                    <p className='Text500__6'>14.7. The notifying party indemnifies Lab-H9 against all claims of third parties in relation to the blocking or removal of Materials or the stopping of activities as a result of the notification. The indemnification includes all damages and costs Lab-H9 suffers, could suffer or which Lab-H9 must incur in relation to such a claim, including but not limited to the compensation of legal assistance.
                    </p>
                    <p className='Text500__6'>14.8. Lab-H9 respects and protects the privacy of those who provide notifications. All personal data that it receives as part of a notification will only be used to process the notice.
                    </p>
                    <p className='Text500__6'>14.9. Lab-H9 reserves the right to forward the notice to the user responsible for the Material to which the notice refers.
                    </p>
                    <h3 className="test-boost">Article 15. Liability
                    </h3>
                    <p className='Text500__6'>15.1. Lab-H9 accepts no liability whatsoever for damages resulting from the provision of the Platform, including damage resulting from or in connection with the use of the Platform or from wrongful acts or otherwise, in so far as this is permitted pursuant to mandatory law.
                    </p>
                    <p className='Text500__6'>15.2. In the event that Lab-H9– despite the above – is liable, this liability is limited to direct damages the user suffers as a result of a shortcoming or wrongful act that can be attributed to lab-H9. Direct damage only includes material damage to goods, reasonable costs incurred to prevent or limit direct damage and reasonable costs incurred to determine the cause of the damage. Lab-H9 shall in no event be liable for any special, incidental or consequential damages whatsoever (“indirect damages”), including – but not limited to – loss and/or damage of data (including  Material), loss of profits and lost sales, is excluded.
                    </p>
                    <p className='Text500__6'>15.3. Lab-H9’s liability is in any case limited to the highest of the following amounts: (i) the amount actually paid by the user as premium account, or (ii) € 500,00 (five hundred euros/00).
                    </p>
                    <p className='Text500__6'>15.4. The limitations and exclusions of liability mentioned in the preceding paragraphs of this article will lapse if and in so far as the damage is the result of intentional or willful recklessness on the part of Lab-H9 or its managers (“own actions”).
                    </p>
                    <h3 className="test-boost">Article 16. Force Majeure
                    </h3>
                    <p className='Text500__6'>16.1. In the case of force majeure, the non-fulfillment of the agreement between you and Lab-H9cannot be counted as an attributable failure and Lab-H9 is released from all contractual obligations and liability.
                    </p>
                    <p className='Text500__6'>16.2. Force majeure includes, but is not limited to, illness of employees and/or absence of personnel crucial for the provision of the Platform, disruptions of the electricity supply, fire, natural disasters, strikes, floods, failures to perform by third parties hired by Lab-H9, disturbances in the internet connection, hardware disruptions, disruptions in the (telecommunications) networks and other unforeseen circumstances.
                    </p>
                    <h3 className="test-boost">Article 17. Termination
                    </h3>
                    <p className='Text500__6'>17.1. You are entitled to discontinue your use of the Platform and to terminate your Profile.
                    </p>
                    <p className='Text500__6'>17.2. You accept and acknowledge that any Materials uploaded, or any output created and stored, by you beforehand, will be delated from the Platform.
                    </p>
                    <h3 className="test-boost">Article 18. Miscellaneous
                    </h3>
                    <p className='Text500__6'>18.1. These Terms of Use and the use of the Platform are governed by and construed in accordance with the laws of Lithuania
                    </p>
                    <p className='Text500__6'>18.2. Any and all disputes arising from or related to the Platform or the Terms of Use will be brought before the competent court in Vilnius, unless compulsory law mandates otherwise.
                    </p>
                    <p className='Text500__6'>18.3. If at any time any provision of these Terms of Use is or becomes illegal, void or invalid for any reason whatsoever, such invalidity shall not affect the validity of the remainder of the Terms of Use and such invalid provision shall be replaced by another provision which, being valid in all respects, shall have effect as close as possible to that of such replaced one
                    </p>
                    <p className='Text500__6'>18.4. Lab-H9 is entitled to assign any rights and obligations that result from these Terms of Use to third parties, in which case it will notify you. In the event that you cannot agree with the assignment, your only remedy is to cease the use of the Platform and delete your Profile.
                    </p>
                    <br></br>
                </div>
            );
        },
        "DAILY": () => {
            return (<>Dienos</>);
        },
        "PLAN_DOESNT_INCLUDE_FOLDERS": () => {
            return ("Į planą neįtraukti aplankai");
        },
        "PLAN_DOESNT_INCLUDE_VOICE": () => {
            return ("Į planą neįtraukti balso įrašai");
        },
        "PLAN_DOESNT_INCLUDE_VIDEO": () => {
            return ("Į planą neįtraukti vaizdo įrašai")
        },
        "SUBSCRIPTION_REQUIRED": () => {
            return ("Reikalinga prenumerata norint atlikti veiksmą");
        },
        "COOKIE_POLICY_WELCOME": () => {
            return "Sveiki atvykę į ANNA";
        },
        "LEARN_MORE": () => {
            return "Sužinoti daugiau";
        },
        "I_UNDERSTAND": () => {
            return "Išsaugoti pasirinkimus";
        },
        "NECESSARY_COOKIES": () => {
            return `Būtini slapukai`;
        },
        "THIRD_PARTY_COOKIES": () => {
            return `Trečiųjų šalių slapukai reklamoms`;
        },
        "COOKIE_POLICY_TEXT": () => {
            return (<div className="gdpr-modal-list Text500__5" style={{ textAlign: 'justify' }}>
                <p>ANNA sukūrė UAB LAB-H9, Lietuva, Laisvės al. 82, Kaunas,  <a href="mailto:anna@labh9.eu">anna@labh9.eu.</a></p>
                <p>
                    Mes naudojame vietinę (naršyklės) atmintį, saugumo tikslais (prisijungiant ir registruojantis) ir jūsų pasirinktos kalbos saugojimui.
                </p>
                <p>
                    Mes jokiais būdais nesidaliname ir nesidalinsime šia informacija, ji yra prieinama tik Jūsų naršyklėje.
                </p>
                <p>
                    Norint prisijungti naudojant trečiasias šalis, tokias kaip Google arba Facebook, šios šalys gali naudoti slapukus bei naršyklės atmintį, dėl to Jus nukreipiame į atitinkamas privatumo politikas:
                </p>
                <ul className="Text500__5" style={{ textAlign: 'left' }}>
                    <li>
                        <a href="https://policies.google.com/technologies/cookies" target="_blank" rel='noopener noreferrer'>
                            Google slapukų politika
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/privacy/policies/cookies" target="_blank" rel='noopener noreferrer'>
                            Facebook slapukų politika
                        </a>
                    </li>
                </ul>
                <p>
                    Mes nesaugosime ir netvarkysime jokios trečiųjų šalių slapukų informacijos, bet saugumo žetonai (security tokens), kuriuos naudoja tiek svetainė, tiek trečiosios šalys gali būti saugomi ANNA duomenų bazėje teisiniams arba saugumo tikslams.
                </p>
                <p>
                    Tam, kad žinotumėme, jog šią politiką perskaitėte, Jūsų naršyklėje išsaugosime slapuką, kurio mes su niekuo nesidalinsime ir kuris bus naudojamas tik tam, kad šis pranešimas nebūtų rodomas kiekvieną kartą, kai atidarote ANNA svetainę.
                </p>
            </div>
            )
        },
        "COOKIE_POLICY_TABLE": () => {
            return (<div className="gdpr-more-info Text500__5" style={{ alignItems: 'center' }}>
                <p>These are the local storage items and cookies managed by ANNA web site on its domain:</p>
                <table className='cookies-table Text500__5 mb-3 mx-auto'>
                    <tbody>
                        <tr>
                            <th>Type</th>
                            <th>Name</th>
                            <th>Purpose</th>
                        </tr>
                        <tr>
                            <td>Cookie</td>
                            <td>cookieConsent</td>
                            <td>Remembering that You’ve read this cookie page</td>
                        </tr>
                        <tr>
                            <td>Local storage</td>
                            <td>_grecaptcha</td>
                            <td>Used for security purposes, during the registration, to prevent non-human (bot) interactions with the page</td>
                        </tr>
                        <tr>
                            <td>Local storage</td>
                            <td>UserAuth</td>
                            <td>Used for security purposes to store on Your browser Your identity data and token after You have logged in.</td>
                        </tr>
                        <tr>
                            <td>Cookie</td>
                            <td>active_language</td>
                            <td>Used to remember Your preferred language for the user interface</td>
                        </tr>
                    </tbody>
                </table>
                <h3 className="test-boost">Third party cookies</h3>
                <p>
                    The Site uses statistical cookies (for advertising and marketing purposes) to monitor visits to the Site.
                    <br />These cookies and their processing are subject to your consent and therefore will be used only if you accept.
                </p>
                <p>
                    If you accept third party cookies please refer to this providers and privacy disclosures policies:
                </p>
                <ul>
                    <li>Google Analytics
                        <ul>
                            <li><a href="https://www.google.com/policies/privacy/partners/">Google's Privacy & Terms</a></li>
                            <li><a href="https://support.google.com/analytics/answer/6004245">Safeguarding your data</a></li>
                        </ul>
                    </li>
                    <li>Google TAG manager
                        <ul>
                            <li><a href="https://support.google.com/tagmanager/answer/9323295?hl=en">Data privacy and security</a></li>
                        </ul>
                    </li>
                    <li>META PIXEL
                        <ul>
                            <li><a href="https://www.facebook.com/privacy/policy/">Meta Privacy Policy</a></li>
                        </ul>
                    </li>
                </ul>
            </div>);
        },
        "COOKIE_POLICY_CONTACT": () => {
            return (<div className="Text500__5" style={{ textAlign: 'justify' }}>
                <p>Jei turite klausimų, kreipkitės: <a href="mailto:info@labh9.eu">info@labh9.eu</a></p>
            </div>)
        },
        "GO_TO_CATALOGUE": () => {
            return ("Eiti į katalogą");
        },
        "COUNTRY_NOT_FOUND": () => {
            return `Pridėkite valstybę paskyros nustatymuose!`;
        },
        "CONTACT_US": () => {
            return `Susisekite su mumis`;
        },
        "WRITE_EMAIL": () => {
            return `E-Mail`;
        },
        "LEAVE_MAIL_MESSAGE_HERE": () => {
            return `Įrašykite pranešimą čia`;
        },
        "OPS": () => {
            return `Opssss...`;
        },
        "NAME_MANDATORY": () => {
            return `Įrašykite savo vardą`;
        },
        "SURNAME_MANDATORY": () => {
            return `Įrašykite savo pavardę`;
        },
        "MAIL_MANDATORY": () => {
            return `Įrašykite savo el.paštą`;
        },
        "TEXT_MANDATORY": () => {
            return `Negalite išsiųsti tuščio laiško`
        },
        "INVALID_IMAGE": () => {
            return `Leidžiami tik JPEG ir PNG tipo paveikslėliai!`;
        },
        "STATUS": () => {
            return `Statusas`;
        },
        "SUBSCRIPTION": () => {
            return `Prenumerata`;
        },
        "AMOUNT": () => {
            return `Suma`;
        },
        "DATE": () => {
            return `Data`;
        },
        "UPLOAD_DOC_MOBILE_TITLE": () => {
            return `Įkelti dokumentą`;
        },
        "UPLOAD_DOC_MOBILE_DESC": () => {
            return `Įkelkite PDF, ebook, Word, PowerPoint, Excel arba teksto dokumentą`;
        },
        "CREATE_FOLDER_MOBILE_TITLE": () => {
            return `Sukurti aplanką`;
        },
        "CREATE_FOLDER_MOBILE_DESC": () => {
            return `Sukurkite aplanką, kuriame galėsite laikyti įkeltus dokumentus!`;
        },
        "SUBMIT_PICTURE_MOBILE_TITLE": () => {
            return `Įkelti nuotrauką`;
        },
        "BOOKS_INSIDE": (params) => {
            const { number } = params;
            if (number > 0) {
                if (number > 1) {
                    return (

                        <small>{`${number} dokument `}</small>)
                }
                else {
                    return (

                        <small>{`${number} dokumentas`}</small>)
                }
            } else {
                return (
                    <small>{`Tuščias aplankas`}</small>)
            }
        },
        "SUBMIT_PICTURE_MOBILE_DESC": () => {
            return `Nuskanuokite knygą arba įkelkite nuotrauką iš galerijos `;
        },
        "UPLOAD_VIDEO_MOBILE_TITLE": () => {
            return `Įkelti vaizdo įrašą`;
        },
        "UPLOAD_VIDEO_MOBILE_DESC": () => {
            return `Nufilmuokite vaizdo įrašą gyvai arba įkelkite jau įrašytą`;
        },
        "UPLOAD_URL_MOBILE_TITLE": () => {
            return `Įkelti tinklalapį`;
        },
        "UPLOAD_URL_MOBILE_DESC": () => {
            return `Duok man puslapio URL ir gausiu visą turinį`;
        },
        "UPLOAD_URL_MOBILE_LABEL": () => {
            return `Puslapio, kurį norite įkelti, adresas`;
        },
        "UPLOAD_URL_CHECK_MOBILE_LABEL": () => {
            return `Sekite nuorodas puslapyje`;
        },
        "UPLOAD_URL_MOBILE_MODAL_DESC": () => {
            return `Nurodytas tinklalapis bus atsisiųstas ir visas jo turinys bus naudojamas kaip bibliotekos dokumentas.`;
        },
        "UPLOAD_URL_MOBILE_DOWNLOADING_DESC": () => {
            return `Atsisiunčiu tavo puslapį. Palaukite.`;
        },
        "UPLOAD_SPEECH_MOBILE_TITLE": () => {
            return `Garso įrašymas`;
        },
        "UPLOAD_SPEECH_MOBILE_DESC": () => {
            return `Įrašykite garsą naudodami mikrofoną.`;
        },
        "UPLOAD_SPEECH_DESKTOP_DESC": () => {
            return `Įrašykite garsą naudodami mikrofoną, arba įkelkite garso įrašą.`;
        },
        "UNABLE_TO_TRANSCRIBE": () => {
            return `Nepavyko gauti teksto iš pateiktų paveikslėlių`;
        },
        "LOGIN_CREDENTIAL": () => {
            return "Įveskite savo prisijungimo duomenis";
        },
        "APPROVAL": () => {
            return `Patvirtinimas`;
        },
        "NO_INFORMATION_CLASS": () => {
            return `Nėra informacijos apie klasę`;
        },
        "COUNTRY_REQUIRED": () => {
            return `Privaloma pasirinkti valstybę!`;
        },
        "DURATION_SUB_MONTHLY_PLURAL": (params) => {
            var { expirationUnit } = params;
            if (expirationUnit === "year") {
                expirationUnit = "metams";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "month") {
                expirationUnit = "mėnesiams";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "week") {
                expirationUnit = "savaitėms";
                return `${expirationUnit}`;
            }
            return `${expirationUnit}`;
        },
        "EMAIL_SENT": () => {
            return `Laiškas išsiųstas, patikrinkite savo el. pašto dežutę.`;
        },
        "PASSWORD_RESET": () => {
            return `Slaptažodžio atkūrimas`;
        },
        "ENTER_EMAIL_FORGOT_PASSWORD": () => {
            return (<p>Įveskite <strong>el. paštą</strong>, kuris yra susijęs su Jūsų paskyra ir mes Jums atsiųsime nuorodą <strong>slaptažodžiui</strong> atkurti.</p>);
        },
        "REQUEST": () => {
            return `Siųsti užklausą`;
        },
        "REQ_PROFESSOR": () => {
            return `Ar tikrai norite atnaujinti savo paskyros profilį iš standartinio naudotojo į profesoriaus? Bus pradėtas tikrinimo procesas ir su jumis susisieks mūsų pagalbinė tarnyba.`;
        },
        "CONFIRM_ROLE_PROFESSOR": () => {
            return `Pakeisti į profesoriaus paskyrą`;
        },


        //about anna screen
        "ABOUTANNA": () => {
            return `Apie ANNA`;
        },
        "ABOUTANNA_WHOISANNA": () => {
            return `Kas yra ANNA?`;
        },
        "ABOUTANNA_WHOISANNA_ANSWER": () => {
            return (
                <p>
                    <strong>ANNA</strong> yra skaitmeninis korepetitorius, pagrįstas pažangiomis dirbtinio intelekto technologijomis.
                    <br></br>

                    <strong>ANNA</strong> gali padėti mokytis optimizuojant laiką bei supaprastinant mokymąsi naudojant minčių žemėlapius, egzamino simuliacijas bei susirašinėjimus.
                    <br></br>

                    <strong>ANNA</strong> naudoja tik tavo pateiktus duomenis, t.y. knygas, video, dokumentus, todėl yra patikimas ir saugus įrankis.
                    <br></br>

                    <strong>ANNA</strong> yra ideali studijų draugė, kurią gali išbandyti nemokamai!
                </p>
            );
        },
        "ABOUTANNA_HOWITWORKS_TITLE": () => {
            return `Kaip ANNA veikia?`;
        },
        "ABOUTANNA_HOWITWORKS": () => {
            return `Kaip tai veikia?`;
        },
        "ABOUTANNA_HOWITWORKS_ANSWER": () => {
            return (
                <div>
                    <p>Pasirink kalbą</p>

                    <br></br>
                    <div className='image_div'>
                        <img className="about_image" alt="Language selector" src={changeLanguage}></img>
                    </div>
                    <p>
                        Nesijaudink, tu gali keisti kalbą bet kada, o laikui bėgant, atsiras dar daugiau kalbų.
                        <br></br>

                        Įkelk savo dokumentus į <strong>ANNA</strong>. Tu gali naudoti bet kokio formato dokumentus: pdf, word, ebook, excel, power point arba multimediją, kaip nuotraukas, garso bei vaizdo įrašus. </p>

                    <div className='image_div'>
                        <img className="about_image" alt="File Uploading" src={fileUpload}></img>
                    </div>

                    <p> Galite fotografuotis savo knygas ar užrašus </p>

                    <div className='image_div'>
                        <img className="about_image" alt="ImageUploading" src={imgUpload}></img>
                    </div>


                    <p>Arba įrašyti savo balsą.</p>

                    <div className='image_div'>
                        <img className="about_image" alt="Audio uploading" src={audioUpload}></img>
                    </div>

                    <p> Gali organizuoti savo turinį aplankuose bei iš dokumentų išskirti tik reikalingus puslapius.</p>

                    <div className='image_div'>
                        <img className="about_image"  alt="Create folder" src={folderExample}></img>
                    </div>

                    <p>Nesijaudink dėl vertimo, <strong>ANNA</strong> visada naudoja tavo norimą kalbą.</p>
                </div>
            );
        },
        "ABOUTANNA_Q&A_TITLE": () => {
            return `Kas yra K&A?`;
        },

        "WHATISQ&A": () => {
            return `Kas yra Klausimų ir Atsakymų funkcija?`;

        },
        "WHATISQ&A_ANSWER": () => {
            return (
                <p>

                    Naudodamiesi šia funkcija galite pasinaudoti <strong>generatyvinio dirbtinio intelekto</strong> galią susirašinėti su <strong>ANNA</strong> naudojant savo dokumentus kaip žinių bazę.


                    <br></br>

                    <strong>ANNA</strong> ne tik atsako į jūsų klausimus, bet ir daro kur kas daugiau.

                    <br></br>

                    Naudojant tik tavo pateiktus failus (knygas, užrašus, nuotraukas, vaizdo ar garso įrašus) <strong>ANNA</strong> gali sukurti santraukas, išgauti ir apdoroti informaciją, generuoti lenteles, palyginti duomenis ar generuoti naują turinį pagal užklausą.
                    <br></br>

                    Nedėk limitų kaip <strong>ANNA</strong> gali naudoti.


                </p>

            );
        },

        "MINDMAP_USE_QUESTION": () => {
            return `Kas yra minčių žemėlapiai?`;

        },
        "MINDMAP_USE_ANSWER": () => {
            return (
                <p>
                    Mind maps are a great tool for studying vast or complex topics.

                    <br></br>
                    <strong>ANNA</strong> sukuria minčių žemėlapius kiekvienam dokumentui ar aplankui grafiniu pavidalu suskirstydama jo turinį. Naudinga įsiminti pagrindinius elementus.
                    <br></br>

                    Tu gali naviguoti minčių žemėlapius išskleisdamas temas, peržiūrint jų santraukas.
                    <br></br>

                    Gali dalintis, spausdinti ar exportuoti minčių žemėlapius tik su vienu paspaudimu.

                    <br></br>
                    <div className='image_div'>

                        <img src={mindMap} alt="Mindmap" className="about_image"></img>
                    </div>
                </p>

            );

        },

        "HOW_TO_USE_TEST": () => {
            return `Kaip veikia ANNA testai?`;
        },
        "HOW_TO_USE_TEST_ANSWER": () => {
            return (
                <p>
                    Su <strong> ANNA </strong> gali simuliuoti testus pasitikrinti savo žinių lygį.
                    <br></br>
                    Pasirink dokumentą ar aplanką ir sukurk žinių įsivertinimo testą.
                    <br></br>
                    Gali pritaikyti testą nurodant klausimų skaičių ir testo tipą su atvirais arba uždarais atsakymais.
                    <br></br>
                    Atsakyk į klausimus ir sužinok rezultatą. <strong> ANNA </strong>tau paaiškins visas klaidas nurodant teisingą atsakymą su paaiškinimu bei kuriame puslapyje jį rasti.
                    <br></br>
                    Mokykis su <strong>ANNA</strong> testais ir pasiruošk and prepare kitai klasės užduočiai, klausimui ar egzaminui.
                </p>

            )

        },
        "HOW_TRY_ANNA": () => {
            return `Kaip galiu išbandyti ANNA?`;
        },
        "HOW_TRY_ANNA_ANSWER": () => {
            return (
                <p>
                    Gali išbandyti <strong>ANNA</strong> nemokamai. Tiesiog užsiregistruok arba prisijunk su "Google" ar "Facebook" paskyra.
                    <br></br>
                    Galėsi išbandyti su pirmais penkiais dokumentais iki 50 klausimų ir 5 testais.
                    <br></br>
                    Jei <strong>ANNA</strong> atitinka poreikius, gali įsigyti savaitinę arba mėnesinę prenumeratą.
                </p>

            )
        },

        "HOW_MUCH": () => {
            return `Kiek ANNA kainuoja?`;
        },

        "HOW_MUCH_ANSWER": () => {
            return (
                <p>
                    <strong>ANNA</strong> yra nemokama pirmiems penkiems dokumentams.
                    <br></br>
                    Nemokamos bandomosios versijos metu turite ne daugiau kaip 50 klausimų ir 5 testus.
                    <br></br>
                    Galite peržiūrėti mūsų <a href='/catalogue' >katalogą </a> ir palyginti skirtingus prenumeratos planus.
                    <br></br>

                    Prenumeratas galite įsigyti naudodami „PayPal“ ir populiariausias kredito korteles.
                    <br></br>

                    Galite bet kada sustabdyti automatinį prenumeratos atnaujinimą.

                    <br></br>

                    Mokykloms ir mokytojams gali būti taikomos specialios nuolaidos, susisiekite su mumis dėl išsamesnės informacijos.
                </p>

            )
        },
        "ANNA_LAB": () => {
            return `Kas yra ANNA-LAB?`;
        },

        "ANNA_LAB_ANSWER": () => {
            return (
                <p>
                    Jei norite tapti beta testuotoju <strong>ANNA-LAB</strong> yra puiki vieta.
                    <br></br>
                    Galėsite išbandyti naujas dar kuriamas funkcijas ir bendrauti su mūsų kūrėjais.
                </p>

            )
        },
        "PROMO_CODE": () => {
            return `Promo kodas`;
        },

        "NOACCOUNT":()=>{
            return 'Neturite paskyros?'
        },

        "REGISTERFREE":()=>{
            return 'Registruokitės nemokamai'

        },
        "CANCEL_SUBSCRIPTION_MODAL":()=>{
            return "Ar tikrai norite atšaukti? Prenumerata galėsite naudotis iki jos galiojimo pabaigos."
        },
        "FILE_UPLOADED":()=>{
            return "Failas įkeltas"
        },
        
        "FILE_UPLOADED_SUCCESS":()=>{
            return "Dokumentas įkeltas sėkmingai"
        },
        "ERROR_ACCESSING_MICROPHONE":()=>{
            return "Klaida bandant pasiekti mikrofoną"
        },
        "FAILED_TO_GET_PAYMENTS":()=>{
            return "Nepavyko gauti mokėjimų"
        },
        "SUCCESS":()=>{
            return "Atlikta sėkmingai";
        },
        "QUICKCARDS_SESSION_TITLE":()=>{
            return `QuickCards`;
        },

        "QUICKCARDS_SESSION":()=>{
            return `Galiu jums sukurti trumpąsias korteles, kurios padės įsisavinti svarbiausias sąvokas su puikiomis santraukomis.`;
        },
          "HOW_ANNA_WORK": () => {
            return `Pamatykite, kaip tai veikia`;
        }
    }
}

export { getText };