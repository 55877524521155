import * as labels_it from "./labels_it";
import * as labels_en from "./labels_en";
import * as labels_lt from "./labels_lt";
import * as labels_es from "./labels_es";
import * as labels_fr from "./labels_fr";


const urls = {
    catalogue: "/catalogue",
    account: "/user_profile",
    logout: "/logout",
    student_classrooms: "/classrooms",
    professor_classrooms: "/professor/classrooms",
    language_selector: `<button type="button" class="btn language-button Text500__5" data-toggle="modal" data-target="#languageModal" onClick="$('#languageModal').modal('show')">
                    ##LANGUAGE##
                </button>`,
    not_confirmed_professor_landing: "/not_confirmed_professor"
};

const _checkLanguages = () => {
    const _languages = [
        { code: "it", labels: labels_it.getText() },
        { code: "en", labels: labels_en.getText() },
        { code: "es", labels: labels_es.getText() },
        { code: "lt", labels: labels_lt.getText() },
        { code: "fr", labels: labels_fr.getText() }

    ];

    const _getMissings = (a, b) => {
        let _missings = [];

        for (var i of a) {
            if (!b.find(x => x == i)) {
                _missings.push(i);
            }
        }

        return _missings;
    }

    // const _getDuplicates = (a) => {
    //     let _duplicates = [];


    //     for (var i of a) {
    //         if (a.filter(x => x == i).length > 1) {
    //             _duplicates.push(i);
    //         }
    //     }

    //     return _duplicates;
    // }


    let _errors = [];
    for (var i = 0; i < _languages.length; i++) {
        let _actual = _languages[i];


        for (var t = 0; t < _languages.length; t++) {
            if (t !== i) {
                let _toCompare = _languages[t];

                let _actualLabels = Object.getOwnPropertyNames(_actual.labels);
                let _toCompareLabels = Object.getOwnPropertyNames(_toCompare.labels);
                let _missings = _getMissings(_actualLabels, _toCompareLabels);
                // let _duplicates = _getDuplicates(_actualLabels);



                // if (_duplicates.length > 0) {
                //     _errors.push(`on ${_actual.code} are duplicates: ${_duplicates.join(", ")}`)
                // }

                if (_missings.length > 0) {
                    _errors.push(`on ${_toCompare.code} are missings: ${_missings.join(", ")}`);
                }


            }
        }



    }

    if (_errors.length > 0) {
        throw new Error(_errors.join("\n"));
    }
}

_checkLanguages();

const getLanguage = () => {
    let _language;
    let _path = window.location.pathname.split("/");
    if (_path.length >= 2) {
        _language = _path[1];
        const _preferredLanguage = localStorage.getItem("preferred.language");
        if (_preferredLanguage != _language) {
            switch (_language) {
                case "it":
                case "lt":
                case "es":
                case "fr":
                case "en": {
                    localStorage.setItem("preferred.language", _language);
                }
            }

        }

    } else {
        _language = "en";
    }

    return _language;

};

const getText = (label, params) => {

    const _getLocal = () => {
        switch (getLanguage()) {
            case "it":
                {
                    return labels_it;
                }
            case "lt":
                {
                    return labels_lt;
                }
            case "es":
                {
                    return labels_es;
                }
            case "fr":
                {
                    return labels_fr;
                }
            default:
                {
                    return labels_en;
                }


        }
    };

    return _getLocal().getText(label, params);
}

export { getText, getLanguage, urls };