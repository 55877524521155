import '../App.css';
import { useContext, useEffect } from "react";
import UserContext from '../components/User_Context';
import React, { useState } from 'react';
import * as labels from "../components/localized/labels";
import Select from 'react-select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as api from '../api/apiConnector';
import { getBreadcrumb, SetMenu, } from '../components/Navigation';
import { useNavigate, Navigate, useParams } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import TagManager from "react-gtm-module";
import { useCookies } from 'react-cookie';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

function Registration_Screen() {
    const { profileCode } = useParams();
    const { user } = useContext(UserContext);
    const [otp, setOtp] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [correctOtp, setCorrectOtp] = useState('');
    const navigate = useNavigate();
    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userPasswordConfirm, setUserPasswordConfirm] = useState("");
    const [lastName, setLastName] = useState("");
    const [name, setName] = useState("");
    const [dateOfBirth, setBirthDate] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [country, setCountry] = useState("");
    const [checkBoxTerms, setCheckBoxTerms] = useState(false);
    const [showRequirements, setShowRequirements] = useState(false);
    const [cookiesThirdParty, setThirdPartyCookie] = useCookies(localStorage.getItem("thirdPartyCookies"));
    const { login } = useContext(UserContext);
    let oAuthGoogle = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

    var t = process.env.REACT_APP_RECAPTCHA_JS_URL;
    useEffect(() => {
        const fetchCountries = async () => {
            if (countryList.length === 0) {
                const response = await api.sendMessage("getCountries", {});
                setCountryList(response.map(c => ({ label: c.country_name, value: c.country_code })));
            }
        };
        fetchCountries();
    }, [countryList]);

    const handleFocus = () => {
        setShowRequirements(true);
    };

    const handleBlur = () => {
        setShowRequirements(false);
    };
    //for simple user
    const [register, setRegister] = useState({
        userName: '',
        password: '',
        lastName: '',
        name: '',
        dateOfBirth: '',
        country: '',
        isActive: false,
        isProfessor: false
    });
    //#endregion 
    function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    function validateBirthYear(dateOfBirth) {
        let birthArray = dateOfBirth.split("-");
        var birthYear = birthArray[0];
        var birthMonth = birthArray[1];
        var birthDay = birthArray[2];
        const d = new Date();
        let year = d.getFullYear();
        if (parseInt(birthMonth) > 12 || parseInt(birthMonth) < 1) {
            return false;
        }
        else if (parseInt(birthDay) > 31 || parseInt(birthDay) < 1) {
            return false;
        }
        if ((parseInt(birthYear) < 1900 || parseInt(birthYear) > year)) {
            return false;
        }
        return true;
    }
    function validatePassword(password) {
        const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]{8,999}$/;

        return regex.test(password);
    }
    async function resendEmail(email) {
        var result = (await api.sendMessage("resendEmail", {
            body: {
                email: email,
            }
        }));
        if (result.success) {

            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "success",
                text: result.message,
                timer: 2000
            });
        }
    }
    async function checkOTP() {
        var result = (await api.sendMessage("checkOTP", {
            body: {
                otp: otp,
                userName: register.userName
            }
        }));
        if (result.success) {
            setOtp(null);
            setShowModal(false);


            if (process.env.REACT_APP_ENEABLE_TRACKING && cookiesThirdParty?.cookieConsent) {

                // send custom event to GTM for complete registration
                try {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: "CompleteRegistration",
                            // category: "category",
                            action: "action",
                            // label: "label",
                            // value: "value",
                        },
                    });
                } catch (err) {
                    console.log(err);
                }
            }

            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "success",
                text: result.message,
                timer: 2000
            }).then((result) => {
                if (result.isConfirmed || result.isDismissed) {
                    if (profileCode) {
                        navigate(`/login/${profileCode}`);

                    }
                    else {
                        navigate("/login");
                    }
                }
            });

        }
        else if (result.error) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: result.error,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 2000
            });
            setOtp(null);
            setCorrectOtp("WRONG_CODE")
        }
    }
    function createModalOTP() {
        return (
            <Modal show={showModal} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton onClick={(e) => { setShowModal(false); return (<Navigate to="/" />); }}>
                    <Modal.Title className="Text700__5">{labels.getText()["OTP_MODAL_TITLE"]()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body text-center Text500__5">
                        <p>{labels.getText()["OTP_MODAL_BODY"]()}</p>
                        <OtpInput
                            inputType='tel'
                            value={otp}
                            onChange={setOtp}
                            numInputs={5}
                            renderSeparator={<span>-</span>}
                            inputStyle={{
                                width: '2em',
                            }}
                            renderInput={(props) => <input {...props} />}
                        />
                        <br></br>
                        <p>{labels.getText()["OTP_MODAL_FIRST_PART_RESEND"]()}<strong><a href="#" onClick={(e) => { resendEmail(register.userName) }}>{labels.getText()["OTP_MODAL_SECOND_PART_RESEND"]()}</a></strong>  {labels.getText()["OTP_MODAL_THIRD_PART_RESEND"]()}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    <button className="then-small-button" type="button" onClick={(e) => { setShowModal(false) }}>
                        {labels.getText()["CLOSE"]()}
                    </button>
                    <button className="then-small-button" type="button" onClick={(e) => { checkOTP() }}>
                        {labels.getText()["SUBMIT_BUTTON"]()}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
    async function doRegister(token, recaptchaResponse) {
        var result = (await api.sendMessage("register", {
            body: {
                register: register,
                token: token,
                recaptchaResponse: recaptchaResponse

            }
        }));
        if (result.success) {
            setShowModal(true);

            if (process.env.REACT_APP_ENEABLE_TRACKING && cookiesThirdParty?.cookieConsent) {

                // send custom event to GTM for initiate registration
                try {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: "InitiateRegistration",
                            // category: "category",
                            action: "action",
                            // label: "label",
                            // value: "value",
                        },
                    });
                } catch (err) {
                    console.log(err);
                }
            }
        }
        else if (result.error === 'USER_EXISTS' || result.error === 'MISSING_FIELDS' || result.error === 'PASSWORD_REQ') {
            var errorMessage = labels.getText()[result.error]();
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: errorMessage,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 2000
            });
        }
    }

    function responseMessageGoogle(response) {
        // console.log(response.credential);

        api.sendMessage("googleLogin", {
            body: {
                credential: response.credential
            }

        }).then((data) => {
            if (data.token) {
                var user = data.user;
                user.token = data.token;
                if (data.newUser) {

                    if (process.env.REACT_APP_ENEABLE_TRACKING && cookiesThirdParty?.cookieConsent) {

                        // send custom event to GTM for initiate registration
                        try {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: "InitiateRegistration",
                                    // category: "category",
                                    action: "action",
                                    // label: "label",
                                    // value: "value",
                                },
                            });
                        } catch (err) {
                            console.log(err);
                        }
                    }
                }

                login(JSON.stringify(user));
                if (profileCode) {
                    navigate(`/checkout/${profileCode}`);
                }
            }
            else {

            }

        })
            .catch((err) => {
                alert(labels.getText()["PROBLEM"]());
            });

    }

    function errorMessageGoogle(response) {
        var errorResponse = response;

        alert(
            labels.getText()["GOOGLE_PROBLEM"]()

        )
    }

    function responseFacebook(response) {
        //console.log(response);
        api.sendMessage("facebookLogin", {
            body: {
                accessToken: response.accessToken
            }

        }).then((data) => {
            if (data.token) {
                var user = data.user;
                user.token = data.token;
                login(JSON.stringify(user));

                if (data.newUser) {

                    if (process.env.REACT_APP_ENEABLE_TRACKING && cookiesThirdParty?.cookieConsent) {

                        // send custom event to GTM for initiate registration
                        try {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: "InitiateRegistration",
                                    // category: "category",
                                    action: "action",
                                    // label: "label",
                                    // value: "value",
                                },
                            });
                        } catch (err) {
                            console.log(err);
                        }
                    }
                }

                if (profileCode) {
                    navigate(`/checkout/${profileCode}`);
                }
            }
            else {

            }

        })
            .catch((err) => {
                alert(
                    labels.getText()["FACEBOOK_PROBLEM"]()
                );
            });
    }



    if (!user) {

        return (
            <GoogleOAuthProvider clientId={oAuthGoogle}>

                <div className="App">

                    <Helmet>
                        <script src={process.env.REACT_APP_RECAPTCHA_JS_URL}></script>
                    </Helmet>
                    {createModalOTP()}
                    {SetMenu({ user: user })}
                    <div id="registrationBody" className="container py-4 h-100 shadow my-5 bg-white rounded">
                        <div className="row d-flex justify-content-center h-100">
                            <div className="col-lg-7 col-lg-5 col-xl-5 offset-xl-1">
                                <h1 className="mb-4">
                                    {labels.getText()["CREATE_YOUR_IDENTITY"]()}
                                </h1>
                                <div className="form-outline mb-4 Text500__5">
                                    <label className="form-label" >
                                        {labels.getText()["NAME"]()}
                                    </label>
                                    <input type="text" id="name" title={labels.getText()["NAME"]()} className="form-control form-control-lg" onChange={(e) => {
                                        register.name = e.target.value;
                                        setName(e.target.value);
                                    }} value={name} required />

                                </div>
                                <div className="form-outline mb-4 Text500__5">
                                    <label className="form-label" >
                                        {labels.getText()["LAST_NAME"]()}
                                    </label>
                                    <input type="text" id="lastName" title={labels.getText()["LAST_NAME"]()} className="form-control form-control-lg" onChange={(e) => {
                                        register.lastName = e.target.value;
                                        setLastName(e.target.value);

                                    }} value={lastName} required />

                                </div>
                                <div className="form-outline mb-4 Text500__5">
                                    <label className="form-label" >
                                        {labels.getText()["EMAIL_ADDRESS"]()}
                                    </label>
                                    <input type="email" id="email" title={labels.getText()["EMAIL_ADDRESS"]()} className="form-control form-control-lg" onChange={(e) => {
                                        register.userName = e.target.value;
                                        setUserName(e.target.value);
                                    }} value={userName} required />

                                </div>
                                <div className="form-outline mb-4 Text500__5">
                                    <label className="form-label" >
                                        {labels.getText()["DATE_OF_BIRTH"]()}
                                    </label>
                                    <label htmlFor="date"></label> <input id="date" title={labels.getText()["DATE_OF_BIRTH"]()} className="form-control" type="date" max={new Date().toISOString().split('T')[0]} onChange={(e) => {
                                        register.dateOfBirth = new Date(e.target.value);
                                        setBirthDate(e.target.value);
                                    }} value={dateOfBirth} required />


                                </div>
                                <div className="form-outline mb-4 Text500__5">
                                    <label className="form-label me-4 mb-4" title={labels.getText()["CHOOSE"]()} >
                                    </label>
                                    <Select
                                        onChange={(e) => {
                                            register.country = e.value;
                                            setCountry(e.value);
                                        }}
                                        options={countryList}
                                        placeholder={labels.getText()["CHOOSE"]()}
                                        noOptionsMessage={() => labels.getText()["NO_OPTIONS"]()}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                neutral50: '#555',  // Placeholder color
                                            },

                                        })}
                                        required />
                                </div>
                                <div className="form-outline mb-4 Text500__5" style={{ position: 'relative' }}>
                                    <label className="form-label">
                                        {labels.getText()["PASSWORD"]()}
                                    </label>
                                    <input type="password" id="password" title={labels.getText()["PASSWORD"]()} className="form-control form-control-lg" onFocus={handleFocus}
                                        onBlur={handleBlur} onChange={(e) => {
                                            register.password = e.target.value;
                                            setUserPassword(e.target.value);
                                        }} value={userPassword}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {

                                            }
                                        }} required />
                                    {showRequirements && (
                                        <div className="password-requirements" id="password-Req" style={{ position: 'absolute', width: '100%', display: 'block' }}>
                                            <label>{labels.getText()["PASS_REQS"]()}</label>
                                            <ul>
                                                <li>{labels.getText()["PASS_REQS1"]()}</li>
                                                <li>{labels.getText()["PASS_REQS2"]()}</li>
                                                <li>{labels.getText()["PASS_REQS3"]()}</li>
                                                <li>{labels.getText()["PASS_REQS4"]()}</li>
                                                <li>{labels.getText()["PASS_REQS5"]()}</li>
                                            </ul>
                                        </div>
                                    )}
                                </div>

                                <div className="form-outline mb-4 Text500__5">
                                    <label className="form-label">
                                        {labels.getText()["CONFIRM_PASSWORD"]()}
                                    </label>
                                    <input type="password" title={labels.getText()["CONFIRM_PASSWORD"]()} className="form-control form-control-lg" onChange={(e) => { setUserPasswordConfirm(e.target.value); }} value={userPasswordConfirm}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {

                                            }
                                        }} required />

                                </div>
                                <div className="d-flex justify-content-around align-items-center mb-4 Text500__5">
                                    <div className="form-check Text500__5">
                                        <input className="form-check-input" type="checkbox" value="" id="form1Example3" onClick={(e) => { setCheckBoxTerms(e.target.checked); }}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {

                                                }
                                            }} required />
                                        <label className="form-check-label" htmlFor="form1Example3">
                                            <p className='Text500__6'>{labels.getText()["CHECK_TERMS_CONDITIONS1"]()}<strong><a href="/terms_and_conditions" target='_blank' rel="noopener noreferrer" >{labels.getText()["CHECK_TERMS_CONDITIONS2"]()}</a></strong> {labels.getText()["PRIVACY_POLICY_REGISTER"]()}</p>
                                        </label>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center align-items-center mb-4">
                                    <button
                                   
                                        className="then-big-button animated slideInRight"
                                        onClick={e => {

                                            if (!register.name && register.userName && register.lastName && register.password && register.dateOfBirth && register.country) {
                                                alert(labels.getText()["ENTER_NAME"]());
                                            }
                                            else if (register.name && !register.userName && register.lastName && register.password && register.dateOfBirth && register.country) {
                                                alert(labels.getText()["ENTER_EMAIL"]());
                                            }
                                            else if (register.name && register.userName && !register.lastName && register.password && register.dateOfBirth && register.country) {
                                                alert(labels.getText()["ENTER_LASTNAME"]());
                                            }
                                            else if (register.name && register.userName && register.lastName && !register.password && register.dateOfBirth && register.country) {
                                                alert(labels.getText()["ENTER_PASSWORD"]());
                                            }
                                            else if (register.name && register.userName && register.lastName && register.password && !register.dateOfBirth && register.country) {
                                                alert(labels.getText()["ENTER_BIRTHDATE"]())
                                            }
                                            else if (register.name && register.userName && register.lastName && register.password && register.dateOfBirth && !register.country) {
                                                alert(labels.getText()["ENTER_COUNTRY"]())
                                            }
                                            else if (register.name && register.userName && register.lastName && register.password && register.dateOfBirth && register.country && !userPasswordConfirm) {
                                                alert(labels.getText()["ENTER_CONFIRM_PASSWORD"]())
                                            }
                                            else if (!validateBirthYear(dateOfBirth)) {
                                                alert(labels.getText()["ENTER_BIRTHDATE"]())
                                            }
                                            else if (register.name && register.userName && register.lastName && register.password && register.dateOfBirth && register.country) {
                                                if (validateEmail(register.userName)) {
                                                    if (register.password == userPasswordConfirm) {
                                                        if (checkBoxTerms) {
                                                            window.grecaptcha.enterprise.ready(async () => {
                                                                const token = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_REACAPTCHA_EXECUTE, { action: 'signup' });
                                                                var result = (await api.sendMessage("reCaptchaCheck", {
                                                                    body: {
                                                                        token: token,
                                                                        recaptchaAction: 'signup',
                                                                        userName: register.userName
                                                                    }
                                                                }));

                                                                if (result.recaptchaResponse.valid) {

                                                                    if (validatePassword(register.password)) {
                                                                        setRegister(register);
                                                                        doRegister(result.jwtToken, result.recaptchaResponse.valid);
                                                                    }
                                                                    else {
                                                                        alert(labels.getText()["PASSWORD_REQ"]());
                                                                    }


                                                                    // alert("Email resent");
                                                                } else {
                                                                    withReactContent(Swal).fire({
                                                                        title: <i>{labels.getText()["ERROR"]()}</i>,
                                                                        icon: "error",
                                                                        text: result.recaptchaResponse.invalidReason,
                                                                        showConfirmButton: true,
                                                                        allowOutsideClick: false,
                                                                        showCloseButton: true,
                                                                    });
                                                                }



                                                            });

                                                        }
                                                        else {
                                                            alert(labels.getText()["ACCEPT_T&C"]())
                                                 
                                                        }
                                                    }
                                                    else {
                                                        alert(labels.getText()["PASSWORDS_NO_MATCH"]())
                                          
                                                    }
                                                }
                                                else {
                                                    alert(labels.getText()["NOT_GOOD_EMAIL"]())
                                                }

                                            }
                                            else {
                                                alert(labels.getText()["PLEASE_FILL_IN_THE_FIELDS"]())
                                               
                                            }
                                        }}
                                    >{labels.getText()["REGISTER"]()}</button>


                                
                                </div>

                                <h2>Social login</h2>
                          
                                            <label className='Text500__6'>{labels.getText()["CHECK_TERMS_CONDITIONS_SOCIAL"]()}<strong><a href="/terms_and_conditions" target='_blank' rel="noopener noreferrer" >{labels.getText()["CHECK_TERMS_CONDITIONS2"]()}</a></strong> {labels.getText()["PRIVACY_POLICY_REGISTER"]()}</label>
                                <div className="d-flex flex-md-row justify-content-center align-items-center space-between ">
                                        {/* <div className="mb-3" id='btnGoogle'> */}

                                        <div id='btnGoogle'>
                                            <GoogleLogin
                                                shape='pill'
                                                text='signin'
                                                type='icon'
                                                theme='filled_blue'
                                                width="150"
                                                onSuccess={responseMessageGoogle}
                                                onError={errorMessageGoogle}

                                            />
                                        </div>



                                        <div  >
                                            <FacebookLogin
                                                appId="1092987268420959"
                                                cssClass="then-small-button-facebook animated slideInRight "
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                icon={< FontAwesomeIcon icon={faFacebook} ></ FontAwesomeIcon>}
                                                // onClick={componentClicked}
                                                callback={responseFacebook}
                                                // textButton={labels.getText()["FACEBOOK_LOGIN"]()}
                                                textButton={""}

                                            /></div>


                                    </div>

                            </div>


                        </div>
                    </div>
                </div >
            </GoogleOAuthProvider>

        );
    }
    else {
        SetMenu({ user: user })
        return (<Navigate to="/" />);
    }

}

export default Registration_Screen;