import logo from '../hero-img.png';
import '../App.css';
import { useContext } from "react";
import UserContext from '../components/User_Context';
import React, { useState, useRef } from 'react';
import * as labels from "../components/localized/labels";
import Select from 'react-select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as api from '../api/apiConnector';
import { getBreadcrumb, SetMenu, } from '../components/Navigation';
import { useNavigate, Navigate, useParams } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';


function Registration_Professor_Screen() {
    const { user } = useContext(UserContext);
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [correctOtp, setCorrectOtp] = useState('');
    //for professors
    const [register, setRegister] = useState({
        userName: '',
        password: '',
        lastName: '',
        name: '',
        dateOfBirth: '',
        country: '',
        school: '',
        isProfessor: true,
        isConfirmedProfessor: false,
        isActive: false
    });

    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userPasswordConfirm, setUserPasswordConfirm] = useState("");
    const [lastName, setLastName] = useState("");
    const [name, setName] = useState("");
    const [dateOfBirth, setBirthDate] = useState("");
    const [country, setCountry] = useState("");
    const [school, setSchool] = useState("");
    const [checkBoxTerms, setCheckBoxTerms] = useState(false);
    const [showRequirements, setShowRequirements] = useState(false);

    const handleFocus = () => {
        setShowRequirements(true);
    };

    const handleBlur = () => {
        setShowRequirements(false);
    };
    const options = [
        { label: 'Afghanistan', value: 'AF' },
        { label: 'Åland Islands', value: 'AX' },
        { label: 'Albania', value: 'AL' },
        { label: 'Algeria', value: 'DZ' },
        { label: 'American Samoa', value: 'AS' },
        { label: 'Andorra', value: 'AD' },
        { label: 'Angola', value: 'AO' },
        { label: 'Anguilla', value: 'AI' },
        { label: 'Antarctica', value: 'AQ' },
        { label: 'Antigua and Barbuda', value: 'AG' },
        { label: 'Argentina', value: 'AR' },
        { label: 'Armenia', value: 'AM' },
        { label: 'Aruba', value: 'AW' },
        { label: 'Australia', value: 'AU' },
        { label: 'Austria', value: 'AT' },
        { label: 'Azerbaijan', value: 'AZ' },
        { label: 'Bahamas', value: 'BS' },
        { label: 'Bahrain', value: 'BH' },
        { label: 'Bangladesh', value: 'BD' },
        { label: 'Barbados', value: 'BB' },
        { label: 'Belarus', value: 'BY' },
        { label: 'Belgium', value: 'BE' },
        { label: 'Belize', value: 'BZ' },
        { label: 'Benin', value: 'BJ' },
        { label: 'Bermuda', value: 'BM' },
        { label: 'Bhutan', value: 'BT' },
        { label: 'Bolivia, Plurinational State of', value: 'BO' },
        { label: 'Bonaire, Sint Eustatius and Saba', value: 'BQ' },
        { label: 'Bosnia and Herzegovina', value: 'BA' },
        { label: 'Botswana', value: 'BW' },
        { label: 'Bouvet Island', value: 'BV' },
        { label: 'Brazil', value: 'BR' },
        { label: 'British Indian Ocean Territory', value: 'IO' },
        { label: 'Brunei Darussalam', value: 'BN' },
        { label: 'Bulgaria', value: 'BG' },
        { label: 'Burkina Faso', value: 'BF' },
        { label: 'Burundi', value: 'BI' },
        { label: 'Cambodia', value: 'KH' },
        { label: 'Cameroon', value: 'CM' },
        { label: 'Canada', value: 'CA' },
        { label: 'Cape Verde', value: 'CV' },
        { label: 'Cayman Islands', value: 'KY' },
        { label: 'Central African Republic', value: 'CF' },
        { label: 'Chad', value: 'TD' },
        { label: 'Chile', value: 'CL' },
        { label: 'China', value: 'CN' },
        { label: 'Christmas Island', value: 'CX' },
        { label: 'Cocos (Keeling) Islands', value: 'CC' },
        { label: 'Colombia', value: 'CO' },
        { label: 'Comoros', value: 'KM' },
        { label: 'Congo', value: 'CG' },
        { label: 'Congo, the Democratic Republic of the', value: 'CD' },
        { label: 'Cook Islands', value: 'CK' },
        { label: 'Costa Rica', value: 'CR' },
        { label: 'Côte d\'Ivoire', value: 'CI' },
        { label: 'Croatia', value: 'HR' },
        { label: 'Cuba', value: 'CU' },
        { label: 'Curaçao', value: 'CW' },
        { label: 'Cyprus', value: 'CY' },
        { label: 'Czech Republic', value: 'CZ' },
        { label: 'Denmark', value: 'DK' },
        { label: 'Djibouti', value: 'DJ' },
        { label: 'Dominica', value: 'DM' },
        { label: 'Dominican Republic', value: 'DO' },
        { label: 'Ecuador', value: 'EC' },
        { label: 'Egypt', value: 'EG' },
        { label: 'El Salvador', value: 'SV' },
        { label: 'Equatorial Guinea', value: 'GQ' },
        { label: 'Eritrea', value: 'ER' },
        { label: 'Estonia', value: 'EE' },
        { label: 'Ethiopia', value: 'ET' },
        { label: 'Falkland Islands (Malvinas)', value: 'FK' },
        { label: 'Faroe Islands', value: 'FO' },
        { label: 'Fiji', value: 'FJ' },
        { label: 'Finland', value: 'FI' },
        { label: 'France', value: 'FR' },
        { label: 'French Guiana', value: 'GF' },
        { label: 'French Polynesia', value: 'PF' },
        { label: 'French Southern Territories', value: 'TF' },
        { label: 'Gabon', value: 'GA' },
        { label: 'Gambia', value: 'GM' },
        { label: 'Georgia', value: 'GE' },
        { label: 'Germany', value: 'DE' },
        { label: 'Ghana', value: 'GH' },
        { label: 'Gibraltar', value: 'GI' },
        { label: 'Greece', value: 'GR' },
        { label: 'Greenland', value: 'GL' },
        { label: 'Grenada', value: 'GD' },
        { label: 'Guadeloupe', value: 'GP' },
        { label: 'Guam', value: 'GU' },
        { label: 'Guatemala', value: 'GT' },
        { label: 'Guernsey', value: 'GG' },
        { label: 'Guinea', value: 'GN' },
        { label: 'Guinea-Bissau', value: 'GW' },
        { label: 'Guyana', value: 'GY' },
        { label: 'Haiti', value: 'HT' },
        { label: 'Heard Island and McDonald Islands', value: 'HM' },
        { label: 'Holy See (Vatican City State)', value: 'VA' },
        { label: 'Honduras', value: 'HN' },
        { label: 'Hong Kong', value: 'HK' },
        { label: 'Hungary', value: 'HU' },
        { label: 'Iceland', value: 'IS' },
        { label: 'India', value: 'IN' },
        { label: 'Indonesia', value: 'ID' },
        { label: 'Iran, Islamic Republic of', value: 'IR' },
        { label: 'Iraq', value: 'IQ' },
        { label: 'Ireland', value: 'IE' },
        { label: 'Isle of Man', value: 'IM' },
        { label: 'Israel', value: 'IL' },
        { label: 'Italy', value: 'IT' },
        { label: 'Jamaica', value: 'JM' },
        { label: 'Japan', value: 'JP' },
        { label: 'Jersey', value: 'JE' },
        { label: 'Jordan', value: 'JO' },
        { label: 'Kazakhstan', value: 'KZ' },
        { label: 'Kenya', value: 'KE' },
        { label: 'Kiribati', value: 'KI' },
        { label: 'Korea, Democratic People\'s Republic of', value: 'KP' },
        { label: 'Korea, Republic of', value: 'KR' },
        { label: 'Kuwait', value: 'KW' },
        { label: 'Kyrgyzstan', value: 'KG' },
        { label: 'Lao People\'s Democratic Republic', value: 'LA' },
        { label: 'Latvia', value: 'LV' },
        { label: 'Lebanon', value: 'LB' },
        { label: 'Lesotho', value: 'LS' },
        { label: 'Liberia', value: 'LR' },
        { label: 'Libya', value: 'LY' },
        { label: 'Liechtenstein', value: 'LI' },
        { label: 'Lithuania', value: 'LT' },
        { label: 'Luxembourg', value: 'LU' },
        { label: 'Macao', value: 'MO' },
        { label: 'Macedonia, the former Yugoslav Republic of', value: 'MK' },
        { label: 'Madagascar', value: 'MG' },
        { label: 'Malawi', value: 'MW' },
        { label: 'Malaysia', value: 'MY' },
        { label: 'Maldives', value: 'MV' },
        { label: 'Mali', value: 'ML' },
        { label: 'Malta', value: 'MT' },
        { label: 'Marshall Islands', value: 'MH' },
        { label: 'Martinique', value: 'MQ' },
        { label: 'Mauritania', value: 'MR' },
        { label: 'Mauritius', value: 'MU' },
        { label: 'Mayotte', value: 'YT' },
        { label: 'Mexico', value: 'MX' },
        { label: 'Micronesia, Federated States of', value: 'FM' },
        { label: 'Moldova, Republic of', value: 'MD' },
        { label: 'Monaco', value: 'MC' },
        { label: 'Mongolia', value: 'MN' },
        { label: 'Montenegro', value: 'ME' },
        { label: 'Montserrat', value: 'MS' },
        { label: 'Morocco', value: 'MA' },
        { label: 'Mozambique', value: 'MZ' },
        { label: 'Myanmar', value: 'MM' },
        { label: 'Namibia', value: 'NA' },
        { label: 'Nauru', value: 'NR' },
        { label: 'Nepal', value: 'NP' },
        { label: 'Netherlands', value: 'NL' },
        { label: 'New Caledonia', value: 'NC' },
        { label: 'New Zealand', value: 'NZ' },
        { label: 'Nicaragua', value: 'NI' },
        { label: 'Niger', value: 'NE' },
        { label: 'Nigeria', value: 'NG' },
        { label: 'Niue', value: 'NU' },
        { label: 'Norfolk Island', value: 'NF' },
        { label: 'Northern Mariana Islands', value: 'MP' },
        { label: 'Norway', value: 'NO' },
        { label: 'Oman', value: 'OM' },
        { label: 'Pakistan', value: 'PK' },
        { label: 'Palau', value: 'PW' },
        { label: 'Palestinian Territory, Occupied', value: 'PS' },
        { label: 'Panama', value: 'PA' },
        { label: 'Papua New Guinea', value: 'PG' },
        { label: 'Paraguay', value: 'PY' },
        { label: 'Peru', value: 'PE' },
        { label: 'Philippines', value: 'PH' },
        { label: 'Pitcairn', value: 'PN' },
        { label: 'Poland', value: 'PL' },
        { label: 'Portugal', value: 'PT' },
        { label: 'Puerto Rico', value: 'PR' },
        { label: 'Qatar', value: 'QA' },
        { label: 'Réunion', value: 'RE' },
        { label: 'Romania', value: 'RO' },
        { label: 'Russian Federation', value: 'RU' },
        { label: 'Rwanda', value: 'RW' },
        { label: 'Saint Barthélemy', value: 'BL' },
        { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
        { label: 'Saint Kitts and Nevis', value: 'KN' },
        { label: 'Saint Lucia', value: 'LC' },
        { label: 'Saint Martin (French part)', value: 'MF' },
        { label: 'Saint Pierre and Miquelon', value: 'PM' },
        { label: 'Saint Vincent and the Grenadines', value: 'VC' },
        { label: 'Samoa', value: 'WS' },
        { label: 'San Marino', value: 'SM' },
        { label: 'Sao Tome and Principe', value: 'ST' },
        { label: 'Saudi Arabia', value: 'SA' },
        { label: 'Senegal', value: 'SN' },
        { label: 'Serbia', value: 'RS' },
        { label: 'Seychelles', value: 'SC' },
        { label: 'Sierra Leone', value: 'SL' },
        { label: 'Singapore', value: 'SG' },
        { label: 'Sint Maarten (Dutch part)', value: 'SX' },
        { label: 'Slovakia', value: 'SK' },
        { label: 'Slovenia', value: 'SI' },
        { label: 'Solomon Islands', value: 'SB' },
        { label: 'Somalia', value: 'SO' },
        { label: 'South Africa', value: 'ZA' },
        { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
        { label: 'South Sudan', value: 'SS' },
        { label: 'Spain', value: 'ES' },
        { label: 'Sri Lanka', value: 'LK' },
        { label: 'Sudan', value: 'SD' },
        { label: 'Suriname', value: 'SR' },
        { label: 'Svalbard and Jan Mayen', value: 'SJ' },
        { label: 'Swaziland', value: 'SZ' },
        { label: 'Sweden', value: 'SE' },
        { label: 'Switzerland', value: 'CH' },
        { label: 'Syrian Arab Republic', value: 'SY' },
        { label: 'Taiwan, Province of China', value: 'TW' },
        { label: 'Tajikistan', value: 'TJ' },
        { label: 'Tanzania, United Republic of', value: 'TZ' },
        { label: 'Thailand', value: 'TH' },
        { label: 'Timor-Leste', value: 'TL' },
        { label: 'Togo', value: 'TG' },
        { label: 'Tokelau', value: 'TK' },
        { label: 'Tonga', value: 'TO' },
        { label: 'Trinidad and Tobago', value: 'TT' },
        { label: 'Tunisia', value: 'TN' },
        { label: 'Turkey', value: 'TR' },
        { label: 'Turkmenistan', value: 'TM' },
        { label: 'Turks and Caicos Islands', value: 'TC' },
        { label: 'Tuvalu', value: 'TV' },
        { label: 'Uganda', value: 'UG' },
        { label: 'Ukraine', value: 'UA' },
        { label: 'United Arab Emirates', value: 'AE' },
        { label: 'United Kingdom', value: 'GB' },
        { label: 'United States', value: 'US' },
        { label: 'United States Minor Outlying Islands', value: 'UM' },
        { label: 'Uruguay', value: 'UY' },
        { label: 'Uzbekistan', value: 'UZ' },
        { label: 'Vanuatu', value: 'VU' },
        { label: 'Venezuela, Bolivarian Republic of', value: 'VE' },
        { label: 'Viet Nam', value: 'VN' },
        { label: 'Virgin Islands, British', value: 'VG' },
        { label: 'Virgin Islands, U.S.', value: 'VI' },
        { label: 'Wallis and Futuna', value: 'WF' },
        { label: 'Western Sahara', value: 'EH' },
        { label: 'Yemen', value: 'YE' },
        { label: 'Zambia', value: 'ZM' },
        { label: 'Zimbabwe', value: 'ZW' }
    ];

    function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    function validatePassword(password) {
        const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]{8,999}$/;

        return regex.test(password);
    }
    function validateBirthYear(dateOfBirth) {
        let birthArray = dateOfBirth.split("-");
        var birthYear = birthArray[0];
        var birthMonth = birthArray[1];
        var birthDay = birthArray[2];
        const d = new Date();
        let year = d.getFullYear();
        if (parseInt(birthMonth) > 12 || parseInt(birthMonth) < 1) {
            return false;
        }
        else if (parseInt(birthDay) > 31 || parseInt(birthDay) < 1) {
            return false;
        }
        if ((parseInt(birthYear) < 1900 || parseInt(birthYear) > year)) {
            return false;
        }
        return true;
    }
    async function resendEmail(email) {
        var result = (await api.sendMessage("resendEmail", {
            body: {
                email: email,
            }
        }));
        if (result.success) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "success",
                text: result.message,
                timer: 2000
            });
        }
    }
    async function checkOTP() {
        var result = (await api.sendMessage("checkOTP", {
            body: {
                otp: otp,
                userName: register.userName
            }
        }));
        if (result.success) {
            setOtp(null);
            setShowModal(false);
            //var successMessage = labels.getText()[result.message]()
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "success",
                text: result.message,
                timer: 2000
            }).then((result) => {
                if (result.isConfirmed || result.isDismissed) {
                    navigate(`/${language}/login`);
                }
            });
        }
        // else if (result.error == 'WRONG_CODE') {
        else if (result.error) {
            //var errorMessage = labels.getText()[result.error]();
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: result.error,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 2000
            });
            setOtp(null);
            setCorrectOtp("WRONG_CODE")
        }
    }
    function createModalOTP() {
        return (
            <Modal show={showModal} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton onClick={(e) => { setShowModal(false) }}>
                    <Modal.Title className="Text700__5">{labels.getText()["OTP_MODAL_TITLE"]()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body text-center Text500__5">
                        <p>{labels.getText()["OTP_MODAL_BODY"]()}</p>
                        <OtpInput
                            inputType='tel'
                            value={otp}
                            onChange={setOtp}
                            numInputs={5}
                            renderSeparator={<span>-</span>}
                            inputStyle={{
                                width: '2em',
                              }}
                            renderInput={(props) => <input {...props} />}
                        />
                        <br></br>
                        <p>{labels.getText()["OTP_MODAL_FIRST_PART_RESEND"]()}<strong><a href="#" onClick={(e) => { resendEmail(register.userName) }}>{labels.getText()["OTP_MODAL_SECOND_PART_RESEND"]()}</a></strong>  {labels.getText()["OTP_MODAL_THIRD_PART_RESEND"]()}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    <button className="then-small-button" type="button" onClick={(e) => { setShowModal(false) }}>
                        {labels.getText()["CLOSE"]()}
                    </button>
                    <button className="then-small-button" type="button" onClick={(e) => { checkOTP() }}>
                        {labels.getText()["SUBMIT_BUTTON"]()}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
    async function doRegister(token, recaptchaResponse) {
        var result = (await api.sendMessage("register", {
            body: {
                register: register,
                token: token,
                recaptchaResponse: recaptchaResponse
            }
        }));
        if (result.success) {
            setShowModal(true);

        }
        // else if (result.error == 'USER_EXISTS' || result.error == 'MISSING_FIELDS' || result.error == 'PASSWORD_REQ') {
        else if (result.error) {
            //var errorMessage = labels.getText()[result.error]();
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: result.error,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 2000
            });
        }
    }


    if (!user) {

        return (

            <div className="App">

                <Helmet>
                <script src={process.env.REACT_APP_RECAPTCHA_JS_URL}></script>
                </Helmet>
                {createModalOTP()}
                {SetMenu({ user: user })}
                {/* <div className="container py-5">
                        <div className="row g-5 "> */}
                {/* <div className='bg-primary hero-header'>
                    <div className="container pt-5">
                        <div className="row g-5 pt-5">
                            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                                <h1 className="display-4 text-white mb-4 animated slideInRight">
                                    {labels.getText()["CREATE_YOUR_IDENTITY"]()}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div id="registrationBody" className="container py-4 h-100 shadow my-5 bg-white rounded">
                    <div className="row d-flex justify-content-center h-100">
                        <div className="col-lg-7 col-lg-5 col-xl-5 offset-xl-1">
                            <h1 className="mb-4">
                                {labels.getText()["CREATE_YOUR_IDENTITY"]()}
                            </h1>
                            <div className="form-outline mb-4 Text500__5">
                                <label className="form-label" htmlFor="form1Example13">
                                    {labels.getText()["NAME"]()}
                                </label>
                                <input type="text" id="form1Example13" title={labels.getText()["NAME"]()} className="form-control form-control-lg" onChange={(e) => {
                                    register.name = e.target.value;
                                    setName(e.target.value);

                                }} value={name} required />

                            </div>
                            <div className="form-outline mb-4 Text500__5">
                                <label className="form-label" htmlFor="form1Example13">
                                    {labels.getText()["LAST_NAME"]()}
                                </label>
                                <input type="text" id="form1Example13" title={labels.getText()["LAST_NAME"]()} className="form-control form-control-lg" onChange={(e) => {
                                    register.lastName = e.target.value;
                                    setLastName(e.target.value);

                                }} value={lastName} required />

                            </div>
                            <div className="form-outline mb-4 Text500__5">
                                <label className="form-label" htmlFor="form1Example13">
                                    {labels.getText()["EMAIL_ADDRESS"]()}
                                </label>
                                <input type="email" id="form1Example13" title={labels.getText()["EMAIL_ADDRESS"]()} className="form-control form-control-lg" onChange={(e) => {
                                    register.userName = e.target.value;
                                    setUserName(e.target.value);
                                }} value={userName} required />

                            </div>
                            <div className="form-outline mb-4 Text500__5">
                                <label className="form-label" htmlFor="form1Example13">
                                    {labels.getText()["DATE_OF_BIRTH"]()}
                                </label>
                                <label for="date"></label> <input id="date" className="form-control" title={labels.getText()["DATE_OF_BIRTH"]()} type="date" max={new Date().toISOString().split('T')[0]} onChange={(e) => {
                                    register.dateOfBirth = new Date(e.target.value);
                                    setBirthDate(e.target.value);
                                }} value={dateOfBirth} required />


                            </div>
                            <div className="form-outline mb-4 Text500__5">
                                <label title={labels.getText()["CHOOSE"]()} className="form-label me-4 mb-4" htmlFor="form1Example13">
                                </label>
                                <Select
                                    onChange={(e) => {
                                        register.country = e.value;
                                        setCountry(e.value);
                                    }}
                                    options={options}
                                    placeholder={labels.getText()["CHOOSE"]()}
                                    noOptionsMessage={() => labels.getText()["NO_OPTIONS"]()}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            neutral50: '#555',  // Placeholder color
                                        },

                                    })}
                                    required />

                            </div>
                            <div className="form-outline mb-4 Text500__5">
                                <label className="form-label" htmlFor="form1Example13">
                                    {labels.getText()["SCHOOL_NAME"]()}
                                </label>
                                <input type="text" id="form1Example13" title={labels.getText()["SCHOOL_NAME"]()} className="form-control form-control-lg" onChange={(e) => {
                                    register.school = e.target.value;
                                    setSchool(e.target.value);
                                }} value={school} required />

                            </div>
                            <div className="form-outline mb-4 Text500__5">
                                <label className="form-label" htmlFor="form1Example23">
                                    {labels.getText()["PASSWORD"]()}
                                </label>
                                <span className="tooltip" data-tooltip="Passwords must contain 3 A's, a 7 and at least 2 question marks">?</span>
                                <input type="password" id="form1Example23" title={labels.getText()["PASSWORD"]()} onFocus={handleFocus}
                                    onBlur={handleBlur} className="form-control form-control-lg" onChange={(e) => {
                                        register.password = e.target.value;
                                        setUserPassword(e.target.value);
                                    }} value={userPassword}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {

                                        }
                                    }} required />
                                {showRequirements && (
                                    <div className="password-requirements" id="password-Req">
                                        <p>{labels.getText()["PASS_REQS"]()}</p>
                                        <ul>
                                            <li>{labels.getText()["PASS_REQS1"]()}</li>
                                            <li>{labels.getText()["PASS_REQS2"]()}</li>
                                            <li>{labels.getText()["PASS_REQS3"]()}</li>
                                            <li>{labels.getText()["PASS_REQS4"]()}</li>
                                            <li>{labels.getText()["PASS_REQS5"]()}</li>
                                        </ul>
                                    </div>
                                )}

                            </div>
                            <div className="form-outline mb-4 Text500__5">
                                <label className="form-label" htmlFor="form1Example23">
                                    {labels.getText()["CONFIRM_PASSWORD"]()}
                                </label>
                                <input type="password" id="form1Example23" title={labels.getText()["CONFIRM_PASSWORD"]()} className="form-control form-control-lg" onChange={(e) => { setUserPasswordConfirm(e.target.value); }} value={userPasswordConfirm}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {

                                        }
                                    }} required />

                            </div>
                            <div className="d-flex justify-content-around align-items-center mb-4">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="form1Example3" onClick={(e) => { setCheckBoxTerms(e.target.checked) }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {

                                            }
                                        }} required />
                                    <label className="form-check-label" htmlFor="form1Example3">
                                        <p className='Text500__6'>{labels.getText()["CHECK_TERMS_CONDITIONS1"]()}<strong><a href="/terms_and_conditions" target='_blank' rel="noopener noreferrer" >{labels.getText()["CHECK_TERMS_CONDITIONS2"]()}</a></strong> {labels.getText()["PRIVACY_POLICY_REGISTER"]()}</p> </label>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center align-items-center mb-4">
                                <button

                                    className="then-big-button animated slideInRight"
                                    input type="submit"
                                    value="Submit"
                                    onClick={e => {
                                        let birthYear = dateOfBirth.split("-");
                                        birthYear = birthYear[0];
                                        const d = new Date();
                                        let year = d.getFullYear();
                                        if (!register.name && register.userName && register.lastName && register.password && register.dateOfBirth && register.country && register.school) {
                                            alert(labels.getText()["ENTER_NAME"]());
                                        }
                                        else if (register.name && !register.userName && register.lastName && register.password && register.dateOfBirth && register.country && register.school) {
                                            alert(labels.getText()["ENTER_EMAIL"]());
                                        }
                                        else if (register.name && register.userName && !register.lastName && register.password && register.dateOfBirth && register.country && register.school) {
                                            alert(labels.getText()["ENTER_LASTNAME"]());
                                        }
                                        else if (register.name && register.userName && register.lastName && !register.password && register.dateOfBirth && register.country && register.school) {
                                            alert(labels.getText()["ENTER_PASSWORD"]());
                                        }
                                        else if (register.name && register.userName && register.lastName && register.password && !register.dateOfBirth && register.country && register.school) {
                                            alert(labels.getText()["ENTER_BIRTHDATE"]());
                                        }
                                        else if (birthYear.length != 4 && (parseInt(birthYear) < 1900 || parseInt(birthYear) > year)) {
                                            alert(labels.getText()["ENTER_BIRTHDATE"]())
                                        }
                                        else if (register.name && register.userName && register.lastName && register.password && register.dateOfBirth && !register.country && register.school) {
                                            alert(labels.getText()["ENTER_COUNTRY"]());
                                        }
                                        else if (register.name && register.userName && register.lastName && register.password && register.dateOfBirth && register.country && !userPasswordConfirm && register.school) {
                                            alert(labels.getText()["ENTER_CONFIRM_PASSWORD"]());
                                        }
                                        else if (register.name && register.userName && register.lastName && register.password && register.dateOfBirth && register.country && userPasswordConfirm && !register.school) {
                                            alert(labels.getText()["ENTER_SCHOOL"]());
                                        }
                                        else if (!validateBirthYear(dateOfBirth)) {
                                            alert(labels.getText()["ENTER_BIRTHDATE"]())
                                        }
                                        else if (register.name && register.userName && register.lastName && register.password && register.dateOfBirth && register.school && register.country) {
                                            if (validateEmail(register.userName)) {
                                                if (register.password == userPasswordConfirm) {

                                                    if (checkBoxTerms) {
                                                        //recaptcha
                                                        e.preventDefault();
                                                        window.grecaptcha.enterprise.ready(async () => {
                                                            const token = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_REACAPTCHA_EXECUTE, { action: 'signup' });
                                                            var result = (await api.sendMessage("reCaptchaCheck", {
                                                                body: {
                                                                    token: token,
                                                                    recaptchaAction: 'signup',
                                                                    userName: register.userName
                                                                }
                                                            }));

                                                            if (result.recaptchaResponse.valid) {

                                                                if (validatePassword(register.password)) {
                                                                    setRegister(register);
                                                                    doRegister(result.jwtToken, result.recaptchaResponse.valid);
                                                                }
                                                                else {
                                                                    alert(labels.getText()["PASSWORD_REQ"]());
                                                                }


                                                                // alert("Email resent");
                                                            }
                                                            else {
                                                                withReactContent(Swal).fire({
                                                                    title: <i>{labels.getText()["ERROR"]()}</i>,
                                                                    icon: "error",
                                                                    text: result.recaptchaResponse.invalidReason,
                                                                    showConfirmButton: true,
                                                                    allowOutsideClick: false,
                                                                    showCloseButton: true,
                                                                });
                                                            }


                                                        });



                                                    }
                                                    else {
                                                        alert(labels.getText()["ACCEPT_T&C"]())
                                                        // withReactContent(Swal).fire({
                                                        //     title: <i>{labels.getText()["ERROR"]()}</i>,
                                                        //     icon: "error",
                                                        //     text: "You have not agreed to terms & conditions",
                                                        //     showConfirmButton: true,
                                                        //     allowOutsideClick: false,
                                                        //     showCloseButton: true,
                                                        // });
                                                    }
                                                }

                                                else {
                                                    alert(labels.getText()["PASSWORDS_NO_MATCH"]())
                                                    // withReactContent(Swal).fire({
                                                    //     title: <i>{labels.getText()["ERROR"]()}</i>,
                                                    //     icon: "error",
                                                    //     text: "Passwords do not match",
                                                    //     showConfirmButton: true,
                                                    //     allowOutsideClick: false,
                                                    //     showCloseButton: true,
                                                    // });
                                                }
                                            }
                                            else {
                                                alert(labels.getText()["NOT_GOOD_EMAIL"]())
                                            }

                                        }
                                        else {
                                            alert(labels.getText()["PLEASE_FILL_IN_THE_FIELDS"]())
                                            // withReactContent(Swal).fire({
                                            //     title: <i>{labels.getText()["ERROR"]()}</i>,
                                            //     icon: "error",
                                            //     text: "Please fill in all the fields",
                                            //     showConfirmButton: true,
                                            //     allowOutsideClick: false,
                                            //     showCloseButton: true,
                                            // });
                                        }
                                    }}
                                >{labels.getText()["REGISTER"]()}</button>
                            </div>
                            <div className=" d-flex justify-content-center align-items-center space-between ">



                            </div>
                        </div>


                    </div>
                </div >
                {/* </div>
                    </div> */}
                {/* </div> */}
            </div >
        );
    }
    else {
        { SetMenu({ user: user }) }
        return (<Navigate to="/" />);
    }

}

export default Registration_Professor_Screen;