import { ImCheckboxUnchecked } from "react-icons/im";
import { ImCheckboxChecked } from "react-icons/im";
import { useState, useRef, useContext } from 'react';
import { useIsVisible } from 'react-is-visible';
import UserContext from '../components/User_Context';
import { VscError } from "react-icons/vsc";
import { GiConfirmed } from "react-icons/gi";
import ScrollToTop from "react-scroll-to-top";
import { Form } from 'react-bootstrap';
import * as api from '../api/apiConnector';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as labels from "../components/localized/labels";
import { React } from "react";

function Survey_Widget(props) {
    const { questionsPackage, answerCodes = [
        "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q",
        "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
    ], onSubmitted = null, onDownload = null, onDownloadOpen = null, classroom } = props;
    const [answered, setAnswered] = useState(0);
    const [openQuestions, setopenQuestions] = useState(questionsPackage);
    const standardProgress = useRef();
    const fixedProgress = useRef();
    const isVisible = useIsVisible(standardProgress);
    const [finalScoreStatus, setFinalScoreStatus] = useState("questions");
    const [checkingOpenQuestionsStatus, setCheckingOpenQuestionsStatus] = useState("checking");
    let { user } = useContext(UserContext);
    const [textAreaAnswer, setTextAreaAnswer] = useState("");
    const { id, packageId = null } = useParams();
    user = user ? JSON.parse(user) : null;
    function _computeAnswered() {
        if (questionsPackage.answersType == "open") {
            var _count = 0;
            questionsPackage.questions.forEach((q) => {
                var _answered = false;
                if (q.User_Answer != undefined && q.User_Answer != null && q.User_Answer != '') {
                    _answered = true;
                }

                if (_answered) {
                    _count++;
                }
            });
        }
        else {
            var _count = 0;
            questionsPackage.questions.forEach((q) => {
                var _answered = false;
                q.Question_Answers.forEach((a) => {
                    if (a.selected) {
                        _answered = true;
                    }
                });

                if (_answered) {
                    _count++;
                }
            });
        }

        var _percent = questionsPackage.questions?.length > 0
            ? Math.round((_count * 100) / questionsPackage.questions.length) : 0;

        setAnswered(_percent);
    }

    const handleSubmit = () => {
        textAreaAnswer.forEach((answer, index) => {
            console.log(`Answer ${index + 1}: ${answer}`);
        });
    }

    const handleTextAreaChange = (index) => {
        console.log(`textArea_${index}`);
        setTextAreaAnswer(document.getElementById(`textArea_${index}`).value);
        console.log(textAreaAnswer);
    }

    const _drawResultBar = () => {
        var _maxScore = questionsPackage
            .questions.length;

        if (questionsPackage.answersType == "open") {
            var _actualScore = (openQuestions
                .questions.filter(x => x.Correct)
                || []).length;
        }
        else {
            if (questionsPackage.correctAnswers === 'single_choice') {
                var _actualScore = (questionsPackage
                    .questions.filter(x => x.Question_Answers.find(y => y.Correct && y.selected))
                    || []).length;
            } else {
                var _actualScore = 0;
                questionsPackage.questions.forEach((question, index) => {
                    if ((question.Question_Answers.filter(y => y.Correct && y.selected) || []).length == (question.Question_Answers.filter(y => y.Correct) || []).length && (question.Question_Answers.filter(y => y.Correct && y.selected) || []).length == (question.Question_Answers.filter(y => y.selected) || []).length) { _actualScore++; }
                })
            }

        }


        if (_maxScore == _actualScore) {
            return (<div className="alert alert-success" role="alert">
                <h4 className="alert-heading"> {labels.getText()["WELL_DONE"]()}
                   </h4>
                <p>
                    {labels.getText()["ALL_CORRECT"]({maxScore:_maxScore})}
                    </p>
                <hr />
                <p className="mb-0">{labels.getText()["TOPIC_EXPERT"]()}
                   </p>
            </div>)
        }
        else {
            if (_maxScore > 0 && _actualScore / _maxScore > 0.6) {
                return (<div className="alert alert-success" role="alert">
                    <h4 className="alert-heading"> {labels.getText()["GOOD_JOB"]()} Good job!</h4>
                    <p>{labels.getText()["SOMEONE_CORRECT"]({actualScore:_actualScore, maxScore : _maxScore})}</p>
                    <hr />
                    <p className="mb-0">{labels.getText()["TEST_TRY_AGAIN"]()}
                        !</p>
                </div>)
            }
            else {
                return (<div className="alert alert-warning" role="alert">
                    <h4 className="alert-heading">{labels.getText()["STUDY_MORE"]()}</h4>
                    <p>{labels.getText()["SOMEONE_CORRECT_SAD"]({actualScore:_actualScore, maxScore : _maxScore})}</p>
                    <hr />
                    <p className="mb-0">{labels.getText()["TEST_TRY_AGAIN"]()}</p>
                </div>)
            }
        }


    }
    const CheckFlag = (questionsPackage) => {
        var count = 0;
        questionsPackage.questions.forEach((question) => {
            if (question.__checked == true) count++;
        });
        return count;
    }
    function _drawQuestions(summary) {
        return (
            <div>
                <div ref={fixedProgress}
                    className="border-secondary"
                    style={
                        {
                            zIndex: 10,
                            visibility: isVisible || finalScoreStatus != "questions" ? "hidden" : "visible",
                            position: "fixed",
                            opacity: 0.75,
                            left: "1em",
                            right: "1em",
                            top: "5em"
                        }
                    }
                >
                    <div>
                        <label>
                            {labels.getText()["PROGRESS"]()}
                        </label>
                        <div className="progress mx-auto w-75" style={{ marginBottom: "2em", height: "3em" }}>
                            <div className="progress-bar" role="progressbar" style={{ width: `${answered}%` }}>{`${answered}%`}</div>
                        </div>
                    </div>

                </div>
                <div ref={standardProgress}
                    style={
                        {

                            visibility: finalScoreStatus != "questions" ? "hidden" : "visible",
                            display: finalScoreStatus != "questions" ? "none" : null,
                        }
                    }>
                    <label>{labels.getText()["PROGRESS"]()}</label>
                    <div className="progress mx-auto w-75" style={{ marginBottom: "2em", height: "3em" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${answered}%` }}>{`${answered}%`}</div>
                    </div>
                </div>

                {finalScoreStatus == "summary" ? (_drawResultBar()) : null}

                <div >
                    {
                        questionsPackage.questions.map((question) => {
                            return _drawQuestion(question, questionsPackage.questions.findIndex(x => x === question), questionsPackage.answersType, summary);
                        })
                    }
                </div>



                <div>
                    <div className="row">
                        <div className="d-grid gap-2 col">
                            {(finalScoreStatus != "questions") ? (
                                <div className="container">
                                    <div className="row" style={{ '--bs-gutter-x': '0rem', display: 'flex', marginBottom: '1rem' }}>
                                        <button className="btn btn-primary" type="button" style={{ flex: 1 }}
                                            onClick={() => {
                                                setFinalScoreStatus("questions");
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}
                                        >{labels.getText()["BACK_TO_QUESTIONS"]()}</button>
                                    </div>
                                    <div style={{ '--bs-gutter-x': '0rem', display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{
                                            flex: 1, // Make each div take equal space
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '1px solid black',
                                            borderRadius: '10px',
                                            marginRight: '10px',
                                            borderColor: "#0dcaf0",
                                            color: "#0dcaf0",
                                            width: '100%',
                                        }}>
                                            <button className="btn btn-info" type="button" style={{ flex: 1 }}>
                                              {labels.getText()["SHARE_RESULT"]()}  
                                            </button>
                                        </div>
                                        <div style={{
                                            flex: 1, // Make each div take equal space
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '1px solid black',
                                            borderRadius: '10px',
                                        }}>
                                            <button
                                                className="btn btn-default"
                                                type="button"
                                                style={{ flex: 1 }} // Use flex to make it take the full height of the div
                                                onClick={async () => {
                                                    if (questionsPackage.answersType == "open") {
                                                        if (CheckFlag(questionsPackage) != questionsPackage.questions.length) {
                                                            var checkedAnswers = await _checkOpenAnswers(questionsPackage);
                                                            setopenQuestions(checkedAnswers);
                                                        }
                                                        onDownloadOpen(questionsPackage);
                                                    }
                                                    if (onSubmitted && questionsPackage.answersType != "open") {
                                                        onDownload(questionsPackage, 1);
                                                    }
                                                }}
                                            >
                                                {labels.getText()["DOWNLOAD_PDF"]()}
                                            </button>
                                        </div>
                                    </div>
                                </div>) : (
                                <div className="container">
                                    <div className="row" style={{ display: 'flex', marginBottom: '1rem' }}>
                                        <button
                                            className="btn btn-primary"
                                            type="button"
                                            style={{ flex: 1 }} // Use flex to make it take the full height of the row
                                            onClick={async () => {
                                                if (questionsPackage.answersType == "open") {
                                                    var checkedAnswers = await _checkOpenAnswers(questionsPackage);
                                                    setopenQuestions(checkedAnswers);
                                                    onSubmitted(checkedAnswers);
                                                }
                                                if (onSubmitted && questionsPackage.answersType != "open") {
                                                    onSubmitted(questionsPackage);
                                                }
                                                setFinalScoreStatus("summary");
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}
                                        >
                                            {labels.getText()["SUBMIT_BUTTON"]()}
                                        </button>
                                    </div>
                                    <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div
                                            style={{
                                                flex: 1, // Make each div take equal space
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '1px solid black',
                                                borderRadius: '10px',
                                                marginRight: '10px'
                                            }}
                                        >
                                            <button
                                                className="btn btn-default"
                                                type="button"
                                                style={{ flex: 1 }} // Use flex to make it take the full height of the div
                                                onClick={async () => {
                                                    if (questionsPackage.answersType == "open") {
                                                        if (CheckFlag(questionsPackage) != questionsPackage.questions.length) {
                                                            var checkedAnswers = await _checkOpenAnswers(questionsPackage);
                                                            setopenQuestions(checkedAnswers);
                                                        }
                                                        onDownloadOpen(questionsPackage);
                                                    }
                                                    if (onSubmitted && questionsPackage.answersType != "open") {
                                                        onDownload(questionsPackage, 0);
                                                    }
                                                }}
                                            >
                                                {labels.getText()["DOWNLOAD_PDF"]()}
                                            </button>
                                        </div>
                                        {/* {classroom ? null :<div
                                            style={{
                                                flex: 1, // Make each div take equal space
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '1px solid black',
                                                borderRadius: '10px',
                                            }}
                                        >
                                             <button
                                                className="btn btn-default"
                                                type="button"
                                                style={{ flex: 1 }} // Use flex to make it take the full height of the div
                                                onClick={async () => {
                                                    // Your onClick logic for the second button
                                                }}
                                            >
                                                {labels.getText()["ADD_TO_CLASSROOM"]()}
                                            </button>
                                        </div>} */}
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    const _checkOpenAnswers = async (questionsPackage) => {

        withReactContent(Swal).fire({
            title: <i>{labels.getText()["WAIT"]()}</i>,
            icon: "info",
            text: labels.getText()["CHECKING_ANSWERS"](),
            showConfirmButton: false,
            allowOutsideClick: false,
            showCloseButton: false
        });

        var correctAnswers = await api.sendMessage("checkOpenAnswers", {
            body: {
                // this is questions package ID, not doc id
                packageId: [id],
                answersArray: questionsPackage.questions,   
            },
            user: user,
        });

        return new Promise((resolve, reject) => {
            for (var i = 0; i < questionsPackage.questions.length; i++) {
                if( correctAnswers.aiAnswers[i].Correct != undefined && correctAnswers.aiAnswers[i].Correct !=null)
                {
                    questionsPackage.questions[i].Correct = correctAnswers.aiAnswers[i].Correct;
                    questionsPackage.questions[i].__checked = correctAnswers.aiAnswers[i].__checked;
                }

            }

            resolve(questionsPackage);

            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "success",
                text: labels.getText()["ANSWERES_CHECKED"]() 
            });
        });
    }


    const _drawTextArea = (question, index, summary, setQuestion) => {
        if (summary) {
            const answer = openQuestions.questions.find((element, idx) => idx === index);

            return (
                <div key={`textArea_${index}`} className="row" style={{ marginBottom: "1em" }}>
                    {answer.Correct ?
                        (
                            <div className="col-2 text-success"><GiConfirmed style={{ fontSize: "2em" }}

                            /></div>
                        )
                        :
                        (
                            <div className="col-2 text-danger"><VscError style={{ fontSize: "2em" }}

                            /></div>
                        )
                    }
                    <Form>
                        <Form.Group className="mb-3" controlId={`textArea_${index}`}>
                            <Form.Label>{labels.getText()["ANSWER"]()} </Form.Label>
                            <Form.Control as="textarea" rows={3} value={question.User_Answer} readOnly={true}
                            />
                        </Form.Group>
                    </Form>
                    <p><small>{labels.getText()["CONTEXT_HELP"]()} {question.Question_Context} 
                        {labels.getText()["PAGES_LIST_WITHPAGE"]({pages: question.Page_Number})}
                        </small></p>
                </div>
            );
        }
        else {
            return (
                <div key={`textArea_${index}`} className="row" style={{ marginBottom: "1em" }}>
                    <Form>
                        <Form.Group className="mb-3" controlId={`textArea_${index}`}>
                            <Form.Label>
                                {labels.getText()["ANSWER"]()}
                                 </Form.Label>
                            <Form.Control as="textarea" rows={3} value={question.User_Answer} onChange={
                                (e) => {
                                    question.User_Answer = e.target.value;
                                    question.__checked = false;
                                    _computeAnswered();
                                    setQuestion(question);
                                }
                            } />
                        </Form.Group>
                    </Form>
                </div>
            );
        }
    }

    const _drawAnswer = (question, answer, questionIndex, index, setQuestion, summary) => {
        if (summary) {
            return (
                <div key={`answer_${questionIndex}_${index}`} className="row" style={{ marginBottom: "1em" }}>
                    {answer.Correct ?
                        (
                            <div className="col-2 text-success"><GiConfirmed style={{ fontSize: "2em" }}

                            /></div>
                        )
                        :
                        (
                            <div className="col-2 text-danger"><VscError style={{ fontSize: "2em" }}

                            /></div>
                        )
                    }

                    <div className="col text-left" style={{ textAlign: "left", fontStyle: "italic" }}>
                        {answer.Answer_Text}
                        {summary && answer.Correct ? (
                            <p><small><br />From page {answer.PageNumber}</small>
                                <br />
                                <small><br /> ... {answer.AnswerLocation} ...</small>
                            </p>
                        ) : null}
                    </div>
                </div>);
        }
        else {
            const _toggleAnswer = () => {
                var _actualSelected = answer.selected;

                if (questionsPackage.correctAnswers === 'single_choice') {
                    question.Question_Answers.forEach(a => {
                        a.selected = false;
                    });
                }
                answer.selected = !_actualSelected;

                _computeAnswered();
                setQuestion(question);
            };

            return (
                <div key={`answer_${questionIndex}_${index}`} className="row" style={{ marginBottom: "1em" }}>
                    {answer.selected ?
                        (
                            <div className="col-2 text-info"><ImCheckboxChecked style={{ fontSize: "2em" }}
                                onClick={() => {
                                    _toggleAnswer();
                                }}
                            /></div>
                        )
                        :
                        (
                            <div className="col-2 text-info"><ImCheckboxUnchecked style={{ fontSize: "2em" }}
                                onClick={() => {
                                    _toggleAnswer();
                                }}
                            /></div>
                        )
                    }

                    <div
                        onClick={() => {
                            _toggleAnswer();
                        }}
                        className="col text-left"
                        style={{ cursor: "pointer", textAlign: "left", fontStyle: "italic" }}> {answerCodes[index]} - {answer.Answer_Text}</div>
                </div>);

        }

    }

    const _drawAnswers = (question, index, setQuestion, summary) => {
        if (!summary) {
            return (
                <div>
                    {
                        question.Question_Answers.map((answer) => {
                            return _drawAnswer(question, answer, index, question.Question_Answers
                                .findIndex(x => x === answer), setQuestion);
                        })
                    }
                </div>
            )

        }
        else {
            if (questionsPackage.answersType == "open") {

            }
            else {
                let _answered;
                let _correctOne;
                _answered = question.Question_Answers.filter(x => x.selected);
                _correctOne = question.Question_Answers.filter(x => x.Correct);
                const _answeredPart = () => {
                    if (_answered.length) {
                        return _answered.map((answer, index) => {
                            return _drawAnswer(question, answer, index, question.Question_Answers
                                .findIndex(x => x === answer), setQuestion, summary);
                        })
                    }
                    else {
                        return (<div style={{ fontSize: 40 }}>{labels.getText()["ANSWER_NOT_SELECTED"]()}</div>)
                    }
                }
                const _scorePart = () => {
                    return _correctOne.map((correct, index) => {
                        if (_answered?.Correct) {
                            return (<div>Your answer</div>)
                        }
                        else {

                            return (

                                <div>
                                    {_drawAnswer(question, correct, index, question.Question_Answers
                                        .findIndex(x => x === correct), setQuestion, summary)}
                                </div>
                            )
                        }
                    })

                }
                return (<div>
                    <label>{labels.getText()["YOU_ANSWERED"]()}</label>
                    <hr />
                    {_answeredPart()}
                    <label>{labels.getText()["CORRECT_ANSWER"]()}</label>
                    <hr />
                    {_scorePart()}
                </div>);
            }
        }
    }

    const _drawQuestion = (question, index, answersType, summary) => {

        const [_refreshVar, _setRefreshVar] = useState(null);

        const qCallBack = (_q) => {
            _setRefreshVar(new Date());
        }
        return (
            <div key={`question_${index}`} className="card" style={{ marginBottom: "2em" }}>
                <div className="card-header">
                    {index + 1} - {question.Question_Text}
                </div>
                {answersType == "open" ?
                    (
                        <div className="card-body">
                            <div className="card-text">
                                {_drawTextArea(question, index, summary, qCallBack)}
                            </div>
                        </div>
                    )
                    :
                    (
                        <div className="card-body">
                            <div className="card-text">
                                {_drawAnswers(question, index, qCallBack, summary)}
                            </div>
                        </div>
                    )
                }

            </div>
        );

    }


    if (questionsPackage?.questions) {
        switch (finalScoreStatus) {
            case "summary": {
                return _drawQuestions(true);
            }
                break;
            default:
                {
                    return _drawQuestions(false);
                }
                break;
        }

    }
    else {
        return (null);
    }
}

export { Survey_Widget }