import avatars from '../assets/img/landing-page/Avatars.svg';
import imageCover from '../assets/img/landing-page/Img Cover.svg';
import studentVilleLogo from '../assets/img/logos/LogoStudentville.svg';
import logoPuntoInformatico from '../assets/img/logos/LogoPuntoInformatico.svg';
import investLithuania from '../assets/img/logos/Invest_Lithuania_logo.svg';

import rectangle2 from '../assets/img/landing-page/Rectangle 580.svg';
import rectangle3 from '../assets/img/landing-page/Rectangle 581.svg';
import rectangle4 from '../assets/img/landing-page/Rectangle 582.svg';
import React, { useContext } from 'react';
import styles from './../landing.module.css';
import Stickers1 from '../assets/img/landing-page/Stickers.svg';
import * as labels from '../components/localized/labels';
import students from '../assets/img/landing-page/Students.svg';
import teacher from '../assets/img/landing-page/Teacher.svg';
import student from '../assets/img/landing-page/Student.svg';
import quote from '../assets/img/landing-page/Quote.svg';
import teacherIcon from '../assets/img/landing-page/TeacherIcon.svg';
import purpleQuote from '../assets/img/landing-page/PurpleQuote.svg';
import Subscriptions from '../components/Subscriptions';
import Card from '../components/Card';
import UserContext from '../components/User_Context';
import { SetMenu } from '../components/Navigation';
import { useParams } from 'react-router-dom';

function Landing_Screen(props) {
  let { user } = useContext(UserContext);
  user = user ? JSON.parse(user) : null;
  const language = labels.getLanguage()
  return (
    <div className={styles.wrap}>
      {SetMenu({ user: user })}
      <section style={{ width: '100%' }} className={(styles.transformBooksSection, props.className, 'landing-page')}>
        <div className={styles.flexRowContainer}>
          <div className={styles.flexColumnContainer}>
            <div className={styles.imageColumn}>
              <img className={styles.heroImage} src={Stickers1} alt="alt text" />
              <h1 className={styles.heroTitle}>
                {labels.getText()["BOOKS_TRANSFORM_SUPERPOWERS"]()}
              </h1>
            </div>
            <div className={styles.textContentColumn}>
              <h2 className={styles.mediumTitle}>
                {labels.getText()["BOOKS_TRANSFORM_SUPERPOWERS_ANNA"]()}
              </h2>
              <div className={styles.actionButtonRow}>
                <button className={styles.trialButton} onClick={() => {
                  window.location.href = `/${labels.getLanguage()}/login`;
                }
                }>
                  {labels.getText()["TRY_ANNA"]()}
                </button>

                <div className={styles.studentHighlightRow} hidden>
                  <img
                    className={styles.highlightedImage}
                    src={avatars}
                    alt="alt text"
                  />
                  <p className={styles.studentHighlightText}>
                    {labels.getText()["USED_BY_STUDENTS"]()}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <img className={styles.additionalImage} src={imageCover} alt="alt text" />
        </div>
        <div className={styles.coverBottom} >
          <p className={styles.coverBottomText}>
            {labels.getText()["THEY_TALKED_ABOUT_US"]()}
          </p>
          <div className='row'
          >
            <div className={`${styles.logos} col-md-4`} >
              <a href="https://www.studentville.it/guide/anna-la-tutor-ai-che-cambia-il-tuo-modo-di-studiare/" target="_blank">

                <img src={studentVilleLogo} width="500" className='img-fluid' />

              </a>
            </div>
            <div className={`${styles.logos} col-md-4`}>
              <a href="https://www.punto-informatico.it/linnovazione-di-lab-h9-e-la-rivoluzione-educativa-di-anna-intervista-a-marco-aveta" target="_blank">

                <img src={logoPuntoInformatico} width="500" className='img-fluid' />

              </a>
            </div>
            <div className={`${styles.logos} col-md-4`}>
              <a href="https://investlithuania.com/news/lab-h9-announces-expansion-to-lithuania-bringing-ai-innovation-to-new-horizons/" target="_blank">

                <img src={investLithuania} width="500" className='img-fluid' />

              </a>
            </div>
          </div>
        </div>
      </section>
      <section style={{ width: '100%' }} className={styles.featuresSection}>
        <div className={styles.featuresContainer}>
          <div className={styles.featuresTitleContainer}>
            <p className={styles.featuresTitle}>{labels.getText()["BOOST_STUDY_WITH_AI"]()}</p>
            <p className={styles.featuresSubtitle}>{labels.getText()["LANDING_FUNCTIONS_DESC"]()}</p>
          </div>
          {/* <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))" }}> */}
          <div className={styles.cardsContainer}>
            <Card
              icon={`/huge-icon/QA/Robot.svg`}
              stickerLabel={labels.getText()["DOCUMENT_QA_SESSION_TITLE"]()}
              text={labels.getText()["LANDING_FUNCTIONS_DESC_CARD_1"]()}
            />
            <Card
              icon={`/huge-icon/QA/blockNotes.svg`}
              stickerLabel={labels.getText()["MIND_MAP_SESSION_TITLE"]()}
              text={labels.getText()["LANDING_FUNCTIONS_DESC_CARD_2"]()}
            />
            <Card
              icon={`/huge-icon/QA/test.svg`}
              stickerLabel={labels.getText()["TRAINING_SESSION_TITLE"]()}
              text={labels.getText()["LANDING_FUNCTIONS_DESC_CARD_3"]()}
            />
            <Card
              icon={`/huge-icon/Generic/quickCard.svg`}
              stickerLabel={labels.getText()["QUICKCARDS_SESSION_TITLE"]()}
              text={labels.getText()["LANDING_FUNCTIONS_DESC_CARD_4"]()}
            />
          </div>
        </div>
      </section>
      <section style={{ width: '100%' }} className={styles.studentsSection}>
        <div className={styles.studentsContainer}>
          <div className={styles.flexRowContainer}>
            <img className={styles.studentColumnImage} src={students} alt="students" />
            <div className={styles.flexColumnContainer}>
              <div className={styles.studentSticker}>
                <span className={styles.studentStickerText}>{labels.getText()["LANDING_STICKER_STUDENT_TEXT"]()}</span>
              </div>
              <p className={styles.studentsTitle}>{labels.getText()["LANDING_STUDENTS_TITLE"]()}</p>
              <p className={styles.studentsSubtitle}>{labels.getText()["LANDING_STUDENTS_SUBTITLE"]()}</p>
              <ul className={styles.studentsList}>
                <li>{labels.getText()["LANDING_STUDENTS_LIST_1"]()}</li>
                <li>{labels.getText()["LANDING_STUDENTS_LIST_2"]()}</li>
                <li>{labels.getText()["LANDING_STUDENTS_LIST_3"]()}</li>
                <li>{labels.getText()["LANDING_STUDENTS_LIST_4"]()}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section style={{ width: '100%' }} className={styles.studentsSection}>
        <div className={styles.studentsContainer}>
          <div className={styles.flexRowContainer}>
            <div className={styles.flexColumnContainer}>
              <div className={styles.teacherSticker}>
                <span className={styles.studentStickerText}>{labels.getText()["LANDING_STICKER_TEACHER_TEXT"]()}</span>
              </div>
              <p className={styles.studentsTitle}>{labels.getText()["LANDING_TEACHER_TITLE"]()}</p>
              <p className={styles.studentsSubtitle}>{labels.getText()["LANDING_TEACHER_SUBTITLE"]()}</p>
              <ul className={styles.studentsList}>
                <li>{labels.getText()["LANDING_TEACHER_LIST_1"]()}</li>
                <li>{labels.getText()["LANDING_TEACHER_LIST_2"]()}</li>
                <li>{labels.getText()["LANDING_TEACHER_LIST_3"]()}</li>
              </ul>
            </div>
            <img className={styles.studentColumnImage} src={teacher} alt="teacher" />
          </div>
        </div>
      </section>
      <section style={{ width: '100%' }} className={styles.subscriptionsSection} hidden={true}>
        <div className={styles.featuresContainer}>
          <div className={styles.featuresTitleContainer}>
            <p className={styles.featuresTitle}>{labels.getText()["LANDING_SUBSCRIPTIONS_TITLE"]()}</p>
            <p className={styles.featuresSubtitle}>{labels.getText()["LANDING_SUBSCRIPTIONS_SUBTITLE"]()}</p>
          </div>
          <Subscriptions />
        </div>
      </section>
      <section style={{ width: '100%' }} className={styles.commonQuestionsSection}>
        <div className={styles.commonQuestionsContainer}>

          <div className={styles.commonQuestionsTextColumn}>
            <p className={styles.commonQuestionsTitle}>{labels.getText()["LANDING_QUESTIONS_TITLE"]()}</p>
            <p className={styles.commonQuestionsDescription}>{labels.getText()["LANDING_QUESTIONS_DESCRIPTION"]()}</p>

            <button className={styles.trialButton} onClick={() => window.location.href = `/${language}/aboutAnna`}>
              {labels.getText()["HOW_ANNA_WORK"]()}

            </button>
          </div>
          <div className={styles.commonQuestionsQuestionsColumn}>

            {/* zero qa container */}
            <div className={styles.questionContainer}>
              <div className={styles.questionTitle} type="button" data-bs-toggle="collapse" data-bs-target="#qa0" aria-expanded="false" aria-controls="collapseExample">
                <p>{labels.getText()["LANDING_QUESTIONS_LIST_0"]()}</p>
                <span>+</span>
              </div>
              <div className="collapse Text500__5" id="qa0">
                <p className={styles.questionText}>{labels.getText()["LANDING_QUESTIONS_LIST_0_ANSWER"]()}</p>
              </div>
            </div>
            {/* zero first  qa container  */}


            {/* first qa container */}
            <div className={styles.questionContainer}>
              <div className={styles.questionTitle} type="button" data-bs-toggle="collapse" data-bs-target="#qa1" aria-expanded="false" aria-controls="collapseExample">
                <p>{labels.getText()["LANDING_QUESTIONS_LIST_1"]()}</p>
                <span>+</span>
              </div>
              <div className="collapse Text500__5" id="qa1">
                <p className={styles.questionText}>{labels.getText()["LANDING_QUESTIONS_LIST_1_ANSWER"]()}</p>
              </div>
            </div>
            {/* end first  qa container  */}


            {/* second qa container */}
            <div className={styles.questionContainer}>
              <div className={styles.questionTitle} type="button" data-bs-toggle="collapse" data-bs-target="#qa2" aria-expanded="false" aria-controls="collapseExample">
                <p>{labels.getText()["LANDING_QUESTIONS_LIST_2"]()}</p>
                <span>+</span>
              </div>
              <div className="collapse Text500__5" id="qa2">
                <p className={styles.questionText}>{labels.getText()["LANDING_QUESTIONS_LIST_2_ANSWER"]()}</p>
              </div>
            </div>
            {/* end second  qa container  */}


          </div>
        </div>
      </section>
      <section style={{ width: '100%' }} className={styles.messagesSection} hidden>
        <div className={styles.messagesContainer}>
          <div className={styles.messagesInnerContainer}>
            <p className={styles.messagesTitle}>{labels.getText()["LANDING_MESSAGES_TITLE"]()}</p>
            <div className={styles.messagesContentContainer}>
              <div className={styles.messagesContent}>
                <div className={styles.messagesContentText}>
                  <div className={styles.messageQuote}>
                    <img src={quote} alt="quote" />
                  </div>
                  <p>{labels.getText()["LANDING_MESSAGES_QUOTE1"]()}</p>
                </div>
                <div className={styles.messagesContentAuthor}>
                  <img src={student} alt="Avatar" />
                  <p>{labels.getText()["LANDING_MESSAGES_QUOTE1_AUTHOR"]()}</p>
                  <div className={styles.messageStricker}>
                    <span>{labels.getText()["STUDENT"]()}</span>
                  </div>
                </div>
              </div>
              <div className={styles.messagesContent}>
                <div className={styles.messagesContentText}>
                  <div className={styles.messageQuote}>
                    <img className={styles.quote} src={purpleQuote} alt="quote" />
                  </div>
                  <p>{labels.getText()["LANDING_MESSAGES_QUOTE2"]()}</p>
                </div>
                <div className={styles.messagesContentAuthor}>
                  <img src={teacherIcon} alt="Avatar" />
                  <p>{labels.getText()["LANDING_MESSAGES_QUOTE2_AUTHOR"]()}</p>
                  <div className={styles.message2Stricker}>
                    <span>{labels.getText()["TEACHER"]()}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Landing_Screen;
