import '../App.css';
import UserContext from '../components/User_Context';
import { React, useState, useContext, useEffect } from 'react';
import * as labels from "../components/localized/labels";
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import { useNavigate, useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import * as api from '../api/apiConnector';
import * as callRender from '../components/ApprovalCellRenderer';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function Class_Screen() {
    //classroom code from params
    const { professorClassRoomId } = useParams();
    //about the class text
    const [text, setText] = useState(labels.getText()["NO_INFORMATION_CLASS"]());
    //classroom object
    const [classroom, setClassroom] = useState(null);
    //professors in the classroom
    const [professors, setProfessors] = useState([]);
    //documents in the classroom
    const [documents, setDocuments] = useState([]);
    //students in the classroom
    const [student, setStudent] = useState([]);
    const navigate = useNavigate();
    //professor mail field 
    const [professorEmail, setProfessorEmail] = useState('');
    //flag to manage the modal showup
    const [showInviteProfessorModal, setShowInviteProfessorModal] = useState(false);
    //flag to manage the remove student modal
    const [showRemoveStudentModal, setShowRemoveStudentModal] = useState(false);
    //student id to remove frome the classroom 
    const [studentIdToRemove, setStudentIdToRemove] = useState(null);

    //flag to manage the remove professor modal
    const [showRemoveProfessorModal, setShowRemoveProfessorModal] = useState(false);
    //student id to remove frome the classroom 
    const [professorIdToRemove, setProfessorIdToRemove] = useState(null);

    //taking user form userContext
    let { user } = useContext(UserContext);
    user = user ? JSON.parse(user) : null;

    //default grid column
    const defaultColDef = {
        flex: 1,
        resizable: false
    };
    //date formatting
    const formatCreationDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    //text input change
    const handleTextChange = async (event) => {
        //changing text state variable
        setText(event.target.value);
        //calling api to save the text
        await api.sendMessage("saveClassText", {
            body: {
                classroomId: professorClassRoomId,
                text: event.target.value
            },
            user: user
        });

    };

    //navigating to the library
    const navigateToLibrary = () => {
        navigate(`/professor/library/classroom/${classroom._id}`);
    };

    //retriving folders
    const loadFolders = async () => {

        const folder = await api.sendMessage("getFolders", {
            body: {
                classroomId: professorClassRoomId
            },
            user: user,
        });
        return folder;

    };
    //retriving books
    const loadBooks = async () => {
        const book = await api.sendMessage("getDocs", {
            body: {
                professorclassroomId: professorClassRoomId
            },
            user: user,
        });
        return book;
    };
    //retriving classroom
    const getClass = async () => {
        var group = (await api.sendMessage("getClassroomFromId", {
            body: {
                id: professorClassRoomId
            },
            user: user,
        })).classroom;
        setClassroom(group);
        return group;
    }
    //retriving professors
    const getProfessor = async () => {
        var group = (await api.sendMessage("getClassroomProfessor", {
            body: {
                code: professorClassRoomId
            },
            user: user,
        })).professors;
        return group;
    }
    //retriving students
    const getStudents = async () => {
        var group = (await api.sendMessage("getClassroomStudent", {
            body: {
                classroomId: professorClassRoomId
            },
            user: user,
        })).students;
        return group;
    }
    const refreshDocuments = async () => {
        const folder = await loadFolders();
        const book = await loadBooks();
        let newFolder = [];
        let newBook = [];
        //taking only needed params
        if (folder.result) {
            newFolder = folder.result.map(f => ({
                _id: f._id,
                name: f.name,
                creationDate: formatCreationDate(f.creationDate),
                type: "folder"
            }));
        }
        //taking only needed params
        if (book.documents) {
            newBook = book.documents.map(f => ({
                _id: f._id,
                name: f.fileName,
                creationDate: formatCreationDate(f.uploadTime),
                type: "book"
            }));
        }
        //setting everything into one variable
        setDocuments([...newFolder, ...newBook])
        const professor = await getProfessor();
        const student = await getStudents();
        const classRoom = await getClass();
        setClassroom(classRoom);
        setProfessors(professor);
        setStudent(student);
        setText(classRoom.text || labels.getText()["NO_INFORMATION_CLASS"]());

    }
    useEffect(() => {
        (async () => {
            refreshDocuments();
        })();
    }, []);
    //professors grid columns
    const colProf = [
        { headerName: labels.getText()["PROFESSOR_NAME"](), field: 'name' },
        { headerName: labels.getText()["EMAIL_PROFESSOR"](), field: 'eMail' },
        { headerName: labels.getText()["MOBILE_PHONE"](), field: 'mobileNumber' },
        {
            headerName: labels.getText()["PROFESSOR_ACTION"](),
            cellRenderer: callRender.RemoveProfessoCellRender, // Use the cell renderer component
            cellRendererParams: {
                classroomCode: professorClassRoomId, // Pass any other props you want to pass              
                onRemove: (professor) => removeProfessorOpeningModal(professor),

            }
        }
    ];
    const acceptStudent = async (student, classroomId) => {
        const result = await api.sendMessage("acceptStudent", {
            body: {
                classroomId: classroomId,
                student: student
            },
            user: user
        });
        refreshDocuments();
        return result;
    };

    const removeStudentOpeningModal = async (student) => {
        setStudentIdToRemove(student._id);
        setShowRemoveStudentModal(true);

    };

    const removeProfessorOpeningModal = async (professor) => {
        setProfessorIdToRemove(professor._id);
        setShowRemoveProfessorModal(true);

    };

    const removeProfessor = async () => {
        const result = await api.sendMessage("removeProfessorInClassroom", {
            body: {
                classroomId: professorClassRoomId,
                professorId: professorIdToRemove
            },
            user: user
        });

        if (result.success) {
            setShowRemoveProfessorModal(false);
            withReactContent(Swal).fire({
                title: labels.getText()["PROFESSOR_DELETE_DONE"](),
                icon: "sucess",
                text: labels.getText()["PROFESSOR_DELETE_SUCCESSFULLY"](),
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: false
            });
            setProfessors((prevProfessor) => prevProfessor.filter(s => s._id !== professorIdToRemove));
            setProfessorIdToRemove(null);
            refreshDocuments();
        }
        else {
            withReactContent(Swal).fire({
                title: labels.getText()["ERROR"](),
                icon: "sucess",
                text: labels.getText()["PROFESSOR_DELETE_PROBLEM"](),
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: false
            });
        }
        return result;
    };

    const removeStudent = async () => {
        const result = await api.sendMessage("removeStudent", {
            body: {
                classroomId: professorClassRoomId,
                studentId: studentIdToRemove
            },
            user: user
        });

        if (result.success) {
            setShowRemoveStudentModal(false);
            withReactContent(Swal).fire({
                title: labels.getText()["STUDENT_DELETE_DONE"](),
                icon: "sucess",
                text: labels.getText()["STUDENT_DELETE_SUCCESSFULLY"](),
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: false
            });
            setStudent((prevStudents) => prevStudents.filter(s => s._id !== studentIdToRemove));
            setStudentIdToRemove(null);
            refreshDocuments();
        }
        else {
            withReactContent(Swal).fire({
                title: labels.getText()["ERROR"](),
                icon: "sucess",
                text: labels.getText()["STUDENT_DELETE_PROBLEM"](),
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: false
            });
        }
        return result;
    };

    const getStudent = async (student, classroom) => {
        const result = await api.sendMessage("getClassroomUserRelation", {
            body: {
                classroomId: classroom,
                student: student
            },
            user: user
        });
        if (result.checkFlag) {
            return result.checkFlag.active;
        }
        else {
            return null;
        }

    };

    //invteProfessor
    const inviteProfessor = async () => {
        setShowInviteProfessorModal(false);
        const book = await api.sendMessage("inviteProfessor", {
            body: {
                professorclassroomId: professorClassRoomId,
                email: professorEmail
            },
            user: user,
        });
        setProfessorEmail('');
        return book;
    };
    //students grid columns
    const colStud = [
        { headerName: labels.getText()["NAME"](), field: 'name' },
        { headerName: labels.getText()["LAST_NAME"](), field: 'lastName' },
        { headerName: labels.getText()["EMAIL_ADDRESS"](), field: 'eMail' },
        {
            headerName: labels.getText()["APPROVAL"](),
            cellRenderer: callRender.ApprovalCellRenderer, // Use the cell renderer component
            cellRendererParams: {
                classroomCode: professorClassRoomId, // Pass any other props you want to pass
                onAccept: (student, professorClassRoomId) => acceptStudent(student, professorClassRoomId),
                onRemove: (student) => removeStudentOpeningModal(student),
                getStudent: (student, professorClassRoomId) => getStudent(student, professorClassRoomId),
            }
        }
    ];

    //documents grid columns
    const colDoc = [
        { headerName: labels.getText()["DOCUMENT_NAME"](), field: 'name' },
        { headerName: labels.getText()["UPLOADED_IN"](), field: 'creationDate' },
        { headerName: labels.getText()["ELEMENT_TYPE"](), field: 'type' },
    ];
    //when email field changes calling this function
    const handleChange = (value) => {
        setProfessorEmail(value);
    };
    //add new card modal 
    const inviteNewprofessor = () => {
        return (<Modal show={showInviteProfessorModal} onHide={() => { setShowInviteProfessorModal(false); setProfessorEmail(''); }}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {labels.getText()["INVITE_PROFESSOR"]()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="accordion-body" style={{ textAlign: 'left' }}>asd
                    <input type="text" className="custom-text-input" placeholder={labels.getText()["INSERT_EMAIL"]()} onChange={(event) => handleChange(event.target.value)} value={professorEmail} ></input>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="then-small-button" type="button" onClick={() => { setShowInviteProfessorModal(false); setProfessorEmail(''); }}>
                    {
                        labels.getText()["CLOSE"]()
                    }
                </button>
                <button className="then-small-button" type="button" onClick={inviteProfessor}>
                    {
                        labels.getText()["INVITE_PROFESSOR"]()
                    }
                </button>
            </Modal.Footer>
        </Modal>);

    };

    const removeProfessorModal = () => {
        return (<Modal show={showRemoveProfessorModal} onHide={() => { setShowRemoveProfessorModal(false); }}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {labels.getText()["REMOVE_PROFESSOR_HEADER"]()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {labels.getText()["REMOVE_PROFESSOR_BODY"]()}
            </Modal.Body>
            <Modal.Footer>
                <button className="then-small-button" type="button" onClick={() => { setShowRemoveProfessorModal(false); }}>
                    {
                        labels.getText()["CLOSE"]()
                    }
                </button>
                <button className="then-small-button" type="button" onClick={() => removeProfessor()}>
                    {
                        labels.getText()["REMOVE_PROFESSOR_BUTTON"]()
                    }
                </button>
            </Modal.Footer>
        </Modal>);

    };


    const removeStudentModal = () => {
        return (<Modal show={showRemoveStudentModal} onHide={() => { setShowRemoveStudentModal(false); }}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {labels.getText()["REMOVE_STUDENT_HEADER"]()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {labels.getText()["REMOVE_STUDENT_BODY"]()}
            </Modal.Body>
            <Modal.Footer>
                <button className="then-small-button" type="button" onClick={() => { setShowRemoveStudentModal(false); }}>
                    {
                        labels.getText()["CLOSE"]()
                    }
                </button>
                <button className="then-small-button" type="button" onClick={() => removeStudent()}>
                    {
                        labels.getText()["REMOVE_STUDENT_BUTTON"]()
                    }
                </button>
            </Modal.Footer>
        </Modal>);

    };

    return (
        <div className="App wrap">
            <div className="container-fluid bg-light py-5 px-1">
                {SetMenu({ user })}
                {getBreadcrumb({ page: "class_room_management", professorclassroomId: professorClassRoomId, classroomName: classroom?.name })}
                            <h1 className="document-boost display-4 mb-4 animated slideInRight">
                                {classroom ? labels.getText(classroom.name)["CLASS_NAME"]({ name: classroom.name }) : labels.getText()["LOADING"]()}
                            </h1>
                <div className="container-fluid py-5">
                    <div className="row">

                        <h2>{labels.getText()["GENERAL_INFO"]()}</h2>
                        <h4 className="bc-grey float-start">{labels.getText()["CLASSROOM_CODE_LABEL_PROFESSOR"]({ code: classroom?.classRoomCode })}</h4>

                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        <h4>{labels.getText()["ABOUT_CLASS"]()}</h4>
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body" style={{ textAlign: 'left' }}>
                                        <textarea className="form-control" value={text} onChange={handleTextChange} style={{ width: '100%', height: '300px' }} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <button type="button" className="then-small-button float-end" onClick={navigateToLibrary}>{labels.getText()["GO_TO_LIBRARY"]()}</button>
                            <h2>{labels.getText()["DOCUMENT_FOLDERS"]()}</h2>

                            <div
                                className="ag-theme-quartz" // applying the grid theme
                                style={{ height: 300, marginTop: 15, textAlign: 'center' }} // the grid will fill the size of the parent container
                            >
                                <AgGridReact
                                    rowData={documents}
                                    columnDefs={colDoc}
                                    defaultColDef={defaultColDef}
                                    overlayNoRowsTemplate={`<span>${labels.getText()["NO_DOCUMENTS_GRID"]()}</span>`}
                                />

                            </div>
                            <br />
                            <button type="button" className="then-small-button float-end" onClick={() => {
                                setShowInviteProfessorModal(true);
                            }}>{labels.getText()["INVITE_PROFESSORS"]()}</button>
                            <h2>{labels.getText()["CLASSROOM_PROFESSORS"]()}</h2>
                            <br />
                            <div
                                className="ag-theme-quartz" // applying the grid theme
                                style={{ height: 300, marginTop: 15 }} // the grid will fill the size of the parent container
                            >
                                <AgGridReact
                                    rowData={professors}
                                    columnDefs={colProf}
                                    defaultColDef={defaultColDef}
                                    overlayNoRowsTemplate={`<span>${labels.getText()["NO_PROFESSOR_GRID"]()}</span>`}

                                />
                            </div>
                            <br />

                            <h2>{labels.getText()["CLASSROOM_STUDENTS"]()}</h2>
                            <br />
                            <div
                                className="ag-theme-quartz" // applying the grid theme
                                style={{ height: 300, marginTop: 15 }} // the grid will fill the size of the parent container
                            >
                                <AgGridReact
                                    rowData={student}
                                    columnDefs={colStud}
                                    defaultColDef={defaultColDef}
                                    overlayNoRowsTemplate={`<span>${labels.getText()["NO_STUDENT_GRID"]()}</span>`}

                                    rowHeight={50}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {inviteNewprofessor()}
            {removeStudentModal()}
            {removeProfessorModal()}


        </div>
    );

}

export default Class_Screen;
