import { React, useState, useEffect, useContext, useRef } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as labels from "../components/localized/labels";
import * as api from '../api/apiConnector';
import UserContext from '../components/User_Context';
const chunkSize = 1024 * 1024 * 1;
import { getBreadcrumb, SetMenu } from '../components/Navigation';

const Camera_Book_Screen = (params) => {
    const [pictures, setPictures] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(null);
    let { user } = useContext(UserContext);
    user = user ? JSON.parse(user) : null;
    let system_message_timer = null;

    useEffect(() => {
        _startChat();
    }, []);

    useEffect(() => {
        if (window.$("#carousel").length) {
            const carousel = new bootstrap.Carousel('#carousel');
        }

        window.$("#camera-add-picture-icon").animate({
            opacity: 1,
        }, 750, function () {

        });

    }, [pictures]);

    //#region upload
    const fileUpload = async (params) => {
        const { files, showProgress = true, onFileAdded } = params;

        if (files) {
            for (var i = 0; i < files.length; i++) {
                const f = files[i];
                const { size, name, type, lastModifiedDate } = f;
                window.client.filesCounter++;

                let start = 0;
                let end = chunkSize - 1;

                while (start < size) {
                    const chunk = f.slice(start, end + 1);
                    const chunk_array = await chunk.arrayBuffer();
                    const chunk_data = Array.from(new Uint8Array(chunk_array));

                    var startResult = await _sendMessage({
                        type: "add_picture_chunk",
                        picture_id: window.client.filesCounter,
                        data: chunk_data
                    });

                    start += chunkSize;
                    end += chunkSize;

                    await new Promise(resolve => setTimeout(resolve, 250));
                }

                pictures.push(URL.createObjectURL(f));
                setPictures(JSON.parse(JSON.stringify(pictures)));
            }
        }
    }
    //#endregion

    //#region chat
    const show_system_message = (params) => {
        const { message, timeOut = 2000 } = params;

        const inner_div = window.$(`<div class="col-xl-12">
            <div class="card dz-card">
                <div class="card-header flex-wrap border-0">
                    <div>
                        <h4 class="card-title">ANNA CHATBOT</h4>
                    </div>											
                </div>
                <div class="tab-content">
                    <div class="tab-pane fade active show" role="tabpanel">
                        <div class="card-body pt-0">
                            <h6><span></span>
                                <span class="pull-end"></span>
                            </h6>                                                                        
                        </div>
                    </div>
                </div>
    
            </div>
        </div>`);

        let div = window.$("#chat-system-message");

        if (div.length == 0) {
            div = window.$(`<div id="chat-system-message" style="position: fixed; right: 20px; bottom: 30px; width: 350px; height: 100px; opacity: 0.75"></div>`);
            window.$("body").append(div);
            div.append(inner_div);
        }

        const message_div = div.find(".pull-end");


        message_div.html("");
        message_div.append(window.$("<div/>").html(message));

        if (system_message_timer) {
            clearTimeout(system_message_timer);
        }

        if (timeOut > 0) {
            system_message_timer = setTimeout(() => {
                div.remove();
            }, timeOut);
        }
    }

    const _parseMessage = (message) => {
        switch (message.type) {
            case "create_document_done": {
                window.document.location.href = `/${labels.getLanguage()}/document/${message.document._id}`;
            }
                break;
            case "wellcome": {
                window.client.id = message.id;
                _sendMessage({
                    type: "authenticate",
                    token: user.token
                });
                show_system_message({
                    timeOut: 0,
                    message: `
                        <strong>${labels.getText()["CONNECTING_CHAT_AI_ENGINE"]()}</strong>...
                        `});
            }
                break;
            case "authenticated": {
                window.$("#question-area").prop("hidden", null);

                show_system_message({
                    message: `
                        <strong>${labels.getText()["CONNECTED_CHAT_AI_ENGINE"]()}</strong>...
                        `});


            }
                break;
        }
    }

    const _sendMessage = async (message) => {
        if (window.client?.id) {
            message.id = window.client.id;
        }

        var result = await api.sendMessage("camera/sendChatMessage", {
            user: user,
            body: {
                message
            }
        });
    }

    const _startChat = async () => {
        if (!window.client) {
            const _client = new window.EventSource(`${process.env.REACT_APP_API_URL}/camera/startChat`);
            _client.onmessage = (e) => {
                _parseMessage(JSON.parse(e.data));
            }

            window.client = {
                emitter: _client,
                filesCounter: 0
            }

            return _client;
        }
    }
    //#endregion

    const _pictures = () => {
        if (pictures.length > 0) {
            return (
                <div style={{ padding: 15 }}>
                    <div style={{

                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"

                    }}>
                        <div id="carousel" className="carousel slide" style={{ maxWidth: 400, maxHeight: 350, minHeight: 350 }} onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}>
                            <div className="carousel-inner">
                                {pictures.map((x) => {
                                    return (<div key={`picture_${x}`} className={`carousel-item ${pictures[pictures.length - 1] === x ? "active" : ""}`}>
                                        <img src={x} className={`img-thumbnail`} alt="..." style={{ maxHeight: 350, minHeight: 350 }} />
                                        <div
                                            onClick={(e) => {
                                                const _p = window.$(`img[src='${x}']`);

                                                if (pictures.length == 1) {
                                                    window.$("#camera-add-picture-icon").animate({
                                                        opacity: 0,
                                                    }, 750, function () {

                                                    });
                                                }

                                                _p.animate({
                                                    opacity: 0,
                                                }, 750, function () {
                                                    pictures.splice(pictures.findIndex(y => y === x), 1);
                                                    setPictures(JSON.parse(JSON.stringify(pictures)));
                                                });


                                            }}
                                            style={{
                                                position: "absolute",
                                                zIndex: 102,
                                                right: 2,
                                                bottom: 2,
                                                color: "white"
                                            }}

                                        >
                                            <span className="fa-stack fa-2x">
                                                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                                                <i className="fa fa fa-trash fa-1x fa-stack-1x fa-inverse"></i>
                                            </span>
                                        </div>
                                    </div>)
                                })}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>);
        }
    }

    const _dropZone = () => {
        return (<><div className="wow fadeIn library-drop-zone drop-zone drag-zone container" data-wow-delay="0.1s"
            style={{
                marginTop: 20,
                height: 400,
                width: "100%",
                cursor: "pointer"
            }}
            onClick={(e) => {
                window.$("#input-image-upload").click();
            }}
            onDragEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();

            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();

            }}
            onDragEnd={(e) => {
                e.preventDefault();


            }}
            onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();


            }}
            onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();


                const files = e.dataTransfer ?
                    Array.from(e.dataTransfer.files) :
                    Array.from(e.target.files);

                fileUpload({ files });
            }}>
            <div style={{
                position: "absolute",
                zIndex: 200,
                left: pictures.length > 0 ? 20 : null,
                top: pictures.length > 0 ? 20 : null
            }}>
                <i id="camera-add-picture-icon" className="fa fa-camera fa-4x"></i>
            </div>
            {_pictures()}
        </div>
            <input id="input-image-upload" className="inputFile" type="file" accept="image/*" multiple onChange={(e) => {
                fileUpload({ files: e.target.files });
            }} />
            <div style={{ textAlign: "center" }}>
                <button type="submit" className="then-small-button" onClick={async (e) => {
                    withReactContent(Swal).fire({
                        title: <i>{labels.getText()["LOADING"]()}</i>,
                        icon: "info",
                        text: labels.getText()["WAIT"](),
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        showCloseButton: true,
                    });

                    await _sendMessage({
                        type: "create_document"
                    });


                }}>OK</button>
            </div>
        </>
        );
    }

    return (<>
        {SetMenu({ user })}
        {_dropZone()}
    </>);
}

export default Camera_Book_Screen;