import '../App.css';
import { useContext } from "react";
import UserContext from '../components/User_Context';
import React, { useState, useRef } from 'react';
import * as labels from "../components/localized/labels";
import { SetMenu, } from '../components/Navigation';

function Terms_Conditions_Screen() {
    const { user } = useContext(UserContext);


    return (
        <div>
            {SetMenu({ user: user})}
            {labels.getText()["TERMS_AND_CONDITIONS_TEXT"]()};
        </div>

    );
}

export default Terms_Conditions_Screen;