import React, { forwardRef, useEffect, useRef, useState } from "react";
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
import * as labels from "../components/localized/labels";

const JSMindMM = forwardRef((props, ref) => {
  const { mind, styles, options, onNodeClick, document } = props;
  const jm = useRef();
  const [pdf, setPdf] = useState(null);
  const [isVisible, setIsVisible] = useState(false);


  useEffect(() => {
    if (!jm.current) {
      if (onNodeClick) {
        options.onNodeClick = onNodeClick;
      }

      jm.current = new window.jsMind(options);

      if (ref) {
        ref.current = jm.current;
      }
    }

    jm.current.show(mind);
    jm.current.view.setZoom(0.5);
    jm.current.view.e_panel.scrollLeft = jm.current.view.e_nodes.clientWidth / 2;
    
  }, [mind]);

  useEffect(() => {

    const nodes = window.document.getElementsByClassName('theme-greensea')[0];
    const edges = window.document.getElementsByClassName('jsmind')[0];

    // Calculate the combined canvas size
    const useWidth = Math.max(nodes.scrollWidth, edges.scrollWidth);
    const useHeight = Math.max(nodes.scrollHeight, edges.scrollHeight);


  }, [jm.current]);

  const downloadCanvas = () => {
    let canvasPdf = pdf;
    let filename = document.fileName;
    let parts = filename.split('.');
    let newFilename = parts[0];
    canvasPdf.save(`${newFilename}_mindmap.pdf`);
  }

  return (
    <>
      {isVisible ? (
        <button className="btn btn-primary rounded-pill px-4 mt-2 wow fadeIn" onClick={downloadCanvas} id="download-button">
          {labels.getText()["DOWNLOAD"]()}
        </button>
      ) : null
      }
      <div>
        <div id="jsmind_container" style={styles}>
        </div>
      </div>
    </>
  );
});

export default JSMindMM;

// this for future
// http://hizzgdev.github.io/jsmind/example/2_features.html