import '../App.css';
import UserContext from '../components/User_Context';
import { React, useState, useContext, useEffect } from 'react';
import * as labels from "../components/localized/labels";
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import { Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { HexColorPicker } from 'react-colorful';
import { Dropdown } from 'react-bootstrap';
import * as api from '../api/apiConnector';
import Swal from 'sweetalert2';

import withReactContent from 'sweetalert2-react-content';
function ClassRooms_Screen() {
    //taking user form userContext
    let { user } = useContext(UserContext);
    const { professor } = useParams();
    //navigation
    const navigate = useNavigate();
    //flag to manage the modal showup
    const [showModal, setShowModal] = useState(false);
    //clasrooms array
    const [cards, setCards] = useState([]);
    //card name
    const [cardName, setCardName] = useState("");
    //managing cards into pages
    const [currentPage, setCurrentPage] = useState(1);
    //card color
    const [color, setColor] = useState('#ffffff');
    //flag for editing button
    const [editFlag, setFlag] = useState(false);
    //clasroom code
    const [code, setCode] = useState('');
    const [activation, setActivation] = useState(false);
    const [studentFlag, setStudentFlag] = useState(false);
    const [classroomId, setClassroomId] = useState(null);

    const [showDeleteStudentModal, setShowDeleteStudentModal] = useState(false);


    //how many cards per page
    const cardsPerPage = 12;
    //checking if we have the user
    user = user ? JSON.parse(user) : null;
    //when name field changes calling this function
    const handleChange = (value) => {
        setCardName(value);
    };
    //when color field changes calling this function
    const handleColorChange = (color) => {
        setColor(color);
    };
    //getting classrooms from db
    const getData = async () => {
        return (await api.sendMessage("getClasses", {
            user: user,
        })).classrooms;
    }
    //getting classrooms from db
    const getStudentsData = async () => {
        return (await api.sendMessage("getStudentClasses", {
            user: user,
        })).classrooms;
    }
    //setting cards array with classrooms
    const loadCards = async () => {
        const _classrooms = professor ? await getData() : await getStudentsData();
        if (_classrooms) {
            const newCards = _classrooms.map(classrooms => ({
                text: classrooms.name,
                color: classrooms.color,
                code: classrooms.classRoomCode, // classroom code
                classId: classrooms._id
            }));
            if (!professor) {
                for (const card of newCards) {
                    await showWaiting(card);
                }
            }

            setCards(newCards);

        }

    };
    //when page loads or cards changes
    useEffect(() => {
        loadCards();
    }, []);
    //add new card modal 
    const addNewCard = () => {
        return (<Modal show={showModal} onHide={() => { setShowModal(false); setCardName(''); setColor('#ffffff'); setFlag(false); }}>
            <Modal.Header closeButton>
                <Modal.Title className="Text700__5">
                    {labels.getText()["CREATE_CLASSROOM"]()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="Text500__5">
                <div>
                    {labels.getText()["CLASSROOM_NAME"]()}
                </div>
                <div>
                    <input type="text" className="custom-text-input" placeholder={labels.getText()["CLASSROOM_NAME"]()} onChange={(event) => handleChange(event.target.value)} value={cardName} ></input>
                </div>
                <br />
                <br />
                <div >
                    {labels.getText()["COLOR"]()}
                    <br />
                    <div className='row'>
                        <div className="col-md-6 d-flex align-items-center mt-3">
                            <HexColorPicker color={color} onChange={handleColorChange} />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <p> {labels.getText()["SELECTED_COLOR"]()} </p>
                            <div
                                style={{
                                    backgroundColor: color,
                                    width: '100px',
                                    height: '100px',
                                    marginLeft: '10px',
                                    border: '1px solid #000',
                                    borderRadius: '4px',
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="then-small-button" type="button" onClick={() => { setShowModal(false); setCardName(''); setColor('#ffffff'); setFlag(false); }}>
                    {
                        labels.getText()["CLOSE"]()

                    }
                </button>
                {!editFlag && cardName != '' ?
                    <button className="then-small-button" type="button" onClick={createNewCard}>
                        {
                            labels.getText()["CREATE_CARD"]()
                        }
                    </button> : null}
                {editFlag ?
                    <button className="then-small-button" type="button" onClick={editCard}>
                        {
                            labels.getText()["EDIT_CARD"]()
                        }
                    </button> : null}
            </Modal.Footer>
        </Modal>);

    };
    //activation code changes
    const handleInputChange = (e, index) => {
        const value = e.target.value.toUpperCase();
        if (/^[A-Z0-9]$/.test(value) || value === "") {
            let newCode = [...activationCode];
            newCode[index] = value;
            setActivationCode(newCode);

            if (value && index < 7) {
                document.getElementById(`codeInput-${index + 1}`).focus();
            }
        }
    };
    //when code submitted
    const handleSubmit = async () => {
        // Add logic to handle the code submission
        //console.log("Submitted Code:", activationCode);
        const result = (await api.sendMessage("addClassToStudentsLibrary", {
            body: {
                activationCode: activationCode
            },
            user: user
        }))
        if (!result.success) {
            withReactContent(Swal).fire({
                title: labels.getText()["ERROR"](),
                icon: "error",
                text: result.message,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true
            });
        }
        else {
            const sendMail = await api.sendMessage("sendEmailToProfessors", {
                body: {
                    classroom: activationCode
                },
                user: user
            })
        }
        setActivation(false);
        setActivationCode(new Array(8).fill(""));
        loadCards();
    };
    //activation code
    const [activationCode, setActivationCode] = useState(new Array(8).fill(""));
    //add new class into the classroom as a student
    const addNewClass = () => {
        return (<Modal show={activation} onHide={() => { setActivation(false); setActivationCode(new Array(8).fill("")); }}>
            <Modal.Header closeButton>
                <Modal.Title className="Text700__5">
                    {labels.getText()["ADD_CLASSROOM"]()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="Text500__5">
                <div className="form-group">
                    <label>{labels.getText()["ENTER_CODE"]()}</label>
                    <div className="code-inputs">
                        {activationCode.map((char, index) => (
                            <input
                                key={index}
                                id={`codeInput-${index}`}
                                type="text"
                                className="code-input"
                                value={char}
                                onChange={(e) => handleInputChange(e, index)}
                                maxLength="1"
                            />
                        ))}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="then-small-button" type="button" onClick={handleSubmit}>
                    {labels.getText()["ENTER_CLASS"]()}
                </button>
            </Modal.Footer>
        </Modal>);

    };
    //edit card function
    const editCard = async () => {
        //closing modal
        setShowModal(false);
        //checking if selected color is not white
        if (color === '#ffffff') {
            //generating random color 
            var randomColor = '#ffffff';
            while (randomColor === '#ffffff') {
                randomColor = Math.floor(Math.random() * 16777215).toString(16);
            }
            //calling api 
            await api.sendMessage("editClassRoom", {
                body: {
                    name: cardName,
                    color: "#" + randomColor,
                    classRoomCode: code
                },
                user: user,
            });
        }
        else {
            //calling api 
            await api.sendMessage("editClassRoom", {
                body: {
                    name: cardName,
                    color: color,
                    classRoomCode: code
                },
                user: user,
            });
        }

        //clearing variables
        setCardName('');
        setColor('#ffffff');
        setCode('');
        setFlag(false);
    }
    //create card function
    const createNewCard = async () => {
        //closing modal
        setShowModal(false);
        var result;
        var classRoomColor = color;

        //checking if selected color is not white
        if (color === '#ffffff') {
            //generating random color 
            var randomColor = '#ffffff';
            while (randomColor === '#ffffff') {
                randomColor = Math.floor(Math.random() * 16777215).toString(16);
            }

            classRoomColor = `#${randomColor}`;
        }

        //calling api 
        result = await api.sendMessage("createClassRoom", {
            body: {
                name: cardName,
                color: classRoomColor
            },
            user: user,
        });


        //alerts
        if (result.success) {

            //setting the classrooms to cards array
            setCards([...cards, { text: cardName, color: color }]);

            withReactContent(Swal).fire({
                title: labels.getText()["CLASSROOM_ADDED"](),
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
                allowOutsideClick: false,
                showCloseButton: false
            });
        }
        else {
            withReactContent(Swal).fire({
                title: labels.getText()["CLASSROOM_ADDED_ERROR"](),
                icon: "error",
                //text: labels.getText()[result.message](),
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true
            });
        }
        //clearing the variables
        setCardName('');
        setColor('#ffffff');
        loadCards();
    }

    const removeStudentFromClassroom = async () => {
        var result = (await api.sendMessage("deleteStudentFromClassRoom", {
            body: {
                classRoomId: classroomId
            },
            user: user,
        }));

        if(result.success){
            setShowDeleteStudentModal(false);
            setClassroomId("");
        }
    }
    const onDeleteStudentModal =  ()=> {
        return (<Modal show={showDeleteStudentModal} onHide={() => { setShowDeleteStudentModal(false); }}>
            <Modal.Header closeButton>
                <Modal.Title className="Text700__5">
                    {labels.getText()["LEAVE_CLASSROOM"]()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="Text500__5">
                <div>
                    {labels.getText()["LEAVE_CLASSROOM_MESSAGE"]()}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <button className="then-small-button" type="button" onClick={() => { setShowDeleteStudentModal(false) }}>
                    {
                        labels.getText()["CLOSE"]()

                    }
                </button>
                
                    <button className="then-small-button" type="button" onClick={removeStudentFromClassroom}>
                        {
                            labels.getText()["LEAVE_CLASSROOM_BUTTON"]()
                        }
                    </button>

            </Modal.Footer>
        </Modal>);
    }
    //deleting card
    const onDelete = async (value) => {

        //clearing from db
        var result = (await api.sendMessage("deleteClassRoom", {
            body: {
                classRoomCode: value.code
            },
            user: user,
        }));
        //alerts
        if (result.success) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["CLASSROOM_DELETE"]()}</i>,
                icon: "success",
                timer: 1000,
                showConfirmButton: false,
                allowOutsideClick: false,
                showCloseButton: false
            });
        }
        else {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["CLASSROOM_DELETE_ERROR"]()}</i>,
                icon: "error",
                timer: 1000,
                text: result.message,

                showConfirmButton: false,
                allowOutsideClick: false,
                showCloseButton: false
            });
        }
        //clearing from the array
        setCards(cards.filter(card => card !== value));


    }
    //splitting into the pages
    const getPageCards = () => {
        const startIndex = (currentPage - 1) * cardsPerPage;
        return cards.slice(startIndex, startIndex + cardsPerPage);
    };
    //going to next page
    const nextPage = () => {
        if (currentPage * cardsPerPage < cards.length) {
            setCurrentPage(currentPage + 1);
        }
    };
    //going back
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    //redirection to classroom
    const cardClicked = async (card) => {
        if (professor) {
            navigate(`/professor/classroom/${card.classId}`);
        }
        else {
            const result = await api.sendMessage("getClassroomUserRelation", {
                body: {
                    classroomId: card.classId
                },
                user: user
            });
            if (result.success) {
                navigate(`/library/classroom/${card.classId}`);
            }
            else {
                withReactContent(Swal).fire({
                    title: labels.getText()["ERROR"](),
                    icon: "error",
                    text: labels.getText()["YOU_CANNOT_ENTER_THIS_CLASS"](),
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    showCloseButton: false
                });
            }

        }

    }
    const showWaiting = async (card) => {
        const result = await api.sendMessage("getClassroomUserRelation", {
            body: {
                classroomId: card.classId
            },
            user: user
        });
        setStudentFlag(prevFlags => ({
            ...prevFlags,
            [card.code]: result.checkFlag
        }));
    };
    return (
        <div className="App">
            <div className='wrap'>
                <div className="py-5">
                    {SetMenu({ user: user })}
                    {getBreadcrumb({ page: "classRoomScreen", })}
                    <h1 className="document-boost">{labels.getText()["CLASSROOM"]()}</h1>
                    <div className="py-5">
                        
                        {professor ? <button className="then-big-button" onClick={() => {
                            setShowModal(true);
                        }}>
                            {labels.getText()["ADD_NEW_CLASSROOM"]()}
                        </button> : <button className="then-big-button" onClick={() => {
                            setActivation(true);
                        }}>
                            {labels.getText()["JOIN_NEW_CLASSROOM"]()}
                        </button>}
                        <div className="">
                            <div className="cards-container">
                                {getPageCards().map((card, index) => (
                                    <a key={index} className="cards" style={{ textDecoration: 'none', color: 'inherit' }} >
                                        <div className='row'>
                                            <div className="col-auto">
                                                <Dropdown style={{ width: '10px' }}>
                                                    {<Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                        <div className="three-dots"></div>
                                                        <div className="three-dots"></div>
                                                        <div className="three-dots"></div>
                                                    </Dropdown.Toggle>}
                                                    {professor ? <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => {
                                                            setCardName(card.text);
                                                            setColor(card.color);
                                                            setCode(card.code);
                                                            setFlag(true);
                                                            setShowModal(true);
                                                        }}>{labels.getText()["EDIT"]()}</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => {
                                                            onDelete(card);
                                                        }}>{labels.getText()["DELETE"]()}</Dropdown.Item>
                                                    </Dropdown.Menu> : <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => {
                                                            setShowDeleteStudentModal(true);
                                                            setClassroomId(card.classId)
                                                        }}>{labels.getText()["DELETE"]()}</Dropdown.Item>
                                                    </Dropdown.Menu>}

                                                </Dropdown>
                                            </div>
                                            <div  >
                                                <h5 style={{ marginTop: '-10px' }}>{card.text}</h5>
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => cardClicked(card)}
                                            style={{
                                                backgroundColor: card.color,
                                                width: '100%',
                                                height: '160px',
                                                margin: '1px auto',
                                                borderRadius: '10px',
                                            }}
                                        ></div>
                                        {professor ? <></> : <div>{studentFlag[card.code] ? '' : labels.getText()["WAITING_FOR_APPROVAL"]()}</div>}
                                    </a>
                                ))}
                            </div>
                            <div className="pagination">
                                {currentPage > 1 &&
                                    <button onClick={prevPage}>
                                        {labels.getText()["PREVIOUS"]()}
                                    </button>}
                                {currentPage * cardsPerPage < cards.length && (
                                    <button onClick={nextPage}>
                                        {labels.getText()["NEXT"]()}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        {addNewCard()}
                        {addNewClass()}
                        {onDeleteStudentModal()}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ClassRooms_Screen;
