import '../App.css';
import UserContext from '../components/User_Context';
import { React, useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams,useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as api from '../api/apiConnector';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as labels from "../components/localized/labels";
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import HtmlToDocx from '../components/export/HtmlToDocx';
import PagePreview from "../components/PagePreview.js";
import AudioRecorder from 'audio-recorder-polyfill'
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder'
import * as utils from '../libs/utils.js';
import { VolumeMeter } from '../components/VolumeMeter.js';
import { getAutoHeightDuration } from '@mui/material/styles/createTransitions.js';
import fileIcon from '../assets/img/huge-icon/FilesAndFolder/bulk/file03.svg';
import folderIcon from '../assets/img/huge-icon/FilesAndFolder/bulk/folder.svg'
import audioIcon from '../assets/img/Generic/mic.svg';
import videoIcon from '../assets/img/Generic/clapperboard.svg';
import bookLoader from '../assets/img/BookLoader/loader.svg'


import { faBlackTie } from '@fortawesome/free-brands-svg-icons'; AudioRecorder.encoder = mpegEncoder
AudioRecorder.prototype.mimeType = 'audio/mpeg'
window.MediaRecorder = AudioRecorder

function Q_A_Screen() {
    AudioRecorder.encoder = mpegEncoder
    AudioRecorder.prototype.mimeType = 'audio/mpeg'
    window.MediaRecorder = AudioRecorder;


    const _maxNumberOfQuestionsToShow = 5;
    const navigate = useNavigate();
    const [document, setDocument] = useState(null);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [question, setQuestion] = useState(null);
    const [buttonAvailability, setButtonAvailability] = useState(true);
    const buttonRef = useRef();
    const boxRef = useRef();
    const textAreaRef = useRef(null);
    const [pageDocument, setPageDocument] = useState(null);
    const [pageNumber, setPageNumber] = useState(null);

    const [questions, setQuestions] = useState([]);
    const [tState, setTState] = useState(null);
    const [folder, setFolder] = useState(null);
    const firstElementRef = useRef(null);
    let { user } = useContext(UserContext);
    const { documentId, folderId, professorclassroomId, classroomId,autoSearch } = useParams();
    user = user ? JSON.parse(user) : null;
    const [classroom, setClassroom] = useState(null);

    const isAudioEnabled = () => {
        return window.$("#recordingBar").is(":visible");
    }

    let recorder;
    let submitting = false;
    let recording = false;
    let playback = false;

    const setAnnaAnswering = (value) => {
        submitting = value;
        if (value) {
            window.$("#meterBar").parent().prop("hidden", true);
            window.$("#meterAnnaAnswering").prop("hidden", null);
        }
        else {
            window.$("#meterBar").parent().prop("hidden", null);
            window.$("#meterAnnaAnswering").prop("hidden", true);
        }
    }

    const startRecording = async () => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
            let lastVoice = null;
            let lastSilence = null;
            recorder = new MediaRecorder(stream);

            let meter = new VolumeMeter(getAudioContext());
            meter.connectToSource(stream, function () {
                setInterval(() => {
                    //console.log(meter.volume.toFixed(2));
                    window.$("#meterBar").css("width", `${meter.volume * 300}%`);

                    if (meter.volume > 0.07) {
                        lastVoice = lastVoice || new Date();
                        lastSilence = null;
                        if (!recording) {
                            recorder.start();
                            recording = true;
                        }
                    }
                    else {
                        lastSilence = lastSilence || new Date();
                    }

                    if (lastVoice && lastSilence && !submitting && !playback) {
                        let ms = new Date() - lastSilence.getTime();
                        if (ms > 2000) {
                            lastSilence = null;
                            lastVoice = null;
                            stopRecording();
                        }
                    }
                }, 250);
            });


            // Set record to <audio> when recording will be finished
            recorder.addEventListener('dataavailable', async (e) => {
                if (!isAudioEnabled() || playback) return;

                window.$("#recordingBar").prop("hidden", true);
                window.$("#microphone").attr("hidden", true);

                let audioData = await utils.blob2base64(e.data, "audio/mpeg");
                var response = await api.sendMessage('getAudioContentText', {
                    body: {
                        audioFile: audioData,
                        mimeType: 'audio/mpeg',
                        base64: true,
                        silentMode: true
                    },
                    user: user
                });

                setAnnaAnswering(true);
                submitQuestion(response.result.text, true);

                window.$("#recordingBar").prop("hidden", null);
                window.$("#microphone").attr("hidden", true);
            });

            // Start recording
            recorder.start()
        });
    }


    const stopRecording = async () => {
        if (recorder) {
            recording = false;
            recorder.stop();
        }
    }



    function getImages() {
        if (document.isAudio) {
            return (<img className="list-items-content-icon-color" src={audioIcon}></img>)
        } else if (document.isVideo) {
            return (<img className="list-items-content-icon-color" src={videoIcon}></img>)
        }
        else {
            return (<img className="list-items-content-icon-color" src={fileIcon}></img>)
        }

    }

    const getData = async () => {
        var classroom = (await api.sendMessage("getClassroomFromId", {
            body: {
                id: professorclassroomId || classroomId,
            },
            user: user
        })).classroom;
        setClassroom(classroom);


        return (await api.sendMessage("getDoc", {
            body: {
                id: documentId,
                classroom: professorclassroomId || classroomId
            },
            user: user
        })).document;

      
    }

    const getFolder = async () => {
        return (await api.sendMessage("getFolder", {
            body: {
                id: folderId,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).folder;
    }

    const copyToClipboard = (answer) => {
        const blobHtml = new Blob([answer], { type: "text/html" });

        navigator.clipboard.write([new ClipboardItem({ "text/html": blobHtml })]).then(() => {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["TEXT_COPIED_TO_CLIPBOARD"]()}</i>,
                icon: "success",
                timer: 1000,
                showConfirmButton: false,
                allowOutsideClick: false,
                showCloseButton: false
            });
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const managePageLinks = () => {
        let links = window.$("a[data-page]");
        links.each((i, l) => {
            l = window.$(l);
            let page = Number(l.attr("data-page"));
            let pageDoc = l.attr("data-doc-id");
            if (!isNaN(page)) {
                l.on("click", (e) => {
                    console.log(`show page ${pageDoc} ${page}`);
                    setPageDocument(pageDoc)
                    setPageNumber(page);
                });
            }
        });

        let topicLinks = window.$("a[data-topic]");
        topicLinks.each((i, l) => {
            l = window.$(l);
            l.on("click", (e) => {                
                submitQuestion(l.attr("data-topic"));
            });
        });

    }

    const getAudioContext = () => {
        if (!window.AudioContext) {
            if (!window.webkitAudioContext) {
                alert("Your browser does not support any AudioContext and cannot play back this audio.");
                return;
            }
            window.AudioContext = window.webkitAudioContext;
        }

        let context = new AudioContext();

        return context;
    }

    let source = null;

    const stopAnswer = () => {
        try {
            if (source) {
                source.stop();
            }
        }
        catch (err) {
            //nop
        }
    }

    const playAnswer = async (answer) => {
        return new Promise((resolve) => {
            if (!isAudioEnabled()) resolve(false);
            let context;
            let buf;

            function play() {
                // Create a source node from the buffer
                source = context.createBufferSource();
                source.buffer = buf;
                // Connect to the final output node (the speakers)
                source.connect(context.destination);
                // Play immediately
                source.start(0);
                playback = true;
                source.onended = () => {
                    playback = false;
                    resolve(true);
                }
            }

            context = getAudioContext();
            if (!context) resolve(false);

            var arrayBuffer = new ArrayBuffer(answer.length);
            var bufferView = new Uint8Array(arrayBuffer);
            for (var i = 0; i < answer.length; i++) {
                bufferView[i] = answer[i];
            }

            context.decodeAudioData(arrayBuffer, function (buffer) {
                buf = buffer;
                play();
            });
        });
    }

    const submitQuestion = async (text) => {
        if (text) {
            setQuestion(text);
        }

        let _question = text || question;

        if (_question === null || _question === "" || _question.trim() == "") {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: `${labels.getText()["ERROR_INSERT_VALID_QUESTION"]()}`
            });
            setAnnaAnswering(false);
            return;
        }

        setButtonAvailability(false);

        let _newQuestion = {
            questionText: _question
        };
        questions.unshift(_newQuestion);
        setTState(new Date());

        submitting = true;
        var result = await api.sendMessage("getAnswer", {
            body: {
                docId: document ? document._id : null,
                folderId: folderId ? folderId : null,
                questions: questions,
                question: _question,
                professorclassroomId,
                classroomId
            },
            user: user,
            silentMode: true
        });
        setTState(new Date());
        if (result.success === false) {

            _newQuestion.answerText = `${result.message}`;
            _newQuestion.status = "done";
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["OPS"]()}</i>,
                icon: "error",
                text: result.message,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: false
            });
        } else {
            if (result) {
                _newQuestion.id = result.id;
                let _charPosition = 0;

                (async () => {
                    let audioAnswer;

                    while (_newQuestion.status !== "done") {
                        try {
                            let _answer = await api.sendMessage("getAnswerText", {
                                body: {
                                    id: _newQuestion.id,
                                    audioEnabled: isAudioEnabled()
                                },
                                user: user,
                                silentMode: true
                            });

                            _newQuestion.answerText = `${_answer.answer.answerText}`;
                            _newQuestion.status = _answer.answer.done ? "done" : "progress";
                            audioAnswer = _answer.audioAnswer;

                            while (_newQuestion.answerText && _charPosition < _newQuestion.answerText.length) {
                                let _textSpan = window.$(`#answer_${_newQuestion.id}`);
                                _textSpan.html(_newQuestion.answerText.substring(0, _charPosition));
                                managePageLinks();
                                if (_newQuestion.answerText.length - _charPosition > 50) {
                                    _charPosition += 35;
                                }
                                _charPosition++;
                                await new Promise(resolve => setTimeout(resolve, 25));
                            }


                            if (!_newQuestion.answerText) {
                                let _textSpan = window.$(`#answer_${_newQuestion.id}`);
                                _textSpan.html("...");
                            }

                            await new Promise(resolve => setTimeout(resolve, 1500));
                        }
                        catch (error) {
                            console.log(error);
                        }
                    }

                    if (!_newQuestion.answerText || _newQuestion.answerText == "") {
                        _newQuestion.answerText = labels.getText()["NO_DATA_AVAILABLE"]();
                    }

                    if (audioAnswer && audioAnswer.length > 0) {
                        for (var audio of audioAnswer) {
                            await playAnswer(audio.data);
                        }
                    }

                    setAnnaAnswering(false);
                    if (isAudioEnabled()) recorder.start();
                    setTState(new Date());
                    setQuestion("");
                    setButtonAvailability(true);
                    window.$("#microphone").attr("hidden", false);


                })();
            }

            _newQuestion.answerText = result.answer || result.error;
            _newQuestion.answerPages = result.pages || [];

            setTState(new Date());
            setQuestion("");
            setButtonAvailability(true);
        }

    }

    useEffect(() => {
        const fetchData = async () => {
            const _book = await getData();
            const _folder = await getFolder();
            setDocument(_book);
            setFolder(_folder);
        };

        fetchData();

        
    }, []);

    useEffect(() => {
        if (!isDataLoaded && (document || folder)) {
            setIsDataLoaded(true);
            
            if(document && autoSearch){
                submitQuestion(autoSearch);
            }
        }
    }, [document, folder, isDataLoaded]);

    useEffect(() => {
        managePageLinks();
        if (firstElementRef.current) {
            firstElementRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
    }, [tState]);



    if (!isDataLoaded) {
        { SetMenu({ user: user }) }
        return (

            <div className="wrap">
                <p>{labels.getText()["WAITING_FOR_DATA"]()}</p>
            </div>
        )
    }
    else {
        function _drawQuestions() {
            let _questions = questions.length > _maxNumberOfQuestionsToShow ? questions.slice(0, -1) : questions;

            { SetMenu({ user: user }) }

            return (
                <div>
                    {
                        _questions.map((x, index) => {
                            return (
                                <div
                                    key={index}
                                    ref={index === 0 ? firstElementRef : null}
                                    className="list-group-item list-group-item-action flex-column align-items-start"
                                    id={`question_${x.id}`}
                                >
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-1">{x.questionText}</h5>
                                    </div>

                                    {
                                        !x.answerText ? (<div>
                                             {/* <div className="spinner-grow text-primary" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                            <div className="spinner-grow text-secondary" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                            <div className="spinner-grow text-success" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                            <div className="spinner-grow text-danger" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                            <div className="spinner-grow text-warning" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                            <div className="spinner-grow text-info" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                            <div className="spinner-grow text-light" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                            <div className="spinner-grow text-dark" role="status">
                                                <span className="sr-only"></span>
                                            </div> */}
                                            <img className='bookLoader' alt="loading book" src={bookLoader}></img>
                                            <img className='bookLoader' alt="loading book" src={bookLoader}></img>
                                            <img className='bookLoader' alt="loading book" src={bookLoader}></img>
                                            <img className='bookLoader' alt="loading book" src={bookLoader}></img>
                                            <img className='bookLoader' alt="loading book" src={bookLoader}></img>
                                            <img className='bookLoader' alt="loading book" src={bookLoader}></img>


                                            </div>) : (null)
                                    }

                                    <div style={{ overflow: "hidden" }}> {/*overflow-wrap: break-word*/}
                                        <p
                                            ref={textAreaRef}
                                            className="mb-1" id={`answer_${x.id}`} dangerouslySetInnerHTML={{ __html: x.answerText }}></p>

                                        <div style={{ float: "right" }}>


                                            <button className='then-small-button margin'>
                                                <i
                                                    className="fa fa-clone"
                                                    style={{ cursor: "pointer" }}
                                                    title={labels.getText()["COPY_TO_CLIPBOARD"]()}
                                                    onClick={() => copyToClipboard(x.answerText)}
                                                ></i>
                                            </button>
                                            {
                                                <HtmlToDocx user={user} fileName={x} question={x.questionText} richText={x.answerText} />
                                            }
                                        </div>
                                    </div>



                                </div>);
                        })
                    }
                </div>
            )
        }




        const recordingBar = () => {
            return (<div
                id="recordingBar"
                style={{
                    backgroundColor: "white",
                    position: "fixed",
                    border: "3px solid black",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 70,
                    opacity: 0.75
                }}
                hidden
            >
                <div className="row" id="meterRow">
                    <div className="col-11">
                        <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ height: 65 }}>
                            <div className="progress-bar" id="meterBar" style={{
                                width: "0%"
                            }}></div>
                        </div></div>
                    <div className="col-1" style={{ textAlign: "center" }}>
                        <button className="then-big-button withMarginTop" type="submit"
                            onClick={(e) => {
                                if (recorder) recorder.stop();
                                window.$("#recordingBar").prop("hidden", true);
                                window.$("#microphone").attr("hidden", null);
                                stopAnswer();
                            }}
                        >
                            <i className="fa fa-stop fa-2x blackIcon" style={{ fontSize: "1em" }}></i>
                        </button>
                    </div>
                </div>

                <div className="row" id="meterAnnaAnswering" hidden>
                    <div className="col">
                        ANNA IS ANWERING...
                    </div>
                </div>
            </div>)
        }

        return (
            <div className='wrap'>
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="stack-title">
                                {documentId ?
                                    (getBreadcrumb({ page: "q&a", id: document._id, name: document.fileName, classroomId, professorclassroomId })) :
                                    (getBreadcrumb({ page: "q&a_folder", id: folderId, folderId: folderId, name: folder.name, classroomId: classroomId, professorclassroomId: professorclassroomId, classroomName: classroom?.name }))
                                }
                                <h1 className="document-boost">{labels.getText()["Q&A_HEADER"]()}</h1>
                            </div>



                            <div className="card-document">
                                <div className="list-items">
                                    <div className="list-items-content">
                                        {documentId ?
                                            getImages() :
                                            (<img className="list-items-content-icon-color" src={folderIcon}></img>)

                                        }
                                        <div className="list-items-content-text">
                                            <div className="title-number ">
                                                <p className="Text700__5">{document?.fileName ?? folder?.name}</p>

                                            </div>
                                            {documentId ?
                                                (<span className="Text500__5">{labels.getText()["DOCUMENT_UPLOADED"]({ date: new Date(document?.uploadTime) })}</span>) :
                                                (<span className="Text500__5">{labels.getText()["DOCUMENT_UPLOADED"]({ date: new Date(folder?.creationDate) })}</span>
                                                )
                                            }
                                        </div>

                                    </div>
                                </div>


                            </div>

                            <div className='question-box'>
                                <div className='question-title'>
                                    <p className="stretch  Text700__2 violet500">{labels.getText()["QUESTION"]()}</p>
                                    <textarea ref={boxRef} className="form-control inputTextArea Text500__7 neutral500" onChange={(e) => {
                                        window.$("#microphone").attr("hidden", e.target.value.length > 0);
                                        setQuestion(e.target.value);
                                    }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" && buttonAvailability) {
                                                submitQuestion();
                                            }
                                        }}
                                        value={question} placeholder={labels.getText()["LEAVE_MESSAGE_HERE"]()} id="message" style={{ height: 350 }}></textarea>
                                </div>

                                <div id="button-row-QA" className="row">
                                    <div className='col'>
                                        <button ref={buttonRef} className="then-big-button withMarginTop" id='submit-button' type="submit"
                                            disabled={!buttonAvailability}
                                            onClick={async () => {
                                                submitQuestion();

                                            }}><i className="fa fa-paper-plane fa-2x blackIcon" style={{ fontSize: "1em" }}></i></button></div>
                                    <div className='col'>
                                        <button id="microphone" className="then-big-button withMarginTop" type="submit"
                                            disabled={!buttonAvailability}
                                            onClick={(e) => {
                                                if (!recorder) startRecording();
                                                window.$("#recordingBar").prop("hidden", null);
                                                window.$(e.target).prop("hidden", true);
                                            }}
                                        >
                                            <i className="fa fa-microphone fa-2x blackIcon" style={{ fontSize: "1em" }}></i>
                                        </button>
                                    </div>

                                </div>


                            </div>

                        </div>

                        <div className='col-lg-7 column-dx-bg'>
                            <div className="row g-4">
                                <div className="list-group" >
                                    {
                                        _drawQuestions()
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {pageNumber ? (
                    <PagePreview document={pageDocument} page={pageNumber} user={user} onClose={() => {
                        setPageNumber(null);
                    }}>

                    </PagePreview>

                ) : (null)}
                {recordingBar()}
            </div >


        )
    }

}

export default Q_A_Screen;
