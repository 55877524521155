import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState, useEffect, useContext } from "react";
import * as api from '../api/apiConnector';
import UserContext from '../components/User_Context';
import { LastPage } from '@mui/icons-material';
import * as labels from "../components/localized/labels";

function PageSelector(props) {
    const { document, onClose, screenWidth, screenHeight } = props;

    let imageWidth = Math.trunc(screenWidth / 10);
    if (imageWidth < 100) imageWidth = 100;

    let _buttonDown = false;
    let _action = null;
    let selected = [];

    //retrieve user from user context
    let { user } = useContext(UserContext);

    ///convert the user in the real object user
    user = user ? JSON.parse(user) : null;

    window.$.fn.is_on_screen = function () {
        var win = window.$(window);
        var viewport = {
            top: win.scrollTop(),
            left: win.scrollLeft()
        };
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();

        var bounds = this.offset();
        bounds.right = bounds.left + this.outerWidth();
        bounds.bottom = bounds.top + this.outerHeight();

        return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    };

    const parsePagesString = (input) => {
        let parts = input.split(",");
        let output = [];

        for (var p of parts) {
            if (p.includes("-")) {
                let range = p.split("-");
                if (!isNaN(Number(range[0])) && !isNaN(Number(range[1]))) {
                    let _start = Number(range[0]);
                    let _stop = Number(range[1]);
                    for (var i = _start; i <= _stop; i++) {
                        output.push(i);
                    }
                }
            } else {
                if (!isNaN(Number(p)) && Number(p) > 0) {
                    output.push(Number(p));
                }
            }
        }

        return output;
    }

    const getPagesString = () => {
        let output = [];
        let current = [];

        selected = selected.sort(function (a, b) { return a - b; });

        for (var i = 0; i < selected.length; i++) {
            let page = selected[i];
            if (current.length > 0) {
                if (current[current.length - 1] == page - 1) {
                    current.push(page);
                }
                else {
                    output.push(current);
                    current = [];
                    current.push(page);
                }
            } else {
                current.push(page);
            }
        }

        if (current.length > 0) {
            output.push(current);
        }

        let pagesText = "";
        for (var o of output) {
            if (pagesText != "") pagesText += ", ";
            if (o.length > 1) {
                pagesText += `${o[0]}-${o[o.length - 1]}`;
            }
            else {
                pagesText += `${o[0]}`;
            }
        }



        return pagesText;
    }

    const chunk = (arr, size) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
            arr.slice(i * size, i * size + size)
        );

    let lazyTimer = null;
    const lazyLoad = async () => {
        if (lazyTimer) clearTimeout(lazyTimer);
        lazyTimer = setTimeout(() => {
            _lazyLoad();
        }, 500);
    }

    const showZoomed = (img) => {
        let div = window.$(`<div class="text-center"/>`);
        window.$("body").append(div);
        div.css("z-index", "2000");
        div.css("position", "fixed");
        div.css("top", 0);
        div.css("background-color", "blue");
        div.css("bottom", 0);
        div.css("left", 0);
        div.css("right", 0);
        let img2 = window.$(`<img class="img-fluid" />`);
        

        div.append(img2);
        img2.attr("src", img.attr("src"));
      
        if (div.width() > div.height()) {
            img2.css("height", "99vh");

        }
        else {
            img2.css("width", "100%");
        }

        div.on("mousemove", () => {
            div.remove();
        });

        img2.on("mousemove", () => {
            div.remove();
        });
    }

    const _lazyLoad = async () => {
        let images = window.$("img[data-processed='false']");
        let pages = [];

        images.each((i, m) => {
            let img = window.$(m);
            if (img.is_on_screen()) {
                img.attr("data-processed", "true");
                pages.push(Number(img.attr("data-page")));
                
                
            }
        });

        let chunks = chunk(pages, 25);

        for (var c of chunks) {
            for (var p of c) {
                var img = window.$(`img[data-page='${p}']`);            
            }

            //get thumbs
            api.sendMessage("getThumbs", {
                body: {
                    id: document._id,
                    pages: c
                },
                user: user
            }).then(thumbs => {
                window.$.each(thumbs.thumbs, (index, t) => {
                    var imageData = `data:image/png;base64,${t.thumb}`;
                    var img = window.$(`img[data-page='${t.page}']`);
                    var page = img.attr("data-page");

                    (async () => {
                        img.attr("src", imageData);
                        console.log(`setting source for image ${page}`);
                        img.css("border", "none");
                        img.css("visibility", "visible")
                        window.$("img[data-page]").css("width", img.css("width"));
                        window.$("img[data-page]").css("height", img.css("height"));
                        window.$(`[data-page="${page}"]`).on("mousedown touchstart", () => {
                            if (timer) clearTimeout(timer);
                            timer = setTimeout(() => {
                                showZoomed(img);
                                clearTimeout(timer);
                                timer = null;
                            }, 500);
                        });
        
                        window.$(`[data-page="${page}"]`).on("mouseup touchend", () => {
                            if (timer) clearTimeout(timer);
                            timer = null;
        
                        });
        
                        img.on("mousemove touchmove", () => {
                            if (timer) clearTimeout(timer);
                            timer = null;
        
                        });
        
        
                    })();

                  
                });
            });

            let timer = null;

            const enlarge = (img) => {
                showZoomed(img);
            }

            
        }
    }

    useEffect(() => {
        try {
            (async () => {
                window.$("body").on("mousedown", () => {
                    _buttonDown = true;
                });

                window.$("body").on("mouseup", () => {
                    _buttonDown = false;
                    _action = null;
                });

                window.$(window).on("resize", () => {

                    lazyLoad();
                });

                window.$("div").on("scroll", () => {
                    lazyLoad();
                });

                lazyLoad();

                window.$("#pagesRange").val(`1-${document.pagesCount}`);
                setTimeout(() => {
                    textSelectionChange();
                }, 1000);


            })();
        } catch (error) {
            console.error('Error:', error);
        }
    }, []);

    const getChunks = (array, size) => {
        let _chunks = [];
        for (let i = 0; i < array.length; i += size) {
            const chunk = array.slice(i, i + size);
            _chunks.push(chunk);
        }
    }

    const clearSelection = () => {
        window.$("img[data-selected='true']").css("opacity", ".2");
        window.$("img[data-selected='true']").attr("data-selected", "false");
        window.$("[data-image-check]").html("");
        selected = [];
    }

    const toggleImage = (e, force) => {
        if (!_buttonDown && !force) return;
        let self = window.$(e.target);
        let page = Number(self.attr("data-page"));
        self = window.$(`img[data-page="${page}"]`);

        let flag = self.parent().find("[data-image-check]");
        console.log(`just selected: ${self.attr("data-selected")}, action: ${_action}`);

        if (_action == "select" && self.attr("data-selected") == "true") return;
        if (_action == "deselect" && self.attr("data-selected") != "true") return;

        if (self.attr("data-selected") == "true") {
            self.css("opacity", ".2");
            self.attr("data-selected", "false");
            flag.html("");
            let index = selected.findIndex(x => x == page);
            if (!isNaN(index)) {
                selected.splice(index, 1);
            }
            _action = "deselect";
        }
        else {
            self.css("opacity", "1");
            self.attr("data-selected", "true");
            flag.append(window.$(`<i class="fa fa-check fa-3x text-primary" data-page="${page}"></i>`));
            selected.push(page);
            _action = "select";
        }

        window.$(`[data-id="selected-count"]`).text(`${selected.length}`);
        if (!force) window.$("#pagesRange").val(getPagesString());
    };

    const pageGrid = () => {
        let _pages = [];
        for (var i = 1; i <= document.pagesCount; i++) {
            _pages.push(i);
        }
        return (<div>{
            _pages.map((p) => {

                let _src = "https://w7.pngwing.com/pngs/412/921/png-transparent-computer-icons-page-icon-design-brief-angle-text-rectangle.png";

                return (
                    <div key={`thumbnail${p}`} style={{borderRadius: 25, float: "left", margin: 5, position: "relative", textAlign: "center", userSelect: "none", msUserSelect: "none", MozUserSelect: "none", "animation": "pulse 3s infinite"}}>
                        <img
                            data-processed="false"
                            data-page={`${p}`}
                            onDoubleClick={(e) => {
                                showZoomed(window.$(e.target));
                            }}
                            onMouseDown={(e) => {
                                _buttonDown = true;
                                toggleImage(e);
                            }}
                            onMouseUp={(e) => {
                                _buttonDown = false;
                                _action = null;

                            }}
                            onMouseEnter={(e) => {
                                toggleImage(e);
                            }} draggable={false} style={{
                                cursor: "pointer", visibility: "hidden", minWidth: imageWidth, width: imageWidth, opacity: "20%", userSelect: "none", msUserSelect: "none", MozUserSelect: "none" }} src={_src} className="img-fluid" alt={`page ${p}`} />
                        <div onMouseDown={(e) => {
                            _buttonDown = true;
                            toggleImage(e);
                        }}
                            onMouseUp={(e) => {
                                _buttonDown = false;
                                _action = null;

                            }} data-page={`${p}`} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "black", fontSize: 44 }}>{p}</div>
                        <div onMouseDown={(e) => {
                            _buttonDown = true;
                            toggleImage(e);
                        }}
                            onMouseUp={(e) => {
                                _buttonDown = false;
                                _action = null;

                            }} data-page={`${p}`} style={{ position: "absolute", top: "51%", left: "51%", transform: "translate(-51%, -51%)", color: "white", fontSize: 44 }}>{p}</div>
                        <div onMouseDown={(e) => {
                            _buttonDown = true;
                            toggleImage(e);
                        }}
                            onMouseUp={(e) => {
                                _buttonDown = false;
                                _action = null;

                            }} data-page={`${p}`} style={{ position: "absolute", top: 8, right: 8 }} data-image-check="true"></div>
                    </div>);
            })
        }</div>);
    }

    const textSelectionChange = () => {
        clearSelection();
        let _selected = parsePagesString(window.$("#pagesRange").val());
        window.$.each(_selected, (i, p) => {
            let img = window.$(`img[data-page="${p}"]`)[0];
            toggleImage({ target: img }, true);

        });
    }

    return (
        <div

        >
            <Modal show={true} size="lg" fullscreen={true}>
                <Modal.Header closeButton onClick={() => {
                    if (onClose) {
                        onClose([]);
                    }
                }}>
                    <Modal.Title className='Text700__9'>{document.title ?? document.fileName} <span style={{ textAlign: "right" }} data-id="selected-count"></span></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <label htmlFor="pagesRange" className="form-label Text700__6">{labels.getText()["PAGES_RANGE"]()}</label>
                    <input type="search" id="pagesRange" className="form-control" aria-describedby="pagesRangeHelp"
                        onChange={(e) => {
                            textSelectionChange();
                        }}
                        onFocus={() => {
                            _buttonDown = false;
                        }}
                    />
                    <div id="pagesRangeHelp" className="form-text Text500__5">
                    {labels.getText()["PAGES_RANGE_HELP"]()}
                    </div>
                    <hr />
                    {pageGrid()}
                </Modal.Body>

                <Modal.Footer>
                    <Button className='then-small-button' onClick={() => {
                    if (onClose) {
                        onClose(selected, getPagesString());
                    }
                }}>OK</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default PageSelector;