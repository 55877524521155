import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { React } from "react";
import * as labels from "../components/localized/labels";

const downloadFile = function (method, params) {

    var url = `${process.env.REACT_APP_API_URL}/${method}`;


    return new Promise(function (resolve, reject) {


        const { body, token } = params;

        var headers = {
            "Content-Type": "application/json",
            'Accept': 'application/json',
        };

        if (params.user && params.user.token) {
            headers["Authorization"] = params.user.token;
        }

        fetch(url, {
            //api allowed verb is POST
            method: 'POST',
            //headers
            headers: headers,
            //body
            body: body ? JSON.stringify(body) : null
        })
            .then(r => r.blob())
            .then(b => resolve(b))
            .catch(error => {
                reject(error);
            });
    });
}




const sendMessage = function (method, params) {

    return new Promise(function (resolve, reject) {
        var url = `${process.env.REACT_APP_API_URL}/${method}`;

        const { body, token } = params;

        var headers = {
            "Content-Type": "application/json",
            'Accept': 'application/json',
        };


        if (params.user && params.user.token) {
            headers["Authorization"] = params.user.token;
            var lang = params.user && params.user._language ? `${params.user._language}-${params.user._language.toUpperCase()}` : "en"
            if (!params.user.language || !params.user.language) {
                lang = labels.getLanguage() || "en";
            }
            headers["Language"] = lang;
        }
        else { //in case of registration
            const lang = labels.getLanguage() || "en";
            headers["Language"] = lang;
        }

        var result = fetch(url, {
            //api allowed verb is POST
            method: 'POST',
            //headers
            headers: headers,
            //body
            body: body ? JSON.stringify(body) : null,

        })
            .then(r => r.json())
            .then((data) => {
                resolve(data);
                if (data.error) {
                    withReactContent(Swal).fire({
                        title: <i>{labels.getText()["ERROR"]()}</i>,
                        icon: "error",
                        text: data.error,
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        showCloseButton: true,
                    });

                    if (data.removeToken) {
                        localStorage.removeItem("UserAuth");
                        window.location.href = "/login";
                    }
                }
            })
            .catch(error => {
                if (!params.silentMode) {
                    withReactContent(Swal).fire({
                        title: <i>{labels.getText()["ERROR"]()}</i>,
                        icon: "error",
                        text: labels.getText()["ERROR_WHILE_CONNECTING_TO_SERVER"](),
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        showCloseButton: true,
                    });
                }
                reject(error);
            });
    });
}

export { sendMessage, downloadFile };