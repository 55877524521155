import SiteRouting from './components/Site_Routing';
import React, { useEffect, useState } from 'react';
import UserContext from './components/User_Context';
import ScrollToTop from './components/ScrollTop';
import CookieConsent from './components/CookieConsent';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import TagManager from "react-gtm-module";


function App() {

  const [cookiesThirdParty, setThirdPartyCookie] = useCookies(localStorage.getItem("thirdPartyCookies"));

  if (process.env.REACT_APP_ENEABLE_TRACKING && cookiesThirdParty?.cookieConsent) {

    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GOOGLE_GTM_ID
    }
    TagManager.initialize(tagManagerArgs)

    window.dataLayer.push({
      event: 'pageview'
    });
  }

  function tracking() {

    if (process.env.REACT_APP_ENEABLE_TRACKING && cookiesThirdParty?.cookieConsent) {

      return (
        <Helmet>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_TAG_ID}`}></script>
        </Helmet>
      )
    } else {
      return (null);
    }
  }

  // useEffect(() => {
  //   if(window.ReactNativeWebView){
  //     alert("user: " + localStorage.getItem("UserAuth"));
  //   }
  // }, []);


  useEffect(() => {
    (async () => {
      if (process.env.REACT_APP_ENEABLE_TRACKING && cookiesThirdParty?.cookieConsent) {
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', process.env.REACT_APP_GOOGLE_TAG_ID);
      }

    })();
  }, []);


  //set the active user shared with all components
  const [user, setUser] = useState(localStorage.getItem("UserAuth"));
  const login = (userData) => {
    setUser(userData);
    localStorage.setItem("UserAuth", userData);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("UserAuth");
  };

  const setLanguage = (language) => {
    let _user = user ? JSON.parse(user) : null;
    if (_user) {
      _user._language = language;
      login(JSON.stringify(_user));
    }
  }

  return (
    <UserContext.Provider value={{ user, login, logout, setLanguage }}>
      <SiteRouting>
        <ScrollToTop>
          <div className="App">
          </div>
        </ScrollToTop>
      </SiteRouting>
      <CookieConsent></CookieConsent>
      {
        tracking()
      }
    </UserContext.Provider>
  );
}

export default App;
