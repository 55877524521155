import { React, useState, useContext, useEffect } from 'react';
import UserContext from '../components/User_Context';
import '../App.css';
import * as labels from "../components/localized/labels";
import { useNavigate, useParams } from 'react-router-dom';
import * as api from '../api/apiConnector';
import { SetMenu } from '../components/Navigation';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
function Invitation_Screen() {
    let { user } = useContext(UserContext);
    user = user ? JSON.parse(user) : null;
    const { classroomId, language } = useParams();
    const [classroom, setClassroom] = useState();
    const navigate = useNavigate();
    //retriving classroom
    const getClass = async () => {
        var group = (await api.sendMessage("getClassroomFromId", {
            body: {
                id: classroomId
            },
            user: user,
        })).classroom;
        setClassroom(group);
    }
    useEffect(() => {
        (async () => {
            if (user) {
                getClass();
            }

        })();
    }, []);
    const handleAccept = async() => {
        const result = await api.sendMessage("saveProfessor", {
            body: {
                classroomId: classroomId
            },
            user: user
        });
        if(result.success)
            {
                withReactContent(Swal).fire({
                    icon: "success",
                    text: labels.getText()["CLASSROOM_JOINED"](),
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    showCloseButton: true
                }).then(() => {
                    navigate(`/${language}/professor/classrooms`); // Change to your desired path
                });
            }
            else
            {
                const result = await api.sendMessage("professorDeclined", {
                    body: {
                        classroomId: classroomId
                    },
                    user: user
                });
                withReactContent(Swal).fire({
                    title: labels.getText()["ERROR"](),
                    icon: "error",
                    text: labels.getText()["YOU_ALREADY_ADDED_THIS_CLASS"](),
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    showCloseButton: true
                });
            }
        
    };

    const handleDecline = () => {
        withReactContent(Swal).fire({
            icon: "success",
            text: labels.getText()["INVITATION_DECLINED"](),
            showConfirmButton: true,
            allowOutsideClick: false,
            showCloseButton: true
        }).then(() => {
            navigate(`/${language}`); // Change to your desired path
        });
    };

    return (
        <div className="App">
            <div className="container-fluid bg-light py-5">
                {SetMenu({ user: user })}
                 <div className="container py-5">
                <div className="wow fadeIn" data-wow-delay="0.1s" style={{ visibility: "visible", "animationDelay": "0.1s", "animationName": "fadeIn" }}>
                        <h1 className="mb-4">
                            {classroom ? labels.getText()["YOU_HAVE_BEEN_INVITED_IN_CLASS"]({ classroom: classroom.name }) : null}
                        </h1>
                        <div className="approval-buttons-container">
                            <button onClick={handleAccept} className="btn btn-success">{labels.getText()["ACCEPT"]()}</button>
                            <button onClick={handleDecline} className="btn btn-danger">{labels.getText()["DECLINE"]()}</button>
                        </div>
                        <br />
                    </div>
                </div> 
            </div>
        </div>
    );
}
export default Invitation_Screen;