import React from 'react';

const ExportPrintButton = ({ map, onPrint }) => {

    return (
        <>
            <div data-button="true" style={{
                position: "fixed",
                right: 290,
                top: 20,
                zIndex: 10001
            }}
                onClick={() => {
                    if (onPrint) {
                        onPrint(map);
                    }
                }}>
                <button className="btn btn-lg btn-primary btn-lg-square"><i className="fa fa-print" ></i></button>
            </div>
        </>
    );
};

export default ExportPrintButton;