import React from 'react';
import { exportToDOCX } from '../exportToDOCX';

const ExportDocxButton = ({ map }) => {

  return (
    <>
      <div
        data-button="true"
        style={{ position: 'fixed', right: 230, top: 20, zIndex: 10001 }}
        onClick={() => exportToDOCX(map)}
      >
        <button className="btn btn-lg btn-primary btn-lg-square">
          <i className="fa fa-file-word"></i>
        </button>
      </div>
    </>
  );
};

export default ExportDocxButton;