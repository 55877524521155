import React, { useContext } from "react";
import * as api from '../../api/apiConnector';
import UserContext from '../User_Context';
import DocUpload from "./FileUpload";
import ImageUpload from "./image/ImageUpload";
import AudioUpload from "./audio/AudioUpload";
import jsPDF from "jspdf";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import * as labels from "../localized/labels";

function getRandomFileName() {
    var timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    var random = ("" + Math.random()).substring(2, 8);
    var random_number = timestamp + random;
    return random_number;
}

function getFileType(file) {

    if (file.type.match('image.*'))
        return 'image';

    if (file.type.match('video.*'))
        return 'video';

    if (file.type.match('audio.*'))
        return 'audio';

    if (file.type.match('application.*'))
        return 'book';

    if (file.type.match('text.*'))
        return 'book';

    return 'other';
}

function FileUpload(params) {
    const { noOverrides = false } = params;
    const { user } = useContext(UserContext);
    const _user = user ? JSON.parse(user) : null;
    const fileType = params.fileType;
    //#region drag and drop
    const handleDragEnter = (e) => {
        //revents the default behavior of the browser, e.g. open the file if dropped outside a specific element.
        e.preventDefault();
    };

    //prevents opening file in tab when dropped
    const handleOnDrop = (e) => {
        e.preventDefault();
    }

    //This function is called continuously while a dragged item is over the drop area.
    const handleDragLeave = (e) => {
        // Prevents the default behavior of the browser, which might be to disallow the drop. It ensures that the drop event can occur
        e.preventDefault();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    //#endregion

    const getUserSubscription = async () => {
        const result = (await api.sendMessage("getUserSubscription", {
            user: _user
        }));
        if (result.success) {
            return result.subscriptionProfile;
        }
    }

    const handleCreatePdf = async (fullText, fileName, audioSource, uploaded) => {
        const pdf = new jsPDF('p');
        const pageHeight = pdf.internal.pageSize.height;
        const margin = 10;
        const maxLineHeight = pageHeight - margin * 2;
        let cursorY = margin;
        let lines = pdf.splitTextToSize(fullText, maxLineHeight);

        lines.forEach((line) => {
            if (cursorY + 10 > maxLineHeight) {
                pdf.addPage();
                cursorY = margin;
            }
            pdf.text(line, margin, cursorY);
            cursorY += 10;
        });
        //pdf.save('output.pdf');
        const pdfFile = pdf.output('blob');
        return handleFileUpload(pdfFile, fileName, audioSource, uploaded);
    };

    const handleOpenModal = () => {
        return params.onAddModal();
    };

    const notSupported = () => {
        withReactContent(Swal).fire({
            text: labels.getText()["FILE_TYPE_NOT_SUPPORTED"](),
            icon: 'error',
            confirmButtonText: 'Ok'
        });
    };

    const inProgress = () => {
        withReactContent(Swal).fire({
            title: <i>{labels.getText()["WAITING_FOR_DATA"]()}</i>,
            icon: "info",
            text: ""
        });
    };

    const handleFileChange = (event) => {
        const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
        const type = getFileType(file);
        if (noOverrides) {
            handleFileUpload(file);
        }
        else {
            switch (type) {
                case "image":
                    inProgress();
                    handleFileUpload(file);
                    break;

                case "audio":
                    inProgress();
                    handleAudioUpload(file);
                    break;
                case "book":
                case "video":
                    handleFileUpload(file);
                    break;
                default:
                    notSupported();
                    break;
            };
        }

    };

    const getDuration = (file) => {
        const audio = new Audio();
        audio.src = URL.createObjectURL(file);
        if(isNaN(audio.duration)) return {h: 0, m: 0, s: 0};
        return new Promise((resolve) => {
            audio.onloadedmetadata = () => {
                resolve({
                    h: Math.floor(audio.duration / 3600),
                    m: Math.floor(audio.duration / 60),
                    s: Math.floor(audio.duration % 60)
                });
            };
        });
    };

    const transcribeAudio = async (fileData, type, duration, uploaded) => {
        try {
            var response = await api.sendMessage('uploadAudio', {
                body: {
                    audioFile: fileData,
                    mimeType: type,
                    audioDuration: duration,
                    fileUploaded: uploaded
                },
                user: _user
            });

            return response;

        } catch (error) {
            console.log(error);
        }
    }

    const handleAudioUpload = async (selectedFile) => {
        const userSubscriptionProfile = await getUserSubscription();
        if (!userSubscriptionProfile) {
            withReactContent(Swal).fire({
                text: labels.getText()["SUBSCRIPTION_REQUIRED"](),
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return;
        } else {
            if (!userSubscriptionProfile.audio) {
                withReactContent(Swal).fire({
                    text: labels.getText()["PLAN_DOESNT_INCLUDE_VOICE"](),
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                return;
            }
        }
        const duration = await getDuration(selectedFile);
        const totalMinutes = (duration.h * 60) + duration.m + (duration.s / 60);
        const byteArray = await selectedFile.arrayBuffer();
        const fileData = Array.from(new Uint8Array(byteArray));

        const transcription = await transcribeAudio(fileData, selectedFile.type, totalMinutes);
        if (transcription.success && transcription.result) {
            handleCreatePdf(transcription.result, selectedFile.name, true);
        } else {
            withReactContent(Swal).fire({
                text: transcription.message,
                icon: 'warning',
                confirmButtonText: labels.getText()["GO_TO_CATALOGUE"](),                            
                cancelButtonText:'OK',
                showCancelButton:true
              }).then((result)=> {
                if(result.isConfirmed)
                {
                  window.location.href = `/catalogue`;
                }
              });
        }
    };

    const handleFileUpload = async (selectedFile, fileName, audioSource, uploaded) => {
        const originalname = fileName ? fileName : selectedFile.name;
        const chunkSize = 1 * 1024 * 1024; // 5MB (adjust based on your requirements)
        const totalChunks = Math.ceil(selectedFile.size / chunkSize);
        let chunkNumber = 0;
        let start = 0;
        let end = chunkSize - 1;

        if (totalChunks === 1) {
            if (params.onChunkSent) {
                params.onChunkSent({
                    chunkNumber: 50 / 100,
                    totalChunks: totalChunks,
                    result: null
                });
            }

        }
        else {
            if (params.onChunkSent) {
                params.onChunkSent({
                    chunkNumber: 1 / totalChunks,
                    totalChunks: totalChunks,
                    result: null
                });
            }
        }


        let randomName = "";
        while (start < selectedFile.size) {

            //check if the end is bigger then the file
            if (end > selectedFile.size - 1) {
                end = selectedFile.size - 1;
            }
            const chunk = selectedFile.slice(start, end + 1);

            const byteArray = await chunk.arrayBuffer();
            const fileData = Array.from(new Uint8Array(byteArray));

            //console.log("sending chunk " + chunkNumber);
            var result = await api.sendMessage("uploadFile", {
                body: {
                    mimeType: selectedFile.type,
                    file: fileData,
                    chunkNumber,
                    totalChunks,
                    originalname: originalname ?? getRandomFileName(),
                    randomName: randomName,
                    classroomId: params.classroomId,
                    audioSource: audioSource,
                    uploaded: uploaded
                },
                user: _user,

            });

            if (!result.success) {
                notSupported();
                if (params.onChunkSent) {
                    params.onChunkSent({
                        chunkNumber: chunkNumber + 1,
                        totalChunks: totalChunks,
                        result: { error: result.message }
                    });
                }

                break;
            } else {
                randomName = result.randomName;

                if (params.onChunkSent) {
                    params.onChunkSent({
                        chunkNumber: chunkNumber + 1,
                        totalChunks: totalChunks,
                        result: result
                    });
                }

                await new Promise(resolve => setTimeout(resolve, 500));

                start += chunkSize;
                end += chunkSize
                chunkNumber++;
            }


        }
    }

    return (
        <div className="stretch"
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleOnDrop}
        >
            {fileType === "image/*" && (
                <ImageUpload
                    handleFileUpload={handleFileUpload}
                    getRandomFileName={getRandomFileName}
                />
            )}
            {fileType === "audio/*" && (
                <AudioUpload
                    getFileType={getFileType}
                    handleCreatePdf={handleCreatePdf}
                    getDuration={getDuration}
                    transcribeAudio={transcribeAudio}
                />
            )}
            {!fileType && (
                <DocUpload
                    handleFileChange={handleFileChange}
                    handleDragOver={handleDragOver}
                    handleDragLeave={handleDragLeave}
                    handleDragEnter={handleDragEnter}
                    handleOpenModal={handleOpenModal}
                />
            )}
        </div>
    );
};

export default FileUpload