import '../App.css';
import UserContext from '../components/User_Context';
import { React, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import * as api from '../api/apiConnector';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as labels from "../components/localized/labels";
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import Form from 'react-bootstrap/Form';
import { FaPencil } from "react-icons/fa6";
import { IconContext } from "react-icons";
import fileIcon from '../assets/img/huge-icon/FilesAndFolder/bulk/file03.svg'
import pagesIcon from '../assets/img/huge-icon/FilesAndFolder/bulk/file-accept.svg'
import quickCard from '../assets/img/huge-icon/Generic/quickCard.svg'

import PageSelector from "../components/PageSelector.js";
import audioIcon from '../assets/img/Generic/mic.svg';
import videoIcon from '../assets/img/Generic/clapperboard.svg';

function Document_Screen() {

    //retrieve user from user context
    let { user } = useContext(UserContext);

    ///convert the user in the real object user
    user = user ? JSON.parse(user) : null;

    //retrieve params from userParams
    const { language, id, professorclassroomId, classroomId } = useParams();

    const navigate = useNavigate();

    const [document, setDocument] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [documentName, setDocumentName] = useState('');
    const [showFullSummary, setShowFullSummary] = useState(false);
    const [showPages, setShowPages] = useState(false);

    //retrieving document by id
    const getData = async () => {
        var doc = (await api.sendMessage("getDoc", {
            body: {
                id: id,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).document;
        if (!doc) {
            navigate(`/${language}`);
        }
        else {
            setDocument(doc);
            setDocumentName(doc.fileName);
        }

        return doc;
    }

    //use Effect
    useEffect(() => {
        (async () => {
            getData();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const summary_text = window.$("#summary-span").html();
            if (summary_text) {
                //window.$("#summary-span").html(summary_text.replace(/\*\*([^*]+)\*/g, "<b>$1<\/b>"));
            }

        })();
    }, [document, showFullSummary]);



    const copyToClipboard = (summary) => {
        navigator.clipboard.writeText(summary).then(() => {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["TEXT_COPIED_TO_CLIPBOARD"]()}</i>,
                icon: "success",
                timer: 1000,
                showConfirmButton: false,
                allowOutsideClick: false,
                showCloseButton: false
            });
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const askingForDelete = async () => {
        withReactContent(Swal).fire({
            title: <i>{labels.getText()["DELETE"]()}</i>,
            icon: "question",
            text: `${labels.getText()["DO_YOU_WANT_TO_DELETE"]()} ${document.title ? document.title : document.fileName}?`,
            showConfirmButton: true,
            showCancelButton: true,
            showCloseButton: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                withReactContent(Swal).fire({
                    title: <i>{labels.getText()["WAIT"]()}</i>,
                    icon: "info",
                    text: `${labels.getText()["DELETING_DOCUMENT"]()}`,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    showCloseButton: false
                });
                //deleting doc
                const result = await api.sendMessage("deleteDoc", {
                    body: {
                        id: id,
                        professorclassroomId: professorclassroomId,
                        classroomId: classroomId
                    },
                    user: user,
                });

                withReactContent(Swal).close();

                if (!result.success) {
                    withReactContent(Swal).fire({
                        title: <i>{labels.getText()["ERROR"]()}</i>,
                        icon: "error",
                        text: result.message,
                        showConfirmButton: true,
                        showCancelButton: false
                    });
                } else {
                    if (professorclassroomId) {
                        navigate(`/professor/library/classroom/${professorclassroomId}`)
                    }
                    else if (classroomId) {
                        navigate(`/library/classroom/${classroomId}`)
                    } else {
                        navigate("/");
                    }
                }
            }
        });
    };

    const toggleSummary = (event) => {
        event.preventDefault(); // Prevent the default link behavior
        setShowFullSummary(!showFullSummary); // Toggle the state
    };

    const renameDocument = async (name) => {
        if (!name || name === document.fileName) return setIsEditing(false);

        const result = await api.sendMessage("changeDocumentName", {
            body: {
                documentName: name,
                id: id,
                classroomId: classroomId
            },
            user,
        });

        const { success, message } = result;

        if (success) {
            // Update the document object with the new name
            document.fileName = name;
            setDocument(document);
        }

        withReactContent(Swal).fire({
            title: <i>{labels.getText()[success ? "DONE" : "ERROR"]()}</i>,
            icon: success ? "success" : "error",
            text: message,
        });


        setIsEditing(false);
    };

    function getImages() {
        if (document.isAudio) {
            return (<img alt='audioIcon' className="list-items-content-icon-color" src={audioIcon}></img>)
        } else if (document.isVideo) {
            return (<img alt='videoIcon' className="list-items-content-icon-color" src={videoIcon}></img>)
        }
        else {
            return (<img alt='fileIcon' className="list-items-content-icon-color" src={fileIcon}></img>)
        }

    }



    if (!document) {
        return (
            <div className="wrap">
                {SetMenu({ user: user })}
                <h5>{labels.getText()["WAITING_FOR_DATA"]()}</h5>
            </div>
        )
    }
    else {

        var summary = (document.summary || "");
        let firstPartOfSummary = "";
        let secondPartOfSummary = "";
        // document.summary.substring(firstPartOfSummary.length).trim();

        if (summary.length >= 300) {
            firstPartOfSummary = summary.slice(0, 300);
            secondPartOfSummary = summary.slice(300, summary.length);
        }
        else {
            firstPartOfSummary = summary;
        }

        return (
            <div className='wrap'>
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-5 wow fadeIn column-sx" data-wow-delay="0.1s">
                            <div className="stack-title">
                                {getBreadcrumb({
                                    page: "document", id: document._id, name: document ? document.fileName : documentName, professorclassroomId: professorclassroomId,
                                    classroomId: classroomId
                                })}
                                <h1 className="document-boost">{labels.getText()["DOCUMENT_PAGE_HEADER"]()}</h1>
                            </div>

                            <div className="card-document Text500__5">
                                <div className="list-items">
                                    <div className="list-items-content">
                                        {getImages()}
                                        <div className="list-items-content-text">
                                            {isEditing ? (
                                                <Form.Control
                                                    type="text"
                                                    placeholder={document.fileName}
                                                    value={documentName}
                                                    className='bg-transparent'
                                                    onChange={(event) => setDocumentName(event.target.value)}
                                                    onBlur={() => renameDocument(documentName)}
                                                    autoFocus
                                                    style={{ width: "100%" }}
                                                />
                                            ) : (
                                                <span className="title-number Text700__5" onClick={() => setIsEditing(true)}>
                                                    {document.fileName}
                                                    <IconContext.Provider value={{ size: "1.23em" }}>
                                                        <FaPencil />
                                                    </IconContext.Provider>
                                                </span>
                                            )}
                                            <span className="Text500__5">{labels.getText()["DOCUMENT_UPLOADED"]({ date: new Date(document.uploadTime) })}</span>
                                        </div>

                                    </div>
                                </div>

                                {(document.summary ?? "").length <= 300 ? (<>{document.summary}</>) :
                                    showFullSummary ? (
                                        <div>
                                            <span id="summary-span" className="stretch Text500__5">
                                                {firstPartOfSummary} {secondPartOfSummary}

                                            </span>
                                            <>
                                                <button className="then-small-link" onClick={toggleSummary} style={{ marginLeft: '5px' }}>
                                                    {labels.getText()["SHOW_LESS"]()}
                                                </button>
                                            </>
                                            <br></br>
                                            <div>
                                                <button className="then-small-button" onClick={() => { copyToClipboard(summary) }}>
                                                    {labels.getText()["COPY_SUMMARY"]()}
                                                </button>
                                            </div>

                                        </div>
                                    ) : (
                                        <div>
                                            <span className="stretch Text500__5">

                                                {firstPartOfSummary}
                                                <button className="then-small-link" onClick={toggleSummary} style={{ marginLeft: '5px' }}>
                                                    {labels.getText()["SHOW_MORE"]()}
                                                </button>
                                            </span>
                                        </div>
                                    )}
                            </div>

                            {
                                showPages && document && !document.multiMedia && document.pagesCount > 5 ? (<div>
                                    <PageSelector document={document} screenWidth={window.screen.width} screenHeight={window.screen.height} onClose={async (selected, pagesDescription) => {
                                        setShowPages(false);
                                        if (selected && selected.length > 0 && selected.length !== document.pagesCount) {
                                            let result = (await api.sendMessage("generateDocumentFromPages", {
                                                body: {
                                                    id: document._id,
                                                    pages: selected,
                                                    pagesDescription: pagesDescription
                                                },
                                                user: user
                                            }));

                                            if (result.document) {
                                                if (professorclassroomId) {

                                                }
                                                else if (classroomId) {

                                                } else {
                                                    window.location.href = `/${language}/document/${result.document._id}`;
                                                }
                                            }
                                        }
                                    }}></PageSelector>
                                </div>) : (null)
                            }

                            <div className="document-buttons-two">
                                <button className='then-big-button' onClick={() => askingForDelete()}><i style={{ fontSize: "1em" }} className="fa fa-book fa-2x"></i>{labels.getText()["DELETE"]()}</button>
                                {classroomId || document?.multiMedia || document.pagesCount <= 5 ? (null) :
                                    <button id="pageSelectorButton" className='then-big-button ms-3' onClick={() => {
                                        setShowPages(true);
                                    }}><img alt='pagesIcon' src={pagesIcon}></img>{labels.getText()["PAGE_SELECTOR"]()}</button>
                                }
                            </div>
                        </div>

                        <div className='col-lg-7 column-dx-bg document-folder'>
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <div className="row g-4">
                                        {/* Q&A */}
                                        <div className="col-12 wow fadeIn custom-box" data-wow-delay="0.1s">


                                            <div className="function-card service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="top-card">
                                                    <div className="div-top-card">
                                                        <img alt='robot' className="document-screen-icon" src="/huge-icon/QA/Robot.svg"></img>
                                                        <div className="sticker">
                                                            <h4 className="Desktop__H4">{labels.getText()["DOCUMENT_QA_SESSION_TITLE"]()}</h4>
                                                        </div>
                                                    </div>

                                                </div>

                                                <p className="stretch Text500__5">{labels.getText()["DOCUMENT_QA_SESSION"]()}</p>

                                                {professorclassroomId ?
                                                    <a href={`/${language}/professor/qa/${document._id}/${professorclassroomId}`}>
                                                        <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>
                                                    </a>
                                                    : classroomId ?
                                                        <a href={`/${language}/classroom/qa/${document._id}/${classroomId}`}>

                                                            <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                        </a> :
                                                        <a href={`/${language}/qa/${document._id}`}>
                                                            <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                        </a>
                                                }

                                            </div>


                                        </div>
                                        {/* TRAINING */}
                                        <div className="col-12 wow fadeIn custom-box" data-wow-delay="0.1s">
                                            <div className="function-card service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="top-card">
                                                    <div className="div-top-card">
                                                        <img alt='trainingIcon' className="document-screen-icon" src="/huge-icon/QA/test.svg"></img>
                                                        <div className="sticker">
                                                            <h4 className="Desktop__H4">{labels.getText()["TRAINING_SESSION_TITLE"]()}</h4>
                                                        </div>
                                                    </div>

                                                </div>
                                                <p className="stretch Text500__5">{labels.getText()["TRAINING_SESSION"]()}</p>


                                                {professorclassroomId ?
                                                    <a href={`/${language}/professor/training/${document._id}/${professorclassroomId}`}>
                                                        <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>
                                                    </a>
                                                    : classroomId ?
                                                        <a href={`/${language}/classroom/training/${document._id}/${classroomId}`}>

                                                            <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                        </a> :
                                                        <a href={`/${language}/training/${document._id}`}>
                                                            <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                        </a>
                                                }

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pt-md-4" id="document-service-second-screen">
                                    <div className="row g-4">
                                        <div className="col-12 wow fadeIn custom-box" data-wow-delay="0.3s">
                                            <div className="function-card service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="top-card">
                                                    <div className="div-top-card">
                                                        <img alt='mindmapIcon' className="document-screen-icon" src="/huge-icon/QA/blockNotes.svg"></img>
                                                        <div className="sticker">
                                                            <h4 className="Desktop__H4">{labels.getText()["MIND_MAP_SESSION_TITLE"]()}</h4>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div>
                                                    <p className="stretch Text500__5">{labels.getText()["MIND_MAP_SESSION"]()}</p>
                                                </div>
                                                {professorclassroomId ?
                                                    <a href={`/${language}/professor/mindmap/${document._id}/${professorclassroomId}`}>
                                                        <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>
                                                    </a>
                                                    : classroomId ?
                                                        <a href={`/${language}/classroom/mindmap/${document._id}/${classroomId}`}>

                                                            <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                        </a> :
                                                        <a href={`/${language}/mindmap/${document._id}`}>
                                                            <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                        </a>
                                                }
                                            </div>

                                        </div>

                                        <div className="col-12 wow fadeIn custom-box" data-wow-delay="0.3s">
                                            <div className="function-card service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="top-card">
                                                    <div className="div-top-card">
                                                        <img alt='quickCardIcon' className="document-screen-icon" src={quickCard}></img>
                                                        <div className="sticker">
                                                            <h4 className="Desktop__H4">{labels.getText()["QUICKCARDS_SESSION_TITLE"]()}</h4>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div>
                                                    <p className="stretch Text500__5">{labels.getText()["QUICKCARDS_SESSION"]()}</p>
                                                </div>
                                                {professorclassroomId ?
                                                    <a href={`/${language}/professor/quickcards/${document._id}/${professorclassroomId}`}>
                                                        <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>
                                                    </a>
                                                    : classroomId ?
                                                        <a href={`/${language}/classroom/quickcards/${document._id}/${classroomId}`}>

                                                            <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                        </a> :
                                                        <a href={`/${language}/quickcards/${document._id}`}>
                                                            <button className='then-small-link'>{labels.getText()["TRY_IT"]()}</button>

                                                        </a>
                                                }
                                            </div>

                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >


        )

    }

}

export default Document_Screen;
