import React from 'react';
import { mindMapsExport } from '../mindMapsExport';

const ExportPptxButton = ({ map }) => {

  return (
    <>
      <div data-button="true" style={{
            position: "fixed",
            right: 170,
            top: 20,
            zIndex: 10001
        }}
            onClick={() => mindMapsExport(map)}>
            <button className="btn btn-lg btn-primary btn-lg-square"><i className="fa fa-download" ></i></button>
        </div>
    </>
  );
};

export default ExportPptxButton;