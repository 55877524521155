import logo from '../logo.svg';
import '../App.css';
import UserContext from '../components/User_Context';
import { React, useContext, useEffect } from "react";
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import { useNavigate, useParams } from 'react-router-dom';
function Logout_Screen() {
    const { user, logout } = useContext(UserContext);
    const { language } = useParams();

    const navigate = useNavigate();
    useEffect(() => {
        logout();
        navigate(`/${language}/Login`);
    }, []);

}

export default Logout_Screen;
