import React from 'react';
import * as labels from "../components/localized/labels";
import paymentsIcon from "../assets/img/Generic/payment-card.svg"
import subIcon from "../assets/img/Generic/subscribe.svg"

const ProfileCards = () => {
    return (
        <div className='col-lg-7 column-dx-bg document-folder'>
            <div className="row g-4">
                <div className="col-md-6">
                    <div className="row g-4">
                        <div className="col-12 wow fadeIn custom-box" data-wow-delay="0.1s">
                            <div className="function-card service-item d-flex flex-column justify-content-center text-center rounded">
                                <div className="top-card">
                                    <div className="div-top-card">
                                        <img alt='robot' className="document-screen-icon" src="/huge-icon/QA/Robot.svg"></img>
                                        <div className="sticker">
                                            <h4 className="Desktop__H4">{labels.getText()["USER_SETTINGS"]()}</h4>
                                        </div>
                                    </div>
                                </div>
                                <p className="stretch Text500__5">{labels.getText()["USER_SETTINGS_DESC"]()}</p>
                                <a href={`/user_profile/`}>
                                    <button className='then-small-link'>{labels.getText()["ENTER_BUTTON"]()}</button>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 wow fadeIn custom-box" data-wow-delay="0.1s">
                            <div className="function-card service-item d-flex flex-column justify-content-center text-center rounded">
                                <div className="top-card">
                                    <div className="div-top-card">
                                        <img alt='payment icon' className="document-screen-icon" src={paymentsIcon}></img>
                                        <div className="sticker">
                                            <h4 className="Desktop__H4">{labels.getText()["PAYMENT_DETAILS"]()}</h4>
                                        </div>
                                    </div>
                                </div>
                                <p className="stretch Text500__5">{labels.getText()["PAYMENT_DETAILS_DESCRIPTION"]()}</p>
                                <a href={`/user_payments`}>
                                    <button className='then-small-link'>{labels.getText()["ENTER_BUTTON"]()}</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 pt-md-4" id="document-service-second-screen">
                    <div className="row g-4">
                        <div className="col-12 wow fadeIn custom-box" data-wow-delay="0.3s">
                            <div className="function-card service-item d-flex flex-column justify-content-center text-center rounded">
                                <div className="top-card">
                                    <div className="div-top-card">
                                        <img alt='subscription icon' className="document-screen-icon" src={subIcon}></img>
                                        <div className="sticker">
                                            <h4 className="Desktop__H4">{labels.getText()["SUBSCRIPTION_DETAILS"]()}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className="stretch Text500__5">{labels.getText()["SUBSCRIPTION_DETAILS_DESCRIPTION"]()}</p>
                                </div>
                                <a href={`/user_subscription`}>
                                    <button className='then-small-link'>{labels.getText()["ENTER_BUTTON"]()}</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileCards;