import { React, useState, useEffect, useContext, useRef } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as labels from "../components/localized/labels";
import * as api from '../api/apiConnector';
import UserContext from '../components/User_Context';
const chunkSize = 1024 * 1024 * 1;
import { getBreadcrumb, SetMenu } from '../components/Navigation';

const YouTube_Screen = (params) => {
    let { user } = useContext(UserContext);
    user = user ? JSON.parse(user) : null;
    let system_message_timer = null;

    useEffect(() => {
        _startChat();
    }, []);



    //#region upload

    //#endregion

    //#region chat
    const show_system_message = (params) => {
        const { message, timeOut = 2000 } = params;

        const inner_div = window.$(`<div class="col-xl-12">
            <div class="card dz-card">
                <div class="card-header flex-wrap border-0">
                    <div>
                        <h4 class="card-title">ANNA CHATBOT</h4>
                    </div>											
                </div>
                <div class="tab-content">
                    <div class="tab-pane fade active show" role="tabpanel">
                        <div class="card-body pt-0">
                            <h6><span></span>
                                <span class="pull-end"></span>
                            </h6>                                                                        
                        </div>
                    </div>
                </div>
    
            </div>
        </div>`);

        let div = window.$("#chat-system-message");

        if (div.length == 0) {
            div = window.$(`<div id="chat-system-message" style="position: fixed; right: 20px; bottom: 30px; width: 350px; height: 100px; opacity: 0.75"></div>`);
            window.$("body").append(div);
            div.append(inner_div);
        }

        const message_div = div.find(".pull-end");


        message_div.html("");
        message_div.append(window.$("<div/>").html(message));

        if (system_message_timer) {
            clearTimeout(system_message_timer);
        }

        if (timeOut > 0) {
            system_message_timer = setTimeout(() => {
                div.remove();
            }, timeOut);
        }
    }

    const _parseMessage = (message) => {
        switch (message.type) {
            case "video_info": {
                show_system_message({
                    timeOut: 0,
                    message: `
                        <strong>${message.e.videoDetails.title}</strong>...
                        `});
            }
                break;
            case "video_info": {
                show_system_message({
                    timeOut: 0,
                    message: `
                            <strong>${message.downloaded} / ${message.total}</strong>...
                            `});
            }
                break;
            case "create_document_done": {
                window.document.location.href = `/${labels.getLanguage()}/document/${message.document._id}`;
            }
                break;
            case "wellcome": {
                window.client.id = message.id;
                _sendMessage({
                    type: "authenticate",
                    token: user.token
                });
                show_system_message({
                    timeOut: 0,
                    message: `
                        <strong>${labels.getText()["CONNECTING_CHAT_AI_ENGINE"]()}</strong>...
                        `});
            }
                break;
            case "authenticated": {
                window.$("#question-area").prop("hidden", null);

                show_system_message({
                    message: `
                        <strong>${labels.getText()["CONNECTED_CHAT_AI_ENGINE"]()}</strong>...
                        `});


            }
                break;
        }
    }

    const _sendMessage = async (message) => {
        if (window.client?.id) {
            message.id = window.client.id;
        }

        var result = await api.sendMessage("youtube/sendChatMessage", {
            user: user,
            body: {
                message
            }
        });
    }

    const _startChat = async () => {
        if (!window.client) {
            const _client = new window.EventSource(`${process.env.REACT_APP_API_URL}/youtube/startChat`);
            _client.onmessage = (e) => {
                _parseMessage(JSON.parse(e.data));
            }

            window.client = {
                emitter: _client,
                filesCounter: 0
            }

            return _client;
        }
    }
    //#endregion



    const _urlZone = () => {
        return (<><div className="wow fadeIn library-drop-zone drop-zone drag-zone container" data-wow-delay="0.1s"
            style={{
                marginTop: 20,
                height: 400,
                width: "100%",
                cursor: "pointer"
            }}
        >
            <div style={{
                position: "absolute",
                zIndex: 200,

            }}>
                <p>Insert here the url or the code of the video you want to use</p>
                <input id="youtube-url" type="text" className="form-control"></input>
                <div style={{ textAlign: "center" }}>
                    <button type="submit" className="then-small-button" onClick={async (e) => {
                        await _sendMessage({
                            type: "create_document",
                            url: window.$("#youtube-url").val()
                        });
                    }}>OK</button>
                </div>
            </div>

        </div>


        </>
        );
    }

    return (<>
        {SetMenu({ user })}
        {_urlZone()}
    </>);
}

export default YouTube_Screen;