import '../App.css';
import { useContext } from "react";
import UserContext from '../components/User_Context';
import React, { useState, useRef } from 'react';
import * as labels from "../components/localized/labels";
import { SetMenu, } from '../components/Navigation';

function Privacy_Policy_Screen() {
    const { user } = useContext(UserContext);

    return (

        <div>
            {SetMenu({ user: user })}
            {labels.getText()["PRIVACY_POLICY_TEXT"]()};
        </div>
    );


}

export default Privacy_Policy_Screen;